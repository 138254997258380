import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap"
import styled from 'styled-components'//styled-component
import CardContainer from './CardContainer'
import './style.css'
import { useSelector } from "react-redux";
import { selectUsers } from "../../redux/users_staff";
import { getUserDataFromLCStorage } from '../../helpers/user'
import { usersByBranch } from "../../api/users";

export default function CustomerManagement() {
  const usersData = useSelector(selectUsers);
  const [user, setUser] = useState([])
  const { branch_id } = getUserDataFromLCStorage()
  React.useEffect(() => {
    usersByBranch(branch_id).then(e => {
      setUser(e.data)
    })
  }, [])
  return (
    <>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item active href="#"> ຈັດການພະນັກງານ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {[
        {
          header: "ຈັດການພະນັກງານ",
          title: "ລູກຄ້າເຄື່ອງສົ່ງ",
          data: [
            ...usersData?.data?.map((e) => ({
              id: `${e?.first_name} ${e?.last_name}`,
              username: `${e?.username || "-"}`,
              name_lastname: `${e?.role || "-"}`,
              right: `${e?.role || "-"}`,
              tellNumber: `${e?.phone || "-"}`,
              email: `${e?.email || "-"}`,
              createDate: `${e?.created_at || "-"}`,
            })),
          ],
        },
      ].map((result, index) => (
        <CardContainer
          key={index}
          DataCustomers={user}
          Header={Header}
          Title={result.header}
        />
      ))}
    </>
  );
}

const RootStyle = styled.div`
  padding: "20px";
`;

//header on table can add or delete it
const Header = [
  { title: "#" },
  { title: "ຊື່ ແລະ ນາມສະກຸນ" },
  { title: "ສິດທິນຳໃຊ້ລະບົບ" },
  { title: "ອີເມວ" },
  { title: "ເບີໂທລະສັບ" },
  { title: "ວັນທີສ້າງ" },
];

const DataCustomer = [
  {
    header: "ຈັດການພະນັກງານ",
    title: "ລູກຄ້າເຄື່ອງສົ່ງ",
    data: [
      {
        id: 1,
        name_lastname: "ມາລີນ້າ ເພັດຊົມພູ",
        right: "admin",
        tellNumber: "02094148194",
        email: "email@gmail.com",
        createDate: "22/22/2222",
      },
    ],
  },
];
