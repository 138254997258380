import {Card,Button} from 'react-bootstrap'


export default function ({msg="ກະລຸນາລອງໃໝ່ອີກຄັ້ງ",confirm}){
    return (
        <>
            <Card  style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',textAlign:'end',padding:'20px'}} body>
                <h2>{msg}</h2>
                <Button variant='danger' onClick={confirm}>ຕົກລົງ</Button>
            </Card>
        </>
    )
}