import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';

/**
 *
 * @Library
 *
 */

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, InputGroup, Row, Table, Form, Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';

/**
 *
 * @API
 *
 */
import { SERVER_URL } from '../../api'
import { getAccessTokkenFromLCS } from "../../helpers/user";
import axios from "axios";
import consts from "../../consts";
import { formatCurrencyGold } from "../../helpers";

const ProductGoldOld = () => {

    const history = useHistory();
    const parsed = queryString.parse(History?.location?.search);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const printRef = React.useRef();

    const [oldGold, setOldGold] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectStatus, setSelectStatus] = useState('');
    const [goldName, setGoldName] = useState(parsed?.name ? parsed?.name : '');

    useEffect(() => {
        getOldGold();
    }, [])
    useEffect(() => {
        getOldGold();
    }, [selectStatus, goldName])

    const getOldGold = async () => {
        let _token =  getAccessTokkenFromLCS();
        try {
            console.log(selectStatus);
            setIsLoading(true);
            await axios.get(`${SERVER_URL}/stock-service/api/v1/take-off?gold_status=${selectStatus}&name=${goldName}`,
                {
                    headers: {
                        Authorization: _token
                    }
                })
                .then((res) => {
                    console.log("========",res.data.data);
                    setOldGold(res?.data)
                    setIsLoading(false)
                })
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    }

    const showDetail = async (id) => {
        try {
            history.push(`${consts.STOCK_MANAGEMENT}/gold-detail/${id}`)
        } catch (error) {
            console.log(error);
        }
    }

    const handleDownloadPdf = async () => {

        const element = printRef.current;
        const canvas = await html2canvas(element, {
            logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
        }).then(canvas => {
            const imgWidth = 208;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;
            heightLeft -= pageHeight;
            const doc = new jsPDF('p', 'mm');
            doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                heightLeft -= pageHeight;
            }
            const date = new Date();
            doc.save('Downld.pdf' + date + '.pdf');
        }
        )
    };


    return (
        <div>
            <Card className='mx-4' body>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mt-2">
                        ປະຫັວດຄຳເກົ່າທັງໝົດ
                    </h4>
                </div>
            </Card>
            <Card
                style={{ padding: "20px", paddingTop: "10px", border: "none" }} body>
                <Row>
                    <Col md={6}>
                        <Form.Label>ຄົ້ນຫາຊື່ສິນຄ້າ</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={goldName}
                                onChange={(e)=>{setGoldName(e.target.value)}}
                                placeholder="ຄົ້ນຫາຊື່ສິນຄ້າ"
                                aria-label="ຄົ້ນຫາຊື່ສິນຄ້າ"
                                aria-describedby="basic-addon2"

                            />
                            <Button variant="outline-danger" id="button-addon2">
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>ສະຖານະ</Form.Label>
                            <Form.Select
                              value={selectStatus} onChange={e => { setSelectStatus(e.target.value) }} 
                            >
                                <option value="">ທັງໝົດ</option>
                                <option value="DAMAGED_GOLD">ຄຳເພ</option>
                                <option value="OLD_GOLD">ຄຳເກົ່າ</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={2} className="d-flex justify-content-end" style={{ marginTop: 33 }}  >
                        <div>
                            <button
                                onClick={handleShow}
                                id="format-PDF"
                                className='buttonRedColor-export'>  Export </button>
                        </div>
                    </Col>
                </Row>
               
                <Table className="table-hover">
                    <thead>
                        <tr>
                            <th>ຮູບພາບ</th>
                            <th>ຊື່</th>
                            <th>ນ້ຳໜັກ</th>
                            <th>ລວດລາຍ</th>
                            <th>ຈຳນວນ</th>
                            <th>ສະຖານະ</th>
                            <th>ຈາກຕູ້</th>
                            <th>ເຄົາເຕີ</th>
                            <th>ຜູ້ດຳເນິດ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isLoading ? (
                                <tr>
                                    <td colSpan={9}>
                                        <div className="loading-card">
                                            <div>
                                                <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} />
                                                <span>Loading....</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) :
                                <>
                                    {oldGold?.data?.map((item) => {
                                        return <tr onClick={e => { showDetail(item.id) }}>
                                            <td><img src={consts.S3 + item?.image?.[0] ?? '/asset/image/image-2-64.ico'} alt="" style={{ width: 80 }} /></td>
                                            <td>{item?.name}</td>
                                            <td>{formatCurrencyGold(item?.weight[0]?.gram) || '_'} g</td>
                                            <td>{item?.branch_product?.pattern_name || "-"}</td>
                                            <td>{item?.amount_take_off}</td>
                                            <td>{item?.gold_status == 'DAMAGED_GOLD' ? 'ຄຳເພ' : 'ຄຳເກົ່າ'}</td>
                                            <td>{ item?.from_cabinet_name || '-' }</td>
                                            <td>{ item?.from_branch_name  || "-"}</td>
                                            <td>{item?.created_by_full_name}</td>
                                        </tr>
                                    })
                                    }
                                </>
                        }

                        <tr className="fw-bold">
                            <td colSpan={3}>ລວມ</td>
                            {/* <td>{ oldGold?.data?.reduce((sum, data)=> sum+ data?.weight[0]?.gram, 0)}</td> */}
                            <td></td>
                            <td>{ oldGold?.data?.reduce((sum, data)=> sum+ data?.amount_take_off, 0) }</td>
                            <td colSpan={4}></td>
                        </tr>
                    </tbody>
                </Table>
            </Card>
            <Modal show={show} onHide={handleClose} size="xl">

                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className='buttonRedColor'
                            style={{ width: "125px", height: "51px" }} onClick={handleDownloadPdf}>
                            Export PDF
                        </Button>
                    </div>
                    <div className="" ref={printRef}>
                        <Table className="table-hover">
                            <thead>
                                <tr>
                                    <th>ຮູບພາບ</th>
                                    <th>ຊື່</th>
                                    <th>ນ້ຳໜັກ</th>
                                    <th>ລວດລາຍ</th>
                                    <th>ຈຳນວນ</th>
                                    <th>ສະຖານະ</th>
                                    <th>ຈາກຕູ້</th>
                                    <th>ເຄົາເຕີ</th>
                                    <th>ຜູ້ດຳເນິດ</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    isLoading ? (
                                        <tr>
                                            <td colSpan={9}>
                                                <div className="loading-card">
                                                    <div>
                                                        <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
                                                        <span>Loading....</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ) :
                                        <>
                                            {oldGold?.data?.map((item) => {
                                        return <tr onClick={e => { showDetail(item.id) }}>
                                            <td><img src={consts.S3 + item?.image?.[0] ?? '/asset/image/image-2-64.ico'} alt="" style={{ width: 80 }} /></td>
                                            <td>{item?.name}</td>
                                            <td>{item?.weight[0]?.gram || '_'} g</td>
                                            <td>{item?.branch_product?.pattern_name || "-"}</td>
                                            <td>{item?.amount}</td>
                                            <td>{item?.gold_status == 'DAMAGED_GOLD' ? 'ຄຳເພ' : 'ຄຳເກົ່າ'}</td>
                                            <td>{ item?.from_cabinet_name || '-' }</td>
                                            <td>{ item?.from_branch_name  || "-"}</td>
                                            <td>{item?.created_by_full_name}</td>
                                        </tr>
                                    })
                                    }
                                        </>
                                }

                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    )
}

export default ProductGoldOld