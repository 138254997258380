const MAIN_BODY = {
  paddingTop: 60,
  fontSize: "14px",
};

const MAIN_CARD = {
  backgroundColor: "#ffffff",
  minHeight: "100vh",
  marginLeft: 15,
  marginRight: 15,
  padding: 30,
};

module.exports = {
  FRIST_COLOR: "#00A991",
  SECONDARY_COLOR: "#3F4952",
  THIRD_COLOR: "#CBD5E1",
  MAIN_BODY,
  MAIN_CARD,
  // color
  PRIMARY_COLOR: "#960000",
  Fill_COLOR: "#F6F6F7",
  TEXT_PRIMARY_COLOR: "#5C5C5C",
  CUSTOMERS_TYPE: ["RETAIL", "AOMKHAM", "NORMAL", "VIP"],
  CUSTOMERS_TYPE_LAOS: [
    "ລູກຄ້າເຄື່ອງສົ່ງ",
    "ລູກຄ້າອອມຄຳ",
    "ລູກຄ້າທົ່ວໄປ",
    "ລູກຄ້າ VIP",
  ],
  COUNTER_TYPES: ["GET_MONEY", "PAY_MONEY"],
  CABINET_TYPES: ["NORMAL", "SPECIAL"],

  VERSION: "v0.1.0",

  ROLE: ["SELLER", "CASHIER"],

  S3: "https://kpv-bucket.s3.ap-southeast-1.amazonaws.com/",
  DATE_FORMAT: "MM/DD/YYYY",

  USER_KEY: "user",
  PAGE_LOGIN: "/login",

  PAGE_REPORT: "/report",

  CUSTOMER_MANAGEMENT: "/customer-management",
  CUSTOMER_MANAGEMENT_DETAIL: "/customer-management-detail",
  CUSTOMER_MANAGEMENT_ADD: "/customer-management-add",
  CUSTOMER_MANAGEMENT_EDIT: "/customer-management-edit",

  STAFF_MANAGEMENT: "/staff-management",
  STAFF_MANAGEMENT_DETAIL: "/staff-management-detail",

  AOMKHAM: "/aomkham",
  BANSI: "/bansi",

  SALE_MANAGEMENT: "/sale-management",
  SALE_MANAGEMENT_DETAIL: "/sale_management_detail",

  COUNTER_MANAGEMENT: "/counter-management",

  CABINAGE_MANAGEMENT: "/cabinage-management",

  CHANGE_FREE_MANAGEMENT: "/change-free-management",
  CHANGE_FREE_MANAGEMENT_DETAIL: "/change_free_management_detail", //

  COUTER_MANAGEMENT: "/couter-management",

  PRODUCT_MANAGEMENT: "/product-management",

  STOCK_MANAGEMENT: "/stock-management",
  TOTAL_STOCK_MANAGEMENT: "/total_stock-management",
  // ======> history buy and order <=======
  HISTORY_BUY: "/history-buy",
  HISTORY_BUY_SUCCESS: "/history-buy/success",
  HISTORY_BUY_REJECT: "/history-buy/reject",
  HISTORY_BUY_DETAIL: "/history-buy/detail",
  HISTORY_BUY_WAITTING: "/history-buy/waitting",
  HISTORY_BUY_APPOVER: "/history-buy/approver",

  HISTORY_ORDER: "/history-order",
  HISTORY_ORDER_WAITTING: "/history-order/waitting",
  HISTORY_ORDER_SUCCESS: "/history-order/success",
  HISTORY_ORDER_APPOVER: "/history-order/approver",
  HISTORY_ORDER_REJECT: "/history-order/reject",
  HISTORY_ORDER_DETAIL: "/history-order/detail",

  HISTORY_BUY_GOLD_BARS: "/history-buy-gold-bars",
  HISTORY_BUY_GOLD_BARS_APPROVE: "/history-buy-gold-bars-approve",
  HISTORY_BUY_GOLD_BARS_SUCCESS: "/history-buy-gold-bars-success",
  HISTORY_BUY_GOLD_BARS_REJECT: "/history-buy-gold-bars-reject",
  HISTORY_BUY_GOLD_BARS_DETAIL: "/history-buy-gold-bars-detail",
  HISTORY_BUY_GOLD_BARS_WAITTING: "/history-buy-gold-bars-waitting",
  // EXPENSE=======>
  EXPENSE: "/expense",
  EXPENSE_HISTORY: "/expense/history",

    //Document
    DOCUMENT_LIST: "/document-list",
  
};
