import { createSlice } from "@reduxjs/toolkit";
// api
import {users} from '../../api/users'

// ---------------------------------------------------
export const usersStaffData = createSlice({
  name: "users_staff",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    setUsers: (state, action) => {
      state.data = [...action.payload];
    },
    addUsers: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    removeUsers: (state, action) => {
      state.data = [
        ...state.data
          .map(JSON.stringify)
          .filter((e) => e !== JSON.stringify(action.payload)),
      ].map(JSON.parse); // ລົບ
    },
    editUsers: (state, action) => {
      state.data = [
        ...state.data.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      ];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addUsers, removeUsers, editUsers, setUsers } =
  usersStaffData.actions;

export const loadUsersAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const data = await users();
    dispatch(setUsers(data));
  } catch {
    console.error("load Users");
  }
  dispatch(setLoading(false));
};

export const selectUsers = (state) => state.users_staff;

export default usersStaffData.reducer;
