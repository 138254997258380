import { createSlice } from "@reduxjs/toolkit";
// api
import { customer } from "../../api/users";

// ---------------------------------------------------
export const customerData = createSlice({
  name: "users_customer",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    setCustomer: (state, action) => {
      state.data = [...action.payload];
    },
    addCustomer: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    removeCustomer: (state, action) => {
      state.data = [
        ...state.data
          .map(JSON.stringify)
          .filter((e) => e !== JSON.stringify(action.payload)),
      ].map(JSON.parse); // ລົບ
    },
    editCustomer: (state, action) => {
      state.data = [
        ...state.data.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      ];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addCustomer, removeCustomer, editCustomer, setCustomer } = customerData.actions;

export const loadCustomerAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const data = await customer();
    dispatch(setCustomer(data));
  } catch {
    console.error("load customer");
  }
  dispatch(setLoading(false));
};

export const selectCustomer = (state) => state.users_customer;

export default customerData.reducer;
