import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { GetDataOrder } from "../../../api/historyBuyAndOrder/index";
import { convertDate, formatCurrency } from "../../../helpers";
import moment from "moment";

// GetDataOrder
export default function HistoryBuyDetail() {
  const History = useHistory();
  const macth = useRouteMatch();

  const [data, setData] = useState({});

  useEffect(() => {
    _getDataOrder();
  }, []);

  const _getDataOrder = async () => {
    const _resOrder = await GetDataOrder(macth?.params?.id);
    console.log('_resOrder: ', _resOrder);
    if (_resOrder) setData(_resOrder);
  };
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p
          style={{ padding: 10, cursor: "pointer" }}
          onClick={() => History.goBack()}
        >
          ລາຍການສັ່ງຊື້ຄໍາ
        </p>
        <p style={{ padding: 10 }}>{" > "}</p>
        <p style={{ padding: 10, color: "#2E72D2" }}>ລາຍລະອຽດ</p>
      </div>
      <div style={{ height: 10 }}></div>
      <Card
        style={{
          padding: "20px",
          paddingTop: "10px",
          border: "none",
        }}
        body
      >
        <div style={{ backgroundColor: "#E9E9E9" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 20,
            }}
          >
            <div>ລະຫັດໃບບິນ: {data?.bill_code}</div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 50 }}>
                {/* ລາຄາຄຳປະຈຳວັນ: 2,000,000 LAK */}
              </div>
              <div>ວັນທີ່ {moment(data?.created_at).format("DD-MM-YYYY")}</div>
            </div>
          </div>
        </div>
        <div style={{ height: 20 }}></div>
        <Card
          style={{
            padding: "20px",
            paddingTop: "10px",
            boxShadow: "1px 0.2px 1px 1px #888888",
          }}
        >
          <table class="table">
            <tr style={{ textAlign: "center" }}>
              <th className="col-3">ລະຫັດສິນຄ້າ</th>
              <th className="col-1">ຮູບ</th>
              <th className="col-1">ຊື່ສິນຄ້າ</th>
              <th className="col-1">ນ້ຳໜັກ</th>
              <th className="col-1">ລາຍ</th>
              <th className="col-1">ລາຄາລາຍ</th>
              <th className="col-1">ລາຄາຄຳ</th>
              <th className="col-1">ຈຳນວນ</th>
              <th className="col-1">ລາຄາລວມລາຍ</th>
              <th className="col-1">ລາຄາລວມສິນຄ້າ</th>
            </tr>
            <tbody>
              <tr style={{ textAlign: "center" }}>
                <td>{data?.product_code ?? "-"}</td>
                <td>
                  <img
                    src={
                      "https://kpv-bucket.s3.ap-southeast-1.amazonaws.com/" +
                      data?.product?.image[0]
                    }
                    alt="Girl in a jacket"
                    width="50"
                    height="50"
                    style={{ borderRadius: 8 }}
                  />
                </td>
                <td>{data?.name ?? "-"}</td>
                <td>{data?.weight ?? "-"}</td>
                <td>{data?.pattern?.name}</td>
                <td>{formatCurrency(data?.pattern_price)}</td>
                <td>{formatCurrency(data?.product_price)}</td>
                <td>{formatCurrency(data?.amount)}</td>
                <td>{formatCurrency(data?.amount * data?.pattern_price)}</td>
                <td>{formatCurrency(data?.total_price)}</td>
              </tr>
            </tbody>
          </table>
        </Card>
        <div style={{ height: 30 }}></div>
        <Card style={{ boxShadow: "1px 0.2px 1px 1px #888888" }}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 30,
              }}
            >
              <div>ຈ່ານຜ່ານທະນາຄານ</div>
              <div>{data?.payment_bank}</div>
            </div>
          </div>
        </Card>
        <div style={{ height: 20 }}></div>
        <div>
          <div>
            ວັນທີ່ຮັບສີນຄ້າ :{" "}
            {moment(data?.picked_up_date).format("DD-MM-YYYY")}
          </div>
          <div>ຄອມເມັ້ນ: {data?.kpv_comment}</div>
          <div>ຮູບພາບ: </div>
          <div style={{height:10}}></div>
          {data?.kpv_image?.map((item, index) => (
            <img
              src={
                "https://kpv-bucket.s3.ap-southeast-1.amazonaws.com/" +
                item
              }
              alt="Girl in a jacket"
              width="100"
              height="100"
              style={{ borderRadius: 8 }}
            />
          ))}
        </div>
      </Card>
    </div>
  );
}
