import axios from "axios";
import { SERVER_URL } from "..";
import { getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

const order = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(`${SERVER_URL}/order-service/api/v1/order`,
    {
      headers: {
        Authorization: _tokken,
      },
    });
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,order());
    }
  }
};

const orderOne = async (id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/order-service/api/v1/order/${id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,orderOne());
    }
  }
};

const orderProduct = async (id) => {
  let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/order-service/api/v1/order-product`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,orderProduct());
    }
  }
};

const addOrder = async (values) => {
  let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/order-service/api/v1/order`,
      values,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,addOrder());
    }
  }
};

const updateOrder = async (_id, value) => {
  let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.put(
      `${SERVER_URL}/order-service/api/v1/order/${_id}`,
      value,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,updateOrder());
    }
  }
};

// const deleteOrder = async (_id) => {
//   try {
//     const data = await axios.delete(
//       `${SERVER_URL}/user-service/api/v1/user/${_id}`
//     );
//     if (data.status >= 300) {
//       return false;
//     }
//     return data?.data;
//   } catch (err) {
//     console.log("err");
//     return false;
//   }
// };

export { order, addOrder, orderOne, updateOrder,orderProduct };
