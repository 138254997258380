import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import { Card, Col, Form, Row, Button, Breadcrumb } from "react-bootstrap";
import  {PatternFormat} from 'react-number-format';
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { SERVER_URL } from "../../api";
import { successAdd, errorAdd, warring } from "../../helpers/sweetalert";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";

export default function Expense() {
  const [dataCategory, setDataCategory] = useState();
  const [dataExpense, setDataExpense] = useState();
  //   ========> get value ====>
  const history = useHistory();
  const [dataLCStorage, setDataLCStorage] = useState(
    getUserDataFromLCStorage()
  );
  const [dataCategoryValue, setDataCategoryValue] = useState();
  const [dataExpenseValue, setDataExpenseValue] = useState({});
  const [dataAmount, setDataAmount] = useState(0);
  const [dataCurrency, setDataCurrency] = useState("");
  const [dataTransfer, setDataTransfer] = useState(false);
  const [dataNote, setDataNote] = useState("");

  useEffect(() => {
    _getCategory();
  }, []);

  const _getCategory = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      let _dataCategory = await axios({
        method: "GET",
        url: SERVER_URL + "/expenditure-service/api/v1/category/",
        headers: {
          Authorization: _token,
        },
      });
      if (_dataCategory?.status === 200)
        setDataCategory(_dataCategory?.data?.data);
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, _getCategory());
      }
    }
  };

  const _getExpense = async (item) => {
    let _tokken = getAccessTokkenFromLCS();
    setDataCategoryValue();
    try {
      let _filterDataCategory = dataCategory.filter(
        (data, inde) => data?.id === item
      );
      if (_filterDataCategory?.length > 0) {
        setDataCategoryValue(_filterDataCategory[0]);
      }
      let _dataExpense = await axios({
        method: "GET",
        url:
          SERVER_URL +
          "/expenditure-service/api/v1/expense/?category_id=" +
          item,
        headers: {
          Authorization: _tokken,
        },
      });
      if (_dataExpense?.status === 200)
        setDataExpense(_dataExpense?.data?.data);
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, _getExpense());
      }
    }
  };

  const _createExpense = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      if (!dataCategoryValue?.id) {
        warring("ກະລຸນາເລືອກປະເພດລາຍຈ່າຍ");
        return;
      }
      if (!dataCurrency) {
        warring("ກະລຸນາເລືອກສະກຸນເງິນ");
        return;
      }
      let _data = {
        category_id: dataCategoryValue?.id,
        category_name: dataCategoryValue?.name,
        expense_id: dataExpenseValue?.id,
        expense_name: dataExpenseValue?.name,
        branch_id: getUserDataFromLCStorage().branch_id,
        amount_paid: parseFloat(dataAmount),
        currency: dataCurrency,
        transfer: !dataTransfer ? "NO" : "YES",
        note: dataNote,
        is_deleted: "NO",
        created_by: dataLCStorage?.first_name,
      };
      let _create = await axios({
        method: "post",
        url: SERVER_URL + "/expenditure-service/api/v1/expenditure",
        data: _data,
        headers: {
          Authorization: _token,
        },
      });
      if (_create?.status === 200) {
        setDataCategoryValue({});
        setDataExpenseValue({});
        setDataAmount(0);
        setDataCurrency("");
        setDataTransfer(false);
        setDataNote("");
        successAdd("ບັນທືກລາຍຈ່າຍສຳເລັດ");
        history.push('/expense/history/limit/40/skip/1')
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, _createExpense());
      }
      errorAdd(err);
    }
  };

  let _filterDataExpected = (id) => {
    let _filterDataCategory = dataExpense.filter(
      (data, inde) => data?.id === id
    );
    if (_filterDataCategory?.length > 0) {
      setDataExpenseValue(_filterDataCategory[0]);
    }
  };

  return (
    <RootStyle>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item active href="#"> ບັນທຶກລາຍຈ່າຍ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <NavBar />
      <Card
        style={{
          padding: "20px",
          border: "none",
          paddingBottom: "100px",
        }}
        body
      >
        <h3>ບັນທຶກລາຍຈ່າຍ</h3>
        <div style={{ height: 20 }}></div>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>ປະເພດລາຍຈ່າຍ</Form.Label>
            <Form.Select
              value={dataCategoryValue?.id ? dataCategoryValue?.id : ""}
              onChange={(e) => _getExpense(e?.target?.value)}
            >
              <option value="" selected disabled>
                ກະລຸນາເລືອກ
              </option>
              {dataCategory?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>ລາຍຈ່າຍ</Form.Label>
            <Form.Select
              value={dataExpenseValue?.id ? dataExpenseValue?.id : ""}
              onChange={(e) => _filterDataExpected(e?.target?.value)}
            >
              <option value="" selected disabled>
                ກະລຸນາເລືອກ
              </option>
              {dataExpense?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Row>
          <Col sm={4}>
            <Form.Group controlId="doj">
              <Form.Label>ຈຳນວນເງິນທີ່ຈ່າຍ</Form.Label>
              <Form.Control type="number" onChange={(e) => setDataAmount(e.target.value)} />
              {/* <PatternFormat className="form-control"  thousandSeparator={true} prefix={""}
              />*/} 
            
            </Form.Group>
          </Col>
          <Col sm={2}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ສະກຸນເງິນ</Form.Label>
              <Form.Select
                value={dataCurrency}
                onChange={(e) => setDataCurrency(e?.target?.value)}
              >
                <option value="" selected disabled>
                  ກະລຸນາເລືອກ
                </option>
                <option value="LAK">LAK</option>
                <option value="USD">USD</option>
                <option value="THB">THB</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            checked={dataTransfer ? true : false}
            onChange={(e) => setDataTransfer(dataTransfer ? false : true)}
          />
          <label className="form-check-label" for="exampleCheck1">
            ໂອນ
          </label>
        </div>
        <div style={{ height: 20 }}></div>
        <Col sm={6}>
          <div className="form-group">
            <Form.Label>ລາຍລະອຽດເພີ່ມເຕີມ</Form.Label>
            <textarea
              value={dataNote}
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={(e) => setDataNote(e.target.value)}
            ></textarea>
          </div>
        </Col>
        <div style={{ height: 20 }}></div>
        {/* <Col md={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>ຜູ້ອະນຸມັດ</Form.Label>
            <Form.Select>
              <option value="" selected disabled>
                ກະລຸນາເລືອກ
              </option>
              <option value="ACTIVE">ເປີດໃຊ້ງານ</option>
              <option value="INACTIVE">ປີດໃຊ້ງານ</option>
            </Form.Select>
          </Form.Group>
        </Col> */}
        <div style={{ height: 10 }}></div>
        <div style={{ textAlign: "right" }}>
          <Button
            type="button"
            className="col-3"
            style={{
              backgroundColor: "#960000",
              border: "solid 0px",
              fontWeight: "bold",
            }}
            onClick={() => _createExpense()}
          >
            ບັນທຶກ
          </Button>
        </div>
      </Card>
    </RootStyle>
  );
}
const RootStyle = styled.div`
  padding: "20px";
`;
