import React, { useState, useEffect } from 'react';

import axios from "axios";
import ReactLoading from 'react-loading';


//api
import { SERVER_URL } from "../../../api";
import { getAccessTokkenFromLCS, getReNewToken } from "../../../helpers/user";
import consts from "../../../consts";
import { warring } from "../../../helpers/sweetalert";




import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';


export default function ViewReader({
    showReader,
    handleCloseReader,
    documentId,
    showTotalReader
}) {
    const [readerList, setReaderList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [totalReader, setTotalReader] = useState(0)

    // useEffect
    useEffect(() => {
        fetchDataDetail(documentId)
    }, [documentId])
    //query one

    const fetchDataDetail = async (id) => {
        setLoading(true)
        let _tokken = getAccessTokkenFromLCS()
        await axios.get(
            `${SERVER_URL}/website-service/api/v1/document-seen?id_document=${id}`,
            {
                headers: {
                    Authorization: _tokken,
                },
            }
        ).then(data => {
            setReaderList(data?.data?.data)
            setTotalReader(data?.data?.total)
            setLoading(false)
        }).catch(err => {
            if (err?.response?.data?.msg === "Token is expired") {
                getReNewToken(err, fetchDataDetail());
                return;
            }
            if (err?.response?.data?.msg === "UNAUTHORIZED") {
                warring('ທ່ານບໍ່ມີສິດເຂົ້າເຖິງ')
                handleCloseReader()
                return;
            }
        })
    }

    return (
        <div>
            <Modal show={showReader} onHide={handleCloseReader} size="lg" className='detail-doc'>
                <Modal.Body style={{ padding: "30px" }} >
                    <div className='detail-head'>
                        <p className='title'>
                            ລາຍຊື່ຜູ້ທີ່ອ່ານເເລ້ວ  {showTotalReader ?? '0/0'}
                        </p>
                        <p className='title' style={{ cursor: "pointer" }} onClick={handleCloseReader}><FaTimes /></p>
                    </div>
                    <hr></hr>
                    <div className='reader-box'>
                        {loading ?
                            // <div className="loading-card">
                            <div>
                                <ReactLoading type='spin' color="#960000" height={50} width={50} />
                                <span>Loading....</span>
                            </div>
                            // </div>
                            : totalReader === 0 ? <div>
                                <img style={{ marginLeft: "40%", marginTop: "10%" }} src='/image/animation_200_ldu129fc.gif' />
                                <p style={{ marginLeft: "45%", marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
                            </div> : readerList?.map((item, index) =>
                                <div className='reader-main' key={'view'+index}>
                                    <img src={`${consts.S3}${item?.user_image}`} />
                                    <div className='reader-info'>
                                        {item?.full_name ?? '-'}
                                        <span>{item?.role ?? '-'}</span>
                                    </div>
                                </div>
                            )}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
