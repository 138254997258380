import React, { useState, useEffect } from "react";

//library
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { FaEye, FaSearch, FaFileUpload } from "react-icons/fa";
import ReactLoading from "react-loading";
import axios from "axios";
import queryString from "query-string";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

//constant
import Consts from "../../consts";
import { formatDate, formatDateTime } from "../../helpers";
import { CHECK_POPUP } from "../../consts/user";

//component
import DetailDocument from "./component/DetailDocument";
import ViewReader from "./component/ViewReader";
import PaginationHelper from "../../helpers/PaginationHelper";
import NotiDocument from "./component/NotiDocument";

//api
import { SERVER_URL } from "../../api";
import {
  getAccessTokkenFromLCS,
  getReNewToken,
  getUserDataFromLCStorage,
} from "../../helpers/user";

//css
import {
  Card,
  Col,
  Form,
  Row,
  Table,
  Button,
  Breadcrumb,
  Modal,
} from "react-bootstrap";
import _ from "lodash";
import moment from "moment";

import { loadDocumentAsync, selectDocument } from "../../redux/document";

function Document() {
  const History = useHistory();
  const parsed = queryString.parse(History?.location?.search);
  const ducumentData = useSelector(selectDocument);
  const dispatch = useDispatch();

  const [showFormCreate, setShowFormCreate] = useState(false);
  const [showDetailDocument, setShowDetailDocument] = useState(false);
  //reader
  const [showReader, setShowReader] = useState(false);
  const [showTotalReader, setShowTotalReader] = useState();

  const [documentList, setDocumentList] = useState([]);
  const [documentTotal, setDocumentTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [documentId, setDocumentId] = useState();

  //noti
  const [isNoti, setIsNoti] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  //filter
  const [filter, setFilter] = useState({
    title: parsed?.title ? parsed?.title : "",
    // from_date: parsed?.from_date ? parsed?.from_date : '',
    // to_date: parsed?.to_date ? parsed?.to_date : '',
    from_date: formatDate(
      parsed?.from_date
        ? parsed?.from_date
        : moment(formatDate(new Date())).subtract(7, "days").toDate()
    ),
    to_date: parsed?.to_date ? parsed?.to_date : formatDate(new Date()),
  });

  // pagination
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  //query document
  const queryDocument = async () => {
    setLoading(true);
    let _tokken = getAccessTokkenFromLCS();
    try {
      let _filter = `is_delete=NO&valid_roles=${
        getUserDataFromLCStorage().role
      }${
        getUserDataFromLCStorage().role !== "ADMIN"
          ? `&department=${getUserDataFromLCStorage().department}`
          : ""
      }&limit=${_limit}&skip=${(_skip - 1) * _limit}`;
      if (filter.title !== "")
        _filter = `is_delete=NO&valid_roles=${getUserDataFromLCStorage().role}${
          getUserDataFromLCStorage().role !== "ADMIN"
            ? `&department=${getUserDataFromLCStorage().department}`
            : ""
        }&title=${filter.title}&limit=${_limit}&skip=${(_skip - 1) * _limit}`;
      if (filter.from_date !== "" && filter.to_date !== "")
        _filter = `is_delete=NO&valid_roles=${getUserDataFromLCStorage().role}${
          getUserDataFromLCStorage().role !== "ADMIN"
            ? `&department=${getUserDataFromLCStorage().department}`
            : ""
        }&limit=${_limit}&skip=${(_skip - 1) * _limit}&from_date=${
          filter.from_date
        }&to_date=${filter.to_date}`;
      if (
        filter.title !== "" &&
        filter.from_date !== "" &&
        filter.to_date !== ""
      )
        _filter = `is_delete=NO&valid_roles=${getUserDataFromLCStorage().role}${
          getUserDataFromLCStorage().role !== "ADMIN"
            ? `&department=${getUserDataFromLCStorage().department}`
            : ""
        }&limit=${_limit}&skip=${(_skip - 1) * _limit}&title=${
          filter.title
        }&from_date=${filter.from_date}&to_date=${filter.to_date}`;

      const data = await axios.get(
        `${SERVER_URL}/website-service/api/v1/document?${_filter}`,
        {
          headers: {
            Authorization: _tokken,
          },
        }
      );
      setLoading(false);
      if (data?.status === 200) {
        setDocumentList(data?.data?.data);
        setDocumentTotal(data?.data?.total);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, queryDocument());
      }
    }
  };

  //useEffect
  useEffect(() => {
    const getData = () => {
      queryDocument();
      // if (ducumentData.isLoading) {
      //   dispatch(loadDocumentAsync());
      // }
      // const checkStatusReadDocument = Cookies.get(CHECK_POPUP);

      // if (checkStatusReadDocument === undefined) {
      //   setIsOpen(true);
      //   return;
      // }
      // if (checkStatusReadDocument === "READ_LATER") {
      //   setIsNoti(false);
      //   return;
      // }
    };
    return getData();
  }, []);

  // useEffect(() => {
  //   //TODO: if document have more than one state is set true
  //   if (isOpen && ducumentData?.data.length > 0) {
  //     setIsNoti(true);
  //     return;
  //   }
  //   //TODO: if document have not more than one state is set false
  //   setIsNoti(false);
  // }, [ducumentData?.data, isOpen]);

  //useEffect for filter
  useEffect(() => {
    queryDocument();
  }, [filter, _limit, _skip]);

  // handleViewReader
  const handleViewReader = (event, item) => {
    event.stopPropagation();
    setShowReader(true);
    setDocumentId(item?.id);
    setShowTotalReader(`${item?.reader} / ${item?.total_reader}`);
  };

  const _showFileType = (file) => {
    let arr = file
      ?.slice(0, 3)
      .map((item) => `.${item?.file.split(".").pop()}`);
    if (arr?.length > 0) return _.uniq(arr);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: false,
    }).then((canvas) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF("p", "mm");
      doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save("Downld.pdf" + date + ".pdf");
    });
  };


  console.log("documentId:::",documentList)

  return (
    <div>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" active>
            ຈັດການເອກະສານ
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card style={{ padding: 20, minHeight: "85vh", paddingBottom: 100 }}>
        <div className="document-head">
          <p className="doc-head">ອັບໂຫຼດເອກະສານ </p>
          {getUserDataFromLCStorage().role !== "ADMIN" ? (
            ""
          ) : (
            <Button
              onClick={() => setShowFormCreate(!showFormCreate)}
              className="btn-head"
            >
              <FaFileUpload />
              &nbsp; ອັບໂຫຼດເອກະສານ
            </Button>
          )}
        </div>

        <Row>
          <Col md={3}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ຄົ້ນຫາ</Form.Label>

              <div className="group-icon-with-input">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="ຄົ້ນຫາຕາມຫົວຂໍ້ເອກະສານ"
                  value={filter?.title}
                  onChange={(e) =>
                    setFilter({ ...filter, title: e?.target?.value })
                  }
                  className="search-input"
                  style={{ outline: "none" }}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ວັນທີ</Form.Label>
              <Form.Control
                type="date"
                value={filter?.from_date}
                onChange={(e) =>
                  setFilter({ ...filter, from_date: e?.target?.value })
                }
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ຫາວັນທີ</Form.Label>
              <Form.Control
                type="date"
                value={filter?.to_date}
                onChange={(e) =>
                  setFilter({ ...filter, to_date: e?.target?.value })
                }
              />
            </Form.Group>
          </Col>
          <Col md={2} style={{ marginTop: 35 }}>
            <div>
              <button
                onClick={handleShow}
                id="format-PDF"
                className="buttonRedColor-export"
              >
                {" "}
                Export{" "}
              </button>
            </div>
          </Col>
        </Row>

        {loading ? (
          <div className="loading-card">
            <div>
              <ReactLoading
                className="loading-cus"
                type="spin"
                color="#960000"
                height={50}
                width={50}
              />
              <span>Loading....</span>
            </div>
          </div>
        ) : documentTotal === 0 ? (
          <div>
            <img
              style={{ marginLeft: "40%", marginTop: "10%" }}
              src="/asset/image/animation_200_ldu129fc.gif"
            />
            <p style={{ marginLeft: "45%", marginTop: -20, color: "grey" }}>
              ຍັງບໍ່ມີຂໍ້ມູນ!
            </p>
          </div>
        ) : (
          <Table className="table-hover" style={{ color: "#034060" }}>
            <thead>
              <tr>
                <th>#</th>
                <th>ຊື່ເອກະສານ</th>
                <th>ປະເພດ File</th>
                <th>ອັບໂຫຼດໂດຍ</th>
                <th>ວັນທີອັບໂຫຼດ</th>
                <th>ສະຖານະການອ່ານ</th>
              </tr>
            </thead>
            <tbody>
              {documentList?.map((item, index) => (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDetailDocument(!showDetailDocument);
                      setDocumentId(item?.id);
                  }}
                >
                  <td>{index + 1 + _limit * (_skip - 1)}</td>
                  <td>
                    <div className="limit-text">{item?.title}</div>
                  </td>
                  <td>
                    {/* {item?.files?.slice(0, 3).map((item) => `.${item?.file.split(".").pop()}`).join("/")}
                                            {item?.files?.length > 3 ? " ..." : ""} */}
                    {_showFileType(item?.files)}
                  </td>
                  <td>{item?.created_by_full_name}</td>
                  <td>{formatDateTime(item?.created_at)}</td>
                  <td>
                    ອ່ານແລ້ວ {item?.reader + `/` + item?.total_reader}
                    <FaEye
                      onClick={(event) => handleViewReader(event, item)}
                      style={{
                        fontSize: 16,
                        color: "#B0B0B0",
                        cursor: "pointer",
                        marginLeft: 12,
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {documentTotal > _limit ? (
          Pagination_helper(
            documentTotal,
            Consts.DOCUMENT_LIST,
            `title=${filter.title}&from_date=${filter.from_date}&to_date=${filter.to_date}`
          )
        ) : (
          <></>
        )}

        {/* CreateDocument */}
        <DetailDocument
          handleShowDetailDocument={() => setShowDetailDocument(true)}
          showDetailDocument={showDetailDocument}
          handleClose={() => {
            setShowDetailDocument(false);
            setDocumentId();
          }}
          handleCloseEdit={() => setShowDetailDocument(false)}
          documentId={documentId}
        />

        <ViewReader
          showReader={showReader}
          documentId={documentId}
          handleCloseReader={() => {
            setShowReader(false);
            setDocumentId();
            setShowTotalReader();
          }}
          showTotalReader={showTotalReader}
        />

        {/* <Success open={popSuccess} /> */}
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              className="buttonRedColor"
              style={{ width: "125px", height: "51px" }}
              onClick={handleDownloadPdf}
            >
              Export PDF
            </Button>
          </div>
          <div className="" ref={printRef}>
            <Table className="table-hover" style={{ color: "#034060" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ຊື່ເອກະສານ</th>
                  <th>ປະເພດ File</th>
                  <th>ອັບໂຫຼດໂດຍ</th>
                  <th>ວັນທີອັບໂຫຼດ</th>
                  <th>ສະຖານະການອ່ານ</th>
                </tr>
              </thead>
              <tbody>
                {documentList?.map((item, index) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowDetailDocument(!showDetailDocument);
                      setDocumentId(item?.id);
                    }}
                  >
                    <td>{index + 1 + _limit * (_skip - 1)}</td>
                    <td>
                      <div className="limit-text">{item?.title}</div>
                    </td>
                    <td>
                      {/* {item?.files?.slice(0, 3).map((item) => `.${item?.file.split(".").pop()}`).join("/")}
                                            {item?.files?.length > 3 ? " ..." : ""} */}
                      {_showFileType(item?.files)}
                    </td>
                    <td>{item?.created_by_full_name}</td>
                    <td>{formatDateTime(item?.created_at)}</td>
                    <td>
                      ອ່ານແລ້ວ {item?.reader + `/` + item?.total_reader}
                      <FaEye
                        onClick={(event) => handleViewReader(event, item)}
                        style={{
                          fontSize: 16,
                          color: "#B0B0B0",
                          cursor: "pointer",
                          marginLeft: 12,
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      {/* <NotiDocument
        isNoti={isNoti}
        handleCloseNoti={() => setIsNoti(false)}
        setShowDetailDocument={setShowDetailDocument}
        setDocumentId={setDocumentId}
      /> */}
    </div>
  );
}

export default Document;
