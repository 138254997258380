import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import queryString from 'query-string';
import { useSelector } from "react-redux";
import { Card, Col, Form, Row, Table, Breadcrumb, Button, Modal } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import CustomTitleCard from "../../common/CustomTitleCard";
import CounterDetail from "./CounterDetail";
import PrimaryButton from "../../common/Button/PrimaryButton";
import CounterAdd from "./CounterAdd";
import { selectCounter } from "../../redux/counter";
import { SERVER_URL } from "../../api";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";
import PaginationHelper from "../../helpers/PaginationHelper";
// ------------------------------------------------------ //
export default function CounterManagement() {
  const counterData = useSelector(selectCounter);
  const [dataDetail, setDataDetail] = useState([]);
  const [showCounterAdd, setShowCounterAdd] = useState(false);
  const clickCloseCounterAdd = () => setShowCounterAdd(false);
  const clickShowCounterAdd = () => setShowCounterAdd(true);
  const parsed = queryString.parse(History?.location?.search);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  const [showCounterDetail, setShowCounterDetail] = useState(false);
  const clickCloseCounterDetail = () => setShowCounterDetail(false);
  const clickShowCounterDetail = () => setShowCounterDetail(true);
  const [counterCode, setCounterCode] = useState("");
  const [formValue, setFormValue] = useState({});
  const [dataCounter, setDataCounter] = useState()
  const [counterTotal, setCounterTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const tableData = counterData?.data?.filter((e) => {
    if (counterCode !== "" && counterCode !== e?.counter_name) return false;
    return true;
  });

  useEffect(() => {
    getCounter();
  }, []);

  useEffect(() => {
    getCounter();
  }, [counterCode]);

  const getCounter = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      setIsLoading(true)
      if (getUserDataFromLCStorage()?.branch_id) {
        const data = await axios.get(
          `${SERVER_URL}/branch-service/api/v1/counter/limit/100/skip/0?counter_name=${counterCode}&branch=${
            getUserDataFromLCStorage()?.branch_id
          }`,
          {
            headers: {
              Authorization: _token,
            },
          }
        );
        if (data.status === 200) {
          setDataCounter(data?.data)
          setCounterTotal(data?.data?.total)
          console.log('data?.data?.total: ', data?.data);
          setIsLoading(false)
        }
      } else return;
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCounter());
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {

    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Downld.pdf' + date + '.pdf');
    }
    )
  };


  return (
    <>
        <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item active href="#"> ຈັດການເຄົາເຕີ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <CustomTitleCard
        title="ຂໍ້ມູນເຄົາເຕີທັງໝົດ"
        icon={faCashRegister}
        style={{ padding: 20, marginBottom: 4 }}
      >
        <div style={{ flexGrow: 1 }} />
        <PrimaryButton onClick={() => clickShowCounterAdd()}>
          + ເພີ່ມເຄົາເຕີ
        </PrimaryButton>
      </CustomTitleCard>
      <Card style={{ padding: 20, minHeight: "80vh", paddingBottom: 100 }}>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group controlId="doj">
              <Form.Label>ເລກເຄົາເຕີ</Form.Label>
              <Form.Control
                onChange={(e) => setCounterCode(e.target.value)}
                type="text"
                placeholder="001"
              />
            </Form.Group>
          </Col>
          <Col md={8} className="d-flex justify-content-end" style={{ marginTop: 35 }}>
            <div>
              <button
                onClick={handleShow}
                id="format-PDF"
                className='buttonRedColor-export'>Export </button>
            </div>
          </Col>
        </Row>
        {
          isLoading ?
            (<div className="text-black">
              <div className="d-flex justify-content-center mt-4">
              <div className="mt-5">
                <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} /> <br />
                <span>Loading....</span>
              </div>
            </div>
            </div>) :
            
            (
              <Table className="table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ເລກເຄົາເຕີ</th>
                  <th>ຜູ້ດູແລ</th>
                  <th>ວັນທີສ້າງ</th>
                </tr>
              </thead>
              <tbody>
                {dataCounter?.data?.map((item, index) => (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormValue(item);
                      clickShowCounterDetail();
                      setDataDetail(() => {
                        tableData.find((d) => d.id == item.id);
                      });
                    }}
                  >
                    <td className="align-middle">{index + 1 + _limit * (_skip - 1)}</td>
                    <td className="align-middle">{item.counter_name}</td>
                    <td className="align-middle">
                      {item.counter_owners?.[0].first_name}
                    </td>
                    <td className="align-middle">
                      {moment(item.created_at).format("DD/MM/YYYY LT")}
                    </td>
                  </tr>
                ))}
              </tbody>
              </Table>
            )
        }
               {/* {Pagination_helper(counterTotal,consts.COUNTER_MANAGEMENT, `filterCabinet=${counterCode}`)} */}
      </Card>
      <CounterDetail
        formValue={formValue}
        showCounterDetail={showCounterDetail}
        clickCloseCounterDetail={clickCloseCounterDetail}
        data={dataDetail}
      />
      <CounterAdd
        showCounterAdd={showCounterAdd}
        clickCloseCounterAdd={clickCloseCounterAdd}
      />
         <Modal show={show} onHide={handleClose} size="xl">

<Modal.Body>
  <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button className='buttonRedColor'
      style={{ width: "125px", height: "51px" }} onClick={handleDownloadPdf}>
      Export PDF
    </Button>
  </div>
  <div className="" ref={printRef}>
  <Table className="table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ເລກເຄົາເຕີ</th>
                  <th>ຜູ້ດູແລ</th>
                  <th>ວັນທີສ້າງ</th>
                </tr>
              </thead>
              <tbody>
                {dataCounter?.data?.map((item, index) => (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormValue(item);
                      clickShowCounterDetail();
                      setDataDetail(() => {
                        tableData.find((d) => d.id == item.id);
                      });
                    }}
                  >
                    <td className="align-middle">{index + 1 + _limit * (_skip - 1)}</td>
                    <td className="align-middle">{item.counter_name}</td>
                    <td className="align-middle">
                      {item.counter_owners?.[0].first_name}
                    </td>
                    <td className="align-middle">
                      {moment(item.created_at).format("DD/MM/YYYY LT")}
                    </td>
                  </tr>
                ))}
              </tbody>
              </Table>
  </div>
</Modal.Body>

</Modal>
    </>
  );
}
