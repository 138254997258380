import Status from "./status";
// ---------------------------------------------------
export default function CustomList({ value = [] }) {
  return (
    <>
      {value.map((item, index) => {
        if (typeof item.value === "string") {
          return (
            <div
              key={index}
              className=' border-bottom border-1 d-flex justify-content-between p-2 mb-3'>
              <strong>{item.title}</strong>
              <span>{item.value}</span>
            </div>
          );
        } else if (Array.isArray(item.value)) {
          return (
            <div
              key={index}
              className=' border-bottom border-1 d-flex justify-content-between p-2 mb-3'>
              <strong>{item.title}</strong>
              <div>
                {item.value.map((e) => (
                  <p style={{ textAlign: "end" }}>{e}</p>
                ))}
              </div>
            </div>
          );
        } else if (typeof item.value === "object") {
          return (
            <div
              className='border'
              style={{ padding: "20px 10px", margin: "20px 0px" }}>
              {item.value.map((result, index) => (
                <div
                  key={index}
                  className='border-bottom border-1 py-2 mb-3 d-flex justify-content-between'>
                  <strong>{result.title}</strong>
                  <span>{result.value}</span>
                </div>
              ))}
            </div>
          );
        } else if (typeof item.value === "boolean") {
          return (
            <div
              key={index}
              className='border-bottom border-1 p-2 mb-3 d-flex justify-content-between'>
              <strong>{item.title}</strong>
              <span>
                <Status status={item.value} />
              </span>
            </div>
          );
        } else if (typeof item.values === "string") {
          return (
            <div key={index} className='border-bottom border-1 p-2 mb-3'>
              <strong className='mb-4'>{item.title}</strong>
              <p className='mt-3'>{item.values}</p>
            </div>
          );
        }
        return <></>;
      })}
    </>
  );
}
