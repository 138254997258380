import React, { useState, useEffect } from "react";
import { Card, Form, Breadcrumb, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import consts from "../../consts";
// redux
import { useSelector } from "react-redux";
import { selectCabinet } from "../../redux/cabinet";
import ModalBootstrap from "../../common/popup/success/ModalBootstrap";
import Warning from '../../common/popup/fail/Warning'
import Fail from '../../common/popup/fail/fail'
import { useDispatch } from "react-redux";
import { loadCabinetAsync } from "../../redux/cabinet";
import { addCabinetProduct } from "../../api/cabinage";
import { SERVER_URL } from "../../api";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";


// ------------------------
export default function Add() {
  const dispatch = useDispatch()
  const { idCabinage } = useParams();
  const History = useHistory();
  const [fail, setFail] = useState(false)
  const [suc, setSuc] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [patternData, setPatternData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [titleValidation, setTitleValidation] = useState('');
  const [amountInput, setAmountInput] = useState(0);
  const handleCloseModal = () => setShowModal(false)

  // global state
  const CabinetData = useSelector(selectCabinet);
  const CabinetOneData = CabinetData.data?.find((e) => e.id === idCabinage);
  const [formValue, setFormValue] = useState();
  const [categoryID, setCategoryID] = useState('');
  const [patternID, setPatternID] = useState('');
  const [genderData, setgenderData] = useState("ALL");

  const [dataForSubmit, setDataForSubmit] = useState({
    cabinet: idCabinage,
    name: "",
    product_code: "",
    product_type_id: "",
    image: [],
    amount: '',
    branch_product: "",
    weight: { name: "", gram: 0, amount: 0 },
  });

  useEffect(() => {
    category();
    pattern();
    getStock();
  }, []);

  useEffect(() => {
    getStock();
  }, [categoryID, patternID]);

  const getStock = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/branch-product?limit=${1000}&skip=0&product_type_id=${categoryID}&pattern_id=${patternID}&branch=${getUserDataFromLCStorage().branch_id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setStockData(data?.data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getStock());
      }
    }
  };

  const pattern = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/product-service/api/v1/pattern`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );

      if (data.status === 200) {
        setPatternData(data?.data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, pattern());
      }
    }
  };

  const category = async () => {
    let _token = getAccessTokkenFromLCS()
    try {
      const data = await axios.get(
        `${SERVER_URL}/product-service/api/v1/product`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setCategories(data?.data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, category());
      }
    }
  };


  const handleAddProductToCabinage = (event) => {
    event.preventDefault();

    const data = {
      cabinet: idCabinage,
      name: dataForSubmit?.name,
      product_id: dataForSubmit?.product_id,
      product_code: dataForSubmit?.product_code,
      image: dataForSubmit?.image,
      amount: amountInput,
      pattern_id: dataForSubmit?.pattern_id,
      gender: genderData,
      goldType: dataForSubmit?.goldType,
      product_type_id: dataForSubmit?.product_type_id,
      branch_product: dataForSubmit?.branch_product,
      weight: [{ name: dataForSubmit?.name, gram: dataForSubmit?.weight?.gram, amount: amountInput }],
    }
    if (data?.amount === 0 && formValue?.stock_amount < data?.amount) {
      setShowModal(true)
    } else {
      addCabinetProduct(data).then((e) => {
        if (e.error === false) {
          setSuc(true)
          setTimeout(() => {
            setSuc(false)
            dispatch(loadCabinetAsync())
            History.replace(`${consts.CABINAGE_MANAGEMENT}/detail/${idCabinage}/limit/50/skip/1`);
          }, 3000)
        } else setFail(true)
      });
    };
  }

  const handleChangeAmount = (e) => {
    if (formValue?.stock_amount === 0) {
      setTitleValidation('ສິນຄ້າຄ້າງໃນສະຕ໊ອກເຫຼືອ 0')
      setShowModal(true)
      setTimeout(() => {
        setShowModal(false)
      }, 1500);
    } else if (formValue?.stock_amount < e) {
      setTitleValidation(`ສິນຄ້າຄ້າງໃນສະຕ໊ອກເຫຼືອ ${formValue?.stock_amount}`)
      setShowModal(true)
      setTimeout(() => {
        setShowModal(false)
      }, 1500);
    } else {
      setAmountInput(e)
    }
  };

  return (
    <>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => History.push(consts.CABINAGE_MANAGEMENT + '/limit/50/skip/1')}>ຈັດການຕູ້</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => History.push(consts.CABINAGE_MANAGEMENT + '/detail/' + idCabinage + '/limit/50/skip/1')} href="#">
            {CabinetOneData?.cabinet_name || "-"}
          </Breadcrumb.Item>
          <Breadcrumb.Item active href="#"> ເພີມສິນຄ້າໃນຕູ້</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card style={{ padding: "20px" }}>
        <h4 className='border-bottom border-2'>ເພີ່ມສິນຄ້າ</h4>
        <Form onSubmit={handleAddProductToCabinage} className='mt-3'>
          <Row>
            <Col sm='4'>
              <Form.Group className='mb-3'>
                <Form.Label>ໝວດຄຳ</Form.Label>
                <Form.Select
                  onChange={(e) => setCategoryID(e.target.value)}>
                  <option value=''>ເລືອກໝວດຄຳ</option>
                  {categories?.map((category, idx) => (
                    <option key={idx} value={category?.id}>{category?.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm='4'>
              <Form.Group className='mb-3'>
                <Form.Label>ລາຍ</Form.Label>
                <Form.Select
                  onChange={(e) => setPatternID(e.target.value)}>
                  <option value=''>ເລືອກລາຍ</option>
                  {patternData?.map((pattern, idx) => (
                    <option key={idx} value={pattern?.id}>{pattern?.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm='4'>
              <Form.Group className='mb-3'>
                <Form.Label>ລະຫັດສິນຄ້າ</Form.Label>
                <Form.Select
                  required
                  onChange={(e) => {
                    const findOne = stockData?.find(
                      (item) => item.id === e.target.value
                    );
                    setFormValue({
                      ...findOne,
                    });
                    setDataForSubmit((prev) => ({
                      ...prev,
                      name: findOne?.name,
                      product_id: findOne?.product_id,
                      product_type_id: findOne?.product_type_id,
                      pattern_id: findOne?.pattern_id,
                      amount: 0,
                      goldType: findOne?.goldType,
                      product_code: findOne?.product_code,
                      branch_product: findOne?.id,
                      image: findOne?.image,
                    }));
                  }}>
                  <option value=''>ຕົວເລືອກ</option>
                  {stockData?.map((stock, index) => (
                    <option key={index} value={stock?.id}>{index + 1}. {stock?.product_code} {stock?.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

          </Row>
          <Form.Group className='mb-3'>
            <Form.Label>ຊື່ສິນຄ້າ</Form.Label>
            <Form.Control required type='text' placeholder='ກະລຸນາປ້ອນ' value={formValue?.name} disabled />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>ໝວດສິນຄ້າ</Form.Label>
            <Form.Control required type='text' placeholder='-' value={formValue?.product_type_name} disabled />
          </Form.Group>
          <Form.Group controlId='doj' className='mb-3'>
            <Form.Label className='d-block'>ສຳລັບເພດ</Form.Label>
            <Form.Check inline name='radioGroup' label='ຍິງ' type='radio' id='radio-1' checked={genderData === "FEMALE" ? true : false} onChange={() => setgenderData("FEMALE")} />
            <Form.Check inline name='radioGroup' label='ຊາຍ' type='radio' id='radio-2' checked={genderData === "MALE" ? true : false} onChange={() => setgenderData("MALE")} />
            <Form.Check inline name='radioGroup' label='ທຸກເພດ' type='radio' id='radio-3' checked={genderData === "ALL" ? true : false} onChange={() => setgenderData("ALL")}
            />
          </Form.Group>
          <div className='p-3 border border-2'>
            <Form.Group controlId='doj'>
              <Form.Label>ນ້ຳໜັກສິນຄ້າ</Form.Label>
              <div className='d-flex align-items-center'>
                <span className='p-2'>01</span>
                <Form.Select
                  required
                  value={dataForSubmit?.weight?.gram}
                  onChange={(e) => {
                    setDataForSubmit((prev) => ({
                      ...prev,
                      weight: {
                        ...prev?.weight,
                        gram: Number(e.target.value),
                      },
                    }));
                  }}
                  disabled={!formValue}>
                  <option value=''>ຕົວເລືອກ</option>
                  {formValue?.weight?.[0]
                    ? formValue?.weight?.map((e, i) => (
                      <option key={i} value={e.gram}>
                        {e.gram}
                      </option>
                    ))
                    : ""}
                </Form.Select>
                <Form.Control disabled type='number' value={formValue?.stock_amount} placeholder="0" style={{ width: 150 }} />
                <Form.Control type='number' placeholder="ປ້ອນຈຳນວນ" style={{ width: 130 }}
                  onChange={(e) => {
                    handleChangeAmount(parseInt(e.target.value))
                  }} />
              </div>
            </Form.Group>
          </div>
          <div
            className='d-flex justify-content-around'
            style={{ margin: "80px 0" }}>
            <input style={buttonStyle}
              value="ຍົກເລີກ"
              type="button"
              onClick={() => History.replace(`${consts.CABINAGE_MANAGEMENT}/detail/${idCabinage}`)}
            />
            <button
              style={buttonStyle}
              className="buttonRedColor"
              type="submit">
              ເພີ່ມສິນຄ້າ
            </button>
          </div>
        </Form>
      </Card>
      <div style={{ display: fail ? 'inline' : 'none', width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.4)', position: 'fixed', top: '0', left: '0' }}>
        <Fail confirm={() => setFail(false)} />
      </div>
      <ModalBootstrap title='ເພີ່ມສິນຄ້າເຂົ້າຕູ້ສຳເລັດ' show={suc} close={suc} />
      <Warning title={titleValidation} show={showModal} close={handleCloseModal} />
    </>
  );
}

const buttonStyle = {
  width: "509px",
  height: "49px",
  border: 'none'
};
