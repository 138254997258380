import styled from "styled-components";
import consts from "../../consts";
// ----------------------------------------- //
const PrimaryButton = ({ children, ...other }) => {
  return <Button {...other}>{children || "PrimaryButton"}</Button>;
};
const Button = styled.button`
  background-color: ${consts.PRIMARY_COLOR};
  border: 0;
  padding: 10px 12px 10px 12px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  :hover {
    background-color: #761200;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
  :disabled,
  [disabled] {
    border: none;
    background-color: #b97c71;
    color: #d1d5db;
    box-shadow: none;
  }
  [size="s"] {
    font-size: 12px;
    padding: 10px 8px 10px 8px;
  }
  [size="l"] {
    font-size: 16px;
    padding: 10px 16px 10px 16px;
    gap: 12px;
    color: blue;
  }
`;
export default PrimaryButton;
