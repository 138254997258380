import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import ReactLoading from 'react-loading';

import { Link } from "react-router-dom";
import styled from "styled-components"; //styled-component
import { Row, Card, Col, Form, Table, Breadcrumb, InputGroup, Button, Modal } from "react-bootstrap";

import { CUSTOMER_MANAGEMENT_DETAIL } from "../../consts";
import { CUSTOMER_MANAGEMENT_ADD } from "../../consts";
import Consts from "../../consts";
import { SERVER_URL } from "../../api";

import PaginationHelper from "../../helpers/PaginationHelper";
import CustomSearch from "../../common/CustomSearch";
import { convertGender, statusDeleteBunsee, formatDate } from "../../helpers";
import { getAccessTokkenFromLCS, getReNewToken } from "../../helpers/user";

export default function Tables(props) {
  const History = useHistory();
  const parsed = queryString.parse(History?.location?.search);
  const [dateFrom, setDateFrom] = useState(
    parsed?.dateFrom ? parsed?.dateFrom : formatDate("01/01/2000")
  );
  const [dateTo, setDateTo] = useState(
    parsed?.to_date ? parsed?.to_date : formatDate(new Date())
  );

  const _dataHeader = [
    {
      header: "ລູກຄ້າເຄື່ອງສົ່ງທັງໝົດ",
      title: "ລູກຄ້າເຄື່ອງສົ່ງ",
      eventKey: "1",
      value: "RETAIL",
    },
    {
      header: "ລູກຄ້າອອມຄຳທັງໝົດ",
      title: "ລູກຄ້າອອມຄຳ",
      eventKey: "2",
      value: "AOMKHAM",
    },
    {
      header: "ລູກຄ້າທົ່ວໄປທັງໝົດ",
      title: "ລູກຄ້າທົ່ວໄປ",
      eventKey: "3",
      value: "NORMAL",
    },
    {
      header: "ລູກຄ້າ VIP ທັງໝົດ",
      title: "ລູກຄ້າ VIP",
      eventKey: "4",
      value: "VIP",
    },
  ];

  const [dataSelect, setDataSelect] = useState({});

  const status = (status) => {
    return status === "ACTIVE" ? (
      <div
        style={{
          color: "green",
          border: "1px solid green",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        ເປີດໃຊ້ງານ
      </div>
    ) : status === "WAITTING" ? (
      <div
        style={{
          color: "#2E72D2",
          border: "1px solid #2E72D2",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        ລໍຖ້າຢືນຢັນ
      </div>
    ) : (
      <div
        style={{
          color: "#D82C0D",
          border: "1px solid #D82C0D",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        ປິດໃຊ້ງານ
      </div>
    );
  };

  const [dataCustomer, setDataCustomer] = useState();
  const [filterData, setFilterData] = useState("");
  const [filterStatusData, setFilterStatusData] = useState("");
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (parsed?.page) {
      let _filterPage = _dataHeader.filter(
        (data) => data?.value === parsed?.page
      );
      if (_filterPage?.length > 0) {
        setDataSelect(_filterPage[0]);
      }
    }
    getCustomerUsersFilter();
  }, []);

  // console.log('tste',dataSelect);

  useEffect(() => {
    if (dataSelect?.value) {
      getCustomerUsersFilter();
    }
  }, [dataSelect?.value, filterStatusData, dateTo, dateFrom]);

  const getCustomerUsersFilter = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      setIsLoading(true)
      let _data = dataSelect?.value;
      if (dataSelect?.value === "AOMKHAM") {
        _data =
          "AOMKHAM&status=" + filterStatusData + "&is_aomkham_account=YES";
      }
      const data = await axios.get(
        `${SERVER_URL}/user-service/api/v1/user?limit=${_limit}&skip=${(_skip - 1) * _limit
        }&role=CUSTOMER&user_status=` +
        filterStatusData +
        `&customer_type=` +
        _data +
        "&first_name=" +
        filterData +
        "&from_date=" +
        dateFrom +
        "&to_date=" +
        moment(moment(dateTo).add(1, "day")).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setDataCustomer(data?.data);
        console.log('data?.data: ', data?.data);
        setIsLoading(false)
    }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCustomerUsersFilter());
      }
    }
  };
  const _clickSearchName = () => {
    getCustomerUsersFilter();
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {

    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Downld.pdf' + date + '.pdf');
    }
    )
  };

  return (
    <>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item active href="#">ຈັດການລູກຄ້າ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {/* ===ງ */}
      <ul className="nav nav-tabs">
        {_dataHeader.map((result, index) => (
          <li key={index} className="nav-item" onClick={() => { setDataSelect(result)}}>
            <a
              className="nav-link"
              style={{
                color: dataSelect?.value === result?.value ? "blue" : "",
              }}
            >
              {result?.title}
            </a>
          </li>
        ))}
      </ul>
      <Card
        style={{
          padding: "20px",
          border: "none",
          paddingBottom: "100px",
        }}
        body
      >
        <div className="d-flex justify-content-between">
          <h4 className="mb-5">{dataSelect?.header}</h4>
          <Link to={`${CUSTOMER_MANAGEMENT_ADD}/2`}>
            {/* <button
                className="buttonRedColor"
                style={{ width: "195px", height: "51px" }}
              >
                + ເພີ່ມຂໍ້ມູນ
              </button> */}
          </Link>
        </div>
        <Form.Label>ຄົ້ນຫາ</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="ຄົ້ນຫາຕາມຊື່"
            aria-label="ຄົ້ນຫາຕາມຊື່"
            aria-describedby="basic-addon2"
            onChange={(e) => setFilterData(e.target.value)}
          />
          <Button variant="outline-danger" id="button-addon2" onClick={() => _clickSearchName()}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>
        <Row className="mt-3">
          <Col xs={3}>
            <Form.Group controlId="doj">
              <Form.Label>ວັນທີສະໝັກ</Form.Label>
              <Form.Control
                type="date"
                value={dateFrom}
                onChange={(e) => {
                  setDateFrom(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="doj">
              <Form.Label>ຫາວັນທີ</Form.Label>
              <Form.Control
                type="date"
                value={dateTo}
                onChange={(e) => {
                  setDateTo(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>ສະຖານະນຳໃຊ້</Form.Label>
              <Form.Select
                onChange={(e) => setFilterStatusData(e?.target?.value)}
              >
                <option value="">ທັງໝົດ</option>
                <option value="ACTIVE">ເປີດໃຊ້ງານ</option>
                <option value="INACTIVE">ປີດໃຊ້ງານ</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2} style={{ marginTop: 33 }}>
            <div>
              <button
                onClick={handleShow}
                id="format-PDF"
                className='buttonRedColor-export'>  Export </button>
            </div>
          </Col>
        </Row>
        {
          isLoading ? (
            <div className="text-black">
              <div className="d-flex justify-content-center mt-4">
                <div className="mt-5">
                  <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} /> <br />
                  <span>Loading....</span>
                </div>
              </div>
            </div>
          )
            : dataCustomer?.data === null ? (
              <div>
                <img style={{ marginLeft: "40%", marginTop: "10%" }} src='/asset/image/animation_200_ldu129fc.gif' />
                <p style={{ marginLeft: "45%", marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
              </div>)
              : (
                <Table className="table table-hover">
                  <thead>
                    <tr>
                      {[
                        { title: "ລຳດັບ" },
                        { title: "ລະຫັດລູກຄ້າ" },
                        { title: "ຊື່ ແລະ ນາມສະກຸນ" },
                        { title: "ເພດ" },
                        { title: "ວັນເດືອນປີເກີດ" },
                        { title: "ຊື່ເຂົ້າລະບົບ" },
                        { title: "ວັນທີສະໝັກ" },
                        { title: "ສະຖານະ (Block)" },
                        { title: "ສະຖານະບັນຊີແອບ" },
                      ]?.map((result, index) => (
                        <th key={index}>{result?.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dataCustomer?.data?.map((result, index) => (
                      <tr
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          History.push(`${CUSTOMER_MANAGEMENT_DETAIL}/${result.id}`)
                        }
                      >
                        <td>{index + 1 + _limit * (_skip - 1)}</td>
                        <td>{result?.username}</td>
                        <td>{`${result?.first_name} ${result?.last_name}`}</td>
                        <td>{convertGender(result?.gender)}</td>
                        <td>{result?.birthday ?? "-"}</td>
                        <td>{result?.username ?? "-"}</td>
                        <td>{moment(result?.created_at).format("MM-DD-YYYY")}</td>
                        <td>{status(result?.user_status)}</td>
                        <td>{statusDeleteBunsee(result?.is_delete)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
        }


        {Pagination_helper(
          dataCustomer?.total,
          Consts.CUSTOMER_MANAGEMENT,
          `page=${dataSelect?.value}`
        )}
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">

        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className='buttonRedColor'
              style={{ width: "125px", height: "51px" }} onClick={handleDownloadPdf}>
              Export PDF
            </Button>
          </div>
          <div className="" ref={printRef}>
            <Table className="">
              <thead>
                <tr>
                  {[
                    { title: "ລຳດັບ" },
                    { title: "ລະຫັດລູກຄ້າ" },
                    { title: "ຊື່ ແລະ ນາມສະກຸນ" },
                    { title: "ເພດ" },
                    { title: "ວັນເດືອນປີເກີດ" },
                    { title: "ຊື່ເຂົ້າລະບົບ" },
                    { title: "ວັນທີສະໝັກ" },
                    { title: "ສະຖານະ (Block)" },
                    { title: "ສະຖານະບັນຊີແອບ" },
                  ]?.map((result, index) => (
                    <th key={index}>{result?.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataCustomer?.data == null ?
                  <tr>
                    <td colSpan={9} className="text-center">
                      <div className="py-5">
                        <h2 className="text-danger"> ບໍ່ມີຂໍ້ມູນ</h2>
                      </div>

                    </td>
                  </tr>
                  : <>
                    {dataCustomer?.data?.map((result, index) => (
                      <tr
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          History.push(`${CUSTOMER_MANAGEMENT_DETAIL}/${result.id}`)
                        }
                      >
                        <td>{index + 1 + _limit * (_skip - 1)}</td>
                        <td>{result?.username}</td>
                        <td>{`${result?.first_name} ${result?.last_name}`}</td>
                        <td>{convertGender(result?.gender)}</td>
                        <td>{result?.birthday ?? "-"}</td>
                        <td>{result?.username ?? "-"}</td>
                        <td>{moment(result?.created_at).format("MM-DD-YYYY")}</td>
                        <td>{status(result?.user_status)}</td>
                        <td>{statusDeleteBunsee(result?.is_delete)}</td>
                      </tr>
                    ))}
                  </>}
              </tbody>
            </Table>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}
const RootStyle = styled.div`
  padding: "20px";
`;
