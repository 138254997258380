import React from "react";
import "./style.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import RouterLink from "./routes/RouterLink";
import { useDispatch } from "react-redux";
import { loadUsersAsync } from "./redux/users_staff";
import { loadOrderAsync } from "./redux/order_data";
import { loadCustomerAsync } from "./redux/customer";
import { loadProductAsync } from "./redux/product";
import { loadStockAsync } from "./redux/stock";
import { loadCounterAsync } from "./redux/counter";
import { loadCabinetAsync } from "./redux/cabinet";
import { loadBranchAsync } from "./redux/branch_data";
import { loadCabineProductAsync } from "./redux/cabinet_product";
import { loadPatternAsync } from "./redux/pattern_data";
import { loadCategoryAsync } from "./redux/category_data";
import { loadOrderProductAsync } from "./redux/order_product";
// import DisableDevtool from 'disable-devtool';
// DisableDevtool();

const App = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const setData = () => {
      dispatch(loadUsersAsync());
      dispatch(loadOrderAsync());
      dispatch(loadCustomerAsync());
      dispatch(loadProductAsync());
      dispatch(loadStockAsync());
      dispatch(loadCounterAsync());
      dispatch(loadCabinetAsync());
      dispatch(loadBranchAsync());
      dispatch(loadCabineProductAsync());
      dispatch(loadPatternAsync());
      dispatch(loadCategoryAsync());
      dispatch(loadOrderProductAsync())
    }
    return setData()
  }, []);
  return (
    <>
      <RouterLink />
    </>
  );
};
export default App;
