import axios from "axios";
import { SERVER_URL } from "..";
import { getUserDataFromLCStorage,getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";
let _tokken = getAccessTokkenFromLCS()

// ------------------------------------- //
const pattern = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/product-service/api/v1/pattern`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,pattern());
    }
  }
};
const patternOne = async (id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/product-service/api/v1/pattern/${id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,patternOne());
    }
  }
};

const addPattern = async (value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/product-service/api/v1/pattern`,
      {
        ...value,
        created_by: `${getUserDataFromLCStorage().first_name} ${
          getUserDataFromLCStorage().last_name
        }`,
      },
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,addPattern());
    }
  }
};
const updatePattern = async (_id, value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.put(
      `${SERVER_URL}/product-service/api/v1/pattern/${_id}`,
      {
        ...value,
        updated_by: `${getUserDataFromLCStorage().first_name} ${
          getUserDataFromLCStorage().last_name
        }`,
      },
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,updatePattern());
    }
  }
};
const deletePattern = async (_id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.delete(
      `${SERVER_URL}/product-service/api/v1/pattern/${_id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,deletePattern());
    }
  }
};
export { pattern, addPattern, updatePattern, deletePattern, patternOne };
