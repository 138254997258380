import { useState } from "react";
import upload from "../api/upload";
import consts from "../consts";

export default function UploadImage({ pictureName }) {
  const [picture, setPicture] = useState('');

  return (
    <>
      <label htmlFor="file">
        <div
          style={{
            padding: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #666",
            height: "170px",
            width: "170px"
          }}
        >
          <p
            style={{ display: picture === "" || undefined ? "block" : "none" }}
          >
            + upload
          </p>
          <img
            style={{
              display: picture === "" || undefined ? "none" : "block",
              width: "100%",
              height: "100%"
            }}
            src={picture}
          />
        </div>
      </label>
      <input
        id="file"
        style={{ display: "none" }}
        type="file"
        onChange={(e) => {
          upload(e).then((event) => {
            pictureName(event.imageName);
            setPicture(consts.S3 + event.imageName);
          });
        }}
      />
    </>
  );
}
