import axios from 'axios'
import { SERVER_URL } from '..'
import { getUserDataFromLCStorage,getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

export const getCabinet = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/branch-service/api/v1/cabinet?branch=${
        getUserDataFromLCStorage().branch_id
      }`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCabinet());
    }
  }
};
export const getCabinetProductAll = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/stock-service/api/v1/cabinet-product?branch=${
        getUserDataFromLCStorage().branch_id
      }`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCabinetProductAll());
    }
  }
};
export const getCabinetOne = async (id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/branch-service/api/v1/cabinet/${id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCabinetOne());
    }
  }
};
export const getCabinetProduct = async (cabinet_id) => {
let _tokken = getAccessTokkenFromLCS()

  try {
    const data = await axios.get(
      `${SERVER_URL}/stock-service/api/v1/cabinet-product?cabinet=${cabinet_id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCabinetProduct());
    }
  }
};
export const addCabinetProduct = async (value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/stock-service/api/v1/cabinet-product`,
      { ...value, branch: getUserDataFromLCStorage().branch_id },
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCabinetProduct());
    }
  }
};

export const addCabinet = async (value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/branch-service/api/v1/cabinet`,
      {
        ...value,
        branch: getUserDataFromLCStorage().branch_id,
        created_by:getUserDataFromLCStorage().branch_name,
        updated_by:getUserDataFromLCStorage().branch_name
      },
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data?.status >= 300) return false;
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCabinetProduct());
    }
  }
};

export const updateCabinet = async (cabinet_id, value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.put(
      `${SERVER_URL}/branch-service/api/v1/cabinet/${cabinet_id}`,
      {
        ...value,
        branch: getUserDataFromLCStorage().branch_id,
        updated_by: `${getUserDataFromLCStorage().first_name}`,
      },
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data?.status >= 300) return false;
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCabinetProduct());
    }
  }
};

export const deleteCabinet = async (cabinet_id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.delete(
      `${SERVER_URL}/branch-service/api/v1/cabinet/${cabinet_id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data?.status >= 300) return false;
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCabinetProduct());
    }
  }
};

