import axios from "axios";
import { SERVER_URL } from "..";
import { getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

const category = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/product-service/api/v1/product`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,category());
    }
  }
};
const categoryOne = async (id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/product-service/api/v1/product/${id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,categoryOne());
    }
  }
};

const addCategory = async (value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/product-service/api/v1/product`,
      value,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,addCategory());
    }
  }
};
const updateCategory = async (_id, value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.put(
      `${SERVER_URL}/product-service/api/v1/product/${_id}`,
      value,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,updateCategory());
    }
  }
};
const deleteCategory = async (_id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.delete(
      `${SERVER_URL}/product-service/api/v1/product/${_id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,deleteCategory());
    }
  }
};
export { category, addCategory, updateCategory, deleteCategory, categoryOne };
