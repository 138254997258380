
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { Card, Row, Col, Table, Button, Tabs, Tab, Breadcrumb } from "react-bootstrap";
import consts from "../../consts";
import CustomList from "../../common/CustomList";
import AddStock from "./popup/AddStock";
import { updateStock } from "../../api/stock";
import { SERVER_URL } from "../../api";
import { formatCurrency, convertGender } from "../../helpers";
import { getAccessTokkenFromLCS, getReNewToken } from "../../helpers/user";

// -------------------------------------------## Edit by Phoudthavisack ##--------------------------------------------------------- //

export default function Detail() {
  const { id } = useParams();
  const { params } = useRouteMatch();
  const [showAddStock, setShowAddStock] = useState(false);
  const history = useHistory();
  const [productOneData, setProductOneData] = useState({});

  useEffect(() => {
    getStock(params?.id)
  }, [params])

  const getStock = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/branch-product/${params?.id}`,
        {
          headers: {Authorization: _token},
        }
      );
      if (data.status === 200) {
        setProductOneData(data?.data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getStock());
      }
    }
  };
  const handleCloseAddStock = () => {
    setShowAddStock(false);
  };

  const handleSubmitAddStock = async (weight) => {
    const values = {
      amount: parseInt(weight[0]?.amount),
      stock_amount: parseInt(weight[0]?.amount),
    };
    await updateStock(id, values);
    getStock()

    // window.location.reload(true);
  };

  return (
    <>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => history.push(consts.STOCK_MANAGEMENT + '/limit/40/skip/1')}>ຈັດການສະຕ໊ອກ</Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>ລາຍລະອຽດ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <AddStock
        weight={productOneData?.weight}
        show={showAddStock}
        onClose={handleCloseAddStock}
        onSubmit={handleSubmitAddStock}
      />

      <Tabs defaultActiveKey='ລາຍລະອຽດ' className='mb-n1'>
        <Tab eventKey='ລາຍລະອຽດ' title='ລາຍລະອຽດ'>
          <Card style={{ padding: "20px" }}>
            <h4 className='border-bottom border-2 pb-3'>ລາຍລະອຽດສິນຄ້າ</h4>
            <Row>
              <Col>
                <CustomList
                  value={[
                    {
                      title: "ລະຫັດສິນຄ້າ",
                      value: `${productOneData?.product_code || "-"}`,
                    },
                    {
                      title: "ຊື່ສິນຄ້າ",
                      value: `${productOneData?.name || "-"}`,
                    },
                    {
                      title: "ໝວດສິນຄ້າ",
                      value: `${productOneData?.product_type_name || "-"}`,
                    },
                    {
                      title: "ເພດ",
                      value: `${convertGender(productOneData?.gender) || "-"}`,
                    },
                    // {
                    //   title: "ຫົວຫນ່ວຍ",
                    //   value: `${productOneData?.unit?.[0] || "-"}`,
                    // },
                    {
                      title: "ລວດລາຍ",
                      value: `${productOneData?.pattern_name || "-"}`,
                    },
                    {
                      title: "ບ່ອນຜະລິດ",
                      value: `${productOneData?.place_production || "-"}`,
                    },
                    {
                      title: "ຄ່າແຮງຫນ້າຮ້ານ ກີບ",
                      value: `${formatCurrency(productOneData?.store_wage_kip) || "-"}`,
                    },
                    {
                      title: "ຄ່າແຮງໂຮງງານ ກິບ",
                      value: `${formatCurrency(productOneData?.factory_wage_kip) || "-"}`,
                    },
                    {
                      title: "ຄ່າແຮງເຄື່ອງສົ່ງ ກີບ",
                      value: `${formatCurrency(productOneData?.transmitter_wage_kip) || "-"}`,
                    },
                    {
                      title: "ຄ່າແຮງທົ່ວໄປ ກິບ",
                      value: `${formatCurrency(productOneData?.sale_wage_kip) || "-"}`,
                    },
                    {
                      title: "ສະຖານະ",
                      value: productOneData?.status,
                    },
                    {
                      title: "ລາຍລະອຽດສິນຄ້າ (ສະແດງທີແອັບອອມຄຳ)",
                      values: `${productOneData?.details || "-"}`,
                    },
                    {
                      title: "ໝາຍເຫດ",
                      values: `${productOneData?.note || "-"}`,
                    },
                    // {
                    //   title: "ຊື່ຊ່າງ",
                    //   value: [`${productOneData?.created_by || "-"}`],
                    // },
                    {
                      title: "ສ້າງໂດຍ",
                      value: [
                        `${productOneData?.created_by_full_name || "-"}`,
                        `${moment(productOneData?.created_at).format(
                          "DD/MM/YYYY LT"
                        )}`,
                      ],
                    },
                    {
                      title: "ອັບເດດໂດຍ",
                      value: [
                        `${productOneData?.updated_by_full_name || "-"}`,
                        `${moment(productOneData?.updated_at).format(
                          "DD/MM/YYYY LT"
                        )}`,
                      ],
                    },
                  ]}
                />
              </Col>

              <Col>
                <div>
                  <div style={{ marginLeft: "7px" }}>
                    ຮູບພາບ ({productOneData?.image?.length || 0}/10)
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3,auto)",
                    }}>
                    {productOneData?.image === null
                      ? ""
                      : productOneData?.image?.map((e, i) => (
                        <img
                          className=' border border-3 '
                          src={`${consts.S3}${e}`}
                          style={{
                            width: "170px",
                            height: "170px",
                            margin: "7px",
                          }}
                          key={i}
                        />
                      ))}
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #D1D5DB",
                    padding: "0px 20px",
                  }}>
                  <Table className='table table-hover'>
                    <thead>
                      <tr>
                        <th style={{ width: "auto" }}>ລໍາດັບ</th>
                        <th>ນໍ້າໜັກຄໍາ</th>
                        <th>ຈໍານວນເພີ່ມເຂົ້າ</th>
                        <th>ຄ້າງໃນສະຕັອກ</th>
                        <th>ຄ້າງໃນຕູ້</th>
                        <th>ຈໍານວນຍັງເຫຼືອ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productOneData?.weight?.map((e, i) => (
                        <tr>
                          <td valign='middle'>0{i + 1}</td>
                          <td valign='middle'>{e?.gram} g</td>
                          <td valign='middle'>{productOneData?.amount}</td>
                          <td valign='middle'>{productOneData?.stock_amount}</td>
                          <td valign='middle'>{productOneData?.cabinet_amount}</td>
                          <td valign='middle'>{productOneData?.remain_amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      onClick={() => setShowAddStock(true)}
                      style={{
                        backgroundColor: "#E5E5E7",
                        padding: 10,
                        paddingLeft: 54,
                        paddingRight: 54,
                        border: "none",
                        color: "#1059BF",
                        marginBottom: 20,
                      }}>
                      + ເພີ່ມ
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Tab>

      </Tabs>
    </>
  );
}
