import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Breadcrumb, Button, Card, Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import axios from 'axios';
import ReactLoading from 'react-loading';

import { getAccessTokkenFromLCS } from "../../helpers/user";
import { SERVER_URL } from '../../api'
import CustomList from '../../common/CustomList';
import moment from 'moment';
import { convertGender, formatCurrency } from '../../helpers';
import consts from '../../consts';

const ProductGoldOldDetail = () => {

  const params = useParams();
  const history = useHistory();

  const [goldDetail, setGoldDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getHistoryGold();
  }, [])

  const getHistoryGold = async () => {
    let _token = getAccessTokkenFromLCS();
    const id = params.id;
    try {
      setIsLoading(true)
      await axios.get(`${SERVER_URL}/stock-service/api/v1/take-off/${id}`,
        {
          headers: {
            Authorization: _token
          }
        }
      )
        .then((res) => {
          setGoldDetail(res?.data?.data);
          setIsLoading(false)
        })

    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }

  console.log('=============>',goldDetail);

  return (
    <>

      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => history.push(consts.STOCK_MANAGEMENT + '/limit/40/skip/1')}>ຈັດການປະຫວັດຄຳເກົ່າ</Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>ລາຍລະອຽດ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Tabs defaultActiveKey='ລາຍລະອຽດ' className='mb-n1'>
        <Tab eventKey='ລາຍລະອຽດ' title='ລາຍລະອຽດ'>
          {
            isLoading ? <>
              <Card style={{ padding: "20px" }}>
                <div className="loading-card">
                  <div>
                    <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
                    <span>Loading....</span>
                  </div>
                </div>
              </Card>
            </> :
              <>
                <Card style={{ padding: "20px" }}>
                  <h4 className='border-bottom border-2 pb-3'>ລາຍລະອຽດສິນຄ້າ</h4>
                  <Row>
                    <Col>
                      <CustomList
                        value={[
                          {
                            title: "ລະຫັດສິນຄ້າ",
                            value: `${goldDetail?.product_code || "-"}`,
                          },
                          {
                            title: "ຊື່ສິນຄ້າ",
                            value: `${goldDetail?.name || "-"}`,
                          },
                          {
                            title: "ໝວດສິນຄ້າ",
                            value: `${goldDetail?.product_type_name || "-"}`,
                          },
                          // {
                          //   title: "ເພດ",
                          //   value: `${convertGender(goldDetail?.gender) || "-"}`,
                          // },
                          // {
                          //   title: "ຫົວຫນ່ວຍ",
                          //   value: `${goldDetail?.unit?.[0] || "-"}`,
                          // },
                          {
                            title: "ລວດລາຍ",
                            value: `${goldDetail?.pattern_name || "-"}`,
                          },
                          // {
                          //   title: "ບ່ອນຜະລິດ",
                          //   value: `${goldDetail?.place_production || "-"}`,
                          // },
                          // {
                          //   title: "ຄ່າແຮງຫນ້າຮ້ານ ກີບ",
                          //   value: `${formatCurrency(goldDetail?.store_wage_kip) || "-"}`,
                          // },
                          // {
                          //   title: "ຄ່າແຮງໂຮງງານ ກິບ",
                          //   value: `${formatCurrency(goldDetail?.factory_wage_kip) || "-"}`,
                          // },
                          // {
                          //   title: "ຄ່າແຮງເຄື່ອງສົ່ງ ກີບ",
                          //   value: `${formatCurrency(goldDetail?.transmitter_wage_kip) || "-"}`,
                          // },
                          // {
                          //   title: "ຄ່າແຮງທົ່ວໄປ ກິບ",
                          //   value: `${formatCurrency(goldDetail?.sale_wage_kip) || "-"}`,
                          // },
                          {
                            title: "ສະຖານະ",
                            value: goldDetail?.gold_status == 'DAMAGED_GOLD' ? 'ຄຳເພ' : 'ຄຳເກົ່າ',
                          },
                          {
                            title: "ລາຍລະອຽດສິນຄ້າ (ສະແດງທີແອັບອອມຄຳ)",
                            values: `${goldDetail?.details || "-"}`,
                          },
                          {
                            title: "ໝາຍເຫດ",
                            values: `${goldDetail?.note || "-"}`,
                          },
                          // {
                          //   title: "ຊື່ຊ່າງ",
                          //   value: [`${goldDetail?.created_by || "-"}`],
                          // },
                          {
                            title: "ສ້າງໂດຍ",
                            value: [
                              `${goldDetail?.created_by_full_name || "-"}`,
                              `${moment(goldDetail?.created_at).format(
                                "DD/MM/YYYY LT"
                              )}`,
                            ],
                          },
                          {
                            title: "ອັບເດດໂດຍ",
                            value: [
                              `${goldDetail?.updated_by_full_name || "-"}`,
                              `${moment(goldDetail?.updated_at).format(
                                "DD/MM/YYYY LT"
                              )}`,
                            ],
                          },
                        ]}
                      />
                    </Col>

                    <Col>
                      <div>
                        <div style={{ marginLeft: "7px" }}>
                          ຮູບພາບ ({goldDetail?.image?.length || 0}/10)
                        </div>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3,auto)",
                          }}>
                          {goldDetail?.image === null
                            ? ""
                            : goldDetail?.image?.map((e, i) => (
                              <img
                                className=' border border-3 '
                                src={`${consts.S3}${e}`}
                                style={{
                                  width: "170px",
                                  height: "170px",
                                  margin: "7px",
                                }}
                                key={i}
                              />
                            ))}
                        </div>
                      </div>
                      <div
                        style={{
                          border: "1px solid #D1D5DB",
                          padding: "0px 20px",
                        }}>
                        {/* <Table className='table table-hover'>
                      <thead>
                        <tr>
                          <th style={{ width: "auto" }}>ລໍາດັບ</th>
                          <th>ນໍ້າໜັກຄໍາ</th>
                          <th>ຈໍານວນເພີ່ມເຂົ້າ</th>
                          <th>ຄ້າງໃນສະຕັອກ</th>
                          <th>ຄ້າງໃນຕູ້</th>
                          <th>ຈໍານວນຍັງເຫຼືອ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {goldDetail?.weight?.map((e, i) => (
                          <tr>
                            <td valign='middle'>0{i + 1}</td>
                            <td valign='middle'>{e?.gram} g</td>
                            <td valign='middle'>{goldDetail?.amount}</td>
                            <td valign='middle'>{goldDetail?.stock_amount}</td>
                            <td valign='middle'>{goldDetail?.cabinet_amount}</td>
                            <td valign='middle'>{goldDetail?.remain_amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table> */}
                        {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        // onClick={() => setShowAddStock(true)}
                        style={{
                          backgroundColor: "#E5E5E7",
                          padding: 10,
                          paddingLeft: 54,
                          paddingRight: 54,
                          border: "none",
                          color: "#1059BF",
                          marginBottom: 20,
                        }}>
                        + ເພີ່ມ
                      </Button>
                    </div> */}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </>

          }
        </Tab>

      </Tabs>
    </>
  )
}

export default ProductGoldOldDetail;