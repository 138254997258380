import { createSlice } from "@reduxjs/toolkit";
// api

// ---------------------------------------------------
export const notiDoc = createSlice({
    name: "notiDoc",
    initialState: {
        isOpenNoti: true,
    },
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpenNoti = action.payload;
        },
        setIsOpenNoti: (state, action) => {
            state.isOpenNoti = action.payload;
        },
    },
});


export const { setIsOpenNoti, setIsOpen } = notiDoc.actions;

export const isOpenNotisAsync = () => async (dispatch) => {
    dispatch(setIsOpenNoti(true))
};

export const isOpenNotiAsync = (value) => async (dispatch) => {
        console.log("value notie::::", value)
        dispatch(setIsOpenNoti(value))
};

export const selectNotiDoc = (state) => state.notiDoc;


export default notiDoc.reducer;
