import axios from "axios";
import { SERVER_URL } from "..";
import { getAccessTokkenFromLCS, getReNewToken } from "../../helpers/user";
let _data = JSON.parse(localStorage.getItem("KPV_MANAGEMENT"));


export const GetDataHistorybuyAndOrder = async (order_type, status_order) => {
  let _tokken = getAccessTokkenFromLCS();
  try {
    const _res = await axios.get(
      SERVER_URL +
        "/order-service/api/v1/make-order?order_type=" +
        order_type +
        "&status_order=" +
        status_order
        +"&branch_id="+_data.data.user.branch_id,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    return _res?.data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err, GetDataHistorybuyAndOrder());
    }
  }
};
export const GetDataHistorybuyAndOrderGoldBar = async (order_type, status_order) => {
  let _tokken = getAccessTokkenFromLCS();
  // let _data = JSON.parse(localStorage.getItem("KPV_MANAGEMENT"));
  try {
    const _res = await axios.get(
      SERVER_URL +
        "/order-service/api/v1/make-order?order_type=" +
        order_type +
        "&status_order=" +
        status_order
        +"&branch_id="+_data.data.user.branch_id,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    return _res?.data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err, GetDataHistorybuyAndOrder());
    }
  }
};
export const GetDataOrder = async (id) => {
  let _tokken = getAccessTokkenFromLCS();


  try {
    const _res = await axios.get(
      SERVER_URL + "/order-service/api/v1/make-order/" + id,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    return _res?.data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err, GetDataOrder());
    }
  }
};
export const UpdateStatusDataHistorybuyAndOrder = async (id, value) => {
  let _tokken = getAccessTokkenFromLCS();
  try {
    const _res = await axios.put(
      SERVER_URL + "/order-service/api/v1/make-order/" + id,
      value,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    return _res;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err, UpdateStatusDataHistorybuyAndOrder());
    }
  }
};
