import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import styled from "styled-components";

import Consts from "../consts";

//----------- admin
import CustomSideNav from "../layout/SideNav";
import NavbarAdmin from "../layout/Navbar";

//------------ pages
import Login from "../pages/login/Login";
import Tables from "../pages/customer/Tables";
import AddCustomer from "../pages/customer/CustomerManagementAdd"
import DetailCustomer from "../pages/customer/CustomerManagementDetail"
import EditCustomer from '../pages/customer/CustomerManagementEdit'

import StaffManagement from "../pages/staff/StaffManagement";
import StaffManagementDetail from "../pages/staff/StaffManagementDetail";

import ProductManagement from "../pages/product/ProductManagement"
import ProductDetail from '../pages/product/Detail'

import StockManagement from "../pages/stock/index";
import StockDetail from "../pages/stock/Detail";

import Report from "../pages/report/Report";
import ReportDetail from "../pages/report/Detail"
import ChangeFree from "../pages/report/change_free/ChangeFree"
import Sale from "../pages/report/sale/Sale"
import SaleDetail from "../pages/report/sale/SaleDetail"
import ChangeFreeDetail from "../pages/report/change_free/ChangeFreeDetail"
import ChangeMore from "../pages/report/change_more/ChangeMore";
import ChangeRefund from "../pages/report/refund/ChangeRefund";
import ChangeRefundDetail from "../pages/report/refund/ChangeRefundDetail";
import ChangeMoreDetail from "../pages/report/change_more/ChangeMoreDetail"
import BuyMore from "../pages/report/buy_more/BuyMore";
import BuyMoreDetail from "../pages/report/buy_more/BuyMoreDetail";
import Buy from "../pages/report/buy/Buy"
import BuyDetail from "../pages/report/buy/BuyDetail";
import Borrow from "../pages/report/borrow/Borrow";
import BorrowDetail from "../pages/report/borrow/BorrowDetail";
import Order from "../pages/report/order/Order";
import OrderDetail from "../pages/report/order/OrderDetail"
import Pledge from "../pages/report/pledge/Pledge";
import PledgeDetail from "../pages/report/pledge/PledgeDetail"

import CounterManagement from "../pages/counter/CounterManagement";
import CabinageManagement from '../pages/cabinage/CabinageManagement'
import CabinageDetail from "../pages/cabinage/CabinageDetail";
import CabinageProductDetail from "../pages/cabinage/ProductDetail";
import AddProductOnCabinage from '../pages/cabinage/AddProductOnCabinage'
import CabinageProductEdit from '../pages/cabinage/EditProductOnCabinage'
import ProductGoldOldDetail from "../pages/stock/ProductGoldOldDetail";
// import ProductGoldOld from '../pages/stock/ProductGoldOld';

import Aomkham from "../pages/aomkham/Aomkham";
// =======> History buy and order <=======
import HistoryBuy from "../pages/historyBuyAndOrder/historyBuy/HistoryBuy";
import HistoryBuySuccess from "../pages/historyBuyAndOrder/historyBuy/HistoryBuySuccess";
import HistoryBuyReject from "../pages/historyBuyAndOrder/historyBuy/HistoryBuyReject";
import HistoryBuyDetail from "../pages/historyBuyAndOrder/historyBuy/HistoryBuyDetail";
import HistoryBuyWaitting from "../pages/historyBuyAndOrder/historyBuy/HistoryBuyWaitting";
import HistoryBuyAppover from "../pages/historyBuyAndOrder/historyBuy/HistoryBuyAppover";
// =======> History buy and order <=======

// =====> order <=======
import HistoryBuyGoldBar from "../pages/historyBuyAndOrder/historybuygoldbar/HistoryBuyGoldBar";
import HistoryBuyGoldBarWaitting from "../pages/historyBuyAndOrder/historybuygoldbar/HistoryBuyGoldBarWaitting";
import HistoryBuyGoldBarApprove from "../pages/historyBuyAndOrder/historybuygoldbar/HistoryBuyGoldBarWaitting";
import HistoryBuyGoldBarSuccess from "../pages/historyBuyAndOrder/historybuygoldbar/HistoryBuyGoldBarSuccess";
import HistoryBuyGoldBarReject from "../pages/historyBuyAndOrder/historybuygoldbar/HistoryBuyGoldBarReject";
// =====> Expense <=======
import Expense from "../pages/expense/Expense";
import ExpenseHistory from "../pages/expense/ExpenseHistory";
import Document from "../pages/document/Document";



const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  padding: 0 20px;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`;

function RouterLink() {
  const [expanded, setExpanded] = useState(false);
  const _onToggle = (exp) => {
    setExpanded(exp);
  };
  return (
    <>
      <Router>
        <Switch>
          <PublicRoute exact path={Consts.PAGE_LOGIN} component={Login} />

          <Route
            render={({ location, history }) => (
              <React.Fragment>
                <CustomSideNav
                  location={location}
                  history={history}
                  onToggle={(exp) => _onToggle(exp)}
                />
                <NavbarAdmin />
            
                <Main expanded={expanded}>
                  <div
                    style={{
                      // marginTop: 80,
                      marginLeft: -20,
                      marginRight: -20,
                      backgroundColor: "#eee",
                      minHeight: "100vh",
                      paddingTop: 64,
                    }}>
                    <PrivateRoute
                      path='/'
                      component={(props) => <Redirect to='report' />}
                      exact
                    />

                    <>
                      <PrivateRoute
                        path={Consts.PAGE_REPORT + '/limit/:limit/skip/:skip'}
                        component={(props) => <Report />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/detail/:id`}
                        component={(props) => <ReportDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.EXPENSE}`}
                        component={(props) => <Expense />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.STOCK_MANAGEMENT}/gold-detail/:id`}
                        component={(props) => <ProductGoldOldDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.EXPENSE_HISTORY}/limit/:limit/skip/:skip`}
                        component={(props) => <ExpenseHistory />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/change-free/limit/:limit/skip/:skip`}
                        component={(props) => <ChangeFree />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/change-free/detail/:id`}
                        component={(props) => <ChangeFreeDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/sale/limit/:limit/skip/:skip`}
                        component={(props) => <Sale />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.PAGE_REPORT + "/sale/detail/:id"}
                        component={(props) => <SaleDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/change-more/limit/:limit/skip/:skip`}
                        exact
                        component={(props) => <ChangeMore />}
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/change-refund/limit/:limit/skip/:skip`}
                        exact
                        component={(props) => <ChangeRefund />}
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/change-more/detail/:id`}
                        component={(props) => <ChangeMoreDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/change-refund/detail/:id`}
                        exact
                        component={(props) => <ChangeRefundDetail />}
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/buy/limit/:limit/skip/:skip`}
                        component={(props) => <Buy />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/buy/detail/:id`}
                        component={(props) => <BuyDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/buy-more/limit/:limit/skip/:skip`}
                        component={(props) => <BuyMore />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/buy-more/detail/:id`}
                        component={(props) => <BuyMoreDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/borrow/limit/:limit/skip/:skip`}
                        component={(props) => <Borrow />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/borrow/detail/:id`}
                        component={(props) => <BorrowDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/order/limit/:limit/skip/:skip`}
                        component={(props) => <Order />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/order/detail/:id`}
                        component={(props) => <OrderDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/pledge/limit/:limit/skip/:skip`}
                        component={(props) => <Pledge />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PAGE_REPORT}/pledge/detail/:id`}
                        component={(props) => <PledgeDetail />}
                        exact
                      />
                    </>

                    <>
                      <PrivateRoute
                        path={Consts.COUNTER_MANAGEMENT + '/limit/:limit/skip/:skip'}
                        component={(props) => <CounterManagement />}
                        exact
                      />
                    </>
                    <>
                      <PrivateRoute
                        path={Consts.CABINAGE_MANAGEMENT + '/limit/:limit/skip/:skip'}
                        component={(props) => <CabinageManagement />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.CABINAGE_MANAGEMENT}/detail/:idCabinage/limit/:limit/skip/:skip`}
                        component={(props) => <CabinageDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.CABINAGE_MANAGEMENT}/detail/:idCabinage/product/:id`}
                        component={(props) => <CabinageProductDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.CABINAGE_MANAGEMENT}/detail/:idCabinage/edit-product/:id`}
                        component={(props) => <CabinageProductEdit />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.CABINAGE_MANAGEMENT}/detail/:idCabinage/limit/:limit/skip/:skip/Add`}
                        component={(props) => <AddProductOnCabinage />}
                        exact
                      />
                    </>
                    <>
                      <PrivateRoute
                        path={Consts.CUSTOMER_MANAGEMENT+"/limit/:limit/skip/:skip"}
                        component={(props) => <Tables />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.CUSTOMER_MANAGEMENT_ADD}/:id`}
                        component={(props) => <AddCustomer />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.CUSTOMER_MANAGEMENT_DETAIL}/:id`}
                        component={(props) => <DetailCustomer />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.CUSTOMER_MANAGEMENT_EDIT}/:id`}
                        component={(props) => <EditCustomer />}
                        exact
                      />
                    </>

                    <>
                      <PrivateRoute
                        path={Consts.AOMKHAM}
                        component={(props) => <Aomkham />}
                        exact
                      />
                    </>

                    <>
                      <PrivateRoute
                        path={Consts.STAFF_MANAGEMENT +"/limit/:limit/skip/:skip"}
                        component={(props) => <StaffManagement />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.STAFF_MANAGEMENT_DETAIL+'/:id'}
                        component={(props) => <StaffManagementDetail />}
                        exact
                      />
                    </>

                    <>
                      <PrivateRoute
                        path={Consts.PRODUCT_MANAGEMENT+"/limit/:limit/skip/:skip"}
                        component={(props) => <ProductManagement />}
                        exact
                      />
                      <PrivateRoute
                        path={`${Consts.PRODUCT_MANAGEMENT}/detail/:id`}
                        component={(props) => <ProductDetail />}
                        exact
                      />
                    </>

                    <>
                      <PrivateRoute
                        path={Consts.STOCK_MANAGEMENT + '/limit/:limit/skip/:skip'}
                        component={(props) => <StockManagement />}
                        exact
                      />
                      {/* <PrivateRoute
                        path={Consts.STOCK_MANAGEMENT + '/product-old-gold'}
                        component={(props) => <ProductGoldOld />}
                        exact
                      /> */}
                      <PrivateRoute
                        path={`${Consts.STOCK_MANAGEMENT}/detail/:id`}
                        component={(props) => <StockDetail />}
                        exact
                      />
                      {/* ========= history Buy and order */}
                      <PrivateRoute
                        path={Consts.HISTORY_BUY}
                        component={(props) => <HistoryBuy />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_SUCCESS}
                        component={(props) => <HistoryBuySuccess />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_REJECT}
                        component={(props) => <HistoryBuyReject />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_DETAIL +"/:id"}
                        component={(props) => <HistoryBuyDetail />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_WAITTING}
                        component={(props) => <HistoryBuyWaitting />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_APPOVER}
                        component={(props) => <HistoryBuyAppover />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_GOLD_BARS}
                        component={(props) => <HistoryBuyGoldBar />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_GOLD_BARS_APPROVE}
                        component={(props) => <HistoryBuyGoldBarApprove />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_GOLD_BARS_SUCCESS}
                        component={(props) => <HistoryBuyGoldBarSuccess />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_GOLD_BARS_REJECT}
                        component={(props) => <HistoryBuyGoldBarReject />}
                        exact
                      />
                      <PrivateRoute
                        path={Consts.HISTORY_BUY_GOLD_BARS_WAITTING}
                        component={(props) => <HistoryBuyGoldBarWaitting />}
                        exact
                      />


                        {/* Document */}
                        <PrivateRoute
                        path={`${Consts.DOCUMENT_LIST}/limit/:limit/skip/:skip`}
                        component={(props) => <Document /> }
                        exact
                      />
                      {/* ===== */}
                    
                      {/* ===== */}
                    </>
                  </div>
                  </Main>
          
              </React.Fragment>
            )}
          />
        </Switch>
      </Router>
    </>
  );
}

export default RouterLink;