import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Card, Row, Col, Breadcrumb } from "react-bootstrap";
import { CUSTOMER_MANAGEMENT } from "../../consts";
import "./style.css";
import consts from "../../consts";
import Success from "../../common/popup/success/Success";
import Fail from "../../common/popup/fail/fail";
import { SERVER_URL } from "../../api";
import { convertGender, convertDate, convertUserStatus, convertCustomerType } from "../../helpers";
import { getAccessTokkenFromLCS, getReNewToken } from "../../helpers/user";

export default function CustomerManagementsDetail() {
  const [fail, setFail] = useState(false);
  const history = useHistory();
  const [suc, setSuc] = useState(false);
  const [dataOne, setDataOne] = useState({});
  const [imageUser, setImageUser] = useState([]);
  const [imageAccountNumber, setImageAccountNumber] = useState({});
  const { id } = useParams();

  useEffect(() => {
    getDataCustomer();
  }, []);

  useEffect(() => {
    getImageDocument();
  }, [dataOne]);
  useEffect(() => {
    getImageAccountNumber();
  }, [dataOne]);

  const getDataCustomer = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      let getDataLocal = await axios({
        method: "GET",
        url: SERVER_URL + "/user-service/api/v1/user/" + id,
        headers: {
          Authorization: _token,
        },
      });
      setDataOne(getDataLocal?.data?.data);
      console.log('getDataLocal?.data?.data: ', getDataLocal?.data?.data);
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getDataCustomer());
      }
    }
  };

  const getImageDocument = async () => {
    try {
      var image_image = []
      for (let index = 0; index < dataOne?.image_document?.length; index++) {
        var data = JSON.stringify({
          "file_name": dataOne?.image_document[index]
        });

        var config = {
          method: 'post',
          url: `${SERVER_URL}/auth-service/api/v1/auth/generate-get-url-private`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        const dataImage = await axios(config)
        if (dataImage?.data?.data !== "") image_image.push(dataImage?.data);
      }

      setImageUser(image_image);
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getDataCustomer());
      }
    }
  };
  const getImageAccountNumber = async () => {
    try {
      var data = JSON.stringify({
        "file_name": dataOne?.image_account_number
      });

      var config = {
        method: 'post',
        url: `${SERVER_URL}/auth-service/api/v1/auth/generate-get-url-private`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      const dataImage = await axios(config)
      setImageAccountNumber(dataImage?.data);
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getDataCustomer());
      }
    }
  };

  const address = dataOne?.address;
  const shipment = dataOne?.shipment;

  return (
    <>
      <div style={{ paddingTop: 12 }}>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => history.push(consts.CUSTOMER_MANAGEMENT + '/limit/40/skip/1?page=RETAIL')} href="#"> ຈັດການລູກຄ້າ</Breadcrumb.Item>
            <Breadcrumb.Item active href="#"> ລາຍລະອຽດ</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      <Card className="p-5" style={{ position: "relative" }}>
        {/* <span style={{ top: '2rem', right: '2rem', position: 'absolute' }}>
                    <span onClick={() => History.push(`${CUSTOMER_MANAGEMENT_EDIT}/${dataOne.id}`,dataOne)} style={{ backgroundColor: '#F6F6F7', margin: '0 5px', padding: '0 6px', width: '36px', height: '36px', borderRadius: '5px', cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faPenToSquare} style={{ color: '#2E72D2' }} />
                    </span>
                    <span style={{ cursor: 'pointer', backgroundColor: '#F6F6F7', margin: '0 5px', padding: '0 6px', width: '36px', height: '36px', borderRadius: '5px' }}>
                        <FontAwesomeIcon onClick={()=>deleteUsers(id).then(e=>{
                            if(e.error === false){
                                setSuc(true)
                                setTimeout(()=>{
                                    History.push(consts.CUSTOMER_MANAGEMENT)
                                    window.location.reload()
                                },3000)
                            }
                            else setFail(true)
                        })} icon={faTrashCan} style={{ color: '#D82C0D' }} />
                    </span>
                </span> */}
        <Row>
          <Col md={5} className="mb-5">
            <div>
              <h3 className="border-bottom">ຂໍ້ມູນສ່ວນໂຕ</h3>
              <img
                className="border border-3 my-5"
                style={{ width: "152px", height: "152px", padding: "5px" }}
                src={consts.S3 + dataOne?.image_profile}
              />
              {[
                // { title: 'ລະຫັດ', value: dataOne?.username },
                {
                  title: "ຊື່ ແລະ ນາມສະກຸນ",
                  value: `${dataOne?.first_name} ${dataOne?.last_name}`,
                },
                { title: "ວັນເດືອນປີເກີດ", value: dataOne?.birthday },
                { title: "ເພດ", value: convertGender(dataOne?.gender) },
                { title: "ເບີໂທ ( ໃຊ້ເຂົ້າລະບົບ )", value: dataOne?.phone },
                { title: "ອິເມວ", value: dataOne?.email },
              ].map((result, index) => (
                <Row key={index} className="border-bottom py-2 mb-3">
                  <Col className="text-start">
                    <strong>{result.title}</strong>
                  </Col>
                  <Col className="text-end">{result.value}</Col>
                </Row>
              ))}
            </div>
          </Col>{" "}
          {/*left top */}
          <Col md={{ span: 5, offset: 1 }} className="mb-5">
            <div>
              <h3 className="border-bottom">ຂໍ້ມູນທົ່ວໄປ</h3>
              {[
                {
                  title: "ປະເພດລູກຄ້າ",
                  value: convertCustomerType(dataOne?.customer_type),
                },
                {
                  title: "ສະຖານະ",
                  value: convertUserStatus(dataOne?.user_status),
                },
                { title: "ປະເພດເອກະສານ", value: dataOne?.document_type },
                // { title: 'ປະເພດເອກະສານ', value: convertDocumentype(dataOne?.document_type )},
                { title: "ລະຫັດບັດ", value: dataOne?.id_card },
                { title: "ຊື່ທະນາຄານ", value: dataOne?.bank_name },
                { title: "ບັນຊີຊື່", value: dataOne?.account_number },
                { title: "ເລກບັນຊີ", value: dataOne?.account_number },
                { title: "ຈຳນວນທີ່ສາມາດສັ່ງຊື້ໄດ້", value: dataOne?.max_buys },
                { title: "ວັນທີສ້າງ", value: convertDate(dataOne?.created_at) },
                {
                  title: "ວັນທີແກ້ໄຂ",
                  value: convertDate(dataOne?.updated_at),
                },
              ].map((result, index) => (
                <Row key={index} className="border-bottom py-2 mb-3">
                  <Col className="text-start">
                    <strong>{result.title}</strong>
                  </Col>
                  <Col className="text-end">{result.value}</Col>
                </Row>
              ))}
              <strong>ບັດປະຈຳໂຕ ຫຼື ປື້ມສຳມະໂນ</strong>
              <div className="d-flex">
                {imageUser?.map((item, index) => {
                  return (
                    <span className="mx-2" >
                      <img key={index} alt='family book' style={{ width: "170px", height: "170px" }} src={item?.data} />
                    </span>
                  )
                })}
              </div>
              <hr />
              <strong>ຮູບບັນຊີ</strong>
              <div className="d-flex">
                <span className="mx-2">
                  {imageAccountNumber !== '' ?
                    <img
                      style={{ width: 170, height: 170 }}
                      alt='account'
                      src={imageAccountNumber?.data}
                    /> :
                    <div className="image-cover">
                      <img
                        style={{ width: 50, height: 50 }}
                        alt='account'
                        src="/asset/image/image-2-64.ico"
                      />
                    </div>
                  }
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={5} className="mb-5">
            <div>
              <h3 className="border-bottom">ຂໍ້ມູນທີ່ຢູ່</h3>
              {[
                {
                  title: "ບ້ານ",
                  value:
                    address?.[0]?.village === undefined
                      ? "-"
                      : address?.[0]?.village,
                },
                {
                  title: "ເມືອງ",
                  value:
                    address?.[0]?.district === undefined
                      ? "-"
                      : address?.[0]?.district,
                },
                {
                  title: "ແຂວງ",
                  value:
                    address?.[0]?.province === undefined
                      ? "-"
                      : address?.[0]?.province,
                },
              ].map((result, index) => (
                <Row key={index} className="border-bottom py-2 mb-3">
                  <Col className="text-start">
                    <strong>{result.title}</strong>
                  </Col>
                  <Col className="text-end">{result.value}</Col>
                </Row>
              ))}
            </div>
          </Col>
          <Col md={{ span: 5, offset: 1 }} className="mb-5">
            <div>
              <h3 className="border-bottom">ຂໍ້ມູນບ່ອນຈັດສົ່ງ</h3>
              {[
                {
                  title: "ບ້ານ",
                  value:
                    shipment?.[0].village === undefined
                      ? "-"
                      : shipment?.[0].village,
                },
                {
                  title: "ເມືອງ",
                  value:
                    shipment?.[0].district === undefined
                      ? "-"
                      : shipment?.[0].district,
                },
                {
                  title: "ແຂວງ",
                  value:
                    shipment?.[0].province === undefined
                      ? "-"
                      : shipment?.[0].province,
                },
              ].map((result, index) => (
                <Row key={index} className="border-bottom py-2 mb-3">
                  <Col className="text-start">
                    <strong>{result.title}</strong>
                  </Col>
                  <Col className="text-end">{result.value}</Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      </Card>
      <div
        style={{
          display: fail ? "inline" : "none",
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(0,0,0,0.4)",
          position: "fixed",
          top: "0",
          left: "0",
        }}
      >
        <Fail confirm={() => setFail(false)} />
      </div>
      <div
        style={{
          zIndex: "9003",
          display: suc ? "inline" : "none",
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(0,0,0,0.4)",
          position: "fixed",
          top: "0",
          left: "0",
        }}
      >
        <Success />
      </div>
    </>
  );
}

const RootStyle = styled.div`
  padding: 20px;
`;
