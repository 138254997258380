import { createSlice } from "@reduxjs/toolkit";
// api
import { branch } from "../../api/branch";

// ---------------------------------------------------
export const branchData = createSlice({
  name: "branch",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    addBranch: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },

    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addBranch } = branchData.actions;

export const loadBranchAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await branch();
    dispatch(addBranch(data));
  } catch {
    console.error("load branch");
  }
  dispatch(setLoading(false));
};

export const selectBranch = (state) => state.branch;

export default branchData.reducer;
