import React, { useState, useEffect, useRef } from "react";
/**
 *
 * @Library
 *
*/
// import axios from "axios";
import * as _ from 'lodash'
// import moment from 'moment'
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
// import { faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";
/**
 *
 * @Component
 *
 */
import { Card, Breadcrumb } from "react-bootstrap";
// import CustomTitleCard from "../../common/CustomTitleCard";
/**
 *
 * @Constant
 *
 */
import StockManagement from './StockManagement';
import ProductGoldOld from './ProductGoldOld';

/**
 *
 * @API
 *
 */

/**
 *
 * @Function
 *
 */
import { getUserDataFromLCStorage } from "../../helpers/user";

// ------------------------------------------------------ //
export default function Report() {
    const History = useHistory();
    const parsed = queryString.parse(History?.location?.search);

    const [dataSelect, setDataSelect] = useState({});

    const [changeUI, setChangeUI] = useState('STOCKMENAGER');

    useEffect(() => {
        if (parsed?.page) {
            let _filterPage = _dataHeader.filter(
                (data) => data?.value === parsed?.page
            );
            if (_filterPage?.length > 0) {
                setDataSelect(_filterPage[0]);
            }
        }
    }, []);

    const _dataHeader = [
        {
            header: "ຈັດການສະຕັອກ",
            title: "ຈັດການສະຕັອກ",
            eventKey: "1",
            value: "STOCKMENAGER",
        },
        {
            header: "ປະຫັວດຄຳເກົ້າ",
            title: "ປະຫັວດຄຳເກົ່າ",
            eventKey: "2",
            value: "PRODUCTGOLDOLD",
        },

    ];






    return (
        getUserDataFromLCStorage()?.role === 'MANAGER' ?
            <>
                {
                    changeUI === "STOCKMENAGER" && (
                        <div style={{ paddingTop: 12 }}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" active>ຈັດການສະຕັອກ</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                   ) 
                }
                
                {
                    changeUI === "PRODUCTGOLDOLD" && (
                        <div style={{ paddingTop: 12 }}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" active>ຈັດການປະຫກັດສີນຄ້າເກົ່າ</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                   ) 
                }
                <ul className="nav nav-tabs">

                    {_dataHeader.map((result, index) => (
                        <li className="nav-item" onClick={() => { setDataSelect(result); setChangeUI(result.value); }}>
                            <a
                                className="nav-link"
                                style={{
                                    color: dataSelect?.value === result?.value ? "blue" : "",
                                }}
                            >
                                {result?.title}
                            </a>
                        </li>
                    ))}
                </ul>
                <Card style={{ padding: 20, minHeight: "80vh", paddingBottom: 100 }}>
                    {
                        changeUI === "STOCKMENAGER" && (
                            <StockManagement />
                        )
                    }
                    {
                        changeUI === "PRODUCTGOLDOLD" && (
                            <ProductGoldOld />
                        )
                    }
                </Card>
            </>
            : <div></div>
    );
}




