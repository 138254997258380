import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Consts from "../../../consts";

export default function NavBar() {
  const History = useHistory();
  const Location = useLocation();

  const _historyPush = (item) => {
    History.push(item);
  };
  return (
    <div>
      <ul class="nav nav-tabs">
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_GOLD_BARS)}
        >
          <a
            class="nav-link"
            style={{
              color: Location?.pathname === Consts.HISTORY_BUY_GOLD_BARS ? "#2E72D2" : "",
            }}
          >
            ລາຍການສັ່ງຊື້ຄໍາແທ່ງ
          </a>
        </li>
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_GOLD_BARS_WAITTING)}
        >
          <a
            class="nav-link"
            style={{
              cursor: "pointer",
              color:
                Location?.pathname === Consts.HISTORY_BUY_GOLD_BARS_WAITTING
                  ? "#2E72D2"
                  : "",
            }}
          >
            ກໍາລັງເຮັດ
          </a>
        </li>
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_GOLD_BARS_APPROVE)}
        >
          <a
            class="nav-link"
            style={{
              cursor: "pointer",
              color:
                Location?.pathname === Consts.HISTORY_BUY_GOLD_BARS_APPROVE
                  ? "#2E72D2"
                  : "",
            }}
          >
            ລາຍການອະນຸມັດ
          </a>
        </li>
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_GOLD_BARS_SUCCESS)}
        >
          <a
            class="nav-link"
            style={{
              cursor: "pointer",
              color:
                Location?.pathname === Consts.HISTORY_BUY_GOLD_BARS_SUCCESS
                  ? "#2E72D2"
                  : "",
            }}
          >
            ຮັບຄໍາແລ້ວ
          </a>
        </li>
        {/* <li class="nav-item" style={{backgroundColor:"#EFF2F6"}} onClick={()=>_historyPush(Consts.HISTORY_BUY_SUCCESS)}> */}

        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_GOLD_BARS_REJECT)}
        >
          <a
            class="nav-link"
            style={{
              color:
                Location?.pathname === Consts.HISTORY_BUY_GOLD_BARS_REJECT
                  ? "#2E72D2"
                  : "",
            }}
          >
            ປະຫວັດການປະຕິເສດ
          </a>
        </li>
      </ul>
    </div>
  );
}
