// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//
import { Card } from "react-bootstrap";
// ---------------------------------------------- //
const CustomTitleCard = ({ icon, title, children, ...other }) => {
  return (
    <Card {...other}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          height: "100%",
        }}>
        <FontAwesomeIcon icon={icon} style={{ width: 32, height: 32 }} />
        <h2 style={{ fontWeight: 400, fontSize: 22, margin: 0 }}>{title}</h2>
        {children}
      </div>
    </Card>
  );
};
export default CustomTitleCard;
