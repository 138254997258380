import { useState } from 'react'
import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Card, Form, Modal } from 'react-bootstrap'
import { faX, faEye, faEyeSlash, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import upload from '../../api/upload'
import { addUsers } from '../../api/users'
import consts from '../../consts'
import Fail from '../../common/popup/fail/fail'
import Success from '../../common/popup/success/Success'
import { useHistory } from "react-router-dom";
import { getUserDataFromLCStorage } from '../../helpers/user'
import  {PatternFormat} from 'react-number-format';


export default function Add({ showAddStaff, handleCloseAddStaff, closeAdd }) {
    const history = useHistory();
    const { branch_id, branch_name } = getUserDataFromLCStorage()
    const [eye, setEye] = useState(false)
    const [imageName, setImageName] = useState("")
    const [picture, setPicture] = useState('');
    const [fail, setFail] = useState(false)
    const [suc, setSuc] = useState(false);
    const [IsDisabled, setIsDisabled] = useState(false)
    const [show, setShow] = useState(false);
    const clickCloseModalAdd = () => handleCloseAddStaff()
    const handleClose = () => {
        // setSuc(false)
        setPicture('')
        setIsDisabled(false)
        setShow(false);
        history.push("/staff-management/limit/40/skip/1");
    }
    const handleShow = () => setShow(true);

    const toggle = () => {
        setEye(!eye)
    }

    function validate() {
        const errors = {};
        if (!values.UserName) { errors.username = "Required"; }
        if (!values.first_name) { errors.first_name = "Required"; }
        if (!values.last_name) { errors.last_name = "Required"; }
        if (!values.Password) { errors.password = "Required"; }
        if (!values.Gender) { errors.gender = "Required"; }
        if (!values.Phone) { errors.phone = "Required"; }
        if (!values.role) { errors.role = "Required"; }
        return errors;
    }
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        resetForm

    } = useFormik({
        initialValues: {
            UserName: '',
            first_name: '',
            last_name: '',
            Email: '',
            Password: '',
            Gender: '',
            Phone: '',
            note: '',
            role: ''
        },
        validate,
        onSubmit: (values) => {
            const data = { ...values, image_profile: imageName, branch_id, branch_name }
            setIsDisabled(true)
            addUsers(data).then(e => {
                clickCloseModalAdd();
                handleShow();
                setTimeout(() => {
                    handleClose();
                }, 2000)
                resetForm({ values: '' })
                if (e.error === false) {
                    setSuc(true)
                    setIsDisabled(true)
                }
            })
        },
    })
    return (
        <>
            <Modal show={showAddStaff} onHide={clickCloseModalAdd} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>ເພີ່ມບັນຊີ</Modal.Title>
                </Modal.Header>
                <Card style={{ paddingLeft: 20, paddingRight: 20 }} >

                    <div className='text-center my-4'>
                        <div style={{ marginLeft: '-100px' }}>ຮູບພະນັກງານ</div>
                        <label htmlFor="file">
                            <div className='image-add-staff'>
                                <p style={{ display: picture === "" || undefined ? "block" : "none" }}>
                                    + upload
                                </p>
                                <img
                                    style={{
                                        display: picture === "" || undefined ? "none" : "block",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                    src={picture}
                                />
                            </div>
                        </label>
                        <input
                            id="file"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => {
                                upload(e).then((event) => {
                                    setImageName(event.imageName)
                                    setPicture(consts.S3 + event.imageName);
                                });
                            }}
                        />
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" >
                            <Form.Label>ຊື່ຜູ້ໃຊ້ງານ<span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control value={values.UserName} name="UserName" onChange={handleChange} onBlur={handleBlur} type="text" placeholder="ກະລຸນາປ້ອນ" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>ຊື່<span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control value={values.first_name} name="first_name" onChange={handleChange} onBlur={handleBlur} type="text" placeholder="ກະລຸນາປ້ອນ" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>ນາມສະກຸນ <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control value={values.last_name} name="last_name" onChange={handleChange} onBlur={handleBlur} type="text" placeholder="ກະລຸນາປ້ອນ" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control value={values.Email} name="Email" onChange={handleChange} type="email" placeholder="ກະລຸນາປ້ອນ" required/>
                        </Form.Group>
                        <Form.Group className="mb-3" style={{ position: 'relative' }}>
                            <Form.Label>ລະຫັດຜ່ານ <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control value={values.Password} name="Password" onChange={handleChange} onBlur={handleBlur} type={eye == true ? 'text' : 'password'} placeholder="ກະລຸນາປ້ອນ" required />
                            <FontAwesomeIcon onClick={toggle} style={{ position: 'absolute', top: '60%', right: '10', cursor: 'pointer' }} icon={eye == true ? faEye : faEyeSlash} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>ຄຳນຳຫນ້າ<span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Select value={values.Gender} name="Gender" onChange={handleChange} onBlur={handleBlur} required >
                                <option value="">ເພດ</option>
                                <option value="female">ຍິງ</option>
                                <option value="male">ຊາຍ</option>
                                <option value="other">ອື່ນໆ</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>ສິດທິນຳໃຊ້ລະບົບ<span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Select value={values.role} name="role" onChange={handleChange} onBlur={handleBlur} required >
                                <option value="">ເລືອກ</option>
                                <option value="STAFF">Staff</option>
                                <option value="SELLER">Seller</option>
                                <option value="CASHIER">Cashier</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>ເບີໂທລະສັບ<span style={{ color: 'red' }}>*</span></Form.Label>
                            {/* <Form.Control valus={values.Phone} name="Phone" onChange={handleChange} onBlur={handleBlur} type="text" placeholder="ກະລຸນາປ້ອນ" required /> */}
                            <PatternFormat style={{ width: "100%", height: 40, border: "1px solid #ced4da", borderRadius: "0.25rem", paddingLeft: 15 }}
                                value={values.Phone} name="Phone" onChange={handleChange} onBlur={handleBlur} placeholder="ກະລຸນາປ້ອນ" required format="########" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>ໝາຍເຫດ</Form.Label>
                            <Form.Control value={values.note} name="note" onChange={handleChange} as="textarea" placeholder="ກະລຸນາປ້ອນ" />
                        </Form.Group>
                        <div className='my-5'>
                            <button disabled={IsDisabled} type="submit" style={{ height: '49px' }} className='w-100 buttonRedColor'>ເພີ່ມຂໍ້ມູນ</button>
                        </div>
                    </Form>
                </Card>
                <div style={{ display: fail ? 'inline' : 'none', width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.4)', position: 'fixed', top: '0', left: '0' }}>
                    <Fail confirm={() => setFail(false)} />
                </div>
                <div style={{ display: suc ? 'inline' : 'none', width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.4)', position: 'fixed', top: '0', left: '0' }}>
                    <Success onClick={() => closeAdd()} />
                </div>
            </Modal>

            <Modal show={show} onHide={handleClose} centered>
                <div style={{ textAlign: "center", paddingBottom: 32, paddingTop: 32 }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div><FontAwesomeIcon icon={faCheckCircle} size="9x" color='#0ca678' /></div>
                    </div>
                    <div style={{ marginTop: 18, fontSize: 24, fontWeight: 700 }}>ເພີ່ມຂໍ້ມູນຜູ້ໃຊ້ລະບົບສຳເລັດ</div>
                </div>
            </Modal>
        </>
    )
}
