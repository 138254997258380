import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Modal, Button, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
    GetDataHistorybuyAndOrderGoldBar,
    UpdateStatusDataHistorybuyAndOrder,
} from "../../../api/historyBuyAndOrder/index";
import NavBar from "./NavBar";
import Consts from "../../../consts";
import { convertDate, formatCurrency } from "../../../helpers/index";
import { successAdd } from "../../../helpers/sweetalert";
import formatDate from "../../../helpers/formatDate";
import { SERVER_URL } from "../../../api/index";

import moment from "moment";

export default function HistoryBuyWaitting() {
    const History = useHistory();
    const [dateFrom, setDateFrom] = useState(formatDate(moment(new Date()).add("days", -30)));
    const [dateTo, setDateTo] = useState(formatDate(moment(new Date())));

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [dataSelect, setDataSelect] = useState({});
    const [dataComment, setDataComment] = useState('');
    const [sumData, setSumData] = useState({});


    const handleClose = () => setShow(false);
    const handleShow = (title, item, status) => {
        setDataSelect({ title, item, status });
        setShow(true);
    };

    useEffect(() => {
        _getDataHistoryBuy();
    }, []);
    useEffect(() => {
        setSumData({})
        let _data = {
            qtyProduct: 0,
            weightGolde: 0,
        };
        if (data?.length > 0) {
            for (let i = 0; i < data?.length; i++) {
                _data.qtyProduct += data[i].amount;
                _data.weightGolde += data[i].weight;
            }
            setSumData(_data)
        }
    }, [data]);
    useEffect(() => {
        _getDataHistoryBuy();
    }, [dateFrom, dateTo]);
    const _getDataHistoryBuy = async () => {
        let _res = await GetDataHistorybuyAndOrderGoldBar("MAKE_ORDER", "PROCESSING", dateFrom, formatDate(moment(dateTo).add('day', 1)));
        setData(_res);
    };
    const _updateStatus = async (status) => {
        let _data = {
            status_order: status ? "SUCCESSFUL" : "REJECT",
            kpv_comment: dataComment,
        };
        const _resUpdate = await UpdateStatusDataHistorybuyAndOrder(
            dataSelect.item.id,
            _data
        );
        if (_resUpdate.status === 200) {
            handleClose();
            _getDataHistoryBuy();
            successAdd("ອັບເດດສຳເລັດ");
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                title: _data?.status_order === "SUCCESSFUL" ? "ການສັ່ງຊື່ຄຳແມ່ນສຳເລັດແລ້ວ" : "ການສັ່ງຊື່ຄໍາຖືກຍົກເລີກ",
                description: dataSelect?.item?.branch_name,
                sender: dataSelect?.item?.customer_id,
                receivers: [dataSelect?.item?.customer_id],
                type: dataSelect?.item?.order_type,
                data: dataSelect?.item?.id,
                image: "",
                note: dataComment,
            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
            const _createData = await fetch(
                `${SERVER_URL}/notification-service/api/v1/notification`,
                requestOptions
            );
        }
    };
    return (
        <div>
            <p style={{ padding: 10 }}>ລາຍການສັ່ງຊື້ຄໍາ</p>
            <div style={{ height: 10 }}></div>
            <NavBar />
            <Card
                style={{
                    padding: "20px",
                    paddingTop: "10px",
                    border: "none",
                }}
                body
            >
                <h3 style={{ color: "#003049" }}>ລາຍການກໍາລັງສັ່ງຊື້</h3>
                <Row className="mt-3">
                    <Col md={3}>
                        <Form.Group controlId="doj">
                            <Form.Label>ເລືອກວັນທີ</Form.Label>
                            <Form.Control
                                type="date"
                                name="doj"
                                placeholder="Date of Joining"
                                value={dateFrom}
                                onChange={(e) => {
                                    setDateFrom(e.target.value)
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="doj">
                            <Form.Label>ຫາວັນທີ</Form.Label>
                            <Form.Control
                                type="date"
                                name="doj"
                                placeholder="Date of Joining"
                                value={dateTo}
                                onChange={(e) => {
                                    setDateTo(e.target.value)
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <div style={{ height: 20 }}></div>
                <table class="table">
                    <tr style={{ textAlign: "center" }}>
                        <th className="col-1">ລຳດັບ</th>
                        <th className="col-1">ລະຫັດລູກຄ້າ</th>
                        <th className="col-1">ຊື່ ແລະ ນາມສະກຸນ</th>
                        <th className="col-1">ເບີໂທ</th>
                        <th className="col-1">ຈໍານວນສິນຄ້າ</th>
                        <th className="col-1">ລວມນໍ້າໜັກ</th>
                        <th className="col-1">ວັນທີຊື້</th>
                        <th className="col-1">ວັນທີຮັບສີນຄ້າ</th>
                        {/* <th className="col-2">ການຈັດການ</th> */}
                    </tr>
                    <tbody>
                        {data?.map((item, index) => (
                            <tr style={{ textAlign: "center" }}>
                                <th
                                    scope="row"
                                    // onClick={() =>
                                    //     History.push(
                                    //         Consts.HISTORY_BUY_DETAIL + "/" + item?.id,
                                    //         item
                                    //     )
                                    // }
                                >
                                    {index + 1}
                                </th>
                                <td
                                    // onClick={() =>
                                    //     History.push(
                                    //         Consts.HISTORY_BUY_DETAIL + "/" + item?.id,
                                    //         item
                                    //     )
                                    // }
                                >
                                    {item?.customer_code}
                                </td>
                                <td
                                    // onClick={() =>
                                    //     History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                                    // }
                                >
                                    {item?.customer_first_name} {item?.customer_last_name}
                                </td>
                                <td
                                    // onClick={() =>
                                    //     History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                                    // }
                                >
                                    {item?.customer_phone ?? "-"}
                                </td>
                                <td
                                    // onClick={() =>
                                    //     History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                                    // }
                                >
                                    {formatCurrency(item?.amount)}
                                </td>
                                <td
                                    // onClick={() =>
                                    //     History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                                    // }
                                >
                                    {item?.weight}
                                </td>
                                <td
                                    // onClick={() =>
                                    //     History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                                    // }
                                >
                                    {moment(item?.created_at).format("yyyy-MM-DD LT")}
                                </td>
                                <td
                                    // onClick={() =>
                                    //     History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                                    // }
                                >
                                    {moment(item?.picked_up_date).format("yyyy-MM-DD")}
                                </td>
                                {/* <td>
                                    <button
                                        type="button"
                                        class="btn btn-danger"
                                        onClick={() => handleShow("ປະຕິເສດ", item, false)}
                                    >
                                        ປະຕິເສດ
                                    </button>{" "}
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => handleShow("ອະນຸມັດ", item, true)}
                                    >
                                        ອະນຸມັດ
                                    </button>
                                </td> */}
                            </tr>
                        ))}
                        <tr style={{ textAlign: "center", fontWeight: "bold" }}>
                            <td colspan={4}>ລວມ</td>
                            <td>{sumData?.qtyProduct}</td>
                            <td>{sumData?.weightGolde} g</td>
                            <td></td>
                            <td></td>
                            {/* <td></td> */}
                        </tr>
                    </tbody>
                </table>
            </Card>
        </div>
    );
}
