import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

/**
 *
 * @Library
 *
 */
// import axios from "axios";
import * as _ from "lodash";
// import moment from 'moment'
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";
/**
 *
 * @Component
 *
 */
import { Card, Breadcrumb } from "react-bootstrap";
// import CustomSearch from "../../common/CustomSearch";
import CustomTitleCard from "../../common/CustomTitleCard";
/**
 *
 * @Constant
 *
 */
// import consts from "../../consts";
import Income from "./income/Income";
import Expenses from "./expenses/Expenses";
import ReportAll from "./ReportAll";

/**
 *
 * @API
 *
 */
// import { SERVER_URL } from "../../api";

/**
 *
 * @Function
 *
 */
import { getUserDataFromLCStorage } from "../../helpers/user";
import { loadDocumentAsync, selectDocument } from "../../redux/document";
import { CHECK_POPUP } from "../../consts/user";
import {
  selectDocumentSeen,
  loadDocumentSeenPersonAsync,
} from "../../redux/document/DocumentSeen";
import DetailDocument from "../document/component/DetailDocument";
import NotiDocument from "../document/component/NotiDocument";

// ------------------------------------------------------ //
export default function Report() {
  const History = useHistory();
  const parsed = queryString.parse(History?.location?.search);
  const ducumentData = useSelector(selectDocument);
  const dispatch = useDispatch();


  const [dataSelect, setDataSelect] = useState({
    header: "ລາຍຮັບ",
    title: "ລາຍຮັບ",
    eventKey: "1",
    value: "INCOME",
  });

  const [changeUI, setChangeUI] = useState("INCOME");

  //noti
  const [isNoti, setIsNoti] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showDetailDocument, setShowDetailDocument] = useState(false);
  const [documentId, setDocumentId] = useState();

  useEffect(() => {
    if (parsed?.page) {
      let _filterPage = _dataHeader.filter(
        (data) => data?.value === parsed?.page
      );
      if (_filterPage?.length > 0) {
        setDataSelect(_filterPage[0]);
      }
    }

    const getData = () => {
      if (ducumentData.isLoading) {
      dispatch(loadDocumentAsync());
      }
      const checkStatusReadDocument = Cookies.get(CHECK_POPUP);

      console.log("checkStatusReadDocument::", checkStatusReadDocument);

      if (checkStatusReadDocument === undefined) {
        setIsOpen(true);
        return;
      }
      if (checkStatusReadDocument === "READ_LATER") {
        setIsNoti(false);
        return;
      }
    };
    return getData();
  }, []);

  useEffect(() => {
    //TODO: if document have more than one state is set true
    if (isOpen && ducumentData?.data.length > 0) {
      setIsNoti(true);
      return;
    }
    //TODO: if document have not more than one state is set false
    setIsNoti(false);
  }, [ducumentData?.data, isOpen]);

  console.log("isNoti", isNoti);

  const _dataHeader = [
    {
      header: "ລາຍງານລວມ",
      title: "ລາຍງານລວມ",
      eventKey: "1",
      value: "REPORTALL",
    },
    {
      header: "ລາຍຮັບ",
      title: "ລາຍຮັບ",
      eventKey: "2",
      value: "INCOME",
    },
    {
      header: "ລາຍຈ່າຍ",
      title: "ລາຍຈ່າຍ",
      eventKey: "3",
      value: "EXPENSES",
    },
  ];

  //gotoDocumentList
  const gotoDocumentList = () => {
    History.replace(`/document-list/limit/40/skip/1`);
    dispatch(loadDocumentAsync());
    dispatch(loadDocumentSeenPersonAsync());
  };

  return (
    <>
      {getUserDataFromLCStorage()?.role === "MANAGER" ? (
        <>
          {/* {changeUI === "INCOME" &&
             <div style={{ paddingTop: 12 }}>
             <Breadcrumb>
               <Breadcrumb.Item href="#" active>
                 ລາຍງານລາຍຮັບ
               </Breadcrumb.Item>
             </Breadcrumb>
           </div>
 }
          {changeUI === "EXPENSES" && 
              <div style={{ paddingTop: 12 }}>
              <Breadcrumb>
                <Breadcrumb.Item href="#" active>
                  ລາຍງານລາຍຈ່າຍ
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          )}
          {changeUI === "REPORTALL" && (
            <div style={{ paddingTop: 12 }}>
              <Breadcrumb>
                <Breadcrumb.Item href="#" active>
                  ລາຍງານລວມ
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          )}
          {changeUI === "INCOME" && (
            <CustomTitleCard
              title="ລາຍງານລາຍຮັບທັງໝົດ"
              icon={faSquarePollVertical}
              style={{ padding: 20, marginBottom: 4 }}
            />
          )}
          {changeUI === "EXPENSES" && (
            <CustomTitleCard
              title="ລາຍງານລາຍຈ່າຍທັງໝົດ"
              icon={faSquarePollVertical}
              style={{ padding: 20, marginBottom: 4 }}
            />
          )}
          {changeUI === "REPORTALL" && (
            <CustomTitleCard
              title="ລາຍງານລວມທັງໝົດ"
              icon={faSquarePollVertical}
              style={{ padding: 20, marginBottom: 4 }}
            />
}
        
       */}
          <ul className="nav nav-tabs mt-3">
            {_dataHeader.map((result, index) => (
              <li
                key={index}
                className="nav-item"
                onClick={() => {
                  setDataSelect(result);
                  setChangeUI(result.value);
                }}
              >
                <a
                  className="nav-link"
                  style={{
                    color: dataSelect?.value === result?.value ? "" : "#2F80ED",
                    backgroundColor: dataSelect?.value === result?.value ? "#fff" : "#eee"
                  }}
                >
                  {result?.title}
                </a>
              </li>
            ))}
          </ul>
          <div style={{ minHeight: "80vh", paddingBottom: 100 }}>
            {changeUI === "INCOME" && <Income />}
            {changeUI === "EXPENSES" && <Expenses />}
            {changeUI === "REPORTALL" && <ReportAll />}
          </div>
        </>
      ) : (
        <div></div>
      )}

      <NotiDocument
        isNoti={isNoti}
        handleCloseNoti={() => setIsNoti(false)}
        setShowDetailDocument={setShowDetailDocument}
        setDocumentId={setDocumentId}
      />
      <DetailDocument
        showDetailDocument={showDetailDocument}
        handleClose={() => {
          setShowDetailDocument(!showDetailDocument);
          setDocumentId();
          gotoDocumentList();
        }}
        documentId={documentId}
      />
    </>
  );
}
