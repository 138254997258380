import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectDocument, loadDocumentAsync } from "../redux/document";
import {
  selectDocumentSeen,
  loadDocumentSeenPersonAsync,
} from "../redux/document/DocumentSeen";
import Cookies from "js-cookie";
import { FaBell, FaFilePdf, FaTimes } from "react-icons/fa";
import DetailDocument from "../pages/document/component/DetailDocument";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { calutionTime } from "../helpers";
import { Navbar, Dropdown, Nav, NavDropdown, Badge } from "react-bootstrap";
import ReactLoading from "react-loading";

import { useHistory } from "react-router-dom";
import {
  ClearUserDataFormLCStorage,
  getUserDataFromLCStorage,
} from "../helpers/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// styled-components
import styled from "styled-components";
import consts from "../consts";
import { CHECK_POPUP } from "../consts/user";
let versionApp = require("../../package.json");

// ------------------------------------------------------------------------------- //
export default function NavbarAdmin() {
  const history = useHistory();
  const ducumentData = useSelector(selectDocument);
  const ducumentSeenData = useSelector(selectDocumentSeen);

  const dispatch = useDispatch();
  const [showDetailDocument, setShowDetailDocument] = useState(false);
  const [documentId, setDocumentId] = useState();
  const [showNoticMenu, setShowNoticMenu] = useState(false);
  const [unRead, setUnRead] = useState(true);
  const [doneRead, setDoneRead] = useState(false);

  useEffect(() => {
    const _resData = getUserDataFromLCStorage();
    if (!_resData?._id) {
      history.push(`/login`);
    }

    const getData = () => {
      if (ducumentData.isLoading) {
        dispatch(loadDocumentAsync());
      }
      if (ducumentSeenData.isLoading) {
        dispatch(loadDocumentSeenPersonAsync());
      }
    };
    return getData();
  }, []);
  const Logout = () => {
    ClearUserDataFormLCStorage();
    Cookies.remove(CHECK_POPUP);
    localStorage.clear();
    sessionStorage.clear();
    history.push("/login");
  };


  //gotoDocumentList
  // const gotoDocumentList = () => {
  //   history.replace(`/document-list/limit/40/skip/1`);
  //   dispatch(loadDocumentAsync());
  // };


//view un read
const viewUnRead = () => {
  setDoneRead(!doneRead);
  setUnRead(!unRead);
};

//view done read
const viewDoneRead = () => {
  setDoneRead(!doneRead);
  setUnRead(!unRead);
};

//gotoDocumentList
const gotoDocumentList = () => {
  history.replace(`/document-list/limit/40/skip/1`);
  dispatch(loadDocumentAsync());
  dispatch(loadDocumentSeenPersonAsync());
};

const active = {
  borderBottom: "1px solid #034060",
};











  return (
    <div className="theme-red">
      <Navbar
        style={{
          backgroundColor: "#ffffff",
          boxShadow:
            "0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12)",
          color: "#00072B!important",
          width: "100%",
          height: 64,
          position: "fixed",
          marginLeft: 65,
          paddingRight: 80,
          zIndex: 999,
          display: "flex",
          // display: "flex",
          // flexDirection: "column",
          // width: "100%",
          // height: 64,
          justifyContent: "end",
          alignItems: "center",
        }}
        variant="dark"
      >
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "30%",
            height: 50,
            alignItems: "center",
            gap: "10px",
            justifyContent: "end",
          }}> */}
        <div style={{ fontSize: 12, color: "grey" }}>{versionApp?.version}</div>

        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            display: showNoticMenu ? "block" : "none",
          }}
          onClick={() => setShowNoticMenu(false)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            position: "relative",
            marginLeft: 8,
            marginRight: 8,
          }}
          className="bell-icon"
        >
          <FaBell
            style={{ color: "#555246", fontSize: 24, zIndex: 100 }}
            onClick={() => setShowNoticMenu(!showNoticMenu)}
          />
          {ducumentData?.data.length > 0 ? (
            <Badge
              className="notic-text"
              style={{ zIndex: 500 }}
              bg="danger"
              onClick={() => setShowNoticMenu(!showNoticMenu)}
            >
              {ducumentData?.data.length > 9 ? "9+" : ducumentData?.data.length}
            </Badge>
          ) : (
            <></>
          )}

          <ul
            className="popup-menu"
            id="popup-menu"
            style={{
              overflowY: "scroll",
              display: showNoticMenu ? "block" : "none",
              zIndex: 100,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 10,
                margin: "10px 20px 0px 20px",
                fontSize: 22,
                fontWeight: 700,
                color: "#034060",
              }}
            >
              <div>ເເຈ້ງເຕືອນ</div>
              <span
                onClick={() => setShowNoticMenu(false)}
                style={{ cursor: "pointer" }}
              >
                <FaTimes />
              </span>
            </div>
            <div className="popup-read">
              <div
                className="unRead"
                style={unRead ? active : {}}
                onClick={() => viewUnRead(true)}
              >
                ຍັງບໍ່ທັນໄດ້ອ່ານ ({ducumentData?.data.length})
              </div>
              <div
                className="dongRead"
                style={doneRead ? active : {}}
                onClick={() => viewDoneRead(true)}
              >
                ອ່ານແລ້ວ
              </div>
            </div>
            {/* <hr style={{marginBottom:5}}></hr> */}

            {unRead ? (
              ducumentData?.isLoading ? (
                <div className="loading-card">
                  <div>
                    <ReactLoading
                      className="loading-cus"
                      type="spin"
                      color="#960000"
                      height={50}
                      width={50}
                    />
                    <span>Loading....</span>
                  </div>
                </div>
              ) : ducumentData?.data.length === 0 ? (
                <div>
                  <center>
                    <img alt="" src="/image/animation_200_ldu129fc.gif" />
                    <p style={{ marginTop: -20, color: "grey" }}>
                      ຍັງບໍ່ມີຂໍ້ມູນ!
                    </p>
                  </center>
                </div>
              ) : (
                ducumentData?.data?.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setShowDetailDocument(!showDetailDocument);
                      setDocumentId(item?.id);
                    }}
                  >
                    <a href="#">
                      <span className="popup-menu-icon">
                        <FaFilePdf />
                      </span>
                      <div className="popup-menu-detail">
                        {item?.title}
                        <span>{calutionTime(item?.created_at)}</span>
                      </div>
                    </a>
                  </li>
                ))
              )
            ) : (
              ""
            )}

            {doneRead ? (
              ducumentSeenData?.isLoading ? (
                <div className="loading-card">
                  <div>
                    <ReactLoading
                      className="loading-cus"
                      type="spin"
                      color="#960000"
                      height={50}
                      width={50}
                    />
                    <span>Loading....</span>
                  </div>
                </div>
              ) : ducumentSeenData?.length === 0 ? (
                <div>
                  <center>
                    <img alt="" src="/image/animation_200_ldu129fc.gif" />
                    <p style={{ marginTop: -20, color: "grey" }}>
                      ຍັງບໍ່ມີຂໍ້ມູນ!
                    </p>
                  </center>
                </div>
              ) : (
                ducumentSeenData?.data?.map((item, index) => (
                  <li
                    style={{ backgroundColor: "#ffffff" }}
                    key={index}
                    onClick={() => {
                      setShowDetailDocument(!showDetailDocument);
                      setDocumentId(item?.id);
                    }}
                  >
                    <a href="#">
                      <span className="popup-menu-icon">
                        <FaFilePdf />
                      </span>
                      <div className="popup-menu-detail">
                        {item?.title}
                        <span>{calutionTime(item?.created_at)}</span>
                      </div>
                    </a>
                  </li>
                ))
              )
            ) : (
              ""
            )}
          </ul>
        </div>

        <Row style={{ cursor: "pointer" }}>
          <Dropdown style={{ outline: "none" }}>
            <Dropdown.Toggle
              style={{
                outline: "none",
                backgroundColor: "#ffffff",
                color: "#000000",
                display: "flex",
                alignItems: "center",
                border: "none",
              }}
              id="dropdown-button-dark-example1"
            >
              <Avatar style={{ margin: "0 5px" }}>
                {getUserDataFromLCStorage()?.image_profile ? (
                  <img
                    src={consts.S3 + getUserDataFromLCStorage()?.image_profile}
                    alt="user"
                  />
                ) : (
                  <FontAwesomeIcon icon={faUser} />
                )}
              </Avatar>
              <h5 className="m-0">{getUserDataFromLCStorage()?.first_name}</h5>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item>ຂໍ້ມູນຜູ້ໃຊ້</Dropdown.Item>  */}
              <Dropdown.Item>
                Role: {getUserDataFromLCStorage()?.role}
              </Dropdown.Item>
              <Dropdown.Item>
                ປະຈຳສາຂາ: {getUserDataFromLCStorage()?.branch_name}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => Logout()}>
                ອອກຈາກລະບົບ
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Row>

        {/* </div> */}
      </Navbar>
      <DetailDocument
        showDetailDocument={showDetailDocument}
        handleClose={() => {
          setShowDetailDocument(!showDetailDocument);
          setDocumentId();
          gotoDocumentList();
        }}
        documentId={documentId}
      />
    </div>
  );
}

const Avatar = styled.div`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
