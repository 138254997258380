import moment from "moment";
import consts from "../consts";

export const convertDate = (date) => {
  return moment(date).format(consts.DATE_FORMAT);
};
export const formatCurrencyGold = (int) => {
  return int?.toFixed(2);
};
export const formatCurrency = (int) => {
  var formatter = new Intl.NumberFormat("en-US");
  return formatter.format(int);
};
// ກຳນົດ ຟໍແມັດເງິນ
export const CurrencyMoney = (value) => {
  const formattedNumber = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'LAK' })
  .format(value)
  .replace(/ LAK$/, '');
  return formattedNumber.split('LAK')[0]
};
export const convertStatusOrder = (status) => {
  if (status == "WAIT_APPROVE") {
    return "ກຳລັງອະນຸມັດ";
  }
  if (status == "PROCCESING") {
    return "ກຳລັງດຳເນີນການ";
  }
  if (status == "SUCCESSFUL") {
    return "ສຳເລັດ";
  }
  if (status == "RECEIVED") {
    return "ຮັບເຄື່ອງແລ້ວ";
  }
  if (status == "REJECT") {
    return "ປະຕິເສດ";
  }
};
export const convertCurrencyNumber = (number) => {
  var numberString = number.toString().replace(/,/g, ''); // Remove commas from the number string
  var length = Math.floor(Math.log10(Math.abs(numberString)))+1 ;
  var placeValue = Math.pow(10, length - 4); // Round to the nearest thousandth
  var rounded = Math.floor(numberString / placeValue) * placeValue; // Use Math.floor to round down

  var lastDigit = Number(numberString[numberString.length - 1]);
  if (lastDigit >= 5) {
    rounded += placeValue; // Add placeValue to round up
  }

  return rounded.toLocaleString();
};

export const convertGender = (status) => {
  if (status == "FEMALE") {
    return "ຍິງ";
  }
  if (status == "Female") {
    return "ຍິງ";
  }
  if (status == "MALE") {
    return "ຊາຍ";
  }
  if (status == "Male") {
    return "ຊາຍ";
  }
  if (status == "All") {
    return "ທຸກເພດ";
  }
  if (status == "ALL") {
    return "ທຸກເພດ";
  }
};
export const convertUserStatus = (status) => {
  if (status == "WAITTING") {
    return "ລໍຖ້າຢືນຢັນ";
  }
  if (status == "ACTIVE") {
    return "ເປີດໃຊ້ງານ";
  }
  if (status == "INACTIVE") {
    return "ປະຕິເສດ ( ປິດໃຊ້ງານ )";
  }
};
export const convertCustomerType = (status) => {
  if (status == "RETAIL") {
    return "ລູກຄ້າເຄື່ອງສົ່ງ";
  }
  if (status == "AOMKHAM") {
    return "ລູກຄ້າອອມຄຳ";
  }
  if (status == "VIP") {
    return "ລູກຄ້າ VIP";
  }
  if (status == "NORMAL") {
    return "ລູກຄ້າທົ່ວໄປ";
  }
};
export const convertDocumentype = (status) => {
  if (status == "id_card") {
    return "ບັດປະຈຳໂຕ";
  }
  if (status == "family_book") {
    return "ປື້ມສຳມະໂນ";
  }
};
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};



export const statusDeleteBunsee = (status) => {
  return status === "NO" ? (
    <div
      style={{
        color: "green",
        border: "1px solid green",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      Active
    </div>
  ) :
    <div
      style={{
        color: "#D82C0D",
        border: "1px solid #D82C0D",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      Delete
    </div>
  ;
};

export const convertOrderType = (type) => {
  let res = "";
  switch (type) {
    case "SALE":
      res = "ຂາຍ";
      break;
    case "CHANGE_FREE":
      res = "ປ່ຽນຟຣີ";
      break;
    case "CHANGE_MORE":
      res = "ປ່ຽນຕື່ມ";
      break;
    case "CHANGE_REFUND":
      res = "ປ່ຽນຄືນເງິນ";
      break;
    case "BUY_MORE":
      res = "ຊື້ຕື່ມ";
      break;
    case "BUY":
      res = "ຊື້ຄຳຄືນ";
      break;
    case "RENT":
      res = "ເຊົ່າ";
      break;
    case "ORDER":
      res = "ຈອງ";
      break;
    case "PLEDGE":
      res = "ຈຳຄຳ";
      break;

    default:
        break;
  }
  return res;
}
export const convertOrderTypeMessage = (type) => {
  let res = "";
  switch (type) {
    case "SALE":
      res = "ເມນູຂາຍ";
      break;
    case "CHANGE_FREE":
      res = "ປ່ຽນຟຣີ";
      break;
    case "CHANGE_MORE":
      res = "ປ່ຽນຕື່ມ";
      break;
    case "CHANGE_REFUND":
      res = "ປ່ຽນຄືນເງິນ";
      break;
    case "BUY_MORE":
      res = "ເມນູຊື້ຕື່ມ";
      break;
    case "BUY":
      res = "ຊື້ຄຳຄືນ";
      break;
    case "RENT":
      res = "ການເຊົ່າຄຳ";
      break;
    case "ORDER":
      res = "ການຈອງຄຳ";
      break;
    case "PLEDGE":
      res = "ການຈຳຄຳ";
      break;

    default:
        break;
  }
  return res;
}
export const convertOrderTypeGoldNew = (type) => {
  let res = "";
  switch (type) {
    case "SALE":
      res = "ຂາຍອອກທັງໝົດ";
      break;
    case "CHANGE_FREE":
      res = "ຄຳປ່ຽນອອກທັງໝົດ";
      break;
    case "CHANGE_MORE":
      res = "ຄຳປ່ຽນອອກທັງໝົດ";
      break;
    case "CHANGE_REFUND":
      res = "ຄຳປ່ຽນອອກທັງໝົດ";
      break;
    case "BUY_MORE":
      res = "ຂາຍອອກທັງໝົດ";
      break;
    case "BUY":
      res = "ຊື້ຄຳຄືນ";
      break;
    case "RENT":
      res = "ຄຳເຊົ່າຮັບຄືນສະເພາະວັນທີ";
      break;
    case "ORDER":
      res = "ຄຳຈອງຮັບແລ້ວທັງໝົດ";
      break;
    case "PLEDGE":
      res = "ຍອດຄຳເກົ່າຮັບຄືນທັງໝົດ";
      break;

    default:
        break;
  }
  return res;
}
export const convertOrderTypeGoldOld = (type) => {
  let res = "";
  switch (type) {
    case "SALE":
      res = "ຄຳຍັງເຫຼືອທັງໝົດ";
      break;
    case "CHANGE_FREE":
      res = "ຍອດຄຳເກົ່າຈາກລູກຄ້າທັງໝົດ";
      break;
    case "CHANGE_MORE":
      res = "ຍອດຄຳເກົ່າຈາກລູກຄ້າທັງໝົດ";
      break;
    case "CHANGE_REFUND":
      res = "ປ່ຽນຄືນເງິນ";
      break;
    case "BUY_MORE":
      res = "ຍອດຄຳເກົ່າຈາກລູກຄ້າທັງໝົດ";
      break;
    case "BUY":
      res = "ຊື້ຄຳຄືນ";
      break;
    case "RENT":
      res = "ຄຳເຊົ່າອອກສະເພາະວັນທີ";
      break;
    case "ORDER":
      res = "ຄຳທີ່ຈອງໄວ້ທັງໝົດ";
      break;
    case "PLEDGE":
      res = "ຍອດຄຳເກົ່າຈາກລູກຄ້າທັງໝົດ";
      break;

    default:
        break;
  }
  return res;
}
export const convertOrderTypeGoldNewExpense = (type) => {
  let res = "";
  switch (type) {
    case "CHANGE_REFUND":
      res = "ຄຳປ່ຽນອອກທັງໝົດ";
      break;
    case "BUY":
      res = "ຍອດຄຳຊື້ຄືນສະເພາະວັນທີ";
      break;
    case "RENT":
      res = "ຈຳນວນຄຳຮັບຄືນທັງໝົດ";
      break;
    case "ORDER":
      res = "ຮັບຄຳຈອງທັງໝົດ";
      break;
    case "PLEDGE":
      res = "ຍອດຄຳຈຳສະເພາະວັນທີ";
      break;

    default:
        break;
  }
  return res;
}
export const convertOrderTypeGoldOldExpense = (type) => {
  let res = "";
  switch (type) {
    case "CHANGE_REFUND":
      res = "ຍອດຄຳເກົ່າຈາກລູກຄ້າທັງໝົດ";
      break;
    case "BUY":
      res = "ຄຳເກົ່າຈາກຮ້ານຄຳພູວົງ";
      break;
    case "RENT":
      res = "ຄຳເຊົ່າອອກທັງໝົດ";
      break;
    case "ORDER":
      res = "ຮັບຄຳຈອງທັງໝົດ";
      break;
    case "PLEDGE":
      res = "ຍອດຄຳຈຳທັງໝົດ";
      break;

    default:
        break;
  }
  return res;
}

export const formatDateTime = (date) => {
  return moment(date).format('YYYY/MM/DD HH:mm');
 };


  //calution time
export const calutionTime = (time) => {
  // Create a new Date object for the specified datetime
  const specifiedTime = new Date(time);

  // Create a new Date object for the current datetime
  const currentTime = new Date();
  // Calculate the time difference in milliseconds
  const timeDifference = currentTime.getTime() - specifiedTime.getTime();

  // Calculate the time difference in terms of minutes, hours, days, weeks, or years
  const minutesAgo = Math.floor(timeDifference / 60000);
  const hoursAgo = Math.floor(timeDifference / 3600000);
  const daysAgo = Math.floor(timeDifference / 86400000);
  const weeksAgo = Math.floor(timeDifference / 604800000);
  const yearsAgo = currentTime.getFullYear() - specifiedTime.getFullYear();

  // Display the time difference in terms of the largest unit of time that has passed
  let result
  if (yearsAgo > 0) {
    result = `${yearsAgo} ປີຜ່ານມາ`;
  } else if (weeksAgo > 0) {
    result = `${weeksAgo} ອາທິດຜ່ານມາ`;
  } else if (daysAgo > 0) {
    result = `${daysAgo} ມື້ຜ່ານມາ`;
  } else if (hoursAgo > 0) {
    result = `${hoursAgo} ຊົ່ວໂມງຜ່ານມາ`;
  } else {
    result = `${minutesAgo} ນາທີຜ່ານມາ`;
  }

  return result;
}