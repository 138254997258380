import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col,Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  GetDataHistorybuyAndOrder,
  UpdateStatusDataHistorybuyAndOrder,
} from "../../../api/historyBuyAndOrder/index";
import NavBar from "./NavBar";
import Consts from "../../../consts";
import {  formatCurrency } from "../../../helpers/index";
import formatDate from "../../../helpers/formatDate";
import moment from "moment";

export default function HistoryBuyAppover() {
  const History = useHistory();

  const [data, setData] = useState([]);
  const [sumData, setSumData] = useState({});
  const [dateFrom, setDateFrom] = useState(formatDate(moment(new Date()).add("days",-30)));
  const [dateTo, setDateTo] = useState(formatDate(moment(new Date())));

  useEffect(() => {
    _getDataHistoryBuy();
  }, []);

  useEffect(() => {
    setSumData({})
    let _data = {
      qtyProduct:0,
      weightGolde:0,
    };
   if(data?.length>0) {
    for(let i=0; i<data?.length; i++) {
      _data.qtyProduct += data[i].amount;
      _data.weightGolde += data[i].weight;
    }
    setSumData(_data)
   }
  }, [data]);

  useEffect(() => {
    _getDataHistoryBuy();
  }, [dateFrom,dateTo]);

  const _getDataHistoryBuy = async () => {
    let _res = await GetDataHistorybuyAndOrder("MAKE_ORDER", "RECEIVED",dateFrom, formatDate(moment(dateTo).add('day',1)));
    setData(_res);
  };

  return (
    <div>
      <p style={{ padding: 10 }}>ລາຍການສັ່ງຊື້ຄໍາ</p>
      <div style={{ height: 10 }}></div>
      <NavBar />
      <Card
        style={{
          padding: "20px",
          paddingTop: "10px",
          border: "none",
        }}
        body
      >
        <h3 style={{ color: "#003049" }}>ລາຍການສັ່ງຊື້ສໍາເລັດ</h3>
        <Row className="mt-3">
          <Col md={3}>
            <Form.Group controlId="doj">
              <Form.Label>ເລືອກວັນທີ</Form.Label>
              <Form.Control
                type="date"
                name="doj"
                placeholder="Date of Joining"
                value={dateFrom}
                onChange={(e) => {
                  setDateFrom(e.target.value)
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="doj">
              <Form.Label>ຫາວັນທີ</Form.Label>
              <Form.Control
                type="date"
                name="doj"
                placeholder="Date of Joining"
                value={dateTo}
                onChange={(e) => {
                  setDateTo(e.target.value)
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <div style={{ height: 20 }}></div>
        <table class="table">
          <tr style={{ textAlign: "center" }}>
            <th className="col-1">ລຳດັບ</th>
            <th className="col-1">ລະຫັດລູກຄ້າ</th>
            <th className="col-1">ຊື່ ແລະ ນາມສະກຸນ</th>
            <th className="col-1">ເບີໂທ</th>
            <th className="col-1">ຈໍານວນສິນຄ້າ</th>
            <th className="col-1">ລວມນໍ້າໜັກ</th>
            <th className="col-1">ວັນທີຊື້</th>
          </tr>
          <tbody>
            {data?.map((item, index) => (
              <tr style={{ textAlign: "center" }}>
                <th
                  scope="row"
                  // onClick={() =>
                  //   History.push(
                  //     Consts.HISTORY_BUY_DETAIL + "/" + item?.id,
                  //     item
                  //   )
                  // }
                >
                  {index + 1}
                </th>
                <td
                  // onClick={() =>
                  //   History.push(
                  //     Consts.HISTORY_BUY_DETAIL + "/" + item?.id,
                  //     item
                  //   )
                  // }
                >
                  {item?.customer_code}
                </td>
                <td
                  // onClick={() =>
                  //   History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                  // }
                >
                  {item?.customer_first_name} {item?.customer_last_name}
                </td>
                <td
                  // onClick={() =>
                  //   History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                  // }
                >
                  {item?.customer_phone ?? "-"}
                </td>
                <td
                  // onClick={() =>
                  //   History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                  // }
                >
                  {formatCurrency(item?.amount)}
                </td>
                <td
                  // onClick={() =>
                  //   History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                  // }
                >
                  {item?.weight}
                </td>
                <td
                  // onClick={() =>
                  //   History.push(Consts.HISTORY_BUY_DETAIL + "/" + item?.id)
                  // }
                >
                  {moment(item?.created_at).format("yyyy-MM-DD LT")}
                </td>
              </tr>
            ))}
              <tr style={{ textAlign: "center",fontWeight:"bold"}}>
                <td colspan={4}>ລວມ</td>
                <td>{sumData?.qtyProduct}</td>
                <td>{sumData?.weightGolde} g</td>
                {/* <td></td> */}
              </tr>
          </tbody>
        </table>
      </Card>
    </div>
  );
}
