import * as _ from 'lodash'
import axios from "axios";
import { SERVER_URL } from "../api";
import { useHistory } from "react-router-dom";
import { warring } from "./sweetalert";

// custom import
import { USER_DATA,ACCESS_TOKKEN_DATA,REFRESH_TOKKEN_DATA } from "../consts/user";
// get user data from localStorage
export const getUserDataFromLCStorage = () => {
  if (localStorage.getItem(USER_DATA)) {
    const user = JSON.parse(localStorage.getItem(USER_DATA));
    return user?.data?.user || { branch_id: null };
  } else {
    return { branch_id: null };
  }
};

export const getAccessTokkenFromLCS = () => {
  const _tokken = JSON.parse(localStorage.getItem(ACCESS_TOKKEN_DATA));
  return `Bearer ${_tokken}` || undefined;
};

export const getReNewToken = async (error, reNewFuction) => {
  try {
    if (error?.response?.data?.msg === "Token is expired") {
      const _tokken = JSON.parse(localStorage.getItem(REFRESH_TOKKEN_DATA));
      let _refresh = {
        refresh_token: _tokken,
      };
      const data = await axios.post(
        `${SERVER_URL}/auth-service/api/v1/auth/new-token`,
        _refresh
        );
      if (data?.status === 200) {
        localStorage?.setItem(
          ACCESS_TOKKEN_DATA,
          JSON?.stringify(data?.data?.data?.accessToken)
        );
        localStorage?.setItem(
          REFRESH_TOKKEN_DATA,
          JSON?.stringify(data?.data?.data?.refreshToken)
        );
        reNewFuction()
      }
      return;
    }
    return;
  } catch (err) {
    if (err?.response?.data?.msg === "GENERATE_REFRESH_TOKEN_FAILED"){
      ClearUserDataFormLCStorage();
      await warring("ກະລຸນາເຂົ້າສູ້ລະບົບໃຫ່ມອີກຄັ້ງ");
      useHistory.replace("/login");
      useHistory.replace({ firstPage: true });
    }
  }
};
// store user data to localStorage
export const storeUserDataToLCStorage = (data) => {
  if (localStorage.getItem(USER_DATA)) {
    localStorage.setItem(USER_DATA, JSON.stringify(data));
  } else return null;
};
export const useAuth = () => {
  const userData = getUserDataFromLCStorage();
  //  check if user login or not
  const isAuthenticated = !_.isEmpty(userData);
  return isAuthenticated;
};

export const ClearUserDataFormLCStorage = () => {
  localStorage.removeItem(USER_DATA);
  localStorage.removeItem(ACCESS_TOKKEN_DATA);
  localStorage.removeItem(REFRESH_TOKKEN_DATA);
};