import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import NavBar from "./NavBar";
import PaginationHelper from "../../helpers/PaginationHelper";
import { SERVER_URL } from "../../api";
import { formatCurrency } from "../../helpers";
import { Card, Row, Col, Form, Table, Breadcrumb } from "react-bootstrap";
import styled from "styled-components"; //styled-component
import Consts from "../../consts";
import queryString from "query-string";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import ReactLoading from 'react-loading';

export default function ExpenseHistory() {
  const [dataCategory, setDataCategory] = useState();
  const parsed = queryString.parse(History?.location?.search);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  const printRef = React.useRef();

  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [dateFrom, setDateFrom] = React.useState(
    parsed?.dateFrom
      ? parsed?.dateFrom
      : moment(moment(new Date()).add("days", -7)).format("YYYY-MM-DD")
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    _getCategory();
  }, []);

  useEffect(() => {
    _getCategory();
  }, [dateFrom, dateTo]);

  const _getCategory = async () => {
    let _tokken = getAccessTokkenFromLCS();
    try {
      let _dataCategory = await axios({
        method: "GET",
        url:
          SERVER_URL +
          `/expenditure-service/api/v1/expenditure?branch_id=${getUserDataFromLCStorage()?.branch_id}&skip=${(_skip - 1) * _limit}&limit=${_limit}&from_date=${dateFrom}&to_date=${moment(moment(dateTo).add("day", 1)).format("YYYY-MM-DD")}`,
        headers: {
          Authorization: _tokken,
        },
      });
      if (_dataCategory?.status === 200) setDataCategory(_dataCategory?.data);
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, _getCategory());
      }
    }
  };
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Downld.pdf' + date + '.pdf');
    }
    )
  };

  return (
    <>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item active href="#"> ບັນທຶກລາຍຈ່າຍ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <NavBar />
      <Card
        style={{
          padding: "20px",
          border: "none",
          paddingBottom: "100px",
        }}
        body
      >
        <h3>ປະຫວັດລາຍຈ່າຍ</h3>
        <div style={{ height: 20 }}></div>
        <Row className="mt-3">
          <Col md={3}>
            <Form.Group controlId="doj">
              <Form.Label>ເລືອກວັນທີ</Form.Label>
              <Form.Control
                type="date"
                name="doj"
                defaultValue={dateFrom}
                placeholder="Date of Joining"
                onChange={(e) => {
                  setDateFrom(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="doj">
              <Form.Label>ຫາວັນທີ</Form.Label>
              <Form.Control
                type="date"
                name="doj"
                defaultValue={dateTo}
                placeholder="Date of Joining"
                onChange={(e) => {
                  setDateTo(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col style={{ display: "flex", alignItems: "center", marginTop: "30px" }}>
            <div>
              <button onClick={handleDownloadPdf} id="format-PDF" className='buttonRedColor-export' >   Export </button>
            </div>
          </Col>
        </Row>
        <div style={{ height: 20 }}></div>
        {dataCategory?.data === undefined ?
          <div className="loading-card">
            <div>
              <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
              <span>Loading....</span>
            </div>
          </div>
          : dataCategory?.total === 0 ?
            <div>
              <img style={{ marginLeft: "40%", marginTop: "10%" }} src='/asset/image/animation_200_ldu129fc.gif' />
              <p style={{ marginLeft: "45%", marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
            </div> :         <Table className="table table-hover" ref={printRef}>
          <thead>
            <tr>
              <td>#</td>
              <td>ປະເພດການຈ່າຍ</td>
              <td>ລາຍຈ່າຍ</td>
              <td>ຈຳນວນເງິນ</td>
              <td>ສະກຸນເງິນ</td>
              <td>ຮູບແບບການຈ່າຍ</td>
              <td>ລາຍລະອຽດເພີ່ມເຕີມ</td>
              <td>ຜູ້ສ້າງ</td>
            </tr>
          </thead>
          <tbody>
            {dataCategory?.data?.map((result, index) => (
              <tr key={index}>
                <td>{index + 1 + _limit * (_skip - 1)}</td>
                <td>{result?.category_name ? result?.category_name : "-"}</td>
                <td>{result?.expense_name ? result?.expense_name : "-"}</td>
                <td>
                  {result?.amount_paid
                    ? formatCurrency(result?.amount_paid)
                    : 0}
                </td>
                <td>{result?.currency ? result?.currency : "-"}</td>
                <td>{result?.transfer === "NO" ? "ຈ່າຍສົດ" : "ໂອນ"}</td>
                <td>{result?.note ? result?.note : "-"}</td>
                <td>
                  <b>{result?.created_by_full_name ? result?.created_by_full_name : "-"}</b>
                  <div>{moment(result?.created_at).format("DD-MM-yyyy")}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>}
        {Pagination_helper(
          dataCategory?.total,
          Consts.EXPENSE_HISTORY
          // `first_name=${filterData}&from_date=${dateFrom}&to_date=${dateTo}`
        )}
      </Card>
    </>
  );
}
const RootStyle = styled.div`
  padding: "20px";
`;
