import React, { useEffect, useState } from "react";
/**
 *
 * @Library
 *
 */
import axios from 'axios'
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import  ReactLoading  from 'react-loading';
/**
 *
 * @Component
 *
 */

import { Card, Row, Col, Breadcrumb, Form } from "react-bootstrap";
import CustomList from "../../common/CustomList";
import PrimaryButton from "../../common/Button/PrimaryButton";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
/**
 *
 * @API
 *
 */
import { SERVER_URL } from '../../api'
import { addStock } from "../../api/stock";
/**
 *
 * @Function
 *
 */
import { loadStockAsync } from "../../redux/stock";
import { getAccessTokkenFromLCS, getReNewToken } from "../../helpers/user";
import { formatCurrency, convertGender, formatCurrencyGold } from "../../helpers";


// ----------------------------------------------## Edit by Phoudthavisack ( therd ) ##----------------------------------------------------- //

export default function Detail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [amountInput, setAmountInput] = useState(0)
  /**
   *
   * @State
   *
   */
  const [productData, setProductData] = useState({});
  const [addProductData, setAddProductData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    getProduct()
  }, [])

  /**
   *
   * @Function
   *
   */
  const handleChangeWeight = (amountInput) => {
    setAmountInput(amountInput)
  };

  const handleSubmit = async () => {
    var newData = {
      ...addProductData,
      amount: amountInput,
      stock_amount: amountInput,
      weight: [{ name: addProductData?.weight[0]?.name, gram: addProductData?.weight[0]?.gram, amount: amountInput }],
      amount: amountInput
    }
    await addStock(newData).then((e) => {
      dispatch(loadStockAsync());
      history.replace(consts.PRODUCT_MANAGEMENT + "/limit/40/skip/1");
    });
  };

  const getProduct = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      setIsLoading(true);
      const data = await axios.get(
        `${SERVER_URL}/product-service/api/v1/products/${id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setProductData(data?.data?.data)
        setIsLoading(false);
        setAddProductData({
          amount: 0,
          stock_amount: 0,
          cabinet_amount: 0,
          goldType: data?.data?.data?.goldType,
          name: data?.data?.data?.name,
          product_id: data?.data?.data?.id,
          product_code: data?.data?.data?.product_code,
          image: data?.data?.data?.image,
          product_type_id: data?.data?.data?.product_type_id,
          product_type_name: data?.data?.data?.product_type_name,
          pattern_id: data?.data?.data?.pattern_id,
          pattern_name: data?.data?.data?.pattern_name,
          weight: data?.data?.data?.weight?.[0]
            ? data?.data?.data?.weight?.map((e) => ({ ...e, amount: 0 }))
            : [],
        });
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getProduct());
      }
    }
  };
  return (
    <>
      {
        isLoading ? (
          <div style={{ paddingTop: 12 }}>
            <Card className="d-flex justify-content-center align-items-center" style={{ padding: "20px" , height:'80vh'}}>
            <div>
              <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
              <span>Loading....</span>
            </div>
          </Card>
          </div>
        ) : (
          <>
            <div style={{ paddingTop: 12 }}>
              <Breadcrumb>
                <Breadcrumb.Item href="#" onClick={() => history.push(consts.PRODUCT_MANAGEMENT + '/limit/40/skip/1')}> ຈັດການສີນຄ້າ </Breadcrumb.Item>
                <Breadcrumb.Item active> ລາຍລະອຽດ </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Card style={{ padding: "20px" }}>
              <h4 className='border-bottom border-2 pb-3'>ລາຍລະອຽດສິນຄ້າ</h4>
              <Row>
                <Col>
                  <CustomList
                    value={[
                      {
                        title: "ລະຫັດສິນຄ້າ",
                        value: `${productData?.product_code}`,
                      },
                      { title: "ຊື່ສິນຄ້າ", value: `${productData?.name}` },
                      {
                        title: "ໝວດສິນຄ້າ",
                        value: `${productData?.product_type_name}`,
                      },
                      { title: "ເພດ", value: `${convertGender(productData?.gender) ?? "ທຸກເພດ"}` },
                      {
                        title: "ລາຍ",
                        value: `${productData?.pattern_name}`,
                      },
                      {
                        title: "ຄ່າແຮງໜ້າຮ້ານ",
                        value: `${formatCurrency(
                          productData?.store_wage_kip
                        )} ກີບ`,
                      },
                      {
                        title: "ຄ່າແຮງໂຮງງານ",
                        value: `${formatCurrency(
                          productData?.factory_wage_kip
                        )} ກີບ`,
                      },
                      {
                        title: "ຄ່າແຮງເຄື່ອງສົ່ງ",
                        value: `${formatCurrency(
                          productData?.transmitter_wage_kip
                        )} ກີບ`,
                      },
                      {
                        title: "ຄ່າແຮງທົ່ວໄປ",
                        value: `${formatCurrency(
                          productData?.sale_wage_kip
                        )} ກີບ`,
                      },
                      {
                        title: "ສະຖານະ",
                        value: productData?.status,
                      },
                      {
                        title: "ລາຍລະອຽດສິນຄ້າ (ສະແດງທີແອັບອອມຄຳ)",
                        values: `${productData?.details || "-"}`,
                      },
                      {
                        title: "ໝາຍເຫດ",
                        values: `${productData?.note || "-"}`,
                      },
                    ]}
                  />
                </Col>

                <Col>
                  <div style={{ marginLeft: "7px" }}>
                    ຮູບພາບ (
                    {productData?.image?.length ?? '0'}/10')
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}>
                    {productData?.image?.map((img, index) => (
                      <img
                        className=' border border-3 '
                        src={`${consts.S3}${img}`}
                        style={{ width: "170px", height: "170px", margin: "7px" }}
                        key={index}
                      />
                    ))
                    }
                  </div>
                  <div style={{ border: "1px solid #D1D5DB", padding: 10 }}>
                    {addProductData?.weight?.map((e, i) => (
                      <div
                        style={{ display: "flex", alignItems: "center", height: 50 }}>
                        <div style={{ padding: "0 20px" }}>{i + 1}</div>
                        <div className="card-add-stock-to-manager">
                          <b>ນໍ້າຫນັກ</b><span>{formatCurrencyGold(e?.gram)} g</span>
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <Form.Group className='mb-2'>
                            <Form.Control type='number' placeholder="ປ້ອນຈຳນວນ"
                              onChange={(e) => {
                                handleChangeWeight(parseInt(e.target.value))
                              }} />
                          </Form.Group>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className='d-flex justify-content-end '
                    style={{ padding: "14px 46px" }}>
                    <PrimaryButton
                      disabled={productData?.status == false ? true : false}
                      style={{ width: "195px", height: "51px" }}
                      onClick={handleSubmit}>
                      + ເພີ່ມເຂົ້າສະຕ໊ອກ
                    </PrimaryButton>
                  </div>
                </Col>
              </Row>
            </Card>
          </>
        )
      }

    </>
  );
}
