import React from "react";
// styled-components
import styled from "styled-components";
// bootstrap
import { Card } from "react-bootstrap";
// components
import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import UndrawImage from "../../image/undraw.svg"
// ------------------------------------------------------ //
export default function Aomkham() {
  return (
    <RootStyle>
      <Card style={{ padding: 30 }}>
        <div
          className='d-flex justify-content-center align-items-center flex-column gap-5'
          style={{ minHeight: "70vh" }}>
          <img src={UndrawImage} alt='' style={{ width: 300 }} />
          <h2>ກຳລັງພັດທະນາ</h2>
        </div>
      </Card>
    </RootStyle>
  );
}
const RootStyle = styled.div`
  padding: 20px;
`;
