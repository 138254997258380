import { useRef } from "react";
import { Modal } from "react-bootstrap"
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { convertOrderType } from "../../helpers";

export default function Print({data, backClose, isShow}) {
    const componentRef = useRef();
    return (
        <Modal show={isShow} onHide={backClose} centered>
            <Modal.Body className="text-center">
                <div ref={componentRef} className="text-center">
                    <h2 className="card bg-dark text-white py-2 mb-4">{convertOrderType(data?.order_type)}</h2>
                    <div>
                        <QRCode value={data?.id} size={200} />
                        <p className="mt-2">ID: {data?.order_code}</p>
                        <p>{`${data?.customer_first_name} ${data?.customer_last_name} ${data?.customer_phone}`}</p>
                        <p className="mt-5">ທ່ານສາມາດ Scan QR Code ເພື່ອກວດສອບ</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ReactToPrint
                    trigger={() => (
                    <Button className='btn-print-report'>
                        <FontAwesomeIcon icon={faPrint} />
                        Print
                    </Button>
                    )}
                    content={() => componentRef.current}
                />
            </Modal.Footer>
        </Modal>
    )
}