import React from "react";
import { Route,useHistory } from "react-router-dom";
import {useAuth} from "../helpers/user"
// import { USER_KEY } from "../consts";
function PrivateRoute({ component: Component, headerTitle, ...rest }) {
  const History = useHistory()
  const isAuthenticated = useAuth();
  if(!isAuthenticated) History.replace('/login');
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <div style={{ padding: 20, paddingTop: 0, paddingBottom: 0 }}>
            <Component {...props} />
          </div>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default PrivateRoute;
