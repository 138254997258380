import React, { useEffect, useState } from "react";
/**
 *
 * @Library
 *
 */
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import moment from "moment";
import axios from 'axios'
import queryString from 'query-string';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactLoading from 'react-loading';
// import { Modal, Button } from 'react-bootstrap'
/**
 *
 * @Component
 *
 */
import { Card, Row, Col, Form, Table, Breadcrumb, Button, Modal, InputGroup } from "react-bootstrap";
import { faBoxes, faSearch } from "@fortawesome/free-solid-svg-icons";
// import CustomSearch from "../../common/CustomSearch";
import Status from "../../common/status";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
/**
 *
 * @API
 *
 */
import { SERVER_URL } from '../../api'
/**
 *
 * @Function
 *
 */
import { getAccessTokkenFromLCS, getReNewToken } from "../../helpers/user";
import { selectCategory } from "../../redux/category_data";
import PaginationHelper from "../../helpers/PaginationHelper";
import { formatCurrencyGold } from "../../helpers";

export default function CustomerManagement() {

  const History = useHistory();
  const parsed = queryString.parse(History?.location?.search);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  const [productData, setProductData] = useState([])
  const [pattern, setPattern] = useState([])
  const [productAllPDF, setProductAllPDF] = useState([])
  // data
  const categoryData = useSelector(selectCategory);
  const [total, setTotal] = useState(0)
  const [filterData, setFilterData] = useState("");
  const [selectGoldType, setSelectGoldType] = useState(parsed?.selectGoldType ? parsed?.selectGoldType : "")
  const [selectPatternId, setSelectPatternId] = useState(parsed?.selectPatternId ? parsed?.selectPatternId : "")
  const [selectStatus, setSelectStatus] = useState(parsed?.selectStatus ? parsed?.selectStatus : "")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getPattern()
    getProduct()
  }, [])

  useEffect(() => {
    getProduct()
  }, [selectGoldType, selectPatternId, selectStatus, filterData])

  const getProduct = async () => {
    let _token = getAccessTokkenFromLCS();

    try {
      const data = await axios.get(
        `${SERVER_URL}/product-service/api/v1/products?limit=${_limit}&skip=${(_skip - 1) * _limit}&pattern_id=` +
        selectPatternId + "&product_type_id=" + selectGoldType + "&status=" + selectStatus + "&name=" + filterData ,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setProductData(data?.data?.data)
        setTotal(data?.data?.total)
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getProduct());
      }
    }
  };

  const getProductToPDF = async () => {
    handleShow();
    let _token = getAccessTokkenFromLCS();
    try {
      setIsLoading(true)
      const data = await axios.get(
        `${SERVER_URL}/product-service/api/v1/products?limit=${100000}&pattern_id=` +
        selectPatternId + "&product_type_id=" + selectGoldType + "&status=" + selectStatus + "&name=" + filterData,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setProductAllPDF(data?.data?.data)
        setIsLoading(false)
        // setTotal(data?.data?.total)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getPattern = async () => {
    let _token = getAccessTokkenFromLCS();

    try {
      const data = await axios.get(
        `${SERVER_URL}/product-service/api/v1/pattern`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setPattern(data?.data?.data)
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getProduct());
      }
    }
  };
  const _selectGoldType = (event) => {
    setSelectGoldType(event.target.value);
    setSelectPatternId("");
    setSelectStatus("");
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {

    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Downld.pdf' + date + '.pdf');
    }
    )
  };

  return (
    <div>
      {/* {productData?.length > 0 ?
        <div> */}
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item active> ຈັດການສີນຄ້າ </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Card style={{ border: "none", margin: "2px 0" }} body>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='mt-2'>
            <span>
              <FontAwesomeIcon
                style={{ width: "23px", height: "23px", margin: "0 5px" }}
                icon={faBoxes}
              />
            </span>{" "}
            ສິນຄ້າທັງໝົດ
          </h4>
        </div>
      </Card>

      <Card
        style={{ padding: "20px", paddingTop: "10px", border: "none" }} body>
        <Row>
          <Col md={12}>
          <Form.Label>ຄົ້ນຫາຊື່ສິນຄ້າ</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="ຄົ້ນຫາຊື່ສິນຄ້າ"
                  aria-label="ຄົ້ນຫາຊື່ສິນຄ້າ"
                  aria-describedby="basic-addon2"
                  onChange={(e) => setFilterData(e.target.value)}
                />
                <Button variant="outline-danger" id="button-addon2">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md={3}>
            <Form.Group className='mb-3'>
              <Form.Label>ໝວດຄຳ</Form.Label>
              <Form.Select value={selectGoldType} onChange={e => _selectGoldType(e)}>
                <option value="">ກະລຸນາເລືອກ</option>
                {
                  categoryData?.data.map((ele, index) => (
                    <option key={index} value={ele.id}>{ele.name}</option>
                  ))
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='mb-3'>
              <Form.Label>ລວດລາຍ</Form.Label>
              <Form.Select value={selectPatternId} onChange={e => setSelectPatternId(e.target.value)} >
                <option value="">ກະລຸນາເລືອກ</option>
                {pattern?.map((ele, index) => (
                  <option key={index} value={ele?.id}>{ele?.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>ສະຖານະ</Form.Label>
              <Form.Select value={selectStatus} onChange={e => { setSelectStatus(e.target.value) }} >
                <option value="">ກະລຸນາເລືອກ</option>
                <option value="true">ເປີດໃຊ້ງານ</option>
                <option value="false">ປີດໃຊ້ງານ</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2} className="d-flex justify-content-end" style={{ marginTop: 33 }}  >
            <div>
              <button
                onClick={getProductToPDF}
                id="format-PDF"
                className='buttonRedColor-export'>  Export </button>
            </div>
          </Col>
        </Row>
        {productData?.length <= 0 ?
          <div className="loading-card">
            <div>
              <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
              <span>Loading....</span>
            </div>
          </div>
          :
          <Table className='table-hover'>
            <thead>
              <tr>
                <th>#</th>
                <th>ຮູບພາບ</th>
                <th>ຊື່ສິນຄ້າ</th>
                <th>ລວດລາຍ</th>
                <th>ນໍ້າໜັກ</th>
                <th>ສະຖານະ</th>
                <th>ວັນທີສ້າງ</th>
              </tr>
            </thead>
            <tbody>
              {productData?.map((e, i) => (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    History.push(`${consts.PRODUCT_MANAGEMENT}/detail/${e.id}`)
                  }>
                  <td> {i + 1 + _limit * (_skip - 1)}</td>
                  <td>
                    <img src={`${e.image?.length > 0 ? 'https://kpv-bucket.s3.ap-southeast-1.amazonaws.com/' + e?.image[0] : "asset/image/image-2-64.ico"}`} alt=""
                      style={{ width: 80, height: 60 }}
                    />
                  </td>
                  <td>{e?.name}</td>
                  <td>{e?.pattern_name}</td>
                  <td>{formatCurrencyGold(e?.weight?.[0].gram) || "-"}</td>
                  <td>
                    <Status status={e.status} />
                  </td>
                  <td>{moment(e.created_at).format("DD/MM/YYYY LT")}</td>
                </tr>
              ))}
            </tbody>
          </Table>

        }
        {Pagination_helper(total, consts.PRODUCT_MANAGEMENT, `selectGoldType=${selectGoldType}&selectPatternId=${selectPatternId}&selectStatus=${selectStatus}`)}
      </Card>
      {/* </div>
        :
        <div style={{backgroundColor: '#fff', width:'100%', height:'100%'}} >
          
        </div> */}

      {/* } */}
      <Modal show={show} onHide={handleClose} size="xl">

        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className='buttonRedColor'
              style={{ width: "125px", height: "51px" }} onClick={handleDownloadPdf}>
              Export PDF
            </Button>
          </div>
          <div className="" ref={printRef}>
            <Table className=''>
              <thead>
                <tr>
                  <th>#</th>
                  {/* <th>ຮູບພາບ</th> */}
                  <th>ຊື່ສິນຄ້າ</th>
                  <th>ລວດລາຍ</th>
                  <th>ນໍ້າໜັກ</th>
                  <th>ສະຖານະ</th>
                  <th>ວັນທີສ້າງ</th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ? (
                    <tr>
                      <td colSpan={6}>
                        <div className="loading-card">
                          <div>
                            <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
                            <span>Loading....</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) :
                    <>
                      {productAllPDF?.map((e, i) => (
                        <tr
                          style={{ cursor: "pointer" }}

                        >
                          <td> {i + 1}</td>
                          <td>{e?.name}</td>
                          <td>{e?.pattern_name}</td>
                          <td>{e?.weight?.[0].gram || "-"}</td>
                          <td>
                            <Status status={e.status} />
                          </td>
                          <td>{moment(e.created_at).format("DD/MM/YYYY LT")}</td>
                        </tr>
                      ))}
                    </>
                }

              </tbody>
            </Table>
          </div>
        </Modal.Body>

      </Modal>
    </div>
  );
}
