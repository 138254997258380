import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function ModalError({title, show, close}) {
  return (
    <div>
         <Modal show={show} onHide={close} centered>
            <div style={{ textAlign: "center", paddingBottom: 32, paddingTop: 32 }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div><FontAwesomeIcon icon={faCircleExclamation} size="9x" color='red' /></div>
                </div>
                <div style={{ marginTop: 18, fontSize: 24, fontWeight: 700 }}>{title !== "" ?  title : "ເກິດຂໍ້ຜິດຜ່ານ ກະລຸນາລອງອີກຄັ້ງ!"}</div>
            </div>
        </Modal>
    </div>
  )
}
