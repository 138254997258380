import { useState } from "react";
import styled from "styled-components";
import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons' //use icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'//use component for use icon
import consts, { CUSTOMER_MANAGEMENT } from '../../consts/'
import './style.css'
import UploadImage from '../../common/UploadImage'
import { selectBranch } from "../../redux/branch_data";
import { useSelector,useDispatch } from "react-redux";
import { loadCustomerAsync } from "../../redux/customer";
import { addUsers } from "../../api/users";
import upload from '../../api/upload'
import { useHistory } from "react-router-dom";
import Success from '../../common/popup/success/Success'
import Fail from '../../common/popup/fail/fail'
import {PROVINCE,DRISTRIC} from '../../helpers/addresses'
import moment from 'moment'
import { getUserDataFromLCStorage } from '../../helpers/user';

export default function CustomerManagementsDetail() {
    let _dateTimeNow = moment(new Date()).format('YYYY-MM-DD')
    const dispath = useDispatch()
    const [fail, setFail] = useState(false)
    const [suc, setSuc] = useState(false);
    const History = useHistory()
    const [selectedDate, setSelectedDate] = useState(_dateTimeNow);
    const [imageName, setImageName] = useState('')
    const [eye, setEye] = useState(false)
    const [validated, setValidated] = useState(false);

    const [username, setUsername] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [birthDay, setBirthday] = useState(_dateTimeNow)
    const [genDer, setGender] = useState('')
    const [Phone, setPhone] = useState('')
    const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [customerType, setCustomerType] = useState('')
    const [Status, setStatus] = useState(false)
    const [documentType, setDocumentType] = useState('')
    const [idCard, setIdCard] = useState('')
    const [bankName, setBankName] = useState('')
    const [Branch, setBranch] = useState('')
    const [accountName, setAccountName] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [maxBuy, setMaxBuy] = useState('')
    const [villageAddress, setVillageAddress] = useState('')
    const [districAddress, setDistricAddress] = useState('')
    const [provinceAddress, setProvinceAddress] = useState('')
    const [villageSend, setVillageSend] = useState('')
    const [districSend, setDistricSend] = useState('')
    const [provinceSend, setProvinceSend] = useState('')
    const [idCardImageName, setIdCardImageName] = useState('')
    const [accountImageName, setAccountImageName] = useState('')
    const [dataDristric, setDataDristric] = useState([])
    const [dataDristricApprover, setDataDristricApprover] = useState([])
    const [dataLocalStore, setdataLocalStore] = useState(getUserDataFromLCStorage())
    const toggle = () => {
        setEye(!eye)
    }
    function PictureName(val) {
        setImageName(val)
    }
    const form_data = {
        image_profile: imageName,
        image_id_card: idCardImageName,
        image_account_number: accountImageName,
        username: Phone,
        role: 'CUSTOMER',
        first_name: firstName,
        last_name: lastName,
        birthday: birthDay,
        gender: genDer,
        phone: Phone,
        email: Email,
        password: Password,
        customer_type: customerType,
        status: Status,
        document_type: documentType,
        id_card: idCard,
        bank_name: bankName,
        account_name: accountName,
        account_number: accountNumber,
        user_status: Status,
        branch_id: dataLocalStore?.branch_id,
        branch_name: dataLocalStore?.branch_name,
        is_aomkham_account: false,
        max_buys: parseInt(maxBuy),
        address:[{
            village: villageAddress,
            district: districAddress,
            province: provinceAddress,
        }],
        shipment: [{
            village: villageSend,
            district: districSend,
            province: provinceSend,
        }],
    }
    const handleSubmit = (event) => {
        console.log("form_data===>",form_data)
        event?.preventDefault()
        const form = event?.currentTarget;
        if (form?.checkValidity() === false) {
            event?.preventDefault();
            setFail(true)
            event?.stopPropagation();
            return
        }

        setValidated(true);
        addUsers(form_data).then(e => {
            if (e?.error === false) {
                setSuc(true)
                setTimeout(() => {
                    dispath(loadCustomerAsync())
                    History?.push(consts.CUSTOMER_MANAGEMENT)
                }, 3000);
            }
            else setFail(true)
        })

    };
    const _filterDrist=(item)=>{
        let _filtterProvin = PROVINCE.filter((data)=>data?.pr_id === item)
        let _filtterDis = DRISTRIC.filter((data)=>data?.pr_id === item)
        setProvinceAddress(_filtterProvin[0]?.pr_name)
        setDataDristric(_filtterDis)
    }
    const _filterDristApprover=(item)=>{
        let _filtterProvin = PROVINCE.filter((data)=>data?.pr_id === item)
        let _filtterDis = DRISTRIC.filter((data)=>data?.pr_id === item)
        setProvinceSend(_filtterProvin[0]?.pr_name)
        setDataDristricApprover(_filtterDis)
    }
    return (
        <RootStyle>
            <CustomBreadcrumbs value={[{ title: 'ຈັດການລູກຄ້າ', path: `${CUSTOMER_MANAGEMENT}` }, { title: 'ເພີ່ມຂໍ້ມູນ' }]} />
            <Card className="p-5">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row >
                        <Col md={5} className="mb-5">
                            <div>
                                <h3 className="border-bottom">ຂໍ້ມູນສ່ວນໂຕ</h3>
                                <UploadImage pictureName={PictureName} />
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>ລະຫັດ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setUsername(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group> */}
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>ຄຳນຳຫນ້າ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select >
                                        <option>ກະລຸນາເລືອກ</option>
                                        <option >Mr</option>
                                        <option >Miss</option>
                                        <option >Mrs</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group className="mb-3">
                                    <Form.Label>ຊື່<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setFirstName(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ນາມສະກຸນ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setLastName(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group controlId='doj' className="mb-3">
                                    <Form.Label>ວັນເດືອນປີເກີດ</Form.Label>
                                    <Form.Control required
                                        type='date'
                                        name='doj'
                                        defaultValue={selectedDate}
                                        placeholder='Date of Joining'
                                        onChange={(e) => setBirthday(e?.target?.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='doj' className="mb-3">
                                    <Form.Label className="d-block">ເພດ</Form.Label>
                                    <Form.Check value={'FEMALE'} onChange={e => setGender(e.target.value)} inline name="radioGroup" label="ຍິງ" type="radio" id="radio-1" />
                                    <Form.Check value={'MALE'} onChange={e => setGender(e.target.value)} inline name="radioGroup" label="ຊາຍ" type="radio" id="radio-2" />
                                    <Form.Check value={'OTHER'} onChange={e => setGender(e.target.value)} inline name="radioGroup" label="ອື່ນໆ" type="radio" id="radio-3" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ອີເມລ</Form.Label>
                                    <Form.Control onChange={e => setEmail(e.target.value)} required type="email" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ເບີໂທ ( ໃຊ້ເຂົ້າລະບົບ )<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setPhone(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                    <Form.Label>ລະຫັດຜ່ານ ( ໃຊ້ເຂົ້າລະບົບ )</Form.Label>
                                    <Form.Control onChange={e => setPassword(e.target.value)} required type={eye == true ? 'text' : 'password'} placeholder="ກະລຸນາປ້ອນ" />
                                    <FontAwesomeIcon onClick={toggle} style={{ position: 'absolute', top: '60%', right: '10', cursor: 'pointer' }} icon={eye == true ? faEye : faEyeSlash} />
                                </Form.Group>
                            </div>
                        </Col> {/*left top */}

                        <Col md={{ span: 5, offset: 1 }} className="mb-5">
                            <div>
                                <h3 className="border-bottom">ຂໍ້ມູນທົ່ວໄປ</h3>
                                <Form.Group className="mb-3">
                                    <Form.Label>ປະເພດລູກຄ້າ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => setCustomerType(e.target.value)} >
                                    <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {
                                            consts.CUSTOMERS_TYPE_LAOS.map((e, index) => (
                                                <option value={consts.CUSTOMERS_TYPE[index]} key={index}>{e}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ສະຖານະນໍາໃຊ້<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => setStatus(e?.target?.value)} >
                                        <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        <option value="ACTIVE">ເປີດໃຊ້ງານ</option>
                                        <option value="INACTIVE">ປິດໃຊ້ງານ</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ປະເພດເອກະສານ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => setDocumentType(e.target.value)} >
                                    <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        <option value="ບັດປະຈຳໂຕ">ບັດປະຈຳໂຕ</option>
                                        <option value="ປື້ມສຳມະໂນ">ປື້ມສຳມະໂນ</option>
                                        <option value="passport">passport</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ລະຫັດບັດ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setIdCard(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ຊື່ທະນາຄານ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => setBankName(e?.target?.value)} >
                                        <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        <option value="BCEL">ທະນາຄານການຄ້າຕ່າງປະເທດລາວ ມະຫາຊົນ</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>ສາຂາ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => setBranch(e.target.value)} >
                                    <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {
                                            branchData.data.map((e, index) => (
                                                <option key={index} value={e.branch_name}>{e.branch_name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group className="mb-3">
                                    <Form.Label>ຊື່ບັນຊີ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setAccountName(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ເລກບັນຊີ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setAccountNumber(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ຈໍານວນທີ່ສາມາດສັ່ງຊື້ໄດ້<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setMaxBuy(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <div className="d-flex">
                                    <span className="mx-2">
                                        <div>ບັດປະຈຳໂຕ ຫຼື ປື້ມສຳມະໂນ </div>
                                        <label htmlFor="idcard">
                                            <div
                                                style={{
                                                    padding: "3px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    border: "1px solid #666",
                                                    height: "170px",
                                                    width: "170px"
                                                }}
                                            >
                                                <p
                                                    style={{ display: idCardImageName === "" || undefined ? "block" : "none" }}
                                                >
                                                    + upload
                                                </p>
                                                <img
                                                    style={{
                                                        display: idCardImageName === "" || undefined ? "none" : "block",
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    src={consts.S3 + idCardImageName}
                                                />
                                            </div>
                                        </label>
                                        <input
                                            id="idcard"
                                            style={{ display: "none" }}
                                            type="file"
                                            onChange={(e) => {
                                                upload(e).then((event) => {
                                                    setIdCardImageName(event.imageName)
                                                });
                                            }}
                                        />
                                    </span>
                                    <span className="mx-2">
                                        <div>ຮູບບັນຊີ</div>
                                        <label htmlFor="family">
                                            <div
                                                style={{
                                                    padding: "3px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    border: "1px solid #666",
                                                    height: "170px",
                                                    width: "170px"
                                                }}
                                            >
                                                <p
                                                    style={{ display: accountImageName === "" || undefined ? "block" : "none" }}
                                                >
                                                    + upload
                                                </p>
                                                <img
                                                    style={{
                                                        display: accountImageName === "" || undefined ? "none" : "block",
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    src={consts.S3 + accountImageName}
                                                />
                                            </div>
                                        </label>
                                        <input
                                            id="family"
                                            style={{ display: "none" }}
                                            type="file"
                                            onChange={(e) => {
                                                upload(e).then((event) => {
                                                    setAccountImageName(event.imageName)
                                                });
                                            }}
                                        />
                                    </span>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className="mb-5">
                            <div>
                                <h3 className="border-bottom">ຂໍ້ມູນທີ່ຢູ່</h3>
                                <Form.Group className="mb-3">
                                    <Form.Label>ແຂວງ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => {
                                        _filterDrist(e?.target?.value)
                                    }} >
                                        <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {PROVINCE?.map((item,index)=>
                                        <option key={index+1} value={item?.pr_id}>{item?.pr_name}</option>
                                        )}
                                    </Form.Select>
                                    {/* <Form.Control onChange={e => setProvinceAddress(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" /> */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ເມືອງ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => {
                                        setDistricAddress(e?.target?.value)
                                    }} >
                                        <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {dataDristric?.map((item,index)=>
                                        <option key={index+1} value={item?.dr_name}>{item?.dr_name}</option>
                                        )}
                                    </Form.Select>
                                    {/* <Form.Control onChange={e => setDistricAddress(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" /> */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ບ້ານ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setVillageAddress(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                            </div>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }} className="mb-5">
                            <div>
                                <h3 className="border-bottom">ຂໍ້ມູນບ່ອນຈັດສົ່ງ</h3>
                                <Form.Group className="mb-3">
                                    <Form.Label>ແຂວງ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => {
                                        _filterDristApprover(e?.target?.value)
                                        // setProvinceSend(e?.target?.value)
                                    }} >
                                           <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {PROVINCE?.map((item,index)=>
                                        <option key={index+1} value={item?.pr_id}>{item?.pr_name}</option>
                                        )}
                                    </Form.Select>
                                    {/* <Form.Control onChange={e => setProvinceSend(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" /> */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ເມືອງ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Select onChange={e => {
                                        setDistricSend(e?.target?.value)
                                    }} >
                                        <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {dataDristricApprover?.map((item,index)=>
                                        <option key={index+1} value={item?.dr_name}>{item?.dr_name}</option>
                                        )}
                                    </Form.Select>
                                    {/* <Form.Control onChange={e => setDistricSend(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" /> */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ບ້ານ<span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control onChange={e => setVillageSend(e.target.value)} required type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 4, offset: 1 }} className="my-2">
                            <Button onClick={() => History.push(consts.CUSTOMER_MANAGEMENT)} variant="light" style={{ width: '100%' }}>ຍົກເລີກ</Button>
                        </Col>
                        <Col md={{ span: 4, offset: 1 }} className="my-2">
                            <button type="button" className="buttonRedColor" style={{ width: '100%', height: '38px' }} onClick={()=>handleSubmit()}>ບັນທຶກ</button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <div style={{ display: fail ? 'inline' : 'none', width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.4)', position: 'fixed', top: '0', left: '0' }}>
                <Fail msg=" ກະລຸນາລອງໃໝ່ອີກຄັ້ງ ຫຼື ປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ" confirm={() => setFail(false)} />
            </div>
            <div style={{ zIndex: '9003', display: suc ? 'inline' : 'none', width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.4)', position: 'fixed', top: '0', left: '0' }}>
                <Success />
            </div>
        </RootStyle>
    )
}

const RootStyle = styled.div`
    padding:20px;
`

const dataAll =
{
    normal: [
        { title: 'ປະເພດລູກຄ້າ', value: 'ສາຍແຂນ' },
        { title: 'ສະຖານະ', value: 'ສາຍແຂນ' },
        { title: 'ປະເພດເອກະສານ', value: 'ສາຍແຂນ' },
        { title: 'ລະຫັດບັດ', value: 'ສາຍແຂນ' },
        { title: 'ຊື່ທະນາຄານ', value: 'ສາຍແຂນ' },
        { title: 'ບັນຊີຊື່', value: 'ສາຍແຂນ' },
        { title: 'ເລກບັນຊີ', value: 'ສາຍແຂນ' },
        { title: 'ຈຳນວນທີ່ສາມາດສັ່ງຊື້ໄດ້', value: 'ສາຍແຂນ' },
        { title: 'ວັນທີສ້າງ', value: 'ສາຍແຂນ' },
        { title: 'ວັນທີແກ້ໄຂ', value: 'ສາຍແຂນ' },
    ],
    profile: [
        { title: 'ລະຫັດ', value: 'ສາຍແຂນ' },
        { title: 'ຄຳນຳຫນ້າ', value: 'ສາຍແຂນ' },
        { title: 'ຊື່ ແລະ ນາມສະກຸນ', value: 'ສາຍແຂນ' },
        { title: 'ວັນເດືອນປີເກີດ', value: 'ສາຍແຂນ' },
        { title: 'ເພດ', value: 'ສາຍແຂນ' },
        { title: 'ເບີໂທ', value: 'ສາຍແຂນ' },
        { title: 'ອິເມວ', value: 'ສາຍແຂນ' },
        { title: 'ລະຫັດຜ່ານ', value: 'ສາຍແຂນ' },
    ],
    address: [
        { title: 'ລະຫັດ', value: 'ສາຍແຂນ' },
        { title: 'ຄຳນຳຫນ້າ', value: 'ສາຍແຂນ' },
        { title: 'ຊື່ ແລະ ນາມສະກຸນ', value: 'ສາຍແຂນ' },
    ],
    sentAddress: [
        { title: 'ລະຫັດ', value: 'ສາຍແຂນ' },
        { title: 'ຄຳນຳຫນ້າ', value: 'ສາຍແຂນ' },
        { title: 'ຊື່ ແລະ ນາມສະກຸນ', value: 'ສາຍແຂນ' },
    ],

}