

import React, { useState, useEffect, useRef } from "react";
/**
 *
 * @Library
 *
*/
import axios from "axios";
import * as _ from 'lodash'
import moment from 'moment'
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from 'react-loading';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
/**
 *
 * @Component
 *
 */
import { Card, Col, Form, Row, Table, Breadcrumb, InputGroup, Button, Modal } from "react-bootstrap";
// import CustomSearch from "../../../common/CustomSearch";
// import CustomTitleCard from "../../../common/CustomTitleCard";
/**
 *
 * @Constant
 *
 */
import consts from "../../../consts";

/**
 *
 * @API
 *
 */
import { SERVER_URL } from "../../../api";

/**
 *
 * @Function
 *
 */
import formatCurrency from "../../../helpers/formatCurrency";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../../helpers/user";
import { formatDate, convertOrderType, convertOrderTypeMessage, convertOrderTypeGoldNewExpense, convertOrderTypeGoldOldExpense, formatCurrencyGold, CurrencyMoney } from "../../../helpers";
import PaginationHelper from "../../../helpers/PaginationHelper";
import { useSelector } from "react-redux";
import { selectOrderProduct } from "../../../redux/order_product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FetchType } from "apollo-boost";
import ExpensesSkeleton from "./ExpensesSkeleton";


// ------------------------------------------------------ //
export default function Expenses() {
    const History = useHistory();
    const parsed = queryString.parse(History?.location?.search);
    const printRef = useRef();

    const [isLoading, setIsLoading] = useState(true)
    const orderProduct = useSelector(selectOrderProduct)
    const [orderTotal, setOrderTotal] = useState(0)
    const [orderData, setOrderData] = useState([])
    // console.log('orderData: ', orderData);

    const [totalProductAmount, setTotalProductAmount] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [staffName, setStaffName] = useState(parsed?.staffName ? parsed?.staffName : '')
    const [customerPhone, setCustomerPhone] = useState(parsed?.customerPhone ? parsed?.customerPhone : '')
    const [customerName, setCustomerName] = useState(parsed?.customerName ? parsed?.customerName : '')
    const [inputOrderCode, setInputOrderCode] = useState(parsed?.inputOrderCode ? parsed?.inputOrderCode : '')
    const [startDate, setStartDate] = useState(formatDate(parsed?.startDate ? parsed?.startDate : formatDate(new Date())))
    const [endDate, setEndDate] = useState(parsed?.endDate ? parsed?.endDate : formatDate(new Date()))
    const [orderStatus, setOrderStatus] = useState(parsed?.orderStatus ? parsed?.orderStatus : 'FINISHED')
    const [orderType, setOrderType] = useState(parsed?.orderType ? parsed?.orderType : 'CHANGE_REFUND')
    const { _limit, _skip, Pagination_helper } = PaginationHelper();
    const [dataSelect, setDataSelect] = useState({});

    const [totalPriceByTypeAndCurrencys, setTotalPriceByTypeAndCurrencys] = useState()
    const [totalPriceByCurrencys, setTotalPriceByCurrencys] = useState()
    const [totalByWeight, setTotalWeight] = useState(0)
    const [totalByWeightGold, setTotalWeightGold] = useState()
    const [totalWeightGoldOld, setTotalWeightGoldOld] = useState(0)
    const [remainAmount, setRemainAmount] = useState(0)
    const [totalPriceBuy, setTotalPriceBuy] = useState(null);
    const [totalPriceRefund, setTotalPriceRefund] = useState(null)
    const [expenses, setExpenses] = useState('EXPENDITURE')
    const [gold, setGold] = useState('');
    const [goldBar, setGoldBar] = useState('');

    const [newWeight, setNewWeight] = useState(0)
    const [oldWeight, setOldWeight] = useState(0)
    const [oldWeightFromOther, setOldWeightFromOther] = useState(0)
    const [totalIncome, setTotalIncome] = useState(0)
    const [totalDepositPrice, setTotalDepositPrice] = useState(0)
    const [moneyIncomeArr, setMoneyIncomeArr] = useState([])

    const [moneyBuy, setMoneyBuy] = useState()
    const [moneyChangeRefund, setMoneyChangeRefund] = useState()
    const [moneyRent, setMoneyRent] = useState()
    const [moneyOrder, setMoneyOrder] = useState()
    const [moneyOther, setMoneyOther] = useState()

    const [newWeightBuyFromKpv, setNewWeightBuyFromKpv] = useState()
    const [newWeightBuyFromOther, setNewWeightBuyFromOther] = useState()
    const [newWeightChangeRefund, setNewWeightChangeRefund] = useState()
    const [newWeightRent, setNewWeightRent] = useState()
    const [newWeightOrder, setNewWeightOrder] = useState()

    const [oldWeightBuyFromKpv, setOldWeightBuyFromKpv] = useState()
    const [oldWeightBuyFromOther, setOldWeightBuyFromOther] = useState()
    const [oldWeightChangeRefund, setOldWeightChangeRefund] = useState()
    const [oldWeightRent, setOldWeightRent] = useState()
    const [oldWeightOrder, setOldWeightOrder] = useState()

    useEffect(() => {
        getOrder();
        getStock();
        getDataReportAll();
    }, [startDate, endDate, customerPhone, orderStatus, orderType, inputOrderCode, _limit, _skip,])

    const getDataReportAll = async () => {
        let _token = getAccessTokkenFromLCS();
        var branch_id = getUserDataFromLCStorage()?.branch_id;
        try {  
          const dataBuy = await axios.get(
            `${SERVER_URL}/order-service/api/v1/dashboard/buy?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
            {
              headers: {
                Authorization: _token,
              },
            }
          );
          if (dataBuy.status === 200) {
            setMoneyBuy(dataBuy?.data?.buy_price_dashboard);
            setNewWeightBuyFromKpv(0)
            setNewWeightBuyFromOther(0)
            setOldWeightBuyFromKpv(dataBuy?.data?.buy_dashboard?.[0]?.total_old_weight_from_kpv ?? 0)
            setOldWeightBuyFromOther(dataBuy?.data?.buy_dashboard?.[0]?.total_old_weight_from_other ?? 0)
          }

          const dataChangeRefund = await axios.get(
            `${SERVER_URL}/order-service/api/v1/dashboard/change-refund?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
            {
              headers: {
                Authorization: _token,
              },
            }
          );
          if (dataChangeRefund.status === 200) {
            setMoneyChangeRefund(dataChangeRefund?.data?.payment);
            setNewWeightChangeRefund(dataChangeRefund?.data?.change_refund_dashboard?.[0]?.total_weight_out ?? 0)
            setOldWeightChangeRefund(dataChangeRefund?.data?.change_refund_dashboard?.[0]?.total_old_weight_from_customer ?? 0)
          }
          
          const dataRent = await axios.get(
            `${SERVER_URL}/order-service/api/v1/dashboard/rent-expenses?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
            {
              headers: {
                Authorization: _token,
              },
            }
          );
          if (dataRent.status === 200) {
            setMoneyRent(dataRent?.data?.payment);
            setNewWeightRent(dataRent?.data?.rent_expenses_dashboard?.total_weight_taken ?? 0)
            setOldWeightRent(dataRent?.data?.rent_expenses_dashboard?.total_rent_weight ?? 0)
          }
          
          const dataOrder = await axios.get(
            `${SERVER_URL}/order-service/api/v1/dashboard/order-expenses?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
            {
              headers: {
                Authorization: _token,
              },
            }
          );
          if (dataOrder.status === 200) {
            setMoneyOrder(dataOrder?.data?.payment);
            setNewWeightOrder(dataOrder?.data?.order_income_dashboard?.total_weight_taken ?? 0)
            setOldWeightOrder(dataOrder?.data?.order_income_dashboard?.total_ordered_weight ?? 0)
          }
          
          const dataOther = await axios.get(
            `${SERVER_URL}/expenditure-service/api/v1/dashboard/other?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
            {
              headers: {
                Authorization: _token,
              },
            }
          );
          if (dataOther.status === 200) {
            setMoneyOther(dataOther?.data?.payment);
          }   
    
        } catch (err) {
          if (err?.response?.data?.msg === "Token is expired") {
            getReNewToken(err, getDataReportAll());
          }
        }
    }

    const getOrder = async () => {
        let _token = getAccessTokkenFromLCS();
        var branch_id = getUserDataFromLCStorage()?.branch_id;
        try {
            setIsLoading(true)

            let api_order_url = ''
            let api_order = ''
            if(orderType == 'OTHER') {
                api_order_url = `${SERVER_URL}/expenditure-service/api/v1/expenditure?limit=${_limit}&skip=${(_skip - 1) * _limit}&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`
            }else if(orderType == 'RENT' || orderType == 'ORDER') {
                api_order_url = `${SERVER_URL}/order-service/api/v1/order?limit=${_limit}&skip=${(_skip - 1) * _limit}&order_code=${inputOrderCode}&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&order_type=${orderType}&customer_phone=${customerPhone}&customer_name=${customerName}`
            }else {
                api_order_url = `${SERVER_URL}/order-service/api/v1/order?limit=${_limit}&skip=${(_skip - 1) * _limit}&order_code=${inputOrderCode}&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&order_status=${orderStatus}&order_type=${orderType}&customer_phone=${customerPhone}&customer_name=${customerName}`
            }
            const dataOrder = await axios.get( api_order_url, {
                headers: {
                    Authorization: _token,
                },
            });
            if (dataOrder.status === 200) {
                setOrderData(dataOrder?.data);
                setOrderTotal(dataOrder?.data?.total);
            }

            let api_url = ''
            if(orderType == 'CHANGE_REFUND') {
                api_url = `${SERVER_URL}/order-service/api/v1/dashboard/change-refund?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}&order_code=${inputOrderCode}`;
            }else if(orderType == 'BUY') {
                api_url = `${SERVER_URL}/order-service/api/v1/dashboard/buy?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}&order_code=${inputOrderCode}`;
            }else if(orderType == 'RENT') {
                api_url = `${SERVER_URL}/order-service/api/v1/dashboard/rent-expenses?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}&order_code=${inputOrderCode}`;
            }else if(orderType == 'ORDER') {
                api_url = `${SERVER_URL}/order-service/api/v1/dashboard/order-expenses?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}&order_code=${inputOrderCode}`;
            }else if(orderType == 'PLEDGE') {
                setNewWeight(0)
                setOldWeight(0)
                setOldWeightFromOther(0)
                setTotalIncome(0)
                setTotalDepositPrice(0)
                setMoneyIncomeArr([])
                setIsLoading(false)
            }else if(orderType == 'OTHER') {
                api_url = `${SERVER_URL}/expenditure-service/api/v1/dashboard/other?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`;
            }
            const data = await axios.get(api_url, {
                headers: {
                  Authorization: _token,
                },
            });
            if (data.status === 200) {
                if(orderType == 'CHANGE_REFUND') {
                    setNewWeight(data?.data?.change_refund_dashboard?.[0]?.total_weight_out)
                    setOldWeight(data?.data?.change_refund_dashboard?.[0]?.total_old_weight_from_customer)
                    setTotalIncome(data?.data?.change_refund_dashboard?.[0]?.total_expenses)
                    // setTotalIncome(data?.data?.change_refund_dashboard?.[0]?.total_final)
                    setMoneyIncomeArr(data?.data?.payment)
                }else if(orderType == 'BUY') {
                    setNewWeight(data?.data?.buy_dashboard?.[0]?.total_weight_buy)
                    setOldWeight(data?.data?.buy_dashboard?.[0]?.total_old_weight_from_kpv)
                    setOldWeightFromOther(data?.data?.buy_dashboard?.[0]?.total_old_weight_from_other)
                    setTotalIncome(data?.data?.buy_dashboard?.[0]?.total_expenses)
                    setMoneyIncomeArr(data?.data?.payment)
                }else if(orderType == 'RENT') {
                    setNewWeight(data?.data?.rent_expenses_dashboard?.total_weight_taken)
                    setOldWeight(data?.data?.rent_expenses_dashboard?.total_rent_weight)
                    setTotalDepositPrice(data?.data?.rent_expenses_dashboard?.total_remaind_deposit)
                    setTotalIncome(data?.data?.rent_expenses_dashboard?.total_expenses)
                    setMoneyIncomeArr(data?.data?.payment)
                }else if(orderType == 'ORDER') {
                    setNewWeight(data?.data?.order_expenses_dashboard?.total_weight_taken)
                    setOldWeight(data?.data?.order_expenses_dashboard?.total_ordered_weight)
                    setTotalDepositPrice(data?.data?.order_expenses_dashboard?.total_remaind_deposit)
                    setTotalIncome(data?.data?.order_expenses_dashboard?.total_expenses)
                    setMoneyIncomeArr(data?.data?.payment)
                }else if(orderType == 'PLEDGE') {
                
                }else if(orderType == 'OTHER') {
                    setTotalIncome(data?.data?.other_dashboard?.[0]?.total_expenditure)
                    setMoneyIncomeArr(data?.data?.payment)
                }

                setIsLoading(false)
            }
        } catch (err) {
            if (err?.response?.data?.msg === "Token is expired") {
                getReNewToken(err, getOrder());
            }
        }
    };

    const _checkOrderTypeToDetail = (order_type, OrderId) => {
        if (order_type === 'BUY') History.push(`${consts.PAGE_REPORT}/buy/detail/${OrderId}`)
        if (order_type === 'BUY_MORE') History.push(`${consts.PAGE_REPORT}/buy-more/detail/${OrderId}`)
        if (order_type === 'CHANGE_MORE') History.push(`${consts.PAGE_REPORT}/change-more/detail/${OrderId}`)
        if (order_type === 'CHANGE_FREE') History.push(`${consts.PAGE_REPORT}/change-free/detail/${OrderId}`)
        if (order_type === 'CHANGE_REFUND') History.push(`${consts.PAGE_REPORT}/change-refund/detail/${OrderId}`)
        if (order_type === 'SALE') History.push(`${consts.PAGE_REPORT}/sale/detail/${OrderId}`)
        if (order_type === 'RENT') History.push(`${consts.PAGE_REPORT}/borrow/detail/${OrderId}`)
        if (order_type === 'PLEDGE') History.push(`${consts.PAGE_REPORT}/pledge/detail/${OrderId}`)
        if (order_type === 'ORDER') History.push(`${consts.PAGE_REPORT}/order/detail/${OrderId}`)
    }

    useEffect(() => {
        getGoldType()
    }, [])

    const getGoldType = async () => {
        try {
            let _token = getAccessTokkenFromLCS();
            var branch_id = getUserDataFromLCStorage()?.branch_id;
            axios.get(`${SERVER_URL}/order-service/api/v1/order-product?branch_id=${branch_id}`, {
                headers: {
                    Authorization: _token,
                }
            })
                .then((res) => {
                    let result = res?.data?.data;
                    let gold = result?.filter((data) => data.goldType == "GOLD");
                    let goldBar = result?.filter((data) => data.goldType == "GOLD_BAR");
                    setGold(gold);
                    setGoldBar(goldBar);
                })
        } catch (error) {
            console.log(error);
        }
    }


    const getStock = async () => {
        let _token = getAccessTokkenFromLCS();
        // console.log('_token: ', _token);
        try {
            var branch_id = getUserDataFromLCStorage()?.branch_id;

            await axios.get(
                `${SERVER_URL}/stock-service/api/v1/branch-product?limit=${_limit}&skip=${(_skip - 1) * _limit}&branch=${getUserDataFromLCStorage().branch_id
                }`,
                {
                    headers: {
                        Authorization: _token,
                    },
                }
            ).then((res) => {
                setRemainAmount(res?.data?.totalWeight)
                // console.log('res----------------->', res.data);
            })
        } catch (err) {
            if (err?.response?.data?.msg === "Token is expired") {
                getReNewToken(err, getStock());
            }
        }
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        await html2canvas(element, {
            logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
        }).then(canvas => {
            const imgWidth = 208;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;
            heightLeft -= pageHeight;
            const doc = new jsPDF('p', 'mm');
            doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                heightLeft -= pageHeight;
            }
            const date = new Date();
            doc.save('Report-' + moment(date).format("MM/DD/YY HH:mm:ss") + '.pdf');
        }
        )
    };
    const incomeType = (currency, type) => {
        let resp = moneyIncomeArr?.filter((item) => {
          return item?.id[0] == currency && item?.id[1] == type;
        });
        if(resp?.length > 0) return formatCurrency(resp?.[0]?.total_payment)
        else return 0
    }
    const totalIncomeByCurrency = (currency) => {
    let resp = moneyIncomeArr?.filter((item) => {
        return item?.id[0] == currency
    });
    const result = _.sumBy(resp, 'total_payment')
    if(resp?.length > 0) return formatCurrency(result)
    else return 0
    }

    const incomeTypeAll = (data, currency, type) => {
        let resp = data?.filter((item) => {
          return item?.id[0] == currency && item?.id[1] == type;
        });
        if(resp?.length > 0) return resp?.[0]?.total_payment
        else return 0
    }
    const convertMoneyBuyFrom = (data, type) => {
        let resp = data?.filter((item) => {
          return item?.id[1] == type;
        });
        if(resp?.length > 0) return resp?.[0]?.total_income_old_gold
        else return 0
    }

    return (
        getUserDataFromLCStorage()?.role === 'MANAGER' ?
            <>
                <Card style={{padding: 20}} className="mb-2">
                    <Row>
                        <Col>
                        <Row>
                            <Col md={3} className="mb-3">
                            <Form.Group controlId='doj'>
                                <Form.Label>ສະແດງຕາມວັນທີ</Form.Label>
                                <Form.Control
                                defaultValue={endDate}
                                onChange={(e) => {
                                    setStartDate(e.target.value)
                                }}
                                type='date' />
                            </Form.Group>
                            </Col>
                            <Col md={3} className="mb-3">
                            <Form.Group controlId='doj'>
                                <Form.Label>ຫາວັນທີ</Form.Label>
                                <Form.Control
                                defaultValue={endDate}
                                onChange={(e) => {
                                    setEndDate(e.target.value)
                                }}
                                type='date' />
                            </Form.Group>
                            </Col>
                            {orderType == 'EXPENDITURE' || orderType == 'OTHER' ? <></> : 
                            <>
                            <Col md={3} className="mb-3"></Col>
                            {/* <Col md={3} className="mb-3">
                                <Form.Label>ສະຖານະໃບບິນ</Form.Label>
                                <Form.Control
                                as='select'
                                className="form-select"
                                defaultValue={orderStatus}
                                onChange={(e) => setOrderStatus(e.target.value)}
                                >
                                <option value="">ທັງໝົດ</option>
                                <option value="FINISHED">ສຳເລັດ</option>
                                <option value="CASHIER_APPROVED">ແຄັດເຊຍອະນຸມັດ</option>
                                <option value="SALE_CREATED">ສ້າງການຂາຍສຳເລັດ</option>
                                <option value="CASHIER_REJECTED">ແຄັດເຊຍປະຕິເສດ</option>
                                </Form.Control>
                            </Col> */}
                            <Col md={3} className="mb-3"></Col>
                            <Col md={3} className="mb-3">
                                <Form.Label>ຄົ້ນຫາຕາມເລກທີໃບບິນ</Form.Label>
                                <InputGroup>
                                <Form.Control
                                    placeholder="ຄົ້ນຫາຕາມເລກທີໃບບິນ"
                                    aria-label="ຄົ້ນຫາຕາມເລກທີໃບບິນ"
                                    aria-describedby="basic-addon2"
                                    onChange={(event) => setInputOrderCode(event?.target?.value)}
                                />
                                <Button variant="outline-danger" id="button-addon2">
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                                </InputGroup>
                            </Col>
                            <Col md={3} className="mb-3">
                                <Form.Label>ຄົ້ນຫາເບິ</Form.Label>
                                <InputGroup>
                                <Form.Control
                                    placeholder="ຄົ້ນຫາເບິ"
                                    aria-label="ຄົ້ນຫາເບິ"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => { setCustomerPhone(e?.target?.value) }}
                                />
                                <Button variant="outline-danger" id="button-addon2">
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                                </InputGroup>
                                {/* <CustomSearch value={customerPhone} onChange={(e)=>{setCustomerPhone(e?.target?.value)}} placeholder='ຄົ້ນຫາເບິ'/> */}
                            </Col>
                            <Col md={3} className="mb-3">
                                <Form.Label>ຄົ້ນຫາຊື່ລູກຄ້າ</Form.Label>
                                <InputGroup>
                                <Form.Control
                                    placeholder="ຄົ້ນຫາຊື່ລູກຄ້າ"
                                    aria-label="ຄົ້ນຫາຊື່ລູກຄ້າ"
                                    aria-describedby="basic-addon2"
                                    onChange={(e) => { setCustomerName(e?.target?.value) }}
                                />
                                <Button variant="outline-danger" id="button-addon2">
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                                </InputGroup>
                                {/* <CustomSearch value={customerName} onChange={(e)=>{setCustomerName(e?.target?.value)}} placeholder='ຄົ້ນຫາຊື່'/> */}
                            </Col>
                            </>
                            }

                            <Col md={3} className="mb-3">
                            <Form.Label>ຮູບແບບທຸລະກຳ</Form.Label>
                            <Form.Control
                                as='select'
                                className="form-select"
                                defaultValue={orderType}
                                onChange={(e) => { setOrderType(e.target.value); setExpenses(e.target.value); }}
                            >
                                <option value="EXPENDITURE">ທັງໝົດ</option>
                                <option value="CHANGE_REFUND">ການປ່ຽນຄືນເງິນ</option>
                                <option value="RENT">ການເຊົ່າ</option>
                                <option value="ORDER">ການຈອງ</option>
                                <option value="PLEDGE">ການຈຳຄຳ</option>
                                <option value="BUY">ຊື້ຄຳຄືນ</option>
                                <option value="OTHER">ລາຍຈ່າຍອື່ນໆ</option>
                            </Form.Control>
                            </Col>
                        </Row>
                        </Col>
                        {/* <Col md={1} className="d-flex justify-content-end align-items-end mb-3">
                        <div style={{ marginTop: "31px" }}>
                            <button onClick={handleShow} id="format-PDF" className='buttonRedColor-export' >Export PDF</button>
                        </div>
                        </Col> */}
                    </Row>
                </Card>
                {orderType == 'EXPENDITURE' ? 
                <>
                    <Card style={{padding: 20}} className="mb-2">
                        <div className="document-head"><p className="doc-head">ລາຍງານລວມ ລາຍຈ່າຍທັງໝົດ</p></div>
                        <Table className='table-hover'>
                        <thead>
                            <tr>
                            <th style={{width: 100}}>ປະເພດ</th>
                            <th>ຊື້ຄຳຄືນ(ຮ້ານ KPV)</th>
                            <th>ຊື້ຄຳຄືນ(ຮ້ານອື່ນ)</th>
                            <th>ປ່ຽນຄືນເງິນ</th>
                            <th>ຮັບຄຳເຊົ່າຄືນ</th>
                            <th>ຮັບຄຳຈອງ</th>
                            <th>ການຈຳຄຳ</th>
                            <th>ລາຍຈ່າຍອື່ນໆ</th>
                            <th>ລວມລາຍຈ່າຍທັງໝົດ</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><b>LAK ສົດ</b></td>
                                <td>{CurrencyMoney(convertMoneyBuyFrom(moneyBuy, 'ຮ້ານຄຳພູວົງ'))}</td>
                                <td>{CurrencyMoney(convertMoneyBuyFrom(moneyBuy, 'ຈາກຮ້ານອື່ນ'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyChangeRefund, 'LAK', 'CASH'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyRent, 'LAK', 'CASH'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOrder, 'LAK', 'CASH'))}</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOther, 'LAK', 'NO'))}</td>
                                <td className="dashboard-text-all-total dashboard-text-red">
                                    {
                                        CurrencyMoney(
                                            convertMoneyBuyFrom(moneyBuy, 'ຮ້ານຄຳພູວົງ') + 
                                            incomeTypeAll(moneyChangeRefund, 'LAK', 'CASH') +
                                            incomeTypeAll(moneyRent, 'LAK', 'CASH') +
                                            incomeTypeAll(moneyOrder, 'LAK', 'CASH') +
                                            incomeTypeAll(moneyOther, 'LAK', 'NO')
                                        )
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td><b>LAK ໂອນ</b></td>
                                <td>0</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyChangeRefund, 'LAK', 'TRANSFER'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyRent, 'LAK', 'TRANSFER'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOrder, 'LAK', 'TRANSFER'))}</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOther, 'LAK', 'YES'))}</td>
                                <td className="dashboard-text-all-total dashboard-text-red">
                                    {
                                        CurrencyMoney(
                                            incomeTypeAll(moneyChangeRefund, 'LAK', 'TRANSFER') +
                                            incomeTypeAll(moneyRent, 'LAK', 'TRANSFER') +
                                            incomeTypeAll(moneyOrder, 'LAK', 'TRANSFER') +
                                            incomeTypeAll(moneyOther, 'LAK', 'YES')
                                        )
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td><b>THB ສົດ</b></td>
                                <td>0</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyChangeRefund, 'THB', 'CASH'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyRent, 'THB', 'CASH'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOrder, 'THB', 'CASH'))}</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOther, 'THB', 'NO'))}</td>
                                <td className="dashboard-text-all-total dashboard-text-red">
                                    {
                                        CurrencyMoney(
                                            incomeTypeAll(moneyChangeRefund, 'THB', 'CASH') +
                                            incomeTypeAll(moneyRent, 'THB', 'CASH') +
                                            incomeTypeAll(moneyOrder, 'THB', 'CASH') +
                                            incomeTypeAll(moneyOther, 'THB', 'NO')
                                        )
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td><b>THB ໂອນ</b></td>
                                <td>0</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyChangeRefund, 'THB', 'TRANSFER'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyRent, 'THB', 'TRANSFER'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOrder, 'THB', 'TRANSFER'))}</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOther, 'THB', 'YES'))}</td>
                                <td className="dashboard-text-all-total dashboard-text-red">
                                    {
                                        CurrencyMoney(
                                            incomeTypeAll(moneyChangeRefund, 'THB', 'TRANSFER') +
                                            incomeTypeAll(moneyRent, 'THB', 'TRANSFER') +
                                            incomeTypeAll(moneyOrder, 'THB', 'TRANSFER') +
                                            incomeTypeAll(moneyOther, 'THB', 'YES')
                                        )
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td><b>USD ສົດ</b></td>
                                <td>0</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyChangeRefund, 'USD', 'CASH'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyRent, 'USD', 'CASH'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOrder, 'USD', 'CASH'))}</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOther, 'USD', 'NO'))}</td>
                                <td className="dashboard-text-all-total dashboard-text-red">
                                    {
                                        CurrencyMoney(
                                            incomeTypeAll(moneyChangeRefund, 'USD', 'CASH') +
                                            incomeTypeAll(moneyRent, 'USD', 'CASH') +
                                            incomeTypeAll(moneyOrder, 'USD', 'CASH') +
                                            incomeTypeAll(moneyOther, 'USD', 'NO')
                                        )
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td><b>USD ໂອນ</b></td>
                                <td>0</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyChangeRefund, 'USD', 'TRANSFER'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyRent, 'USD', 'TRANSFER'))}</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOrder, 'USD', 'TRANSFER'))}</td>
                                <td>0</td>
                                <td>{CurrencyMoney(incomeTypeAll(moneyOther, 'USD', 'YES'))}</td>
                                <td className="dashboard-text-all-total dashboard-text-red">
                                    {
                                        CurrencyMoney(
                                            incomeTypeAll(moneyChangeRefund, 'USD', 'TRANSFER') +
                                            incomeTypeAll(moneyRent, 'USD', 'TRANSFER') +
                                            incomeTypeAll(moneyOrder, 'USD', 'TRANSFER') +
                                            incomeTypeAll(moneyOther, 'USD', 'YES')
                                        )
                                    }
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </Card>
                    <Card style={{padding: 20}} className="mb-2">
                        <div className="document-head"><p className="doc-head">ລາຍງານລວມ ຈຳນວນຄຳທັງໝົດ</p></div>
                        <Table className='table-hover'>
                        <thead>
                            <tr>
                            <th style={{width: 100}}>ປະເພດ</th>
                            <th>ຊື້ຄຳຄືນ(ຮ້ານ KPV)</th>
                            <th>ຊື້ຄຳຄືນ(ຮ້ານອື່ນ)</th>
                            <th>ປ່ຽນຄືນເງິນ</th>
                            <th>ຮັບຄຳເຊົ່າຄືນ</th>
                            <th>ຮັບຄຳຈອງ</th>
                            <th>ການຈຳຄຳ</th>
                            <th>ລວມຍອດຄຳ</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td><b>ຄຳເກົ່າ</b></td>
                            <td>{formatCurrencyGold(oldWeightBuyFromKpv)}g</td>
                            <td>{formatCurrencyGold(oldWeightBuyFromOther)}g</td>
                            <td>{formatCurrencyGold(oldWeightChangeRefund)}g</td>
                            <td>{formatCurrencyGold(oldWeightRent)}g</td>
                            <td>{formatCurrencyGold(oldWeightOrder)}g</td>
                            <td>0g</td>
                            <td className="dashboard-text-all-total dashboard-text-red">
                                {formatCurrencyGold(
                                    oldWeightBuyFromKpv +
                                    oldWeightBuyFromOther +
                                    oldWeightChangeRefund +
                                    oldWeightRent +
                                    oldWeightOrder
                                )}g
                            </td>
                            </tr>
                            <tr>
                            <td><b>ຄຳໃໝ່</b></td>
                            <td>{formatCurrencyGold(newWeightBuyFromKpv)}g</td>
                            <td>{formatCurrencyGold(newWeightBuyFromOther)}g</td>
                            <td>{formatCurrencyGold(newWeightChangeRefund)}g</td>
                            <td>{formatCurrencyGold(newWeightRent)}g</td>
                            <td>{formatCurrencyGold(newWeightOrder)}g</td>
                            <td>0g</td>
                            <td className="dashboard-text-all-total dashboard-text-red">
                                {formatCurrencyGold(
                                    newWeightBuyFromKpv +
                                    newWeightBuyFromOther +
                                    newWeightChangeRefund +
                                    newWeightRent +
                                    newWeightOrder
                                )}g
                            </td>
                            </tr>
                        </tbody>
                        </Table>
                    </Card>
                </>
                :
                <>
                {isLoading ? <ExpensesSkeleton /> :
                <Card style={{padding: 20}} className="mb-2" ref={printRef}>
                    <Row>
                        {orderType == 'OTHER' ? <></> :
                        <Col className="dashboard-grid">
                            <div className="dashboard-container d-flex-center-space-between-column dashboard-bg-lightblue p-0 pb-4">
                            <div className="dashboard-menu-type">
                                <span className="dashboard-text-all-total dashboard-text-orange">{convertOrderTypeMessage(orderType)}(g)</span>
                            </div>
                            <span>{convertOrderTypeGoldNewExpense(orderType)}</span>
                            <span className="dashboard-text-all-total dashboard-text-orange">{formatCurrencyGold(newWeight ?? 0)}g</span>
                            </div>
                        </Col>
                        }
                        <Col className="dashboard-grid">
                            <div className="dashboard-container d-flex-center-column dashboard-bg-red">
                            <span className="dashboard-text-darkblue"><b>{orderType == 'OTHER' ? 'ລາຍຈ່າຍອື່ນໆ' : 'ລາຍຈ່າຍທັງໝົດ'}</b></span>
                            <span className="dashboard-text-all-total dashboard-text-red mt-3">{formatCurrency(totalIncome ?? 0)} LAK</span>
                            </div>
                        </Col>
                        {orderType == 'OTHER' ? 
                        <>
                        <Col className="dashboard-grid">
                            <div className="dashboard-container dashboard-bg-red">
                            <div className="d-flex flex-column align-items-center">
                                <span className="dashboard-title">ລາຍຈ່າຍ <b>LAK</b></span>
                                <span className="dashboard-text-all-total dashboard-text-red">{totalIncomeByCurrency('LAK')} LAK</span>
                            </div>
                            <div className="d-flex flex-column align-items-center mt-3">
                                <div className='dashboard-summery-top' style={{ marginBottom: 5 }}>
                                <span>ເງີນສົດ:</span>
                                <span>{orderType == 'OTHER' ? incomeType('LAK', 'NO') : incomeType('LAK', 'CASH')} LAK</span>
                                </div>
                                <div className='dashboard-summery-bottom'>
                                <span>ເງີນໂອນ:</span>
                                <span>{orderType == 'OTHER' ? incomeType('LAK', 'YES') : incomeType('LAK', 'TRANSFER')} LAK</span>
                                </div>
                            </div>
                            </div>
                        </Col>
                        <Col className="dashboard-grid">
                            <div className="dashboard-container dashboard-bg-red">
                            <div className="d-flex flex-column align-items-center">
                                <span className="dashboard-title">ລາຍຈ່າຍ <b>THB</b></span>
                                <span className="dashboard-text-all-total dashboard-text-red">{totalIncomeByCurrency('THB')} THB</span>
                            </div>
                            <div className="d-flex flex-column align-items-center mt-3">
                                <div className='dashboard-summery-top' style={{ marginBottom: 5 }}>
                                <span>ເງີນສົດ:</span>
                                <span>{incomeType('THB', 'CASH')} THB</span>
                                </div>
                                <div className='dashboard-summery-bottom'>
                                <span>ເງີນໂອນ:</span>
                                <span>{incomeType('THB', 'TRANSFER')} THB</span>
                                </div>
                            </div>
                            </div>
                        </Col>
                        <Col className="dashboard-grid">
                            <div className="dashboard-container dashboard-bg-red">
                            <div className="d-flex flex-column align-items-center">
                                <span className="dashboard-title">ລາຍຈ່າຍ <b>USD</b></span>
                                <span className="dashboard-text-all-total dashboard-text-red">{totalIncomeByCurrency('USD')} USD</span>
                            </div>
                            <div className="d-flex flex-column align-items-center mt-3">
                                <div className='dashboard-summery-top' style={{ marginBottom: 5 }}>
                                <span>ເງີນສົດ:</span>
                                <span>{incomeType('USD', 'CASH')} USD</span>
                                </div>
                                <div className='dashboard-summery-bottom'>
                                <span>ເງີນໂອນ:</span>
                                <span>{incomeType('USD', 'TRANSFER')} USD</span>
                                </div>
                            </div>
                            </div>
                        </Col>
                        </>
                        : <></>
                        }
                        {orderType == 'OTHER' ? <></> 
                        : orderType == 'RENT' || orderType == 'ORDER' ? 
                            <>
                            <Col className="dashboard-grid">
                                <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                                    <span className="dashboard-text-darkblue">ຄ່າມັດຈຳຍັງເຫຼືອທັງໝົດ(LAK)</span>
                                    <span className="dashboard-text-all-total dashboard-text-green">{formatCurrency(totalDepositPrice ?? 0)} LAK</span>
                                </div>
                            </Col>
                            <Col className="dashboard-grid">
                                <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                                    <span className="dashboard-text-darkblue">{convertOrderTypeGoldOldExpense(orderType)} (g)</span>
                                    <span className="dashboard-text-all-total dashboard-text-orange">{formatCurrencyGold(oldWeight ?? 0)}g</span>
                                </div>
                            </Col>
                            </>
                            :
                        
                        <Col className="dashboard-grid">
                            {orderType == 'BUY' ? 
                                <div>
                                    <Col className="h-50" style={{paddingBottom: 4}}>
                                        <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                                            <span className="dashboard-text-darkblue">ຄຳເກົ່າຈາກຮ້ານຄຳພູວົງ (g)</span>
                                            <span className="dashboard-text-all-total dashboard-text-orange">{formatCurrencyGold(oldWeight ?? 0)}g</span>
                                        </div>
                                    </Col>
                                    <Col className="h-50" style={{paddingTop: 4}}>
                                        <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                                            <span className="dashboard-text-darkblue">ຄຳເກົ່າຈາກຮ້ານອື່ນ (g)</span>
                                            <span className="dashboard-text-all-total dashboard-text-orange">{formatCurrency(oldWeightFromOther ?? 0)}g</span>
                                        </div>
                                    </Col>
                                </div>
                                :
                                <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                                    <span className="dashboard-text-darkblue">{convertOrderTypeGoldOldExpense(orderType)} (g)</span>
                                    <span className="dashboard-text-all-total dashboard-text-orange">{formatCurrencyGold(oldWeight ?? 0)}g</span>
                                </div>
                            }
                        </Col>
                        }
                    </Row>
                </Card>
                }
                
                <Card style={{padding: 20}} className="mb-2">
                    {/* card summery report */}
                    {orderData?.length <= 0 ?
                    <div className="loading-card">
                        <div>
                        <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
                        <span>Loading....</span>
                        </div>
                    </div>
                    : orderTotal === 0 ?
                        <div>
                        <img style={{ marginLeft: "40%", marginTop: "10%" }} src='/asset/image/animation_200_ldu129fc.gif' />
                        <p style={{ marginLeft: "45%", marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
                        </div> :
                        <Table className='table-hover'>
                            {orderType == 'OTHER' ?
                            <>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ປະເພດການຈ່າຍ</th>
                                    <th>ລາຍຈ່າຍ</th>
                                    <th>ຈຳນວນເງິນ</th>
                                    <th>ສະກຸນເງິນ</th>
                                    <th>ຮູບແບບການຈ່າຍ</th>
                                    <th>ລາຍລະອຽດເພີ່ມເຕີມ</th>
                                    <th>ຜູ້ສ້າງ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData?.data?.map((result, index) => (
                                <tr key={index}>
                                    <td>{index + 1 + _limit * (_skip - 1)}</td>
                                    <td>{result?.category_name ? result?.category_name : "-"}</td>
                                    <td>{result?.expense_name ? result?.expense_name : "-"}</td>
                                    <td>
                                    {result?.amount_paid
                                        ? formatCurrency(result?.amount_paid)
                                        : 0}
                                    </td>
                                    <td>{result?.currency ? result?.currency : "-"}</td>
                                    <td>{result?.transfer === "NO" ? "ຈ່າຍສົດ" : "ໂອນ"}</td>
                                    <td>{result?.note ? result?.note : "-"}</td>
                                    <td>
                                    <b>{result?.created_by_full_name ? result?.created_by_full_name : "-"}</b>
                                    <div>{moment(result?.created_at).format("DD-MM-yyyy")}</div>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </>
                            :
                            <>
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>ເລກທີໃບບິນ</th>
                                <th>ຊື່ລູກຄ້າ</th>
                                <th>ເບີໂທ</th>
                                <th>ຈ/ນ ສິນຄ້າ</th>
                                <th>ຈ/ນ ນ້ຳໜັກເກົ່າ</th>
                                <th>ຈ/ນ ນ້ຳໜັກໃໝ່</th>
                                <th>ລາຄາລວມ</th>
                                <th>ວັນທີຂາຍ</th>
                                <th>ສະຖານະໃບບິນ</th>
                                <th>ພະນັກງານ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData?.data?.map((e, index) => (
                                <tr
                                    style={{ cursor: "pointer" }}
                                    onClick={() => _checkOrderTypeToDetail(e?.order_type, e?.id)}
                                    key={index}>
                                    <td className='align-middle'>{index + 1 + _limit * (_skip - 1)}</td>
                                    <td className='align-middle'>{e?.order_code}</td>
                                    <td className='align-middle'>{`${e?.customer_first_name} ${e?.customer_last_name}`}</td>
                                    <td className='align-middle'>{e?.customer_phone}</td>
                                    <td className='align-middle'>{e?.product_amount}</td>
                                    <td className='align-middle'>{formatCurrencyGold(e?.total_weight)}</td>
                                    {/* <td className='align-middle'>{orderProduct?.data.find(el => el.order_id === e.id)?.weight}</td> */}
                                    <td className='align-middle'>{formatCurrencyGold(e?.new_weight)}</td>
                                    <td className='align-middle'>{formatCurrency(e?.total_price)}</td>
                                    <td className='align-middle'>{moment(e?.created_at).format(consts.DATE_FORMAT)}</td>
                                    <td className='align-middle' style={{ color: e?.order_status === "FINISHED" ? 'green ' : e?.order_status === "CASHIER_REJECTED" ? 'red' : '' }}>{e?.order_status === "FINISHED" ? "ສຳເລັດ" : e?.order_status === "CASHIER_APPROVED" ? "ແຄັດເຊຍອະນຸມັດ" :
                                    e?.order_status === "SALE_CREATED" ? "ສ້າງການຂາຍສຳເລັດ" : "ແຄັດເຊຍປະຕິເສດ"}</td>
                                    <td className='align-middle'>{e?.updated_by_full_name ?? "-"}</td>

                                </tr>

                                ))
                                }
                            </tbody>
                            </>
                            }
                        </Table>
                    }
                    {Pagination_helper(orderTotal, consts.PAGE_REPORT, `inputOrderCode=${inputOrderCode}&startDate=${startDate}&endDate=${endDate}&orderStatus=${orderStatus}&orderType=${orderType}`)}
                </Card>
                </>
                }
                <Modal show={show} onHide={handleClose} size="xl">
                    <Modal.Body>
                        <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button className='buttonRedColor'
                                style={{ width: "125px", height: "51px" }}
                                onClick={handleDownloadPdf}
                            >
                                Export PDF
                            </Button>
                        </div>
                        <div className="" ref={printRef}>
                            <Table className='table-hover'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>ເລກທີໃບບິນ</th>
                                        <th>ຮູບແບບ</th>
                                        <th>ຊື່ລູກຄ້າ</th>
                                        <th>ເບີໂທ</th>
                                        <th>ຈ/ນ ສິນຄ້າ</th>
                                        <th>ຈ/ນ ນ້ຳໜັກເກົ່າ</th>
                                        <th>ຈ/ນ ນ້ຳໜັກໃໝ່</th>
                                        <th>ລາຄາລວມ</th>
                                        <th>ວັນທີຂາຍ</th>
                                        <th>ສະຖານະໃບບິນ</th>
                                        <th>ພະນັກງານ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderData?.data?.map((e, index) => (
                                        <tr
                                            style={{ cursor: "pointer" }}
                                            key={index}>
                                            <td className='align-middle'>{index + 1 + _limit * (_skip - 1)}</td>
                                            <td className='align-middle'>{e?.order_code}</td>
                                            <td className='align-middle'>{convertOrderType(e?.order_type)}</td>
                                            <td className='align-middle'>{`${e?.customer_first_name} ${e?.customer_last_name}`}</td>
                                            <td className='align-middle'>{e?.customer_phone}</td>
                                            <td className='align-middle'>{e?.product_amount}</td>
                                            <td className='align-middle'>{formatCurrencyGold(e?.total_weight)}</td>
                                            {/* <td className='align-middle'>{orderProduct?.data.find(el => el.order_id === e.id)?.weight}</td> */}
                                            <td className='align-middle'>{formatCurrencyGold(e?.new_weight)}</td>
                                            <td className='align-middle'>{formatCurrency(e?.total_price)}</td>
                                            <td className='align-middle'>{moment(e?.created_at).format(consts.DATE_FORMAT)}</td>
                                            <td className='align-middle' style={{ color: e?.order_status === "FINISHED" ? 'green ' : e?.order_status === "CASHIER_REJECTED" ? 'red' : '' }}>{e?.order_status === "FINISHED" ? "ສຳເລັດ" : e?.order_status === "CASHIER_APPROVED" ? "ແຄັດເຊຍອະນຸມັດ" :
                                                e?.order_status === "SALE_CREATED" ? "ສ້າງການຂາຍສຳເລັດ" : "ແຄັດເຊຍປະຕິເສດ"}</td>
                                            <td className='align-middle'>{e?.updated_by_full_name ?? "-"}</td>
                                        </tr>
                                    ))
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
            : <div></div>
    );
}
