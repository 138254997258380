import axios from "axios";
import { SERVER_URL } from "..";
import {
  USER_DATA,
  ACCESS_TOKKEN_DATA,
  REFRESH_TOKKEN_DATA,
} from "../../consts/user";
import { getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

const createUser = async ({ username, password, role, phone }) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/auth-service/api/v1/auth/signup`,
      {
        username,
        password,
        role,
        phone,
      },
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return;
    }
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,createUser());
    }
  }
};
const signin = async ({ username, password }) => {
  try {
    const data = await axios.post(
      `${SERVER_URL}/auth-service/api/v1/auth/signin-manager`,
      {
        username,
        password,
      }
    );
    if (data?.status === 200) {
      // if (data?.data?.data?.user?.role.toUpperCase() !== "MANAGER" || data?.data?.data?.user?.role.toUpperCase() !== "STAFF")
      //   return false;
      if (!data?.data?.data?.user?.branch_name) return false;
      localStorage.setItem(USER_DATA, JSON.stringify(data?.data));
      localStorage?.setItem(
        ACCESS_TOKKEN_DATA,
        JSON?.stringify(data?.data?.data?.accessToken)
      );
      localStorage?.setItem(
        REFRESH_TOKKEN_DATA,
        JSON?.stringify(data?.data?.data?.refreshToken)
      );
      return data?.data;
      if (!data?.data?.data?.user?.branch_id) return false;
    }
  } catch (err) {
    console.log("err");
    return false;
  }
};
export { createUser, signin };
