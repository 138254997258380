import React from "react";
// styled-components
import styled from "styled-components";
// icons
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
// ---------------------------------------------------------- //
export default function CustomBreadcrumbs({ value = [] }) {
  const History = useHistory();
  return (
    <RootStyle>
      {value.map((result, index) => {
        return (
          <React.Fragment key={index}>
            {index !== 0 ? (
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ width: 14, height: 14 }}
              />
            ) : (
              ""
            )}
            <CustomSpan
              style={
                index === value.length - 1
                  ? index === 0
                    ? {}
                    : {}
                  : { color: "#2E72D2" }
              }
              onClick={() => History.push(result?.path)}>
              {result?.title}
            </CustomSpan>
          </React.Fragment>
        );
      })}
    </RootStyle>
  );
}
const RootStyle = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  gap: 5px;
`;
const CustomSpan = styled.span`
  /* color:#2E72D2; */
  color: "#003049";
  font-weight: 400px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
