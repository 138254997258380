import React, { useEffect, useState } from "react";
// styled-components
import styled from "styled-components";
// bootstrap
import { Card, Col, Form, Row, Button, Breadcrumb, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import formatCurrency from "../../helpers/formatCurrency";
import moment from 'moment'

// image
import { SERVER_URL } from "../../api";
import axios from "axios";
// conts
import consts from "../../consts";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";
import Print from "./Print";

// ------------------------------------------------------ //
export default function Detail() {
  const history = useHistory();
  const [product, setProduct] = useState([])
  const [orderOne, setOrderOne] = useState()
  const { id } = useParams()
  const [print, setPrint] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [imgUrl, setImgUrl] = useState("")
  const [isShowImageGallery, setIsShowImageGallery] = useState(false)
  const [imageGallery, setImageGallery] = useState([])

  useEffect(() => {
    getOrder();
    getOrderProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const getOrder = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(`${SERVER_URL}/order-service/api/v1/order/${id}`,
        {headers: {Authorization: _token}}
      );
      if (data.status === 200) {
        setOrderOne(data?.data)
        console.log('data?.data: ', data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getOrder());
      }
    }
  };

  const getOrderProduct = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/order-service/api/v1/order-product?order_id=${id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setProduct(data?.data)
        console.log('dataFSD: ', data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getOrder());
      }
    }
  };

  const _showImageGallery = async (imgArray) => {
    await setImageGallery(imgArray);
    setIsShowImageGallery(true)
  }

  return (
    getUserDataFromLCStorage()?.role === 'MANAGER' ? 
    <>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => history.push(consts.PAGE_REPORT + '/limit/40/skip/1')}>ລາຍງານການຂາຍ</Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>ລາຍລະອຽດການຂາຍ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card style={{ padding: 20 }}>
        <Card style={{ backgroundColor: "#E9E9E9" }} className='p-3 mb-3'>
          <Row>
            <Col xs={4}>ລະຫັດໃບບິນຂາຍ: {orderOne?.data?.order_code}</Col>
            <Col xs={4} className='text-center'>
              ລາຄາຄຳປະຈຳວັນ: {formatCurrency(orderOne?.data?.gold_price_of_day)} LAK
            </Col>
            <Col xs={4} className='text-end'>
              ວັນທີ {moment(orderOne?.data?.created_at).format("MM/DD/YY LT")}
            </Col>
          </Row>
        </Card>
        <Card style={{ backgroundColor: "#E9E9E9", minHeight: 400, overflow: 'hidden' }} className='p-3 mb-3'>
          <CustomTable>
            <thead>
              <tr >
                <th>ລະຫັດສິນຄ້າ</th>
                {orderOne?.data?.order_type !==  'PLEDGE' ? <th>ຮູບສິນຄ້າ</th> : <></>}
                {/* {orderOne?.data?.order_type ===  'RENT' || orderOne?.data?.order_type ===  'PLEDGE' ? <th>ຮູບ</th> : <></>} */}
                {orderOne?.data?.order_type ===  'RENT' 
                  ? <th>ຮູບຄ້ຳປະກັນ</th> 
                  : orderOne?.data?.order_type ===  'PLEDGE' 
                    ? <th>ຮູບ</th>
                    : <></>
                }
                <th>ຊື່ສິນຄ້າ</th>
                <th>ນ້ຳໜັກ</th>
                <th>ລາຍ</th>
                <th>ລາຄາລາຍ</th>
                <th>ລາຄາຄຳ</th>
                <th>ຈຳນວນ</th>
                <th>ລາຄາລວມລາຍ</th>
                <th>ເປີເຊັນຄຳຮ້ານອື່ນ</th>
                <th>ຄ່າລູ້ຍຫ້ຽງ</th>
                <th>ລາຄາລວມສິນຄ້າ</th>
                <th>ຈາກຕູ້</th>
              </tr>
            </thead>
            <tbody>
              {product?.data?.map((ele, index) => (
                <tr key={index}>
                  <td>{ele?.product_code}</td>
                  {orderOne?.data?.order_type !==  'PLEDGE' 
                    ? 
                    <td>
                      <img
                        src={consts.S3 + ele?.product_images?.[0]}
                        alt=''
                        onClick={() => {
                          setIsShowModal(true);
                          setImgUrl(consts.S3 + ele?.product_images?.[0])
                        }}
                        style={{ width: 70, height: 70 }}
                      />
                    </td>
                    : <></>
                  }
                  {orderOne?.data?.order_type ===  'RENT' || orderOne?.data?.order_type ===  'PLEDGE' 
                  ? <td style={{minWidth: 70, maxWidth: 150}}>
                      <Button onClick={() => _showImageGallery(ele?.old_gold_images)} className='btn-red-outline'>
                        ເບິ່ງຮູບ
                      </Button>
                    </td>
                  : <></>
                  }
                  <td>{ele?.product_name}</td>
                  <td>{ele?.weight}</td>
                  <td>{ele?.pattern_name}</td>
                  <td>{formatCurrency(ele?.pattern_price)}</td>
                  <td>{formatCurrency(ele?.price)}</td>
                  <td>{ele?.amount}</td>
                  <td>{formatCurrency(ele?.pattern_price * ele?.amount)}</td>
                  <td>{ele?.gold_quality + "%"}</td>
                  <td>{formatCurrency(ele?.damage_price)}</td>
                  <td>{formatCurrency(((ele?.price * ele?.amount) + (ele?.pattern_price * ele?.amount)) - (ele?.damage_price))}</td>
                  <td>{ele?.cabinet_from}</td>
                </tr>
              ))
              }
            </tbody>
          </CustomTable>
        </Card>
        <Form.Label className='mb-3'>
          <span style={{ fontWeight: 700 }}>*ໝາຍເຫດ:</span> ຫັກຄ່າລຸ້ຍຫ້ຽນ ແລະ
          ຄ່າຄຳອ່ອນ
        </Form.Label>
        <Row>
          <Col xs={6}>
            <Card className='p-3 mb-1' style={{ backgroundColor: "#E9E9E9" }}>
              <Row>
                <Col xs={6} className='title-detail'> ຂໍ້ມູນຕູ້ ແລະ ເຄົາເຕີ້  </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={6}>ເລກເຄົາເຕີ້:</Col>
                    <Col xs={6}>{orderOne?.data?.counter_name}</Col>
                  </Row>
                  <Row>
                    <Col xs={6}>ເລກຕູ້:</Col>
                    <Col xs={6}>{product?.data?.length > 0 ? product?.data[0].cabinet_name : '-'}</Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card className='p-3 mb-1' style={{ backgroundColor: "#E9E9E9" }}>
              <Row>
                <Col xs={6} className='title-detail'> ຂໍ້ມູນພະນັກງານຂາຍ</Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={6}>ຜູ້ປະຈຳຕູ້:</Col>
                    <Col xs={6}>{`${product?.data?.length > 0 ? product?.data[0]?.cabinet_owner_first_name : '-'} ${product?.data?.length > 0 ? product?.data[0]?.cabinet_owner_last_name : '-'}`}</Col>
                  </Row>
                  <Row>
                    <Col xs={6}>ຜູ້ຂາຍ:</Col>
                    <Col xs={6}>{`${product?.data?.length > 0 ? product?.data[0]?.seller_first_name : '-'} ${product?.data?.length > 0 ? product?.data[0]?.seller_last_name : '-'}`}</Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={6} className='title-detail'> ຂໍ້ມູນພະນັກງານເຄົາເຕີ້</Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={6}>ຜູ້ປະຈຳເຄົາເຕີ້:</Col>
                    <Col xs={6}>{`${orderOne?.data?.counter_owner_first_name || '-'} ${orderOne?.data?.counter_owner_last_name}`}</Col>
                  </Row>
                  <Row>
                    <Col xs={6}>ຜູ້ຂາຍ:</Col>
                    <Col xs={6}>{`${orderOne?.data?.cashier_first_name || '-'} ${orderOne?.data?.cashier_last_name}`}</Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card className='p-3 mb-1' style={{ backgroundColor: "#E9E9E9" }}>
              <Row>
                <Col xs={8} className='title-detail'> ຂໍ້ມູນລູກຄ້າ</Col>
                <Col xs={4}>
                  <Col>{`${orderOne?.data?.customer_first_name} ${orderOne?.data?.customer_last_name}`}</Col>
                  <Col>{orderOne?.data?.customer_phone}</Col>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={6}>
            <Card className='p-3 mb-1' style={{ backgroundColor: "#E9E9E9" }}>
              <Row>
                <Col xs={6} className='title-detail'> ລວມຈ່າຍທັງໝົດ </Col>
                <Col xs={6}>
                  <div style={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "flex-end" }}>
                    {formatCurrency(orderOne?.data?.total_price)} LAK
                  </div>
                </Col>
              </Row>
            </Card>

            <Card className='p-3 mb-1' style={{ backgroundColor: "#E9E9E9", width: "100%", display: "flex", alignItems: "center" }}>
              <div style={{ width: "90%" }}>
                {orderOne?.data?.order_status === "FINISHED" ?
                  <table style={{ width: "100%" }}>
                    <tr>
                      <th style={{ width: '40%' }}>ເງິນທີ່ໄດ້ຮັບມາ</th>
                      <th style={{ width: '20%' }}>ສະກຸນເງິນ</th>
                      <th>ຮູບແບບ</th>
                      <th style={{ width: '20%' }}>ເລດເງິນ</th>
                    </tr>
                    {orderOne?.data?.payment.map((ele, index) => (
                      <tr  >
                        <td >{formatCurrency(ele?.payment_price)}</td>
                        <td >{ele?.currency_type}</td>
                        <td  >{ele?.payment_type === "TRANSFER" ? "ເງິນໂອນ" : ele?.payment_type === "CASH" ? "ຈ່າຍສົດ" : ""}</td>
                        {ele?.currency_type === "USD" ? <td>{formatCurrency(orderOne?.data?.exchange_dollar)}</td> : ele?.currency_type === "THB" ?
                          <td>{formatCurrency(orderOne?.data?.exchange_baht)}</td> : <td>-</td>}

                      </tr>
                    ))}

                  </table> :
                  <table style={{ width: "100%" }}>
                    <tr>
                      <th style={{ width: 200 }}>ເງິນທີ່ໄດ້ຮັບມາ</th>
                      <th style={{ width: 150 }}>ສະກຸນເງິນ</th>
                      <th>ຮູບແບບ</th>
                      <th>ເລດເງິນ</th>
                    </tr>
                    <tr>
                      <td>{formatCurrency(orderOne?.data?.payment_price_kip)}</td>
                      <td>LAK</td>
                      <td>{orderOne?.data?.payment_type_kip === "CASH" ? "ຈ່າຍສົດ" : "ເງິນໂອນ"}</td>
                      <td>-</td>

                    </tr>
                    <tr>
                      <td>{formatCurrency(orderOne?.data?.payment_price_dollar)}</td>
                      <td>USA</td>
                      <td>{orderOne?.data?.payment_type_dollar === "CASH" ? "ຈ່າຍສົດ" : "ເງິນໂອນ"}</td>
                      <td>{formatCurrency(orderOne?.data?.exchange_dollar)}</td>
                    </tr>
                    <tr>
                      <td>{formatCurrency(orderOne?.data?.payment_price_baht)}</td>
                      <td>THB</td>
                      <td>{orderOne?.data?.payment_type_baht === "CASH" ? "ຈ່າຍສົດ" : "ເງິນໂອນ"}</td>
                      <td>{formatCurrency(orderOne?.data?.exchange_baht)}</td>
                    </tr>

                  </table>}
              </div>

            </Card>
            <div className='p-3 d-flex gap-2' style={{ justifyContent: 'end' }}>
              {/* <Button
                variant='light'
                size='lg'
                style={{
                  border: 0,
                  color: "#008060",
                  fontWeight: 700,
                  display: "flex",
                  gap: 7,
                  alignItems: "center",
                  flex: 1,
                  justifyContent: "center",
                  fontSize: 14,
                }}>
                <FontAwesomeIcon icon={faCheckDouble} />
                ຊຳລະເງິນເເລ້ວ
              </Button> */}
              <Button onClick={() => setPrint(true)} className='btn-print-report'>
                <FontAwesomeIcon icon={faPrint} />
                Print
              </Button>
            </div>
          </Col>
        </Row>
        {/* modal image */}
        <Modal show={isShowModal} onHide={() => setIsShowModal(false)}>
          <Modal.Body>
          <img
            src={imgUrl}
            alt=''
            style={{ width: "100%" }}
          />
          </Modal.Body>
        </Modal>

        {/* modal image array */}
        <Modal show={isShowImageGallery} onHide={() => setIsShowImageGallery(false)} size="lg">
          <Modal.Body>
            <Row>
              {imageGallery?.length > 0 
              ? imageGallery?.map((item, i) => (
                <Col xs={3} key={i} className='p-2'>
                  <img
                  src={consts.S3 + item}
                  alt=''
                  style={{ width: "100%", borderRadius: 4 }}
                  />
                </Col>
                ))
                : <></>
              }
            </Row>
          </Modal.Body>
        </Modal>
        {/* print bill */}
        <Print isShow={print} backClose={() => setPrint(false)} data={orderOne?.data} />
      </Card>
    </>
    : <div></div>
  );
}

const CustomTable = styled.table`
  thead tr {
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
  }
  th {
    padding: 10px;
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 1em;
    color: transparent;
  }
  tbody tr{
      background-color: #fff;
      td{
          padding:10px
      }
  }
`;