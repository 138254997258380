import { createSlice } from "@reduxjs/toolkit";
// api
import { documents } from "../../api/document";

// ---------------------------------------------------
export const documentData = createSlice({
    name: "document",
    initialState: {
        isLoading: true,
        data: [],
    },
    reducers: {
        setDocument: (state, action) => {
            state.data = [...action.payload];
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});


export const { setLoading, addDocument,setDocument } =
    documentData.actions;

export const loadDocumentAsync = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const data = await documents();
        dispatch(setDocument(data?.data));
    } catch {
        console.error("load document");
    }
    dispatch(setLoading(false));
};

export const selectDocument = (state) => state.document;


export default documentData.reducer;
