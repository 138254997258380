import React, { useState, useEffect, useRef } from "react";
/**
 *
 * @Library
 *
 */
import axios from "axios";
import * as _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { Formik } from "formik";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
/**
 *
 * @Component
 *
 */
import {
  Card,
  Col,
  Form,
  Row,
  Table,
  Breadcrumb,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
// import CustomSearch from "../../../common/CustomSearch";
// import CustomTitleCard from "../../../common/CustomTitleCard";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";

/**
 *
 * @API
 *
 */
import { SERVER_URL } from "../../api";

/**
 *
 * @Function
 *
 */
import formatCurrency from "../../helpers/formatCurrency";
import {
  getAccessTokkenFromLCS,
  getReNewToken,
  getUserDataFromLCStorage,
} from "../../helpers/user";
import {
  formatDate,
  convertOrderType,
  formatCurrencyGold,
} from "../../helpers";
import PaginationHelper from "../../helpers/PaginationHelper";
import { useSelector } from "react-redux";
import { selectOrderProduct } from "../../redux/order_product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportAllSkeleton from "./ReportAllSkeleton";
import { successAdd } from "../../helpers/sweetalert";

// ------------------------------------------------------ //
export default function Expenses() {
  const History = useHistory();
  const parsed = queryString.parse(History?.location?.search);
  const printRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const orderProduct = useSelector(selectOrderProduct);
  const [orderTotal, setOrderTotal] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [customerPhone, setCustomerPhone] = useState(
    parsed?.customerPhone ? parsed?.customerPhone : ""
  );
  const [customerName, setCustomerName] = useState(
    parsed?.customerName ? parsed?.customerName : ""
  );
  const [inputOrderCode, setInputOrderCode] = useState(
    parsed?.inputOrderCode ? parsed?.inputOrderCode : ""
  );
  const [startDate, setStartDate] = useState(
    formatDate(
      parsed?.startDate
        ? parsed?.startDate
        :formatDate(new Date())
    )
  );
  const [endDate, setEndDate] = useState(
    parsed?.endDate ? parsed?.endDate : formatDate(new Date())
  );
  const [orderStatus, setOrderStatus] = useState(
    parsed?.orderStatus ? parsed?.orderStatus : "FINISHED"
  );
  const [orderType, setOrderType] = useState(
    parsed?.orderType ? parsed?.orderType : "EXPENDITURE"
  );
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  const [dataSelect, setDataSelect] = useState({});

  const [totalPriceByTypeAndCurrencys, setTotalPriceByTypeAndCurrencys] =
    useState();
  const [totalPriceByCurrencys, setTotalPriceByCurrencys] = useState();
  const [totalByWeight, setTotalWeight] = useState(0);
  const [totalPriceBuy, setTotalPriceBuy] = useState(null);
  const [totalPriceRefund, setTotalPriceRefund] = useState(null);
  const [expenses, setExpenses] = useState("EXPENDITURE");

  const [moneyOther, setMoneyOther] = useState();
  const [totalExpenseOther, setTotalExpenseOther] = useState();

  const [oldWeightBuyFromKpv, setOldWeightBuyFromKpv] = useState();
  const [oldWeightBuyFromOther, setOldWeightBuyFromOther] = useState();
  const [totalWeightOrderIncome, setTotalWeightOrderIncome] = useState(0);
  const [totalWeightOrderExpense, setTotalWeightOrderExpense] = useState(0);

  const [totalWeightOutSale, setTotalWeightOutSale] = useState(0);
  const [totalWeightOutBuyMore, setTotalWeightOutBuyMore] = useState(0);
  const [totalWeightOutChangeMore, setTotalWeightOutChangeMore] = useState(0);
  const [totalWeightOutChangeFree, setTotalWeightOutChangeFree] = useState(0);
  const [totalWeightOutChangeRefund, setTotalWeightOutChangeRefund] =
    useState(0);

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalIncomeFinal, setTotalIncomeFinal] = useState(0);
  const [totalIncomeChange, setTotalIncomeChange] = useState(0);
  const [totalIncomeLAK, setTotalIncomeLAK] = useState(0);
  const [totalIncomeLAKCash, setTotalIncomeLAKCash] = useState(0);
  const [totalIncomeLAKTransfer, setTotalIncomeLAKTransfer] = useState(0);
  const [totalIncomeTHB, setTotalIncomeTHB] = useState(0);
  const [totalIncomeTHBCash, setTotalIncomeTHBCash] = useState(0);
  const [totalIncomeTHBTransfer, setTotalIncomeTHBTransfer] = useState(0);
  const [totalIncomeUSD, setTotalIncomeUSD] = useState(0);
  const [totalIncomeUSDCash, setTotalIncomeUSDCash] = useState(0);
  const [totalIncomeUSDTransfer, setTotalIncomeUSDTransfer] = useState(0);

  const [totalExpense, setTotalExpense] = useState(0);

  const [remainAmount, setRemainAmount] = useState(0);
  const [gold, setGold] = useState(0);
  const [goldBar, setGoldBar] = useState(0);

  useEffect(() => {
    getOrderData();
    getOrder();
    getDataReportAll();
  }, [
    startDate,
    endDate,
    customerPhone,
    orderStatus,
    orderType,
    inputOrderCode,
    _limit,
    _skip,
  ]);

  const getDataReportAll = async () => {
    let _token = getAccessTokkenFromLCS();
    var branch_id = getUserDataFromLCStorage()?.branch_id;
    try {
      setIsLoading(true);
      const dataGold = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/remaind-gold/${branch_id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataGold.status === 200) {
        setRemainAmount(dataGold?.data?.data?.[0]?.totalSum ?? 0);
        setGold(dataGold?.data?.gold?.[0]?.totalSum_gold ?? 0);
        setGoldBar(dataGold?.data?.gold_bar?.[0]?.totalSum_gold_bar ?? 0);
      }

      const data = await axios.get(
        `${SERVER_URL}/order-service/api/v1/dashboard/all?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(
          moment(endDate).add(1, "day")
        ).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        // Income
        setTotalIncomeLAK(
          totalByCurrency(data?.data?.sale_payment, "LAK") +
            totalByCurrency(data?.data?.buy_more_payment, "LAK") +
            totalByCurrency(data?.data?.change_more_payment, "LAK") +
            totalByCurrency(data?.data?.change_free_payment, "LAK") +
            totalByCurrency(data?.data?.rent_income_payment, "LAK") +
            totalByCurrency(data?.data?.order_income_payment, "LAK")
        );
        setTotalIncomeLAKCash(
          incomeTypeAll(data?.data?.sale_payment, "LAK", "CASH") +
            incomeTypeAll(data?.data?.buy_more_payment, "LAK", "CASH") +
            incomeTypeAll(data?.data?.change_more_payment, "LAK", "CASH") +
            incomeTypeAll(data?.data?.change_free_payment, "LAK", "CASH") +
            incomeTypeAll(data?.data?.rent_income_payment, "LAK", "CASH") +
            incomeTypeAll(data?.data?.order_income_payment, "LAK", "CASH")
        );
        setTotalIncomeLAKTransfer(
          incomeTypeAll(data?.data?.sale_payment, "LAK", "TRANSFER") +
            incomeTypeAll(data?.data?.buy_more_payment, "LAK", "TRANSFER") +
            incomeTypeAll(data?.data?.change_more_payment, "LAK", "TRANSFER") +
            incomeTypeAll(data?.data?.change_free_payment, "LAK", "TRANSFER") +
            incomeTypeAll(data?.data?.rent_income_payment, "LAK", "TRANSFER") +
            incomeTypeAll(data?.data?.order_income_payment, "LAK", "TRANSFER")
        );

        setTotalIncomeTHB(
          totalByCurrency(data?.data?.sale_payment, "THB") +
            totalByCurrency(data?.data?.buy_more_payment, "THB") +
            totalByCurrency(data?.data?.change_more_payment, "THB") +
            totalByCurrency(data?.data?.change_free_payment, "THB") +
            totalByCurrency(data?.data?.rent_income_payment, "THB") +
            totalByCurrency(data?.data?.order_income_payment, "THB")
        );
        setTotalIncomeTHBCash(
          incomeTypeAll(data?.data?.sale_payment, "THB", "CASH") +
            incomeTypeAll(data?.data?.buy_more_payment, "THB", "CASH") +
            incomeTypeAll(data?.data?.change_more_payment, "THB", "CASH") +
            incomeTypeAll(data?.data?.change_free_payment, "THB", "CASH") +
            incomeTypeAll(data?.data?.rent_income_payment, "THB", "CASH") +
            incomeTypeAll(data?.data?.order_income_payment, "THB", "CASH")
        );
        setTotalIncomeTHBTransfer(
          incomeTypeAll(data?.data?.sale_payment, "THB", "TRANSFER") +
            incomeTypeAll(data?.data?.buy_more_payment, "THB", "TRANSFER") +
            incomeTypeAll(data?.data?.change_more_payment, "THB", "TRANSFER") +
            incomeTypeAll(data?.data?.change_free_payment, "THB", "TRANSFER") +
            incomeTypeAll(data?.data?.rent_income_payment, "THB", "TRANSFER") +
            incomeTypeAll(data?.data?.order_income_payment, "THB", "TRANSFER")
        );

        setTotalIncomeUSD(
          totalByCurrency(data?.data?.sale_payment, "USD") +
            totalByCurrency(data?.data?.buy_more_payment, "USD") +
            totalByCurrency(data?.data?.change_more_payment, "USD") +
            totalByCurrency(data?.data?.change_free_payment, "USD") +
            totalByCurrency(data?.data?.rent_income_payment, "USD") +
            totalByCurrency(data?.data?.order_income_payment, "USD")
        );
        setTotalIncomeUSDCash(
          incomeTypeAll(data?.data?.sale_payment, "USD", "CASH") +
            incomeTypeAll(data?.data?.buy_more_payment, "USD", "CASH") +
            incomeTypeAll(data?.data?.change_more_payment, "USD", "CASH") +
            incomeTypeAll(data?.data?.change_free_payment, "USD", "CASH") +
            incomeTypeAll(data?.data?.rent_income_payment, "USD", "CASH") +
            incomeTypeAll(data?.data?.order_income_payment, "USD", "CASH")
        );
        setTotalIncomeUSDTransfer(
          incomeTypeAll(data?.data?.sale_payment, "USD", "TRANSFER") +
            incomeTypeAll(data?.data?.buy_more_payment, "USD", "TRANSFER") +
            incomeTypeAll(data?.data?.change_more_payment, "USD", "TRANSFER") +
            incomeTypeAll(data?.data?.change_free_payment, "USD", "TRANSFER") +
            incomeTypeAll(data?.data?.rent_income_payment, "USD", "TRANSFER") +
            incomeTypeAll(data?.data?.order_income_payment, "USD", "TRANSFER")
        );

        setTotalIncome(
          (data?.data?.sale_dashboard?.[0]?.total_income ?? 0) +
            (data?.data?.buy_more_dashboard?.[0]?.total_income ?? 0) +
            (data?.data?.change_more_dashboard?.[0]?.total_income ?? 0) +
            (data?.data?.change_free_dashboard?.[0]?.total_income ?? 0) +
            (data?.data?.rent_income_dashboard?.total_income ?? 0) +
            (data?.data?.order_income_dashboard?.total_income ?? 0)
        );
        setTotalIncomeFinal(
          (data?.data?.sale_dashboard?.[0]?.total_final ?? 0) +
            (data?.data?.buy_more_dashboard?.[0]?.total_final ?? 0) +
            (data?.data?.change_more_dashboard?.[0]?.total_final ?? 0) +
            (data?.data?.change_free_dashboard?.[0]?.total_final ?? 0) +
            (data?.data?.rent_income_dashboard?.total_final ?? 0) +
            (data?.data?.order_income_dashboard?.total_final ?? 0)
        );
        setTotalIncomeChange(
          (data?.data?.sale_dashboard?.[0]?.total_change ?? 0) +
            (data?.data?.buy_more_dashboard?.[0]?.total_change ?? 0) +
            (data?.data?.change_more_dashboard?.[0]?.total_change ?? 0) +
            (data?.data?.change_free_dashboard?.[0]?.total_change ?? 0) +
            (data?.data?.rent_income_dashboard?.total_change ?? 0) +
            (data?.data?.order_income_dashboard?.total_change ?? 0)
        );

        setTotalWeightOutSale(
          data?.data?.sale_dashboard?.[0]?.total_weight ?? 0
        );
        setTotalWeightOutBuyMore(
          data?.data?.buy_more_dashboard?.[0]?.total_weight ?? 0
        );
        setTotalWeightOutChangeMore(
          data?.data?.change_more_dashboard?.[0]?.total_weight ?? 0
        );
        setTotalWeightOutChangeFree(
          data?.data?.change_free_dashboard?.[0]?.total_weight ?? 0
        );
        setTotalWeightOrderIncome(
          data?.data?.order_income_dashboard?.total_weight_taken ?? 0
        );

        // Expense
        setTotalExpense(
          (data?.data?.buy_dashboard?.[0]?.total_expenses ?? 0) +
            // (data?.data?.change_refund_dashboard?.[0]?.total_expenses ?? 0) +
            (data?.data?.change_refund_dashboard?.[0]?.total_final ?? 0) +
            (data?.data?.rent_expenses_dashboard?.total_expenses ?? 0) +
            (data?.data?.order_expenses_dashboard?.total_expenses ?? 0)
        );

        setOldWeightBuyFromKpv(data?.data?.all_old_gold?.all_old_gold_kpv ?? 0);
        setOldWeightBuyFromOther(
          data?.data?.all_old_gold?.all_old_gold_other ?? 0
        );
        setTotalWeightOutChangeRefund(
          data?.data?.change_refund_dashboard?.[0]?.total_weight_out ?? 0
        );
        setTotalWeightOrderExpense(
          data?.data?.order_income_dashboard?.total_weight_taken ?? 0
        );

        setDataAllDash(data?.data);
        // setOldGoldOther(
        //     (data?.data?.change_more_dashboard?.[0]?.total_old_weight ?? 0) +
        //     (data?.data?.buy_more_dashboard?.[0]?.total_old_weight ?? 0) +
        //     (data?.data?.change_free_dashboard?.[0]?.total_old_weight ?? 0) +
        //     (data?.data?.buy_dashboard?.[0]?.total_old_weight_from_other ?? 0) +
        //     (data?.data?.change_refund_dashboard?.[0]?.total_old_weight_from_customer ?? 0)
        // )
        setSale_dashboard(
          (data?.data?.sale_dashboard?.[0]?.total_weight ?? 0) +
            (data?.data?.buy_more_dashboard?.[0]?.total_weight ?? 0) +
            (data?.data?.change_more_dashboard?.[0]?.total_weight ?? 0) +
            (data?.data?.change_free_dashboard?.[0]?.total_weight ?? 0) +
            (data?.data?.rent_income_dashboard?.total_weight_taken ?? 0) +
            (data?.data?.order_income_dashboard?.total_weight_taken ?? 0) +
            (data?.data?.change_refund_dashboard?.[0]?.total_weight_out ?? 0)
        );
        setIsLoading(false);
      }

      const dataOther = await axios.get(
        `${SERVER_URL}/expenditure-service/api/v1/dashboard/other?&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(
          moment(endDate).add(1, "day")
        ).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataOther.status === 200) {
        setMoneyOther(dataOther?.data?.payment);
        setTotalExpenseOther(
          dataOther?.data?.other_dashboard?.[0]?.total_expenditure ?? 0
        );
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getDataReportAll());
      }
    }
  };
  const getOrder = async () => {
    let _token = getAccessTokkenFromLCS();
    var branch_id = getUserDataFromLCStorage()?.branch_id;
    try {
      const data = await axios.get(
        `${SERVER_URL}/order-service/api/v1/order?limit=${_limit}&skip=${
          (_skip - 1) * _limit
        }&order_code=${inputOrderCode}&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(
          moment(endDate).add(1, "day")
        ).format(
          "YYYY-MM-DD"
        )}&order_status=${orderStatus}&order_type=${orderType}&customer_phone=${customerPhone}&customer_name=${customerName}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setOrderData(data?.data);

        const dataFilterBuy = data?.data?.data.filter(
          (data, index) => data.order_type == "BUY"
        );
        const dataFilterRefund = data?.data?.data.filter(
          (data, index) => data.order_type == "CHANGE_REFUND"
        );

        const sumTotalPriceBuy = dataFilterBuy.reduce(
          (sum, data) => sum + data.total_price,
          0
        );
        const sumTotalPriceRefund = dataFilterRefund.reduce(
          (sum, data) => sum + data.total_price,
          0
        );

        setTotalPriceBuy(sumTotalPriceBuy);
        setTotalPriceRefund(sumTotalPriceRefund);

        setOrderTotal(data?.data?.total);

        if (!data?.data?.data) {
          setTotalPriceByTypeAndCurrencys();
          setTotalPriceByCurrencys();
          setTotalWeight(0);
          return;
        }

        let _payment = [];
        for (var i = 0; i < data?.data?.data.length; i++) {
          if (data?.data?.data[i]?.payment !== null)
            _payment.push(data?.data?.data[i]);
        }

        // sum price by payment_type and currency_type
        const totalPriceByTypeAndCurrency = _payment.reduce((acc, curr) => {
          curr.payment.forEach((payment) => {
            const { payment_type, currency_type, payment_price } = payment; // destructure payment_type, currency_type and payment_price from payment object
            acc[payment_type] = acc[payment_type] || {};
            acc[payment_type][currency_type] =
              (acc[payment_type][currency_type] || 0) + payment_price; // accumulate the sum of payment_prices for each type and currency
          });
          return acc;
        }, {});
        setTotalPriceByTypeAndCurrencys(totalPriceByTypeAndCurrency);

        // sum price by currency_type
        const totalPriceByCurrency = _payment.reduce((acc, curr) => {
          curr.payment.forEach((payment) => {
            const { currency_type, payment_price } = payment; // destructure currency and price from payment object
            // console.log(' currency_type, payment_price: ',  currency_type, payment_price);
            acc[currency_type] = (acc[currency_type] || 0) + payment_price; // accumulate the sum of prices for each currency
          });

          return acc;
        }, {});
        setTotalPriceByCurrencys(totalPriceByCurrency);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getOrder());
      }
    }
  };
  const [GoldyungLause, setGoldyungLause] = useState(0);
  const [GoldyungLauseData, setGoldyungLauseData] = useState(0);
  const [dataSaving, setDataSaving] = useState();
  const [dataLocallstorage, setDataLocallstorage] = useState();
  const getOrderData = async () => {
    let _token = getAccessTokkenFromLCS();
    var branch_id = getUserDataFromLCStorage()?.branch_id;
    setDataLocallstorage(getUserDataFromLCStorage());
    try {
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/remaind-gold/${branch_id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      const _data = await axios.get(
        `${SERVER_URL}/order-service/api/v1/dashboard/sale?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(
          moment(endDate).add(1, "day")
        ).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      const _dataSaving = await axios.get(
        `${SERVER_URL}/expenditure-service/api/v1/saving/report-all?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(
          endDate
        ).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (_dataSaving.status === 200) {
        setDataSaving(_dataSaving?.data);
      }
      if (data.status === 200) {
        setGoldyungLause(data.data.data[0].totalSum);
      }
      if (_data.status === 200) {
        setGoldyungLauseData(_data.data.gold[0].totalSum_gold);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getOrder());
      }
    }
  };
  const [dataAllDash, setDataAllDash] = useState(0);
  const [dataOldGoldOther, setOldGoldOther] = useState(0);
  const [dataSale_dashboard, setSale_dashboard] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    await html2canvas(element, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: false,
    }).then((canvas) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF("p", "mm");
      doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save("Report-" + moment(date).format("MM/DD/YY HH:mm:ss") + ".pdf");
    });
  };
  const incomeTypeAll = (data, currency, type) => {
    let resp = data?.filter((item) => {
      return item?.id[0] == currency && item?.id[1] == type;
    });
    if (resp?.length > 0) return resp?.[0]?.total_payment;
    else return 0;
  };
  const totalByCurrency = (data, currency) => {
    let resp = data?.filter((item) => {
      return item?.id[0] == currency;
    });
    const result = _.sumBy(resp, "total_payment");
    if (resp?.length > 0) return result;
    else return 0;
  };
  // =======>
  const [showAddSaving, setShowAddSaving] = useState(false);
  const [typeOpenModleReportAll, settypeOpenModleReportAll] = useState("ADD");
  const handleCloseAddSaving = () => setShowAddSaving(false);
  const handleShowAddSaving = (type) => {
    setDataSelectReportAll(dataSaving);
    settypeOpenModleReportAll(type);
    setShowAddSaving(true);
  };
  const [dataSelectReportAll, setDataSelectReportAll] = useState();

  const _createSavingTodate = async (values) => {
    try {
      let _token = getAccessTokkenFromLCS();
      const data = await axios.post(
        `${SERVER_URL}/expenditure-service/api/v1/saving`,
        values,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data?.data?.data) {
        getOrderData();
        handleCloseAddSaving();
        successAdd("ບັນທືກສຳເລັດ");
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: ReportAll.js:584 ~ const_createSavingTodate=async ~ error:",
        error
      );
    }
  };
  console.log(
    "🚀 ~ file: ReportAll.js:579 ~ Expenses ~ dataSelectReportAll:",
    dataSelectReportAll
  );

  return getUserDataFromLCStorage()?.role === "MANAGER" ? (
    <>
      <Card
        style={{ padding: 20, paddingBottom: 20 }}
        className="mb-2"
        ref={printRef}
      >
        <Row>
          <Col md={3}>
            <Form.Group controlId="doj">
              <Form.Label>ສະແດງຕາມວັນທີ</Form.Label>
              <Form.Control
                defaultValue={endDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                type="date"
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="doj">
              <Form.Label>ຫາວັນທີ</Form.Label>
              <Form.Control
                defaultValue={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                type="date"
              />
            </Form.Group>
          </Col>
        </Row>
      </Card>
      {isLoading ? (
        <ReportAllSkeleton />
      ) : (
        <>
          {/* =========== */}
          <Card style={{ padding: 20 }} className="mb-2">
            <div className="document-head">
              <p className="doc-head">ເງີນຍົກຍອດມາ</p>
              <p className="doc-head">
                {dataSaving?.cash_lak_all > 0 ? (
                  <button
                    type="button"
                    class="btn btn-warning"
                    onClick={() => handleShowAddSaving("EDIT")}
                  >
                    ແກ້ໄຂເງີນຍົກຍອດມາ
                  </button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-secondary"
                    onClick={() => handleShowAddSaving("ADD")}
                  >
                    ເພີ່ມເງີນຍົກຍອດມາ
                  </button>
                )}
              </p>
            </div>
            <Row>
              <Col className="dashboard-grid">
                <div
                  className="dashboard-container dashboard-bg-green"
                  style={{ backgroundColor: "#FEE9A6" }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ຍອດຄົງເຫຼືອທັງໝົດ <b>LAK</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(dataSaving?.cash_lak_all)} LAK
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>{formatCurrency(dataSaving?.cash_lak)} LAK</span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(dataSaving?.cash_transafer_lak)} LAK
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div
                  className="dashboard-container dashboard-bg-green"
                  style={{ backgroundColor: "#FEE9A6" }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ຍອດຄົງເຫຼືອທັງໝົດ <b>THB</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(dataSaving?.cash_thb_all)} THB
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>{formatCurrency(dataSaving?.cash_thb)} THB</span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(dataSaving?.cash_transafer_thb)} THB
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div
                  className="dashboard-container dashboard-bg-green"
                  style={{ backgroundColor: "#FEE9A6" }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ຍອດຄົງເຫຼືອທັງໝົດ <b>USD</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(dataSaving?.cash_usd_all)} USD
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>{formatCurrency(dataSaving?.cash_usd)} USD</span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(dataSaving?.cash_transafer_usd)} USD
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
          {/* =========== */}
          <Card style={{ padding: 20 }} className="mb-2">
            <div className="document-head">
              <p className="doc-head">ລາຍງານ</p>
            </div>
            <Row>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ຍອດຄົງເຫຼືອທັງໝົດ <b>LAK</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(
                        formatCurrencyGold(
                          dataSaving?.cash_lak_all +
                            totalIncomeLAK -
                            (totalExpense + totalByCurrency(moneyOther, "LAK"))
                        )
                      )}{" "}
                      LAK
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(
                            dataSaving?.cash_lak +
                              totalIncomeLAKCash -
                              (totalExpense +
                                incomeTypeAll(moneyOther, "LAK", "NO"))
                          )
                        )}{" "}
                        LAK
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(
                            dataSaving?.cash_transafer_lak +
                              totalIncomeLAKTransfer -
                              incomeTypeAll(moneyOther, "LAK", "YES")
                          )
                        )}{" "}
                        LAK
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ຍອດຄົງເຫຼືອທັງໝົດ <b>THB</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(
                        formatCurrencyGold(
                          dataSaving?.cash_thb_all +
                            totalIncomeTHB -
                            totalByCurrency(moneyOther, "THB")
                        )
                      )}{" "}
                      THB
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(
                            dataSaving?.cash_thb +
                              totalIncomeTHBCash -
                              incomeTypeAll(moneyOther, "THB", "NO")
                          )
                        )}{" "}
                        THB
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(
                            dataSaving?.cash_transafer_thb +
                              totalIncomeTHBTransfer -
                              incomeTypeAll(moneyOther, "THB", "YES")
                          )
                        )}{" "}
                        THB
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ຍອດຄົງເຫຼືອທັງໝົດ <b>USD</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(
                        formatCurrencyGold(
                          dataSaving?.cash_usd_all +
                            totalIncomeUSD -
                            totalByCurrency(moneyOther, "USD")
                        )
                      )}{" "}
                      USD
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(
                            dataSaving?.cash_usd +
                              totalIncomeUSDCash -
                              incomeTypeAll(moneyOther, "USD", "NO")
                          )
                        )}{" "}
                        USD
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(
                            dataSaving?.cash_transafer_usd +
                              totalIncomeUSDTransfer -
                              incomeTypeAll(moneyOther, "USD", "YES")
                          )
                        )}{" "}
                        USD
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
          <Card style={{ padding: 20 }} className="mb-2">
            <Row className="pt-5">
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-text-darkblue">
                      <b>ລາຍຮັບທັງໝົດ</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(formatCurrencyGold(totalIncome))} LAK
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ໄດ້ຮັບໂຕຈິງ:</span>
                      <span>
                        {formatCurrency(formatCurrencyGold(totalIncomeFinal))}{" "}
                        LAK
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນທອນ:</span>
                      <span>
                        {formatCurrency(formatCurrencyGold(totalIncomeChange))}{" "}
                        LAK
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col className="dashboard-grid">
                            <div className="dashboard-container d-flex-center-column dashboard-bg-green">
                                <span className="dashboard-text-darkblue"><b>ລາຍຮັບທັງໝົດ</b></span>
                                <span className="dashboard-text-all-total dashboard-text-green mt-3">
                                    {formatCurrency(
                                        totalIncomeSale +
                                        totalIncomeBuyMore +
                                        totalIncomeChangeMore +
                                        totalIncomeChangeFree +
                                        totalIncomeRent +
                                        totalIncomeOrder
                                    )} LAK
                                </span>
                            </div>
                        </Col> */}
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ລາຍຮັບ <b>LAK</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(formatCurrencyGold(totalIncomeLAK))} LAK
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(formatCurrencyGold(totalIncomeLAKCash))}{" "}
                        LAK
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(totalIncomeLAKTransfer)
                        )}{" "}
                        LAK
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ລາຍຮັບ <b>THB</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(formatCurrencyGold(totalIncomeTHB))} THB
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(formatCurrencyGold(totalIncomeTHBCash))}{" "}
                        THB
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(totalIncomeTHBTransfer)
                        )}{" "}
                        THB
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ລາຍຮັບ <b>USD</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-green">
                      {formatCurrency(formatCurrencyGold(totalIncomeUSD))} USD
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(formatCurrencyGold(totalIncomeUSDCash))}{" "}
                        USD
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          formatCurrencyGold(totalIncomeUSDTransfer)
                        )}{" "}
                        USD
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col className="dashboard-grid">
                <div className="dashboard-container d-flex-center-column dashboard-bg-red">
                  <span className="dashboard-text-darkblue">
                    <b>ລາຍຈ່າຍທັງໝົດ</b>
                  </span>
                  <span className="dashboard-text-all-total dashboard-text-red mt-3">
                    {formatCurrency(totalExpense + totalExpenseOther)} LAK
                  </span>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-red">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ລາຍຈ່າຍ <b>LAK</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-red">
                      {formatCurrency(
                        totalExpense + totalByCurrency(moneyOther, "LAK")
                      )}{" "}
                      LAK
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(
                          totalExpense + incomeTypeAll(moneyOther, "LAK", "NO")
                        )}{" "}
                        LAK
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          incomeTypeAll(moneyOther, "LAK", "YES")
                        )}{" "}
                        LAK
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-red">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ລາຍຈ່າຍ <b>THB</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-red">
                      {formatCurrency(totalByCurrency(moneyOther, "THB"))} THB
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(incomeTypeAll(moneyOther, "THB", "NO"))}{" "}
                        THB
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          incomeTypeAll(moneyOther, "THB", "YES")
                        )}{" "}
                        THB
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-red">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ລາຍຈ່າຍ <b>USD</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-red">
                      {formatCurrency(totalByCurrency(moneyOther, "USD"))} USD
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ເງີນສົດ:</span>
                      <span>
                        {formatCurrency(incomeTypeAll(moneyOther, "USD", "NO"))}{" "}
                        USD
                      </span>
                    </div>
                    <div className="dashboard-summery-bottom">
                      <span>ເງີນໂອນ:</span>
                      <span>
                        {formatCurrency(
                          incomeTypeAll(moneyOther, "USD", "YES")
                        )}{" "}
                        USD
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-orange">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">
                      ຈຳນວນຄຳທີ່ເຫຼືອມືນີ້ <b>(g)</b>
                    </span>
                    <span className="dashboard-text-all-total dashboard-text-orange">
                      {formatCurrencyGold(remainAmount - dataSale_dashboard)}g
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div
                      className="dashboard-summery-top"
                      style={{ marginBottom: 5 }}
                    >
                      <span>ຍອດຍົກມາ:</span>
                      <span>{formatCurrencyGold(remainAmount)}g</span>
                    </div>
                    {/* <div className='dashboard-summery-bottom'>
                                        <span>ຄຳເກົ່າ:</span>
                                        <span>{formatCurrencyGold(oldWeightBuyFromKpv)}g</span>
                                    </div> */}
                  </div>
                  {/* <div className="dashboard-container d-flex-center-column dashboard-bg-orange">
                                    <span className="dashboard-text-darkblue"><b>ຈຳນວນຄຳທັງໝົດ (g)</b></span>
                                    // <span className="dashboard-text-all-total dashboard-text-orange mt-3">{formatCurrency(remainAmount)}g</span>
                                </div> */}
                </div>
              </Col>
              {/* <Col className="dashboard-grid">
                            <div className="dashboard-container d-flex-center-column dashboard-bg-orange">
                                <span className="dashboard-text-darkblue"><b>ຄຳຮັບເຂົ້າໃໝ່ (g)</b></span>
                                <span className="dashboard-text-all-total dashboard-text-orange mt-3">700g</span>
                            </div>
                        </Col> */}
              <Col className="dashboard-grid">
                <div className="dashboard-container d-flex-center-column dashboard-bg-orange">
                  <span className="dashboard-text-darkblue">
                    <b>ຄຳອອກຈາກຕູ້ທັງໝົດ (g)</b>
                  </span>
                  <span className="dashboard-text-all-total dashboard-text-orange mt-3">
                    {formatCurrencyGold(dataSale_dashboard)}g
                  </span>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container d-flex-center-column dashboard-bg-orange">
                  <span className="dashboard-text-darkblue">
                    <b>ຄຳເກົ່າຈາກຮ້ານຄຳພູວົງ (g)</b>
                  </span>
                  <span className="dashboard-text-all-total dashboard-text-orange mt-3">
                    {formatCurrencyGold(oldWeightBuyFromKpv)}g
                  </span>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container d-flex-center-column dashboard-bg-orange">
                  <span className="dashboard-text-darkblue">
                    <b>ຄຳເກົ່າຈາກຮ້ານອື່ນ (g)</b>
                  </span>
                  <span className="dashboard-text-all-total dashboard-text-orange mt-3">
                    {formatCurrencyGold(oldWeightBuyFromOther)}g
                  </span>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container d-flex-center-column dashboard-bg-orange">
                  <span className="dashboard-text-darkblue">
                    <b>ຄຳຈອງແລ້ວທັງໝົດ (g)</b>
                  </span>
                  <span className="dashboard-text-all-total dashboard-text-orange mt-3">
                    {formatCurrencyGold(
                      dataAllDash?.order_income_dashboard?.total_ordered_weight
                    )}
                    g
                  </span>
                </div>
              </Col>
            </Row>
          </Card>
        </>
      )}

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              className="buttonRedColor"
              style={{ width: "125px", height: "51px" }}
              onClick={handleDownloadPdf}
            >
              Export PDF
            </Button>
          </div>
          <div className="" ref={printRef}>
            <Table className="table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ເລກທີໃບບິນ</th>
                  <th>ຮູບແບບ</th>
                  <th>ຊື່ລູກຄ້າ</th>
                  <th>ເບີໂທ</th>
                  <th>ຈ/ນ ສິນຄ້າ</th>
                  <th>ຈ/ນ ນ້ຳໜັກເກົ່າ</th>
                  <th>ຈ/ນ ນ້ຳໜັກໃໝ່</th>
                  <th>ລາຄາລວມ</th>
                  <th>ວັນທີຂາຍ</th>
                  <th>ສະຖານະໃບບິນ</th>
                  <th>ພະນັກງານ</th>
                </tr>
              </thead>
              <tbody>
                {orderData?.data?.map((e, index) => (
                  <tr style={{ cursor: "pointer" }} key={index}>
                    <td className="align-middle">
                      {index + 1 + _limit * (_skip - 1)}
                    </td>
                    <td className="align-middle">{e?.order_code}</td>
                    <td className="align-middle">
                      {convertOrderType(e?.order_type)}
                    </td>
                    <td className="align-middle">{`${e?.customer_first_name} ${e?.customer_last_name}`}</td>
                    <td className="align-middle">{e?.customer_phone}</td>
                    <td className="align-middle">{e?.product_amount}</td>
                    <td className="align-middle">{e?.total_weight}</td>
                    {/* <td className='align-middle'>{orderProduct?.data.find(el => el.order_id === e.id)?.weight}</td> */}
                    <td className="align-middle">{e?.new_weight}</td>
                    <td className="align-middle">
                      {formatCurrency(e?.total_price)}
                    </td>
                    <td className="align-middle">
                      {moment(e?.created_at).format(consts.DATE_FORMAT)}
                    </td>
                    <td
                      className="align-middle"
                      style={{
                        color:
                          e?.order_status === "FINISHED"
                            ? "green "
                            : e?.order_status === "CASHIER_REJECTED"
                            ? "red"
                            : "",
                      }}
                    >
                      {e?.order_status === "FINISHED"
                        ? "ສຳເລັດ"
                        : e?.order_status === "CASHIER_APPROVED"
                        ? "ແຄັດເຊຍອະນຸມັດ"
                        : e?.order_status === "SALE_CREATED"
                        ? "ສ້າງການຂາຍສຳເລັດ"
                        : "ແຄັດເຊຍປະຕິເສດ"}
                    </td>
                    <td className="align-middle">
                      {e?.updated_by_full_name ?? "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showAddSaving} onHide={handleCloseAddSaving}>
        <Formik
          initialValues={{
            cash_usd: dataSelectReportAll?.cash_usd
              ? dataSelectReportAll?.cash_usd
              : 0,
            cash_transafer_usd: dataSelectReportAll?.cash_transafer_usd
              ? dataSelectReportAll?.cash_transafer_usd
              : 0,
            cash_thb: dataSelectReportAll?.cash_thb
              ? dataSelectReportAll?.cash_thb
              : 0,
            cash_transafer_thb: dataSelectReportAll?.cash_transafer_thb
              ? dataSelectReportAll?.cash_transafer_thb
              : 0,
            cash_lak: dataSelectReportAll?.cash_lak
              ? dataSelectReportAll?.cash_lak
              : 0,
            cash_transafer_lak: dataSelectReportAll?.cash_transafer_lak
              ? dataSelectReportAll?.cash_transafer_lak
              : 0,
            branch_id: dataLocallstorage?.branch_id,
            branch_name: dataLocallstorage?.branch_name,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.cash_usd) {
              errors.cash_usd = "ກະລຸນາປ້ອນຂໍ້ມູນ";
            }
            if (!values.cash_transafer_usd) {
              errors.cash_transafer_usd = "ກະລຸນາປ້ອນຂໍ້ມູນ";
            }
            if (!values.cash_thb) {
              errors.cash_thb = "ກະລຸນາປ້ອນຂໍ້ມູນ";
            }
            if (!values.cash_transafer_thb) {
              errors.cash_transafer_thb = "ກະລຸນາປ້ອນຂໍ້ມູນ";
            }
            if (!values.cash_lak) {
              errors.cash_lak = "ກະລຸນາປ້ອນຂໍ້ມູນ";
            }
            if (!values.cash_transafer_lak) {
              errors.cash_transafer_lak = "ກະລຸນາປ້ອນຂໍ້ມູນ";
            }
            return errors;
          }}
          onSubmit={(values) => {
            _createSavingTodate(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{typeOpenModleReportAll==="ADD" ? "ເພີ່ມເງີນຍົກຍອດມາ":"ແກ້ໄຂເງີນຍົກຍອດມາ"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* ====== */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ເງີນສົດ LAK</Form.Label>
                  <Form.Control
                    type="number"
                    name="cash_lak"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cash_lak}
                    placeholder="ເງີນສົດ LAK"
                  />
                  <div style={{ color: "red" }}>
                    {errors.cash_lak && touched.cash_lak && errors.cash_lak}
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ເງີນໂອນ LAK</Form.Label>
                  <Form.Control
                    type="number"
                    name="cash_transafer_lak"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cash_transafer_lak}
                    placeholder="ເງີນໂອນ LAK"
                  />
                  <div style={{ color: "red" }}>
                    {errors.cash_transafer_lak &&
                      touched.cash_transafer_lak &&
                      errors.cash_transafer_lak}
                  </div>
                </Form.Group>
                {/* ====== */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ເງີນສົດ THB</Form.Label>
                  <Form.Control
                    type="number"
                    name="cash_thb"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cash_thb}
                    placeholder="ເງີນສົດ THB"
                  />
                  <div style={{ color: "red" }}>
                    {errors.cash_thb && touched.cash_thb && errors.cash_thb}
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ເງີນໂອນ THB</Form.Label>
                  <Form.Control
                    type="number"
                    name="cash_transafer_thb"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cash_transafer_thb}
                    placeholder="ເງີນໂອນ THB"
                  />
                  <div style={{ color: "red" }}>
                    {errors.cash_transafer_thb &&
                      touched.cash_transafer_thb &&
                      errors.cash_transafer_thb}
                  </div>
                </Form.Group>
                {/* ====== */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ເງີນສົດ USD</Form.Label>
                  <Form.Control
                    type="number"
                    name="cash_usd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cash_usd}
                    placeholder="ເງີນສົດ USD"
                  />
                  <div style={{ color: "red" }}>
                    {errors.cash_usd && touched.cash_usd && errors.cash_usd}
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ເງີນໂອນ USD</Form.Label>
                  <Form.Control
                    type="number"
                    name="cash_transafer_usd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cash_transafer_usd}
                    placeholder="ເງີນໂອນ USD"
                  />
                  <div style={{ color: "red" }}>
                    {errors.cash_transafer_usd &&
                      touched.cash_transafer_usd &&
                      errors.cash_transafer_usd}
                  </div>
                </Form.Group>
                {/* ====== */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAddSaving}>
                  ຍົກເລີກ
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  ບັນທືກ
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}
