

import React, { useState, useEffect, useRef } from "react";
/**
 *
 * @Library
 *
*/
import axios from "axios";
import * as _ from 'lodash'
import moment from 'moment'
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from 'react-loading';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
/**
 *
 * @Component
 *
 */
import { Card, Col, Form, Row, Table, InputGroup, Button, Modal } from "react-bootstrap";
/**
 *
 * @Constant
 *
 */
import consts from "../../../consts";

/**
 *
 * @API
 *
 */
import { SERVER_URL } from "../../../api";

/**
 *
 * @Function
 *
 */
import formatCurrency from "../../../helpers/formatCurrency";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../../helpers/user";
import { formatDate, convertOrderType, convertOrderTypeMessage, convertOrderTypeGoldNew, convertOrderTypeGoldOld, formatCurrencyGold, CurrencyMoney } from "../../../helpers";
import PaginationHelper from "../../../helpers/PaginationHelper";
import { useSelector } from "react-redux";
import { selectOrderProduct } from "../../../redux/order_product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IncomeSkeleton from "./IncomeSkeleton";


// ------------------------------------------------------ //
export default function Income() {
  const History = useHistory();
  const parsed = queryString.parse(History?.location?.search);
  const printRef = useRef();

  const [isLoading, setIsLoading] = useState(true)
  const orderProduct = useSelector(selectOrderProduct)
  const [orderTotal, setOrderTotal] = useState(0)
  const [orderData, setOrderData] = useState([])

  const [staffName, setStaffName] = useState(parsed?.staffName ? parsed?.staffName : '')
  const [customerPhone, setCustomerPhone] = useState(parsed?.customerPhone ? parsed?.customerPhone : '')
  const [customerName, setCustomerName] = useState(parsed?.customerName ? parsed?.customerName : '')
  const [inputOrderCode, setInputOrderCode] = useState(parsed?.inputOrderCode ? parsed?.inputOrderCode : '')
  const [startDate, setStartDate] = useState(formatDate(parsed?.startDate ? parsed?.startDate : formatDate(new Date())))
  const [endDate, setEndDate] = useState(parsed?.endDate ? parsed?.endDate : formatDate(new Date()))
  const [orderStatus, setOrderStatus] = useState(parsed?.orderStatus ? parsed?.orderStatus : 'FINISHED')
  const [orderType, setOrderType] = useState(parsed?.orderType ? parsed?.orderType : 'SALE')
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  const [remainAmount, setRemainAmount] = useState(0)
  const [gold, setGold] = useState('');
  const [goldBar, setGoldBar] = useState('');

  const [newWeight, setNewWeight] = useState(0)
  const [oldWeight, setOldWeight] = useState(0)
  const [totalIncome, setTotalIncome] = useState(0)
  const [totalIncomeChange, setTotalIncomeChange] = useState(0)
  const [totalIncomeFinal, setTotalIncomeFinal] = useState(0)
  const [totalDepositPrice, setTotalDepositPrice] = useState(0)
  const [moneyIncomeArr, setMoneyIncomeArr] = useState([])

  const [moneySale, setMoneySale] = useState()
  const [moneyBuyMore, setMoneyBuyMore] = useState()
  const [moneyChangeMore, setMoneyChangeMore] = useState()
  const [moneyChangeFree, setMoneyChangeFree] = useState()
  const [moneyRent, setMoneyRent] = useState()
  const [moneyOrder, setMoneyOrder] = useState()

  const [newWeightSale, setNewWeightSale] = useState()
  const [newWeightBuyMore, setNewWeightBuyMore] = useState()
  const [newWeightChangeMore, setNewWeightChangeMore] = useState()
  const [newWeightChangeFree, setNewWeightChangeFree] = useState()
  const [newWeightRent, setNewWeightRent] = useState()
  const [newWeightOrder, setNewWeightOrder] = useState()

  const [oldWeightSale, setOldWeightSale] = useState()
  const [oldWeightBuyMore, setOldWeightBuyMore] = useState()
  const [oldWeightChangeMore, setOldWeightChangeMore] = useState()
  const [oldWeightChangeFree, setOldWeightChangeFree] = useState()
  const [oldWeightRent, setOldWeightRent] = useState()
  const [oldWeightOrder, setOldWeightOrder] = useState()

  useEffect(() => {
    getOrder();
    getStock();
    getDataReportAll();
  }, [startDate, endDate, customerPhone, orderStatus, orderType, inputOrderCode, _limit, _skip])

  useEffect(() => {
    getGoldType()
  }, [])

  const getDataReportAll = async () => {
    let _token = getAccessTokkenFromLCS();
    var branch_id = getUserDataFromLCStorage()?.branch_id;
    try {
      const remaindGold = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/remaind-gold/${branch_id}?from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (remaindGold.status === 200) {
        setOldWeightSale(remaindGold?.data?.data?.[0]?.totalSum ?? 0);
      }

      const dataSale = await axios.get(
        `${SERVER_URL}/order-service/api/v1/dashboard/sale?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataSale.status === 200) {
        setMoneySale(dataSale?.data?.payment);
        setNewWeightSale(dataSale?.data?.sale_dashboard?.[0]?.total_weight ?? 0)
      }
      
      const dataBuyMore = await axios.get(
        `${SERVER_URL}/order-service/api/v1/dashboard/buy-more?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataBuyMore.status === 200) {
        setMoneyBuyMore(dataBuyMore?.data?.payment);
        setNewWeightBuyMore(dataBuyMore?.data?.buy_more_dashboard?.[0]?.total_weight ?? 0)
        setOldWeightBuyMore(dataBuyMore?.data?.buy_more_dashboard?.[0]?.total_old_weight ?? 0)
      }
      
      const dataChangeMore = await axios.get(
        `${SERVER_URL}/order-service/api/v1/dashboard/change-more?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataChangeMore.status === 200) {
        setMoneyChangeMore(dataChangeMore?.data?.payment);
        setNewWeightChangeMore(dataChangeMore?.data?.change_more_dashboard?.[0]?.total_weight ?? 0)
        setOldWeightChangeMore(dataChangeMore?.data?.change_more_dashboard?.[0]?.total_old_weight ?? 0)
      }
      
      const dataChangeFree = await axios.get(
        `${SERVER_URL}/order-service/api/v1/dashboard/change-free?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataChangeFree.status === 200) {
        setMoneyChangeFree(dataChangeFree?.data?.payment);
        setNewWeightChangeFree(dataChangeFree?.data?.change_free_dashboard?.[0]?.total_weight ?? 0)
        setOldWeightChangeFree(dataChangeFree?.data?.change_free_dashboard?.[0]?.total_old_weight ?? 0)
      }
      
      const dataRent = await axios.get(
        `${SERVER_URL}/order-service/api/v1/dashboard/rent-income?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataRent.status === 200) {
        setMoneyRent(dataRent?.data?.payment);
        setNewWeightRent(dataRent?.data?.rent_income_dashboard?.total_weight_taken ?? 0)
        setOldWeightRent(dataRent?.data?.rent_income_dashboard?.total_rent_weight ?? 0)
      }   

      const dataOrder = await axios.get(
        `${SERVER_URL}/order-service/api/v1/dashboard/order-income?branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataOrder.status === 200) {
        setMoneyOrder(dataOrder?.data?.payment);
        setNewWeightOrder(dataOrder?.data?.order_income_dashboard?.total_weight_taken ?? 0)
        setOldWeightOrder(dataOrder?.data?.order_income_dashboard?.total_ordered_weight ?? 0)
      }      

    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getDataReportAll());
      }
    }
  }

  const getGoldType = async () => {
    try {
      let _token = getAccessTokkenFromLCS();
      var branch_id = getUserDataFromLCStorage()?.branch_id;
      axios.get(`${SERVER_URL}/order-service/api/v1/order-product?branch_id=${branch_id}`, {
        headers: {
          Authorization: _token,
        }
      })
        .then((res) => {
          let result = res?.data?.data;
          let gold = result?.filter((data) => data.goldType == "GOLD");
          let goldBar = result?.filter((data) => data.goldType == "GOLD_BAR");

          setGold(gold);
          setGoldBar(goldBar);
        })
    } catch (error) {
      console.log(error);
    }
  }

  const getOrder = async () => {
    let _token = getAccessTokkenFromLCS();
    var branch_id = getUserDataFromLCStorage()?.branch_id;
    try {
      setIsLoading(true)

      let api_order = ''
      if(orderType == 'RENT' || orderType == 'ORDER') {
        api_order = `${SERVER_URL}/order-service/api/v1/order?limit=${_limit}&skip=${(_skip - 1) * _limit}&order_code=${inputOrderCode}&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&order_type=${orderType}&customer_phone=${customerPhone}&customer_name=${customerName}`
      }else {
        api_order = `${SERVER_URL}/order-service/api/v1/order?limit=${_limit}&skip=${(_skip - 1) * _limit}&order_code=${inputOrderCode}&branch_id=${branch_id}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&order_status=${orderStatus}&order_type=${orderType}&customer_phone=${customerPhone}&customer_name=${customerName}`
      }
      const dataOrder = await axios.get(api_order, {
        headers: {
          Authorization: _token,
        },
      });

      if (dataOrder.status === 200) {
        setOrderData(dataOrder?.data);
        setOrderTotal(dataOrder?.data?.total);
      }

      let api_url = ''
      if(orderType == 'SALE') {
        api_url = `${SERVER_URL}/order-service/api/v1/dashboard/sale?branch_id=${branch_id}&order_code=${inputOrderCode}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&order_status=${orderStatus}&order_type=${orderType}&customer_phone=${customerPhone}&customer_name=${customerName}`
        const remaindGold = await axios.get(
          `${SERVER_URL}/stock-service/api/v1/remaind-gold/${branch_id}`,
          {
            headers: {
              Authorization: _token,
            },
          }
        );
        if (remaindGold.status === 200) {
          setOldWeight(remaindGold?.data?.data?.[0]?.totalSum);
        }
      }else if(orderType == 'BUY_MORE') {
        api_url = `${SERVER_URL}/order-service/api/v1/dashboard/buy-more?branch_id=${branch_id}&order_code=${inputOrderCode}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}`
      }else if(orderType == 'CHANGE_MORE') {
        api_url = `${SERVER_URL}/order-service/api/v1/dashboard/change-more?branch_id=${branch_id}&order_code=${inputOrderCode}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}`
      }else if(orderType == 'CHANGE_FREE') {
        api_url = `${SERVER_URL}/order-service/api/v1/dashboard/change-free?branch_id=${branch_id}&order_code=${inputOrderCode}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}`
      }else if(orderType == 'RENT') {
        api_url = `${SERVER_URL}/order-service/api/v1/dashboard/rent-income?branch_id=${branch_id}&order_code=${inputOrderCode}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}`
      }else if(orderType == 'ORDER') {
        api_url = `${SERVER_URL}/order-service/api/v1/dashboard/order-income?branch_id=${branch_id}&order_code=${inputOrderCode}&from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&customer_phone=${customerPhone}&customer_name=${customerName}`
      }else if(orderType == 'PLEDGE') {
        setNewWeight(0)
        setOldWeight(0)
        setTotalIncome(0)
        setTotalDepositPrice(0)
        setMoneyIncomeArr([])
        setIsLoading(false)
      }
      const data = await axios.get(api_url, {
        headers: {
          Authorization: _token,
        },
      });
      if (data.status === 200) {
        if(orderType == 'SALE') {
          setNewWeight(data?.data?.sale_dashboard?.[0]?.total_weight)
          setTotalIncome(data?.data?.sale_dashboard?.[0]?.total_income)
          setTotalIncomeFinal(data?.data?.sale_dashboard?.[0]?.total_final)
          setTotalIncomeChange(data?.data?.sale_dashboard?.[0]?.total_change)
          setMoneyIncomeArr(data?.data?.payment)
        }else if(orderType == 'BUY_MORE') {
          setNewWeight(data?.data?.buy_more_dashboard?.[0]?.total_weight)
          setOldWeight(data?.data?.buy_more_dashboard?.[0]?.total_old_weight)
          setTotalIncome(data?.data?.buy_more_dashboard?.[0]?.total_income)
          setTotalIncomeFinal(data?.data?.buy_more_dashboard?.[0]?.total_final)
          setTotalIncomeChange(data?.data?.buy_more_dashboard?.[0]?.total_change)
          setMoneyIncomeArr(data?.data?.payment)
        }else if(orderType == 'CHANGE_MORE') {
          setNewWeight(data?.data?.change_more_dashboard?.[0]?.total_weight)
          setOldWeight(data?.data?.change_more_dashboard?.[0]?.total_old_weight)
          setTotalIncome(data?.data?.change_more_dashboard?.[0]?.total_income)
          setTotalIncomeFinal(data?.data?.change_more_dashboard?.[0]?.total_final)
          setTotalIncomeChange(data?.data?.change_more_dashboard?.[0]?.total_change)
          setMoneyIncomeArr(data?.data?.payment)
        }else if(orderType == 'CHANGE_FREE') {
          setNewWeight(data?.data?.change_free_dashboard?.[0]?.total_weight)
          setOldWeight(data?.data?.change_free_dashboard?.[0]?.total_old_weight)
          setTotalIncome(data?.data?.change_free_dashboard?.[0]?.total_income)
          setTotalIncomeFinal(data?.data?.change_free_dashboard?.[0]?.total_final)
          setTotalIncomeChange(data?.data?.change_free_dashboard?.[0]?.total_change)
          setMoneyIncomeArr(data?.data?.payment)
        }else if(orderType == 'RENT') {
          setNewWeight(data?.data?.rent_income_dashboard?.total_weight_taken)
          setOldWeight(data?.data?.rent_income_dashboard?.total_rent_weight)
          setTotalDepositPrice(data?.data?.rent_income_dashboard?.total_deposit_price)
          setTotalIncome(data?.data?.rent_income_dashboard?.total_income)
          setTotalIncomeFinal(data?.data?.rent_income_dashboard?.total_final)
          setTotalIncomeChange(data?.data?.rent_income_dashboard?.total_change)
          setMoneyIncomeArr(data?.data?.payment)
        }else if(orderType == 'ORDER') {
          setNewWeight(data?.data?.order_income_dashboard?.total_weight_taken)
          setOldWeight(data?.data?.order_income_dashboard?.total_ordered_weight)
          setTotalDepositPrice(data?.data?.order_income_dashboard?.total_remaind_deposit)
          setTotalIncome(data?.data?.order_income_dashboard?.total_income)
          setTotalIncomeFinal(data?.data?.order_income_dashboard?.total_final)
          setTotalIncomeChange(data?.data?.order_income_dashboard?.total_change)
          setMoneyIncomeArr(data?.data?.payment)
        }else if(orderType == 'PLEDGE') {

        }

        setIsLoading(false)
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getOrder());
      }
    }
  };

  const _checkOrderTypeToDetail = (order_type, OrderId) => {
    if (order_type === 'BUY') History.push(`${consts.PAGE_REPORT}/buy/detail/${OrderId}`)
    if (order_type === 'BUY_MORE') History.push(`${consts.PAGE_REPORT}/buy-more/detail/${OrderId}`)
    if (order_type === 'CHANGE_MORE') History.push(`${consts.PAGE_REPORT}/change-more/detail/${OrderId}`)
    if (order_type === 'CHANGE_FREE') History.push(`${consts.PAGE_REPORT}/change-free/detail/${OrderId}`)
    if (order_type === 'CHANGE_REFUND') History.push(`${consts.PAGE_REPORT}/change-refund/detail/${OrderId}`)
    if (order_type === 'SALE') History.push(`${consts.PAGE_REPORT}/sale/detail/${OrderId}`)
    if (order_type === 'RENT') History.push(`${consts.PAGE_REPORT}/borrow/detail/${OrderId}`)
    if (order_type === 'PLEDGE') History.push(`${consts.PAGE_REPORT}/pledge/detail/${OrderId}`)
    if (order_type === 'ORDER') History.push(`${consts.PAGE_REPORT}/order/detail/${OrderId}`)
  }

  const getStock = async () => {
    let _token = getAccessTokkenFromLCS();
    // console.log('_token: ', _token);
    try {
      var branch_id = getUserDataFromLCStorage()?.branch_id;

      await axios.get(
        `${SERVER_URL}/stock-service/api/v1/branch-product?limit=${_limit}&skip=${(_skip - 1) * _limit}&branch=${getUserDataFromLCStorage().branch_id
        }`,
        {
          headers: {
            Authorization: _token,
          },
        }
      ).then((res) => {
        setRemainAmount(res?.data?.totalWeight)
        // console.log('res----------------->', res.data);
      })
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getStock());
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Report-' + moment(date).format("MM/DD/YY HH:mm:ss") + '.pdf');
    }
    )
  };

  const incomeType = (currency, type) => {
    let resp = moneyIncomeArr?.filter((item) => {
      return item?.id[0] == currency && item?.id[1] == type;
    });
    if(resp?.length > 0) return formatCurrency(resp?.[0]?.total_payment)
    else return 0
  }

  const incomeTypeAll = (data, currency, type) => {
    let resp = data?.filter((item) => {
      return item?.id[0] == currency && item?.id[1] == type;
    });
    if(resp?.length > 0) return resp?.[0]?.total_payment
    else return 0
  }

  const totalIncomeByCurrency = (currency) => {
    let resp = moneyIncomeArr?.filter((item) => {
      return item?.id[0] == currency
    });
    const result = _.sumBy(resp, 'total_payment')
    if(resp?.length > 0) return formatCurrency(result)
    else return 0
  }

  return (
    getUserDataFromLCStorage()?.role === 'MANAGER' ?
      <>
        <Card style={{padding: 20}} className="mb-2">
          <Row>
            <Col>
              <Row>
                <Col md={3} className="mb-3">
                  <Form.Group controlId='doj'>
                    <Form.Label>ສະແດງຕາມວັນທີ</Form.Label>
                    <Form.Control
                      defaultValue={endDate}
                      onChange={(e) => {
                        setStartDate(e.target.value)
                      }}
                      type='date' />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-3">
                  <Form.Group controlId='doj'>
                    <Form.Label>ຫາວັນທີ</Form.Label>
                    <Form.Control
                      defaultValue={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value)
                      }}
                      type='date' />
                  </Form.Group>
                </Col>
                {orderType == 'INCOME' ? <></> : 
                <>
                  <Col md={3} className="mb-3"></Col>
                  {/* <Col md={3} className="mb-3">
                    <Form.Label>ສະຖານະໃບບິນ</Form.Label>
                    <Form.Control
                      as='select'
                      className="form-select"
                      defaultValue={orderStatus}
                      onChange={(e) => setOrderStatus(e.target.value)}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="FINISHED">ສຳເລັດ</option>
                      <option value="CASHIER_APPROVED">ແຄັດເຊຍອະນຸມັດ</option>
                      <option value="SALE_CREATED">ສ້າງການຂາຍສຳເລັດ</option>
                      <option value="CASHIER_REJECTED">ແຄັດເຊຍປະຕິເສດ</option>
                    </Form.Control>
                  </Col> */}
                  <Col md={3} className="mb-3"></Col>
                  <Col md={3} className="mb-3">
                    <Form.Label>ຄົ້ນຫາຕາມເລກທີໃບບິນ</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="ຄົ້ນຫາຕາມເລກທີໃບບິນ"
                        aria-label="ຄົ້ນຫາຕາມເລກທີໃບບິນ"
                        aria-describedby="basic-addon2"
                        onChange={(event) => setInputOrderCode(event?.target?.value)}
                      />
                      <Button variant="outline-danger" id="button-addon2">
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Label>ຄົ້ນຫາເບິ</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="ຄົ້ນຫາເບິ"
                        aria-label="ຄົ້ນຫາເບິ"
                        aria-describedby="basic-addon2"
                        onChange={(e) => { setCustomerPhone(e?.target?.value) }}
                      />
                      <Button variant="outline-danger" id="button-addon2">
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>
                    </InputGroup>
                    {/* <CustomSearch value={customerPhone} onChange={(e)=>{setCustomerPhone(e?.target?.value)}} placeholder='ຄົ້ນຫາເບິ'/> */}
                  </Col>
                  <Col md={3} className="mb-3">
                    <Form.Label>ຄົ້ນຫາຊື່ລູກຄ້າ</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="ຄົ້ນຫາຊື່ລູກຄ້າ"
                        aria-label="ຄົ້ນຫາຊື່ລູກຄ້າ"
                        aria-describedby="basic-addon2"
                        onChange={(e) => { setCustomerName(e?.target?.value) }}
                      />
                      <Button variant="outline-danger" id="button-addon2">
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>
                    </InputGroup>
                    {/* <CustomSearch value={customerName} onChange={(e)=>{setCustomerName(e?.target?.value)}} placeholder='ຄົ້ນຫາຊື່'/> */}
                  </Col>
                </>
                }

                <Col md={3} className="mb-3">
                  <Form.Label>ຮູບແບບທຸລະກຳ</Form.Label>
                  <Form.Control
                    as='select'
                    className="form-select"
                    defaultValue={orderType}
                    onChange={(e) => setOrderType(e.target.value)}
                  >
                    <option value="INCOME">ທັງໝົດ</option>
                    <option value="SALE">ການຂາຍ</option>
                    <option value="BUY_MORE">ການຊື້ຕື່ມ</option>
                    <option value="CHANGE_MORE">ການປ່ຽນຕື່ມ</option>
                    <option value="CHANGE_FREE">ການປ່ຽນຟຣີ</option>
                    <option value="RENT">ການເຊົ່າ</option>
                    <option value="ORDER">ການຈອງ</option>
                    <option value="PLEDGE">ການຈຳ</option>
                  </Form.Control>
                </Col>
              </Row>
            </Col>
            {/* <Col md={1} className="d-flex justify-content-end align-items-end mb-3">
              <div style={{ marginTop: "31px" }}>
                <button onClick={handleShow} id="format-PDF" className='buttonRedColor-export' >Export PDF</button>
              </div>
            </Col> */}
          </Row>
        </Card>
        {orderType == 'INCOME' ? 
        <>
          <Card style={{padding: 20}} className="mb-2">
            <div className="document-head"><p className="doc-head">ລາຍງານລວມ ລາຍຮັບທັງໝົດ</p></div>
            <Table className='table-hover'>
              <thead>
                <tr>
                  <th style={{width: 100}}>ປະເພດ</th>
                  <th>ການຂາຍຄຳ</th>
                  <th>ການຊື້ຕື່ມ</th>
                  <th>ປ່ຽນຕື່ມ</th>
                  <th>ປ່ຽນຟຣີ</th>
                  <th>ການເຊົ່າຄຳ</th>
                  <th>ການຈອງ</th>
                  <th>ການຈຳ</th>
                  <th>ລວມລາຍຮັບທັງໝົດ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><b>LAK ສົດ</b></td>
                  <td>{formatCurrency(incomeTypeAll(moneySale, 'LAK', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyBuyMore, 'LAK', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeMore, 'LAK', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeFree, 'LAK', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyRent, 'LAK', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyOrder, 'LAK', 'CASH'))}</td>
                  <td>0</td>
                  <td className="dashboard-text-all-total dashboard-text-green">
                    {
                      formatCurrency(
                        incomeTypeAll(moneySale, 'LAK', 'CASH') +
                        incomeTypeAll(moneyBuyMore, 'LAK', 'CASH') +
                        incomeTypeAll(moneyChangeMore, 'LAK', 'CASH') +
                        incomeTypeAll(moneyChangeFree, 'LAK', 'CASH') +
                        incomeTypeAll(moneyRent, 'LAK', 'CASH') +
                        incomeTypeAll(moneyOrder, 'LAK', 'CASH')
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td><b>LAK ໂອນ</b></td>
                  <td>{formatCurrency(incomeTypeAll(moneySale, 'LAK', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyBuyMore, 'LAK', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeMore, 'LAK', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeFree, 'LAK', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyRent, 'LAK', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyOrder, 'LAK', 'TRANSFER'))}</td>
                  <td>0</td>
                  <td className="dashboard-text-all-total dashboard-text-green">
                    {
                      formatCurrency(
                        incomeTypeAll(moneySale, 'LAK', 'TRANSFER') +
                        incomeTypeAll(moneyBuyMore, 'LAK', 'TRANSFER') +
                        incomeTypeAll(moneyChangeMore, 'LAK', 'TRANSFER') +
                        incomeTypeAll(moneyChangeFree, 'LAK', 'TRANSFER') +
                        incomeTypeAll(moneyRent, 'LAK', 'TRANSFER') +
                        incomeTypeAll(moneyOrder, 'LAK', 'TRANSFER')
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td><b>THB ສົດ</b></td>
                  <td>{formatCurrency(incomeTypeAll(moneySale, 'THB', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyBuyMore, 'THB', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeMore, 'THB', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeFree, 'THB', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyRent, 'THB', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyOrder, 'THB', 'CASH'))}</td>
                  <td>0</td>
                  <td className="dashboard-text-all-total dashboard-text-green">
                    {
                      formatCurrency(
                        incomeTypeAll(moneySale, 'THB', 'CASH') +
                        incomeTypeAll(moneyBuyMore, 'THB', 'CASH') +
                        incomeTypeAll(moneyChangeMore, 'THB', 'CASH') +
                        incomeTypeAll(moneyChangeFree, 'THB', 'CASH') +
                        incomeTypeAll(moneyRent, 'THB', 'CASH') +
                        incomeTypeAll(moneyOrder, 'THB', 'CASH')
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td><b>THB ໂອນ</b></td>
                  <td>{formatCurrency(incomeTypeAll(moneySale, 'THB', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyBuyMore, 'THB', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeMore, 'THB', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeFree, 'THB', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyRent, 'THB', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyOrder, 'THB', 'TRANSFER'))}</td>
                  <td>0</td>
                  <td className="dashboard-text-all-total dashboard-text-green">
                    {
                      formatCurrency(
                        incomeTypeAll(moneySale, 'THB', 'TRANSFER') +
                        incomeTypeAll(moneyBuyMore, 'THB', 'TRANSFER') +
                        incomeTypeAll(moneyChangeMore, 'THB', 'TRANSFER') +
                        incomeTypeAll(moneyChangeFree, 'THB', 'TRANSFER') +
                        incomeTypeAll(moneyRent, 'THB', 'TRANSFER') +
                        incomeTypeAll(moneyOrder, 'THB', 'TRANSFER')
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td><b>USD ສົດ</b></td>
                  <td>{formatCurrency(incomeTypeAll(moneySale, 'USD', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyBuyMore, 'USD', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeMore, 'USD', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeFree, 'USD', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyRent, 'USD', 'CASH'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyOrder, 'USD', 'CASH'))}</td>
                  <td>0</td>
                  <td className="dashboard-text-all-total dashboard-text-green">
                    {
                      formatCurrency(
                        incomeTypeAll(moneySale, 'USD', 'CASH') +
                        incomeTypeAll(moneyBuyMore, 'USD', 'CASH') +
                        incomeTypeAll(moneyChangeMore, 'USD', 'CASH') +
                        incomeTypeAll(moneyChangeFree, 'USD', 'CASH') +
                        incomeTypeAll(moneyRent, 'USD', 'CASH') +
                        incomeTypeAll(moneyOrder, 'USD', 'CASH')
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td><b>USD ໂອນ</b></td>
                  <td>{formatCurrency(incomeTypeAll(moneySale, 'USD', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyBuyMore, 'USD', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeMore, 'USD', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyChangeFree, 'USD', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyRent, 'USD', 'TRANSFER'))}</td>
                  <td>{formatCurrency(incomeTypeAll(moneyOrder, 'USD', 'TRANSFER'))}</td>
                  <td>0</td>
                  <td className="dashboard-text-all-total dashboard-text-green">
                    {
                      formatCurrency(
                        incomeTypeAll(moneySale, 'USD', 'TRANSFER') +
                        incomeTypeAll(moneyBuyMore, 'USD', 'TRANSFER') +
                        incomeTypeAll(moneyChangeMore, 'USD', 'TRANSFER') +
                        incomeTypeAll(moneyChangeFree, 'USD', 'TRANSFER') +
                        incomeTypeAll(moneyRent, 'USD', 'TRANSFER') +
                        incomeTypeAll(moneyOrder, 'USD', 'TRANSFER')
                      )
                    }
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
          <Card style={{padding: 20}} className="mb-2">
            <div className="document-head"><p className="doc-head">ລາຍງານລວມ ຈຳນວນຄຳທັງໝົດ</p></div>
            <Table className='table-hover'>
              <thead>
                <tr>
                  <th style={{width: 100}}>ປະເພດ</th>
                  <th>ການຂາຍຄຳ</th>
                  <th>ການຊື້ຕື່ມ</th>
                  <th>ປ່ຽນຕື່ມ</th>
                  <th>ປ່ຽນຟຣີ</th>
                  <th>ການເຊົ່າຄຳ</th>
                  <th>ການຈອງ</th>
                  <th>ການຈຳ</th>
                  <th>ລວມຍອດຄຳ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><b>ຄຳເກົ່າ</b></td>
                  <td>{formatCurrencyGold(oldWeightSale)}g</td>
                  <td>{formatCurrencyGold(oldWeightBuyMore)}g</td>
                  <td>{formatCurrencyGold(oldWeightChangeMore)}g</td>
                  <td>{formatCurrencyGold(oldWeightChangeFree)}g</td>
                  <td>{formatCurrencyGold(oldWeightRent)}g</td>
                  <td>{formatCurrencyGold(oldWeightOrder)}g</td>
                  <td>0</td>
                  <td className="dashboard-text-all-total dashboard-text-green">
                    {
                      formatCurrencyGold(
                        oldWeightSale +
                        oldWeightBuyMore +
                        oldWeightChangeMore +
                        oldWeightChangeFree +
                        oldWeightRent +
                        oldWeightOrder
                      )
                    }g
                  </td>
                </tr>
                <tr>
                  <td><b>ຄຳໃໝ່</b></td>
                  <td>{formatCurrencyGold(newWeightSale)}g</td>
                  <td>{formatCurrencyGold(newWeightBuyMore)}g</td>
                  <td>{formatCurrencyGold(newWeightChangeMore)}g</td>
                  <td>{formatCurrencyGold(newWeightChangeFree)}g</td>
                  <td>{formatCurrencyGold(newWeightRent)}g</td>
                  <td>{formatCurrencyGold(newWeightOrder)}g</td>
                  <td>0</td>
                  <td className="dashboard-text-all-total dashboard-text-green">
                    {
                      formatCurrencyGold(
                        newWeightSale +
                        newWeightBuyMore +
                        newWeightChangeMore +
                        newWeightChangeFree +
                        newWeightRent +
                        newWeightOrder
                      )
                    }g
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </>
        :
        <>
          {isLoading ? <IncomeSkeleton /> :
          <Card style={{padding: 20}} className="mb-2" ref={printRef}>
            <Row>
              <div className="dashboard-grid dashboard-menu-col">
                <div className="dashboard-container d-flex-center-space-between-column dashboard-bg-lightblue p-0 pb-4">
                  <div className="dashboard-menu-type">
                    <span className="dashboard-text-all-total dashboard-text-orange">{convertOrderTypeMessage(orderType)}(g)</span>
                  </div>
                  <span className="dashboard-menu-text">{convertOrderTypeGoldNew(orderType)}</span>
                  <span className="dashboard-text-all-total dashboard-text-orange">{formatCurrencyGold(newWeight ?? 0)}g</span>
                </div>
              </div>
              
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-text-darkblue"><b>ລາຍຮັບທັງໝົດ</b></span>
                    <span className="dashboard-text-all-total dashboard-text-green">{formatCurrency(formatCurrencyGold(totalIncome) ?? 0)} LAK</span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div className='dashboard-summery-top' style={{ marginBottom: 5 }}>
                      <span>ໄດ້ຮັບໂຕຈິງ:</span>
                      <span>{formatCurrency(formatCurrencyGold(totalIncomeFinal ?? 0))} LAK</span>
                    </div>
                    <div className='dashboard-summery-bottom'>
                      <span>ເງີນທອນ:</span>
                      <span>{formatCurrency(formatCurrencyGold(totalIncomeChange ?? 0))} LAK</span>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col className="dashboard-grid">
                <div className="dashboard-container d-flex-center-column dashboard-bg-green">
                  <span className="dashboard-text-darkblue"><b>ລາຍຮັບທັງໝົດ</b></span>
                  <span className="dashboard-text-all-total dashboard-text-green mt-3">{formatCurrency(totalIncome ?? 0)} LAK</span>
                </div>
              </Col> */}
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">ລາຍຮັບ <b>LAK</b></span>
                    <span className="dashboard-text-all-total dashboard-text-green">{totalIncomeByCurrency('LAK')} LAK</span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div className='dashboard-summery-top' style={{ marginBottom: 5 }}>
                      <span>ເງີນສົດ:</span>
                      <span>{incomeType('LAK', 'CASH')} LAK</span>
                    </div>
                    <div className='dashboard-summery-bottom'>
                      <span>ເງີນໂອນ:</span>
                      <span>{incomeType('LAK', 'TRANSFER')} LAK</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">ລາຍຮັບ <b>THB</b></span>
                    <span className="dashboard-text-all-total dashboard-text-green">{totalIncomeByCurrency('THB')} THB</span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div className='dashboard-summery-top' style={{ marginBottom: 5 }}>
                      <span>ເງີນສົດ:</span>
                      <span>{incomeType('THB', 'CASH')} THB</span>
                    </div>
                    <div className='dashboard-summery-bottom'>
                      <span>ເງີນໂອນ:</span>
                      <span>{incomeType('THB', 'TRANSFER')} THB</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                  <div className="d-flex flex-column align-items-center">
                    <span className="dashboard-title">ລາຍຮັບ <b>USD</b></span>
                    <span className="dashboard-text-all-total dashboard-text-green">{totalIncomeByCurrency('USD')} USD</span>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div className='dashboard-summery-top' style={{ marginBottom: 5 }}>
                      <span>ເງີນສົດ:</span>
                      <span>{incomeType('USD', 'CASH')} USD</span>
                    </div>
                    <div className='dashboard-summery-bottom'>
                      <span>ເງີນໂອນ:</span>
                      <span>{incomeType('USD', 'TRANSFER')} USD</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="dashboard-grid">
                {orderType == 'RENT' || orderType == 'ORDER' ? 
                <div className="dashboard-container py-0">
                  <Row className="h-50" style={{paddingBottom: 4}}>
                    <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                      <span className="dashboard-text-darkblue">{convertOrderTypeGoldOld(orderType)} (g)</span>
                      <span className="dashboard-text-all-total dashboard-text-orange">{formatCurrencyGold(oldWeight ?? 0)}g</span>
                    </div>
                  </Row>
                  <Row className="h-50" style={{paddingTop: 4}}>
                    <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                      <span className="dashboard-text-darkblue">
                        {orderType == 'RENT' ? 'ຄ່າມັດຈຳທັງໝົດ(LAK)' : 'ຄ່າມັດຈຳຍັງເຫຼືອທັງໝົດ(LAK)'}
                      </span>
                      <span className="dashboard-text-all-total dashboard-text-green">{formatCurrency(totalDepositPrice ?? 0)}</span>
                    </div>
                  </Row>
                </div>
                :
                <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                  <span className="dashboard-text-darkblue">{convertOrderTypeGoldOld(orderType)} (g)</span>
                  <span className="dashboard-text-all-total dashboard-text-orange">{formatCurrencyGold(oldWeight ?? 0)}g</span>
                </div>
                }
              </Col>
            </Row>
          </Card>
          }
          <Card style={{padding: 20}} className="mb-2">
            {/* card summery report */}
            {orderData?.length <= 0 ?
              <div className="loading-card">
                <div>
                  <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
                  <span>Loading....</span>
                </div>
              </div>
              : orderTotal === 0 ?
                <div>
                  <img style={{ marginLeft: "40%", marginTop: "10%" }} src='/asset/image/animation_200_ldu129fc.gif' />
                  <p style={{ marginLeft: "45%", marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
                </div> :
                <Table className='table-hover'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ເລກທີໃບບິນ</th>
                      <th>ຊື່ລູກຄ້າ</th>
                      <th>ເບີໂທ</th>
                      <th>ຈ/ນ ສິນຄ້າ</th>
                      <th>ຈ/ນ ນ້ຳໜັກເກົ່າ</th>
                      <th>ຈ/ນ ນ້ຳໜັກໃໝ່</th>
                      <th>ລາຄາລວມ</th>
                      <th>ວັນທີຂາຍ</th>
                      <th>ສະຖານະໃບບິນ</th>
                      <th>ພະນັກງານ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData?.data?.map((e, index) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => _checkOrderTypeToDetail(e?.order_type, e?.id)}
                        key={index}>
                        <td className='align-middle'>{index + 1 + _limit * (_skip - 1)}</td>
                        <td className='align-middle'>{e?.order_code}</td>
                        <td className='align-middle'>{`${e?.customer_first_name} ${e?.customer_last_name}`}</td>
                        <td className='align-middle'>{e?.customer_phone}</td>
                        <td className='align-middle'>{e?.product_amount}</td>
                        <td className='align-middle'>{formatCurrencyGold(e?.total_weight)}</td>
                        {/* <td className='align-middle'>{orderProduct?.data.find(el => el.order_id === e.id)?.weight}</td> */}
                        <td className='align-middle'>{formatCurrencyGold(e?.new_weight)}</td>
                        <td className='align-middle'>{CurrencyMoney(e?.total_price)}</td>
                        <td className='align-middle'>{moment(e?.created_at).format(consts.DATE_FORMAT)}</td>
                        <td className='align-middle' style={{ color: e?.order_status === "FINISHED" ? 'green ' : e?.order_status === "CASHIER_REJECTED" ? 'red' : '' }}>{e?.order_status === "FINISHED" ? "ສຳເລັດ" : e?.order_status === "CASHIER_APPROVED" ? "ແຄັດເຊຍອະນຸມັດ" :
                          e?.order_status === "SALE_CREATED" ? "ສ້າງການຂາຍສຳເລັດ" : "ແຄັດເຊຍປະຕິເສດ"}</td>
                        <td className='align-middle'>{e?.updated_by_full_name ?? "-"}</td>

                      </tr>

                    ))
                    }
                  </tbody>
                </Table>
            }
            {Pagination_helper(orderTotal, consts.PAGE_REPORT, `inputOrderCode=${inputOrderCode}&startDate=${startDate}&endDate=${endDate}&orderStatus=${orderStatus}&orderType=${orderType}`)}
          </Card>
        </>
        }
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button className='buttonRedColor'
                style={{ width: "125px", height: "51px" }}
                onClick={handleDownloadPdf}
              >
                Export PDF
              </Button>
            </div>
            <div className="" ref={printRef}>
              <Table className='table-hover'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ເລກທີໃບບິນ</th>
                    <th>ຮູບແບບ</th>
                    <th>ຊື່ລູກຄ້າ</th>
                    <th>ເບີໂທ</th>
                    <th>ຈ/ນ ສິນຄ້າ</th>
                    <th>ຈ/ນ ນ້ຳໜັກເກົ່າ</th>
                    <th>ຈ/ນ ນ້ຳໜັກໃໝ່</th>
                    <th>ລາຄາລວມ</th>
                    <th>ວັນທີຂາຍ</th>
                    <th>ສະຖານະໃບບິນ</th>
                    <th>ພະນັກງານ</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData?.data?.map((e, index) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={index}>
                      <td className='align-middle'>{index + 1 + _limit * (_skip - 1)}</td>
                      <td className='align-middle'>{e?.order_code}</td>
                      <td className='align-middle'>{convertOrderType(e?.order_type)}</td>
                      <td className='align-middle'>{`${e?.customer_first_name} ${e?.customer_last_name}`}</td>
                      <td className='align-middle'>{e?.customer_phone}</td>
                      <td className='align-middle'>{e?.product_amount}</td>
                      <td className='align-middle'>{e?.total_weight}</td>
                      {/* <td className='align-middle'>{orderProduct?.data.find(el => el.order_id === e.id)?.weight}</td> */}
                      <td className='align-middle'>{e?.new_weight}</td>
                      <td className='align-middle'>{formatCurrency(formatCurrencyGold(e?.total_price))}</td>
                      <td className='align-middle'>{moment(e?.created_at).format(consts.DATE_FORMAT)}</td>
                      <td className='align-middle' style={{ color: e?.order_status === "FINISHED" ? 'green ' : e?.order_status === "CASHIER_REJECTED" ? 'red' : '' }}>{e?.order_status === "FINISHED" ? "ສຳເລັດ" : e?.order_status === "CASHIER_APPROVED" ? "ແຄັດເຊຍອະນຸມັດ" :
                        e?.order_status === "SALE_CREATED" ? "ສ້າງການຂາຍສຳເລັດ" : "ແຄັດເຊຍປະຕິເສດ"}</td>
                      <td className='align-middle'>{e?.updated_by_full_name ?? "-"}</td>
                    </tr>
                  ))
                  }

                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      </>
      : <div></div>
  );
}
