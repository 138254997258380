import axios from 'axios'
import { SERVER_URL } from '..'
import { getUserDataFromLCStorage,getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";
const { branch_id } = getUserDataFromLCStorage()

const getCounter = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    if (branch_id) {
      const data = await axios.get(
        `${SERVER_URL}/branch-service/api/v1/counter?branch_id=${branch_id}`,
        {
          headers: {
            Authorization: _tokken,
          },
        }
      );
      if (data.status >= 300) return;
      return data.data;
    } else return
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getCounter());
    }
  }
};
const addCounter = async (formValue) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    if (branch_id) {
      const data = await axios.post(
        `${SERVER_URL}/branch-service/api/v1/counter/`,
        {
          ...formValue,
          branch: getUserDataFromLCStorage().branch_id,
          created_by: getUserDataFromLCStorage().first_name,
          updated_by: getUserDataFromLCStorage().first_name,
        },
        {
          headers: {
            Authorization: _tokken,
          },
        }
      );
      if (data.status >= 300) return;
      return data.data
    } else return
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,addCounter());
    }
  }
};
const deleteCounter = async (_id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.delete(
      `${SERVER_URL}/branch-service/api/v1/counter/${_id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,deleteCounter());
    }
  }
};

const updateCounter = async (_id, _value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.put(
      `${SERVER_URL}/branch-service/api/v1/counter/${_id}`,
      _value,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,updateCounter());
    }
  }
};

export { getCounter, addCounter, deleteCounter, updateCounter };