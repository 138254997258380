import Swal from 'sweetalert2'
export const successAdd = (item) => {
    Swal.fire({
        icon: 'success',
        title: item,
        showConfirmButton: false,
        timer: 2000
    })
}
export const errorAdd = (item) => {
    Swal.fire({
        icon: 'error',
        text: errorCass(item?.message),
        showConfirmButton: false,
        timer: 2000
    })
}
export const warring = (item) => {
    Swal.fire({
        icon: 'warning',
        text: item,
        showConfirmButton: false,
        timer: 2000
    })
}

 const errorCass = (item) => {
    return item == 'GraphQL error: PRODUCT_NOT_STOCK' ? "ຈຳນວນສິນຄ້າບໍ່ພຽງພໍ" :""
  }