import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { SERVER_URL } from ".";
// ---------------------------------------------
const upload = async (event) => {
  try {
    let data = event?.target?.files[0];
    let fileDatatype = event?.target?.files[0]?.type;
    let imageName = uuidv4() + "." + fileDatatype.split("/")[1].toString();

    //   console.log("imageName:", imageName);
    //   console.log("fileDataBoole:", fileDataBoole);

    // TODO: Request preSignedUrl
    let getPresignedUrl = await axios({
      method: "POST",
      url: SERVER_URL+"/auth-service/api/v1/auth/generate-presigned-url",
      data: { file_name: imageName },
    });
    //   console.log("getPresignedUrl: ", getPresignedUrl);

    // TODO: Upload to S3
    if (!getPresignedUrl?.data?.error) {
      let uploadfile = await axios({
        method: "PUT",
        url: getPresignedUrl?.data?.data,
        data: data,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      // console.log("uploadfile: ", uploadfile);
      // console.log(getPresignedUrl?.data?.data.split("?")[0]);
      // console.log("upload success");
      return { imageName, url: getPresignedUrl?.data?.data.split("?")[0] };
    }
  } catch (err) {
    console.error("error upload!");
    return err;
  }
};

export default upload;
