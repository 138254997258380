import { useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Edit from './Edit'
import './style.css'
import consts from '../../consts'
import { deleteUsers } from '../../api/users'
import moment from 'moment'
import ModalBootstrap from '../../common/popup/success/ModalBootstrap'
import { getUserDataFromLCStorage } from '../../helpers/user'
export default function StaffManagementDetail({ showStaffDetail, handleClose, staffData }) {
  console.log('staffData: ', staffData);
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showCusses, setShowCusses] = useState(false)
  const clickShowModalEdit = () => {
    setShowModalEdit(true)
    handleClose()
  }
  const clickCloseModalEdit = () => setShowModalEdit(false)

  return (
    <div >
      <Modal show={showStaffDetail} onHide={handleClose} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ລາຍລະອຽດ</Modal.Title>
        </Modal.Header>
        <Card style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div>
            {getUserDataFromLCStorage()?.role === 'MANAGER' ? 
              <span className='d-flex justify-content-end my-2'>
                <span onClick={() => {
                  clickShowModalEdit()
                }}
                  className='center-point'>
                  <FontAwesomeIcon icon={faPenToSquare} style={{ color: '#2E72D2', width: '16px', height: '16px' }} />
                </span>
                <span className='center-point' onClick={() => {
                  deleteUsers(staffData.id).then(e => {
                    clickCloseModalEdit()
                    if (e.error === false) {
                      handleClose()
                      setShowCusses(true)
                      setTimeout(() => {
                        window.location.reload()
                      }, 3000)
                    }
                  })

                }}>
                  <FontAwesomeIcon icon={faTrashCan} color='#D82C0D' />
                </span>
              </span>
              : <></>
            }
          </div>
          <div className='text-center' style={{ marginTop: -20 }}>ຮູບພະນັກງານ</div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='image-show-staff'>
              {staffData?.image_profile === "" ? <img src='/asset/image/image-2-64.ico' style={{ width: 150, height:170, padding:20 }} /> : <img style={{ width: 170, height: 190 }} src={`${consts.S3}${staffData?.image_profile}`} />}
            </div>
          </div>
          {
            [
              { title: 'ຊື່ ແລະ ນາມສະກຸນ', subtitle: `${staffData.first_name} ${staffData.last_name}` },
              { title: 'ອີເມລ', subtitle: staffData.email },
              { title: 'ສິດທິນຳໃຊ້ລະບົບ', subtitle: staffData.role },
              { title: 'ເບີໂທລະສັບ', subtitle: staffData.phone },
              { title: 'ໝາຍເຫດ', subtitle: staffData.note },
              { title: 'ວັນທີສ້າງ', subtitle: moment(staffData.created_at).format(consts.DATE_FORMAT) },
              { title: 'ວັນທີແກ້ໄຂ', subtitle: moment(staffData.updated_by).format(consts.DATE_FORMAT) === "Invalid date" ? "-" : moment(staffData.updated_by).format(consts.DATE_FORMAT) },
            ].map((item, index) => (
              <div key={index} className='d-flex justify-content-between border-bottom pb-2 mb-4'>
                <strong>{item.title}</strong>
                <span>{item.subtitle}</span>
              </div>
            ))
          }
        </Card>
      </Modal>
      <Edit showModalEdit={showModalEdit} clickShowModalEdit={clickShowModalEdit} clickCloseModalEdit={clickCloseModalEdit} data={staffData} />
      <ModalBootstrap title='ລຶບພະນັກງານສຳເລັດ' show={showCusses} />
    </div>
  )
}