import { createSlice } from "@reduxjs/toolkit";
// api
import { pattern, deletePattern, updatePattern } from "../../api/pattern";

// ---------------------------------------------------
export const patternData = createSlice({
  name: "pattern",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    addPattern: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    removePattern: (state, action) => {
      state.data = [
        ...state.data
          .map(JSON.stringify)
          .filter((e) => e !== JSON.stringify(action.payload)),
      ].map(JSON.parse); // ລົບ
    },
    editPattern: (state, action) => {
      state.data = [
        ...state.data.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      ];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addPattern, removePattern, editPattern } =
  patternData.actions;

export const loadPatternAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await pattern();
    dispatch(addPattern(data));
  } catch {
    console.error("load Pattern");
  }
  dispatch(setLoading(false));
};

export const deletePatternAsync = (value) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await deletePattern(value.id);
    dispatch(removePattern(value));
  } catch {
    console.error("delete Pattern");
  }
  dispatch(setLoading(false));
};

export const editPatternAsync = (value) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await updatePattern(value.id, value);
    dispatch(editPattern(value));
  } catch {
    console.error("edit Pattern");
  }
  dispatch(setLoading(false));
};

export const selectPattern = (state) => state.pattern;

export default patternData.reducer;
