import { createSlice } from "@reduxjs/toolkit";
import { getCabinet } from "../../api/cabinage";

export const cabinetData = createSlice({
  name: "cabinet",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    setCabinet: (state, action) => {
      state.data = [...action.payload];
    },
    addCabinet: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addCabinet, setCabinet } = cabinetData.actions;

export const loadCabinetAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await getCabinet();
    dispatch(setCabinet(data));
  } catch {
    console.error("load cabinet");
  }
  dispatch(setLoading(false));
};

export const selectCabinet = (state) => state.cabinet;

export default cabinetData.reducer;
