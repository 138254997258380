import axios from "axios";
import { SERVER_URL } from "..";
import { getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

const users = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/user-service/api/v1/user?role=ALL`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data?.status >= 300) return false;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,users());
    }
  }
};

const customer = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/user-service/api/v1/user?role=CUSTOMER`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data?.status >= 300) return false;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,customer());
    }
  }
};

const usersStaff = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/user-service/api/v1/user?role=STAFF`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data?.status >= 300) return false;
    return data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,usersStaff());
    }
  }
};

const usersByBranch = async (id,role) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/user-service/api/v1/user?role=${role}&branch=${id}&is_delete=NO`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
      );
      console.log('data: ', data);
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,usersByBranch());
    }
  }
};

export const addUsers = async (values) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/user-service/api/v1/user`,
      values,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,addUsers());
    }
  }
};
 const updateUsers = async (_id, value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.put(
      `${SERVER_URL}/user-service/api/v1/user/${_id}`,
      value,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,updateUsers());
    }
  }
};
export const deleteUsers = async (_id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.delete(
      `${SERVER_URL}/user-service/api/v1/user/${_id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,deleteUsers());
    }
  }
}

export { users, usersStaff,customer,updateUsers,usersByBranch }