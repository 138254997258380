import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import axios from "axios";
import * as _ from "lodash";
import { useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import shortUUID from "short-uuid";
/**
 *
 * @Component
 *
 */
import { Card, Form, Modal } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalBootstrap from "../../common/popup/success/ModalBootstrap";
import ModalError from "../../common/popup/fail/ModalError";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
/**
 *
 * @API
 *
 */
import { SERVER_URL } from "../../api";
import { updateCabinet } from "../../api/cabinage";
/**
 *
 * @Function
 *
 */
import { loadCabinetAsync } from "../../redux/cabinet";
import { getUserDataFromLCStorage, getAccessTokkenFromLCS, getReNewToken } from "../../helpers/user";


export default function EditCabinet({ showCabinetEdit, handlesCloseEdit }) {
  const History = useHistory()
  const dispatch = useDispatch();
  const [fail, setFail] = useState(false)
  const [suc, setSuc] = useState(false);
  const { idCabinage } = useParams()
  const { branch_id } = getUserDataFromLCStorage();
  const [userData, setUserData] = useState([]);
  const [counterData, setCounterData] = useState();
  const [caretakerList, setCaretakerList] = useState([]);
  const [cabinetOne, setCabinetOne] = useState()
  const [textValidation, setTextValidation] = useState("");

  useEffect(() => {
    getUserRoleSeller()
    getCounter();
    getCabinets();
  }, []);

  useEffect(() => {
    setCaretakerList(cabinetOne?.cabinet_owners)
  }, [cabinetOne])

  const getUserRoleSeller = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      if (getUserDataFromLCStorage()?.branch_id) {
        const data = await axios.get(
          `${SERVER_URL}/user-service/api/v1/user?role=SELLER&branch_id=${branch_id.toString()}&is_delete=NO`,
          { headers: { Authorization: _token } }
        );
        if (data.status === 200) {
          setUserData(data?.data?.data);
        }
      } else return;
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getUserRoleSeller());
      }
    }
  };

  const getCabinets = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/branch-service/api/v1/cabinet/${idCabinage}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setCabinetOne(data?.data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCabinets());
      }
    }
  };
  const getCounter = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/branch-service/api/v1/counter/limit/1000/skip/0?branch=${getUserDataFromLCStorage().branch_id
        }`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setCounterData(data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCounter());
      }
    }
  };
  function validate() {
    const errors = {};
    if (!values.choose_counter) { errors.choose_counter = "Required" }
    if (!values.cabinet_name) { errors.cabinet_name = "Required" }
    if (!values.cabinet_type) { errors.cabinet_type = "Required" }
    return errors
  }
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors

  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      choose_counter: cabinetOne?.choose_counter,
      cabinet_name: cabinetOne?.cabinet_name,
      cabinet_type: cabinetOne?.cabinet_type,
      // cabinet_owners: cabinetOne?.cabinet_owners[0]?.first_name
    },
    validate,
    onSubmit: (values) => {
      const data = {
        ...values, cabinet_owners: caretakerList.map(e => (
          {
            id: e.userId ?? e.id,
            first_name: e.first_name,
            last_name: e.last_name
          }
        )),
      }
      if (caretakerList.length < 1) {
        setTextValidation("ກະລຸນາເພີ່ມຜູ້ດູແລຕູ້ກ່ອນ!")
      } else {
        setTextValidation("")
        updateCabinet(cabinetOne?.id, data).then(e => {
          if (e.error === false) {
            handlesCloseEdit();
            setSuc(true)
            setTimeout(() => {
              dispatch(loadCabinetAsync())
              History.push(`${consts.CABINAGE_MANAGEMENT}/detail/${idCabinage}/limit/50/skip/1`)
              setSuc(false)
            }, 3000)
          }
          else setFail(true)
        })
      }
    },
  })

  return (
    <>
      <Modal show={showCabinetEdit} onHide={handlesCloseEdit} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ແກ້ໄຂຕູ້</Modal.Title>
        </Modal.Header>
        <Card style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label>ເລືອກເຄົາເຕີ</Form.Label>
              <Form.Select
                required
                name="choose_counter"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.choose_counter}
              >
                <option value="">ຕົວເລືອກ</option>
                {counterData?.data?.map((e, index) => (
                  <option key={index} value={e.counter_name}>
                    {e.counter_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>
                ຊື່ຕູ້ <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                type='text'
                name="cabinet_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.cabinet_name}
              />
            </Form.Group>

            <Form.Group className='mb-5'>
              <Form.Label>
                ປະເພດຕູ້<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select required name="cabinet_type" onChange={handleChange} onBlur={handleBlur} value={values.cabinet_type}
              >
                <option value="">ເລືອກປະເພດ</option>
                <option value='NORMAL'>ທຳມະດາ</option>
                <option value='SPECIAL'>ພິເສດ</option>
                <option value='SUPPLY'>ເຄື່ອງສົ່ງ</option>
              </Form.Select>
            </Form.Group>
            <Form.Label>
              ຜູ້ດູແລຕູ້<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Group className='mb-5'>
              <div style={{ paddingTop: 20, paddingBottom: 20, border: `1px solid #D1D5DB` }}>

                <div className="mg-x-10">
                  {caretakerList?.map((result, index) => (
                    <div className='d-flex align-items-center mb-3 gap-2' key={index}>
                      <span className="number-owner"> {index + 1}</span>
                      <Form.Select value={result.name} name="cabinet_owners" required
                        onChange={(event) => {
                          if(event.target.value !== 'none'){
                          setCaretakerList((prev) => [..._.filter(prev, (o) => o.id !== result.id),
                          {
                            id: result.id,
                            first_name: event.target.value,
                            last_name: userData?.find(e => e.first_name === event.target.value).last_name,
                            userId: userData.find(e => e.first_name === event.target.value).id
                          },
                          ])
                        }}}>
                        <option value='none'> -- ເລືອກ -- </option>
                        {userData?.map((user, index1) => (
                          <option key={index1} value={user?.first_name} selected={user?.id == cabinetOne?.cabinet_owners[index]?.id ? true : false}>
                            {`${user?.first_name} ${user?.last_name}`}
                          </option>
                        ))}
                      </Form.Select>
                      <span className="delete-owner"
                        onClick={() => setCaretakerList((prev) => [...prev.filter((e, i) => i !== index)])}>
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    </div>
                  ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <input required className='mx-5 btn-add-owner'
                    onClick={() =>
                      setCaretakerList((prev) => [
                        ...prev,
                        { id: shortUUID.generate(), first_name: "", last_name: '', userId: '' },
                      ])
                    }
                    type="button"
                    value="+ ເພີ່ມ"
                  />
                </div>
              </div>
              <span style={{ color: "red" }}>{textValidation}</span>
            </Form.Group>
            <div
              className='d-flex justify-content-around'
              style={{ margin: "80px 0" }}>
              <button
                className='buttonRedColor'
                style={buttonStyle}
                type="submit">
                ບັນທຶກການປ່ຽນແປງ
              </button>
            </div>
          </Form>
        </Card>
      </Modal>
      <ModalBootstrap title='ແກ້ໄຂຕູ້ສຳເລັດ' show={suc} />
      <ModalError title='ເກິດຂໍ້ຜິດຜາດ ກະລຸນາລອງໃໝ່ອີກຄັ້ງ!' show={fail} />
    </>
  );
}

const buttonStyle = {
  width: "100%",
  height: "49px",
};
