import React from 'react'
import { Button } from "react-bootstrap";
import styled from "styled-components";
import {Fill_COLOR } from "../../../consts";
import image from "../../../image/undraw_allow.svg"
// ----------------------------------------------- //
export default function ConfirmApprove({ title, cancel, confirm, ...other }) {
  return (
    <RootStyle {...other}>
      <Card>
        <img src={image} alt='' style={{ width: 110, height: "auto" }} />
        {title != "" ? <h3>{title}</h3> : <h3>ທ່ານຕ້ອງການອະນຸມັດລາຍການສິນຄ້າຫຼືບໍ່?</h3>}
        
        <div className='d-flex gap-2 justify-content-end'>
          <CustomButton
            style={{ backgroundColor: Fill_COLOR, color: "#1890FF" }}
            onClick={cancel}>
            ຍົກເລີກ
          </CustomButton>
          <CustomButton
            style={{ backgroundColor: "#1890FF" }}
            onClick={confirm}>
            ອະນຸມັດ
          </CustomButton>
        </div>
      </Card>
    </RootStyle>
  );
}

const RootStyle = styled.div`
    background-color: rgba(0,0,0,0.2);
    width:100%;
    height:100vh;
    position: fixed;
    top:0;
    left: 0;
    z-index: 9000;
    display:flex;
    justify-content: center;
    align-items: center;
`
const Card = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 9px;
  box-shadow: 0px 12px 24px rgba(37, 42, 49, 0.24),
    0px 8px 60px rgba(37, 42, 49, 0.32);
    display:flex;
    flex-direction: column;
    gap:4px
`;
const CustomButton = styled(Button)({
  padding: "10px 20px",
  fontWeight: 700,
  display: "flex",
  gap: 10,
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: 0,
  zIndex: 100,
});