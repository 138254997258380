import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
 */
import axios from "axios";
import moment from 'moment'
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactLoading from 'react-loading';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
/**
 *
 * @Component
 *
 */
import { faUsersGear } from "@fortawesome/free-solid-svg-icons";
import { Row, Card, Col, Form, Table, Modal, Button } from "react-bootstrap";
import Add from "./Add";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
import StaffManagementDetail from "./StaffManagementDetail";
/**
 *
 * @API
 *
 */
import { SERVER_URL } from "../../api";
/**
 *
 * @Function
 *
 */
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";
import { formatDate } from "../../helpers";
import PaginationHelper from "../../helpers/PaginationHelper";


export default function CardContainer() {
  const history = useHistory();
  const parsed = queryString.parse(history?.location?.search);
  const [popup, setPopup] = useState(false);
  const [startDate, setStartDate] = useState(formatDate(parsed?.startDate ? parsed?.startDate : moment(formatDate(new Date())).subtract(1, 'month').toDate()))
  const [endDate, setEndDate] = useState(formatDate(parsed?.endDate ? parsed?.endDate : new Date()))
  const [dataStaff, setDataStaff] = useState([]);
  const [staffDetail, setStaffDetail] = useState([]);
  const [staffTotal, setStaffTotal] = useState(0);
  const [firstName, setFirstName] = useState('');
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  const [showStaffDetail, setShowStaffDetail] = useState(false);
  const [showAddStaff, setShowAddStaff] = useState(false);

  const handleClose = () => setShowStaffDetail(false);
  const handleShow = () => setShowStaffDetail(true);

  const handleCloseAddStaff = () => setShowAddStaff(false);
  const handleShowAddStaff = () => setShowAddStaff(true);


  useEffect(() => {
    getCustomerUsers();
  }, []);

  useEffect(() => {
    getCustomerUsers();
  }, [startDate, endDate, firstName]);


  const getCustomerUsers = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/user-service/api/v1/user?limit=${_limit}&skip=${(_skip - 1) * _limit}&branch_id=${getUserDataFromLCStorage()?.branch_id}&role=ALL_STAFF&is_delete=NO` + "&first_name=" + firstName + "&from_date=" + startDate + "&to_date=" + moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD"),
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setStaffTotal(data?.data?.total)
        setDataStaff(data?.data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCustomerUsers());
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleCloseEx = () => setShow(false);
  const handleShowEx = () => setShow(true);
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {

    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Downld.pdf' + date + '.pdf');
    }
    )
  };

  return (
    <>
      <div className='fixed' onClick={() => { setPopup(false) }}
        style={{ display: popup === true ? "block" : "none" }}>
      </div>
      <Card style={{ border: "none", margin: "2px 0" }} body>
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className='mt-2'>
            <span>
              <FontAwesomeIcon
                style={{ width: "28px", height: "22px", margin: "0 5px" }}
                icon={faUsersGear}
              />
            </span>{" "}
            ຂໍ້ມູນຜູ້ນຳໃຊ້ທັງໝົດ
          </h4>
          {getUserDataFromLCStorage()?.role === 'MANAGER' ?
            <button onClick={handleShowAddStaff} className='buttonRedColor'
              style={{ width: "195px", height: "51px" }}>
              + ເພີ່ມຂໍ້ມູນ
            </button>
            : <></>
          }
        </div>
      </Card>

      <Card
        style={{ padding: "20px", paddingTop: "10px", border: "none" }} body>
        {/* <CustomSearch value={SearchVal} placeholder={'ຄົ້ນຫາດ້ວຍຊື່'} /> */}

        <Row className='mt-3'>
          <Col md={3}>
            <Form.Group controlId='doj'>
              <Form.Label>ວັນທີສ້າງ</Form.Label>
              <Form.Control type='date' name='doj' placeholder='Date of Joining' defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value)
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId='doj'>
              <Form.Label>ຫາວັນທີ</Form.Label>
              <Form.Control type='date' name='doj' placeholder='Date of Joining' defaultValue={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value)
                }}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="d-flex justify-content-end" style={{ marginTop: 33 }}>
            <div>
              <button
                onClick={handleShowEx}
                id="format-PDF"
                className='buttonRedColor-export'>  Export </button>
            </div>
          </Col>
        </Row>
        {dataStaff?.length <= 0 ?
          <div className="loading-card">
            <div>
              <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
              <span>Loading....</span>
            </div>
          </div>
          : dataStaff == null ? (<div>
             <div>
                <img style={{ marginLeft: "40%", marginTop: "10%" }} src='/asset/image/animation_200_ldu129fc.gif' />
                <p style={{ marginLeft: "45%", marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
              </div>
          </div>) :
            <Table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ຊື່ຜູ້ໃຊ້</th>
                  <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                  <th>ສິດທິນຳໃຊ້ລະບົບ</th>
                  <th>ອີເມວ</th>
                  <th>ເບີໂທລະສັບ</th>
                  <th>ວັນທີສ້າງ</th>
                </tr>
              </thead>
              <tbody>
                {dataStaff?.map((result, index) => (
                  <tr key={index}
                    onClick={() => {
                      if (result?.role == "ADMIN") {
                        console.log("ADMIN");
                      } else {
                        handleShow()
                        setStaffDetail(result);
                      }
                    }}
                  >
                    <td>{index + 1 + _limit * (_skip - 1)}</td>
                    <td>{result?.username}</td>
                    <td>{`${result?.first_name} ${result?.last_name}`}</td>
                    <td>{result?.role}</td>
                    <td>{result?.email ?? "-"}</td>
                    <td>{result?.phone ?? "-"}</td>
                    <td>{moment(result?.created_at).format(consts.DATE_FORMAT)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
        }
        {Pagination_helper(staffTotal, consts.STAFF_MANAGEMENT, `startDate=${startDate}&endDate=${endDate}`)}
      </Card>

      <StaffManagementDetail
        showStaffDetail={showStaffDetail}
        handleClose={handleClose}
        staffData={staffDetail}
      />
      <Add showAddStaff={showAddStaff} handleCloseAddStaff={handleCloseAddStaff} />
      <Modal show={show} onHide={handleClose} size="xl">

        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
            <Button variant="secondary" onClick={handleCloseEx}>
              Close
            </Button>
            <Button className='buttonRedColor'
              style={{ width: "125px", height: "51px" }} onClick={handleDownloadPdf}>
              Export PDF
            </Button>
          </div>
          <div className="" ref={printRef}>

            <Table className="">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ຊື່ຜູ້ໃຊ້</th>
                  <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                  <th>ສິດທິນຳໃຊ້ລະບົບ</th>
                  <th>ອີເມວ</th>
                  <th>ເບີໂທລະສັບ</th>
                  <th>ວັນທີສ້າງ</th>
                </tr>
              </thead>
              <tbody>
                {
                  dataStaff == null ? (
                    <tr>
                      <td colSpan={7}>
                        <div className="text-center">
                          <img src='/asset/image/animation_200_ldu129fc.gif' />
                          <p>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {dataStaff?.map((result, index) => (
                        <tr key={index}
                          onClick={() => {
                            if (result?.role == "ADMIN") {
                              console.log("ADMIN");
                            } else {
                              handleShow()
                              setStaffDetail(result);
                            }
                          }}
                        >
                          <td>{index + 1 + _limit * (_skip - 1)}</td>
                          <td>{result?.username}</td>
                          <td>{`${result?.first_name} ${result?.last_name}`}</td>
                          <td>{result?.role}</td>
                          <td>{result?.email ?? "-"}</td>
                          <td>{result?.phone ?? "-"}</td>
                          <td>{moment(result?.created_at).format(consts.DATE_FORMAT)}</td>
                        </tr>
                      ))}
                    </>
                  )
                }

              </tbody>
            </Table>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}
