import React from "react";
import styled from "styled-components";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// ----------------------------------------------- //
export default function Success({ text = "ສໍາເລັດ!", ...other }) {
  return (
    <RootStyle {...other}>
      <Card>
        <FontAwesomeIcon icon={faCircleCheck} style={{color:'#008060', width: 80, height: "auto" }} />
        <h3>{text}</h3>
      </Card>
    </RootStyle>
  );
}

const RootStyle = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Card = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 9px;
  box-shadow: 0px 12px 24px rgba(37, 42, 49, 0.24),
    0px 8px 60px rgba(37, 42, 49, 0.32);
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 376px;
  min-height: 180px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  h3 {
    font-size: 16px;
  }
`;

