import { createSlice } from "@reduxjs/toolkit";
import { getProduct } from "../../api/product";

export const productData = createSlice({
  name: "product",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    setProduct: (state, action) => {
      state.data = [...action.payload];
    },
    addProduct: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addProduct, setProduct } = productData.actions;

export const loadProductAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await getProduct();
    dispatch(setProduct(data));
  } catch {
    console.error("load product");
  }
  dispatch(setLoading(false));
};

export const selectProduct = (state) => state.product;

export default productData.reducer;
