import { createSlice } from "@reduxjs/toolkit";
// api
import { category, deleteCategory, updateCategory } from "../../api/category";

// ---------------------------------------------------
export const categoryData = createSlice({
  name: "category",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    setCategory: (state, action) => {
      state.data = [...action.payload];
    },
    addCategory: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    removeCategory: (state, action) => {
      state.data = [
        ...state.data
          .map(JSON.stringify)
          .filter((e) => e !== JSON.stringify(action.payload)),
      ].map(JSON.parse); // ລົບ
    },
    editCategory: (state, action) => {
      state.data = [
        ...state.data.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      ];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setLoading,
  addCategory,
  removeCategory,
  editCategory,
  setCategory,
} = categoryData.actions;

export const loadCategoryAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await category();
    dispatch(setCategory(data));
  } catch {
    console.error("load category");
  }
  dispatch(setLoading(false));
};

export const deleteCategoryAsync = (value) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await deleteCategory(value.id);
    dispatch(removeCategory(value));
  } catch {
    console.error("delete category");
  }
  dispatch(setLoading(false));
};

export const editCategoryAsync = (value) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await updateCategory(value.id, value);
    dispatch(editCategory(value));
  } catch {
    console.error("edit category");
  }
  dispatch(setLoading(false));
};

export const selectCategory = (state) => state.category;

export default categoryData.reducer;
