import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import queryString from 'query-string';
import styled from "styled-components";
import { Card, Col, Form, Row, Table, Breadcrumb } from "react-bootstrap";

import moment from 'moment'
import { faSquarePollVertical, } from "@fortawesome/free-solid-svg-icons";
import consts from "../../../consts";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOrderProduct } from "../../../redux/order_product";
import ReactLoading from 'react-loading';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import CustomTitleCard from "../../../common/CustomTitleCard";
import CustomSearch from "../../../common/CustomSearch";
import formatCurrency from "../../../helpers/formatCurrency";
import { SERVER_URL } from "../../../api";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../../helpers/user";
import { formatCurrencyGold, formatDate } from "../../../helpers";
import PaginationHelper from "../../../helpers/PaginationHelper";
import _ from "lodash";

// ------------------------------------------------------ //
export default function ChangeFree() {
  const History = useHistory();
  const printRef = useRef();
  const parsed = queryString.parse(History?.location?.search);
  const [orderTotal, setOrderTotal] = useState(0)
  const orderProduct = useSelector(selectOrderProduct)

  const [orderData, setOrderData] = useState([])
  const [totalProductAmount, setTotalProductAmount] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalOldWeight, setTotalOldWeight] = useState(0)
  const [totalWeight, setTotalWeight] = useState(0)
  const [staffName, setStaffName] = useState(parsed?.staffName ? parsed?.staffName : '')
  const [customerPhone, setCustomerPhone] = useState(parsed?.customerPhone ? parsed?.customerPhone : '')
  const [inputOrderCode, setInputOrderCode] = useState(parsed?.inputOrderCode ? parsed?.inputOrderCode : '')
  const [startDate, setStartDate] = useState(formatDate(parsed?.startDate ? parsed?.startDate : moment(formatDate(new Date())).subtract(7, 'days').toDate()))
  const [endDate, setEndDate] = useState(parsed?.endDate ? parsed?.endDate : formatDate(new Date()))
  const [orderStatus, setOrderStatus] = useState(parsed?.orderStatus ? parsed?.orderStatus : '')
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  useEffect(() => {
    getOrder()
  }, [startDate, endDate, inputOrderCode, orderStatus, staffName, customerPhone])

  const getOrder = async () => {
    let _token = getAccessTokkenFromLCS();
    var branch_id = getUserDataFromLCStorage()?.branch_id;
    try {
      const data = await axios.get(
        `${SERVER_URL}/order-service/api/v1/order?limit=${_limit}&skip=${(_skip - 1) * _limit}&order_type=CHANGE_FREE&order_code=${inputOrderCode}&branch_id=${branch_id}&
        from_date=${startDate}&to_date=${moment(moment(endDate).add(1, "day")).format("YYYY-MM-DD")}&order_status=${orderStatus}&customer_phone=${customerPhone}&created_by_full_name=${staffName}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setOrderTotal(data?.data?.total);
        setOrderData(data?.data)
        console.log('data?.data: ', data?.data);

        const result = _.sumBy(data?.data?.data, 'product_amount')
        setTotalProductAmount(result)

        const result1 = _.sumBy(data?.data?.data, 'total_price')        
        setTotalPrice(result1)

        const result2 = _.sumBy(data?.data?.data, 'total_weight')
        setTotalWeight(result2)

      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getOrder());
      }
    }
  };

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
        logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
            heightLeft -= pageHeight;
        }
        const date = new Date();
        doc.save('changefree' + date + '.pdf');
    }
    )
  };
  return (
    getUserDataFromLCStorage()?.role === 'MANAGER' ? 
    <RootStyle>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" active>ລາຍງານການປ່ຽນຟຣີ</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <CustomTitleCard
        title='ລາຍງານການປ່ຽນຟຣີທັງໝົດ'
        icon={faSquarePollVertical}
        style={{ padding: 20, marginBottom: 4 }}
      />

      <Card style={{ padding: 20, minHeight: "80vh", paddingBottom: 100 }}>
        <div className='mb-3'>
          <CustomSearch value={inputOrderCode} onChange={(event) => setInputOrderCode(event?.target?.value)} placeholder={'ຄົ້ນຫາຕາມເລກໃບບິນ'} />
        </div>
        <div>
        <Row>
          <Col sm='4'>
            <Form.Group>
              <Form.Label>ຊື່ພະນັກງານ</Form.Label>
              <Form.Control defaultValue="" onChange={(e) => setStaffName(e.target.value)} placeholder='ປ້ອນຊື່ພະນັກງານ' type='text' />
            </Form.Group>
          </Col>
          <Col sm='4'>
            <Form.Group>
              <Form.Label>ເບີໂທ</Form.Label>
              <Form.Control defaultValue="" onChange={(e) => setCustomerPhone(e.target.value)} placeholder='ປ້ອນເບີໂທ' type='text' />
            </Form.Group>
          </Col>
          <Col md='4'>
            <Form.Label>ສະຖານະໃບບິນ</Form.Label>
            <Form.Control
              as='select'
              className="form-select"
              defaultValue={orderStatus}
              onChange={(e) => setOrderStatus(e.target.value)}
            >
              <option value="">ທັງໝົດ</option>
              <option value="FINISHED">ອໍເດີສຳເລັດ</option>
              <option value="CASHIER_APPROVED">ເຄົ້າເຕີ້ອະນຸມັດສຳເລັດ</option>
              <option value="SALE_CREATED">ສ້າງລາຍການຈາກຕູ້ສຳເລັດ</option>
              <option value="CASHIER_REJECTED">ເຄົ້າເຕີ້ປະຕິເສດ</option>
            </Form.Control>
          </Col>
          </Row>
          <br/>
          <Row>
            <Col md='4'>
              <Form.Group controlId='doj'>
                <Form.Label>ວັນທີປ່ຽນ</Form.Label>
                <Form.Control
                  defaultValue={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                  }}
                  type='date' />
              </Form.Group>
            </Col>
            <Col md='4'>
              <Form.Group controlId='doj'>
                <Form.Label>ຫາວັນທີ</Form.Label>
                <Form.Control
                  defaultValue={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value)
                  }}
                  type='date' />
              </Form.Group>
            </Col>
            <Col style={{ display: "flex", alignItems: "center", marginTop: "31px" }}>
              <div>
                  <button onClick={handleDownloadPdf} id="format-PDF" className='buttonRedColor-export' >Export PDF</button>
              </div>
            </Col>
          </Row>
        </div>
        {orderData?.data?.length <= 0 ?
          <div className="loading-card">
            <div>
              <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
              <span>Loading....</span>
            </div>
          </div>
          : orderTotal === 0 ?
            <div>
              <img style={{ marginLeft: "40%", marginTop: "10%" }} src='/asset/image/animation_200_ldu129fc.gif' />
              <p style={{ marginLeft: "45%", marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
            </div> :
            <Table className='table-hover' ref={printRef}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ເລກທີໃບບິນ</th>
                  <th>ຊື່ລູກຄ້າ</th>
                  <th>ເບີໂທ</th>
                  <th>ຈຳນວນສິນຄ້າ</th>
                  <th>ນໍ້າໜັກສິນຄ້າເກົ່າ</th>
                  <th>ລາຄາລວມ</th>
                  <th>ວັນທີປ່ຽນ</th>
                  <th>ສະຖານະໃບບິນ</th>
                </tr>
              </thead>
              <tbody>

                {
                  orderData?.data?.map((e, index) => (
                    <tr style={{ cursor: "pointer" }} onClick={() => History.push(`${consts.PAGE_REPORT}/change-free/detail/${e.id}`)}
                      key={index}>
                      <td className='align-middle'>{index + 1 + _limit * (_skip - 1)}</td>
                      <td className='align-middle'>{e?.order_code}</td>
                      <td className='align-middle'>{`${e?.customer_first_name} ${e?.customer_last_name}`}</td>
                      <td className='align-middle'>{e?.customer_phone}</td>
                      <td className='align-middle'>{e?.product_amount}</td>
                      <td className='align-middle'>{formatCurrencyGold(e?.new_weight)}</td>
                      <td className='align-middle'>{formatCurrency(e?.total_price)}</td>
                      <td className='align-middle'>{moment(e?.created_at).format(consts.DATE_FORMAT)}</td>
                      <td className='align-middle'
                        style={{
                          color: e?.order_status === "FINISHED" ? 'green '
                            : e?.order_status === "CASHIER_REJECTED" ? 'red' : ''
                        }}>
                        {e?.order_status === "FINISHED" ? "ອໍເດີສຳເລັດ"
                          : e?.order_status === "CASHIER_APPROVED" ? "ເຄົ້າເຕີ້ອະນຸມັດສຳເລັດ" :
                        e?.order_status === "SALE_CREATED" ? "ສ້າງລາຍການຈາກຕູ້ສຳເລັດ" : "ເຄົ້າເຕີ້ປະຕິເສດ"}</td>
                    </tr>
                  ))
                }
                <tr className="fw-bold">
                  <td colSpan={4}>ລວມ</td>
                  <td>{formatCurrency(totalProductAmount)}</td>
                  <td>{formatCurrencyGold(totalWeight)}</td>
                  <td>{formatCurrency(totalPrice)}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
        }
        <div style={{ flexGrow: 1 }} />
        <Row>
          {/* <Col xs={4} className='d-flex align-items-center'>
            <div>ກຳລັງສະແດງ {}1-10 ຈາກ 30 ລາຍການ</div>
          </Col> */}
          {/* <Col xs={4} style={{ display: "flex", justifyContent: "center" }}> */}
          {Pagination_helper(orderTotal, consts.PAGE_REPORT + '/change-free', `inputOrderCode=${inputOrderCode}&startDate=${startDate}&endDate=${endDate}&orderStatus=${orderStatus}&staffName=${staffName}&customerPhone=${customerPhone}`)}
          {/* </Col> */}
        </Row>
      </Card>
    </RootStyle>
    : <div></div>
  );
}
const RootStyle = styled.div`
`;
