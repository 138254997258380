import { useState,useEffect } from "react";
import styled from "styled-components";
import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import { Card, Row, Col, Form,Button } from 'react-bootstrap'
import consts, { CUSTOMERS_TYPE, CUSTOMER_MANAGEMENT } from '../../consts'
import './style.css'
import { useParams,useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadCustomerAsync } from "../../redux/customer";
import upload from "../../api/upload";
import { updateUsers } from "../../api/users";
import Success from "../../common/popup/success/Success";
import Fail from '../../common/popup/fail/fail'
import {PROVINCE,DRISTRIC} from '../../helpers/addresses'
import { getUserDataFromLCStorage } from "../../helpers/user";

export default function CustomerManagementsDetail() {
    const [fail, setFail] = useState(false)
    const [suc, setSuc] = useState(false);
    const {id} = useParams()
    const History = useHistory()
    const dataOne = History?.location?.state
    const dispath = useDispatch()
    const [imageName,setImageName] = useState(dataOne?.image_profile)
    const [validated, setValidated] = useState(false);
    const [firstName, setFirstName] = useState(dataOne?.first_name)
    const [lastName, setLastName] = useState(dataOne?.last_name)
    const [birthDay, setBirthday] = useState(dataOne?.birthday)
    const [genDer, setGender] = useState(dataOne?.gender)
    const [Phone, setPhone] = useState(dataOne?.phone)
    const [Email, setEmail] = useState(dataOne?.email)
    const [customerType, setCustomerType] = useState(dataOne?.customer_type)
    const [Status, setStatus] = useState(dataOne?.status)
    const [documentType, setDocumentType] = useState(dataOne?.document_type)
    const [idCard, setIdCard] = useState(dataOne?.id_card)
    const [bankName, setBankName] = useState(dataOne?.bank_name)
    const [accountName, setAccountName] = useState(dataOne?.bank_name)
    const [accountNumber, setAccountNumber] = useState(dataOne?.account_number)
    const [maxBuy, setMaxBuy] = useState(dataOne?.max_buys)
    const [villageAddress, setVillageAddress] = useState(dataOne?.address?.length> 0 ? dataOne?.address[0]?.village:"-")
    const [districAddress, setDistricAddress] = useState(dataOne?.address?.length> 0 ? dataOne?.address[0]?.district:"-")
    const [provinceAddress, setProvinceAddress] = useState(dataOne?.address?.length> 0 ? dataOne?.address[0]?.province:"-")
    const [villageSend, setVillageSend] = useState(dataOne?.shipment?.length > 0 ? dataOne?.shipment[0]?.village:"-")
    const [districSend, setDistricSend] = useState(dataOne?.shipment?.length > 0 ? dataOne?.shipment[0]?.district:"-")
    const [provinceSend, setProvinceSend] = useState(dataOne?.shipment?.length > 0 ? dataOne?.shipment[0]?.province:"-")
    const [idCardImageName, setIdCardImageName] = useState(dataOne?.image_id_card)
    const [accountImageName, setAccountImageName] = useState(dataOne?.image_account_number)
    const [dataDristric, setDataDristric] = useState([])
    const [dataDristricApprover, setDataDristricApprover] = useState([])
    const [dataLocalStore, setdataLocalStore] = useState(getUserDataFromLCStorage())

    useEffect(()=>{
        // setImageName(dataOne?.image_profile)
        // setUsername(dataOne?.username)
        // setRole(dataOne?.role)
        // setFirstName(dataOne?.first_name)
        // setLastName(dataOne?.last_name)
        // setGender(dataOne?.gender)
        // setBirthday(dataOne?.birthDay)
        // setPhone(dataOne?.phone)
        // setEmail(dataOne?.email)
        // setPassword(dataOne?.password)
        // setCustomerType(dataOne?.customer_type)
        // setStatus(dataOne?.status)
        // setDocumentType(dataOne?.document_type)
        // setIdCard(dataOne?.id_card)
        // setMaxBuy(dataOne?.max_buys)
        // setBankName(dataOne?.bank_name)
        // setBranch(dataOne?.branch)
        // setAccountName(dataOne?.bank_name)
        // setAccountNumber(dataOne?.account_number)
        // setVillageAddress(dataOne?.address[0]?.village)
        // setDistricAddress(dataOne?.address[0]?.district)
        // setProvinceAddress(dataOne?.address[0]?.province)
        // setVillageSend(dataOne?.shipment[0]?.village)
        // setDistricSend(dataOne?.shipment[0]?.district)
        // setProvinceSend(dataOne?.shipment[0]?.province)
        // setIdCardImageName(dataOne?.image_id_card)
        // setAccountImageName(dataOne?.image_account_number)
        // _filterDrist(dataOne?.address[0]?.province)
        // _filterDristApprover(dataOne?.shipment[0]?.province)
    },[dataOne])

    const form_data = {
        image_profile: imageName,
        image_id_card:idCardImageName,
        image_account_number:accountImageName,
        username: Phone,
        role: 'CUSTOMER',
        first_name: firstName,
        last_name: lastName,
        birthday: birthDay,
        gender: genDer,
        phone: Phone,
        email: Email,
        // password: Password,
        customer_type: customerType,
        status: Status,
        document_type: documentType,
        id_card: idCard?.toString(),
        bank_name: bankName,
        user_status: Status,
        branch_id: dataLocalStore?.branch_id,
        branch_name: dataLocalStore?.branch_name,
        account_name: accountName,
        account_number: accountNumber,
        max_buys: parseInt(maxBuy),
        address:[{
            village: villageAddress,
            district: districAddress,
            province: provinceAddress,
        }],
        shipment:[{
            village: villageSend,
            district: districSend,
            province: provinceSend,
        }],
    }
    const handleSubmit = (event) => {
        event?.preventDefault()
        const form = event?.currentTarget;
        if (form?.checkValidity() === false) {
            event?.preventDefault();
            setFail(true)
            event?.stopPropagation();
            return
        }
        setValidated(true);
        updateUsers(id,form_data).then(e=>{
            if(e?.error === false){
                setSuc(true)
                setTimeout(()=>{
                    dispath(loadCustomerAsync())
                    History?.push(consts.CUSTOMER_MANAGEMENT)
                },3000)
            }
            else setFail(true)
        })

    };
    const _filterDrist=(item)=>{
        let _filtterProvin = PROVINCE?.filter((data)=>data?.pr_name === item)
        let _filtterDis = DRISTRIC?.filter((data)=>data?.pr_id === _filtterProvin[0]?.pr_id)
        setProvinceAddress(_filtterProvin[0]?.pr_name)
        setDataDristric(_filtterDis)
    }
    const _filterDristApprover=(item)=>{
        let _filtterProvin = PROVINCE.filter((data)=>data?.pr_name === item)
        let _filtterDis = DRISTRIC.filter((data)=>data?.pr_id === _filtterProvin[0]?.pr_id)
        setProvinceSend(_filtterProvin[0]?.pr_name)
        setDataDristricApprover(_filtterDis)
    }

    return (    
        <RootStyle>
            <CustomBreadcrumbs value={[{title:'ຈັດການລູກຄ້າ',path:`${CUSTOMER_MANAGEMENT}`},{title:'ແກ້ໄຂ'}]} />
           
            <Card style={{padding:'20px'}}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="d-flex justify-content-between" >
                    <Col className="mb-5" style={{width:'589px'}}>
                        <div>
                            <h3 className="border-bottom">ຂໍ້ມູນສ່ວນໂຕ</h3>
                            <span>
                                        <label htmlFor="imageprofile">
                                            <div
                                                style={{
                                                    padding: "3px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    border: "1px solid #666",
                                                    height: "170px",
                                                    width: "170px"
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        display: imageName === "" || undefined ? "none" : "block",
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    src={consts.S3+imageName}
                                                />
                                            </div>
                                        </label>
                                        <input
                                            id="imageprofile"
                                            style={{ display: "none" }}
                                            type="file"
                                            onChange={(e) => {
                                                upload(e).then((event) => {
                                                    setImageName(event.imageName)
                                                });
                                            }}
                                        />
                                    </span>
                                <Form.Group className="mb-3">
                                    <Form.Label>ຊື່</Form.Label>
                                    <Form.Control onChange={(e)=>setFirstName(e.target.value)} defaultValue={dataOne?.first_name}  type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ນາມສະກຸນ</Form.Label>
                                    <Form.Control onChange={(e)=>setLastName(e.target.value)} defaultValue={dataOne?.last_name} type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group controlId='doj' className="mb-3">
                                    <Form.Label>ວັນເດືອນປີເກີດ</Form.Label>
                                    <Form.Control
                                        type='date'
                                        name='doj'
                                        defaultValue={dataOne?.birthday}
                                        placeholder='Date of Joining'
                                        onChange={(e)=>setBirthday(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId='doj' className="mb-3">
                                    <Form.Label className="d-block">ເພດ</Form.Label>
                                    <Form.Check value={'FEMALE'} checked={genDer==="FEMALE" ? true : false} onChange={e => setGender(e.target.value)} inline name="radioGroup" label="ຍິງ" type="radio" id="radio-1" />
                                    <Form.Check value={'MALE'} checked={genDer==="MALE" ? true : false} onChange={e => setGender(e.target.value)} inline name="radioGroup" label="ຊາຍ" type="radio" id="radio-2" />
                                    <Form.Check value={'OTHER'} checked={genDer==="OTHER" ? true : false} onChange={e => setGender(e.target.value)} inline name="radioGroup" label="ອື່ນໆ" type="radio" id="radio-3" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ອີເມລ</Form.Label>
                                    <Form.Control onChange={(e)=>setEmail(e.target.value)} defaultValue={dataOne?.email} type="email" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ເບີໂທ ( ໃຊ້ເຂົ້າລະບົບ )</Form.Label>
                                    <Form.Control onChange={(e)=>setPhone(e.target.value)} defaultValue={dataOne?.phone} type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                        </div>
                    </Col> {/*left top */}

                    <Col className="mb-5"style={{width:'589px'}}>
                        <div>
                            <h3 className="border-bottom">ຂໍ້ມູນທົ່ວໄປ</h3>
                                <Form.Group className="mb-3">
                                    <Form.Label>ປະເພດລູກຄ້າ</Form.Label>
                                    <Form.Select defaultValue={dataOne?.customer_type} onChange={(e)=>setCustomerType(e.target.value)} >
                                        <option value={""} selected disabled>ກະລຸນາເລືອກ</option>
                                        {
                                            consts.CUSTOMERS_TYPE_LAOS.map((e,i)=>(
                                                <option value={CUSTOMERS_TYPE[i]} key={i}>{e}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ສະຖານະນໍາໃຊ້</Form.Label>
                                    <Form.Select defaultValue={Status} onChange={(e)=>setStatus(e?.target?.value)} >
                                    <option value={""} selected disabled>ກະລຸນາເລືອກ</option>
                                        <option value="ACTIVE">ເປີດໃຊ້ງານ</option>
                                        <option value="INACTIVE">ປິດໃຊ້ງານ</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ປະເພດເອກະສານ</Form.Label>
                                    <Form.Select onChange={(e)=>setDocumentType(e.target.value)} defaultValue={dataOne?.document_type} >
                                    <option value={""} selected disabled>ກະລຸນາເລືອກ</option>
                                    <option value="ບັດປະຈຳໂຕ">ບັດປະຈຳໂຕ</option>
                                        <option value="ປື້ມສຳມະໂນ">ປື້ມສຳມະໂນ</option>
                                        <option value="passport">passport</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ລະຫັດບັດ</Form.Label>
                                    <Form.Control onChange={(e)=>setIdCard(e?.target?.value)} defaultValue={dataOne?.id_card} type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ຊື່ທະນາຄານ</Form.Label>
                                    <Form.Select onChange={(e)=>setBankName(e?.target?.value)} defaultValue={dataOne?.bank_name} >
                                    <option value={""} selected disabled>ກະລຸນາເລືອກ</option>
                                        <option value="BCEL">ທະນາຄານການຄ້າຕ່າງປະເທດລາວ ມະຫາຊົນ</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>ສາຂາ</Form.Label>
                                    <Form.Select onChange={(e)=>setBranch(e.target.value)} >
                                    <option value={""} selected disabled>ກະລຸນາເລືອກ</option>
                                        {
                                            branchData.data.map((e, index) => (
                                                <option key={index} value={e.branch_name}>{e.branch_name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group className="mb-3">
                                    <Form.Label>ຊື່ບັນຊີ</Form.Label>
                                    <Form.Control onChange={(e)=>setAccountName(e.target.value)} defaultValue={dataOne?.bank_name} type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ເລກບັນຊີ</Form.Label>
                                    <Form.Control onChange={(e)=>setAccountNumber(e.target.value)} defaultValue={dataOne?.account_number} type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ຈໍານວນທີ່ສາມາດສັ່ງຊື້ໄດ້</Form.Label>
                                    <Form.Control onChange={(e)=>setMaxBuy(e.target.value)} defaultValue={dataOne?.max_buys} type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>

                                <div className="d-flex">
                                    <span className="mx-2">
                                        <div>ບັດປະຈຳໂຕ ຫຼື ປື້ມສຳມະໂນ</div>
                                        <label htmlFor="idcardEdit">
                                            <div
                                                style={{
                                                    padding: "3px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    border: "1px solid #666",
                                                    height: "170px",
                                                    width: "170px"
                                                }}
                                            >
                                                <p
                                                    style={{ display: idCardImageName === "" || undefined ? "block" : "none" }}
                                                >
                                                    + upload
                                                </p>
                                                <img
                                                    style={{
                                                        display: idCardImageName === "" || undefined ? "none" : "block",
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    src={consts.S3+idCardImageName}
                                                />
                                            </div>
                                        </label>
                                        <input
                                            id="idcardEdit"
                                            style={{ display: "none" }}
                                            type="file"
                                            onChange={(e) => {
                                                upload(e).then((event) => {
                                                    setIdCardImageName(event.imageName)
                                                });
                                            }}
                                        />
                                    </span>
                                    <span className="mx-2">
                                        <div>ຮູບບັນຊີ</div>
                                        <label htmlFor="familyEdit">
                                            <div
                                                style={{
                                                    padding: "3px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    border: "1px solid #666",
                                                    height: "170px",
                                                    width: "170px"
                                                }}
                                            >
                                                <p
                                                    style={{ display: accountImageName === "" || undefined ? "block" : "none" }}
                                                >
                                                    + upload
                                                </p>
                                                <img
                                                    style={{
                                                        display: accountImageName === "" || undefined ? "none" : "block",
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    src={consts.S3+accountImageName}
                                                />
                                            </div>
                                        </label>
                                        <input
                                            id="familyEdit"
                                            style={{ display: "none" }}
                                            type="file"
                                            onChange={(e) => {
                                                upload(e).then((event) => {
                                                    setAccountImageName(event.imageName)
                                                });
                                            }}
                                        />
                                    </span>
                                </div>
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-between">
                    <Col className="mb-5">
                        <div>
                            <h3 className="border-bottom">ຂໍ້ມູນທີ່ຢູ່</h3>
                                <Form.Group className="mb-3">
                                    <Form.Label>ແຂວງ</Form.Label>
                                    <Form.Select onChange={e => {
                                        _filterDrist(e?.target?.value)
                                    }}>
                                        <option value=""  disabled>ກະລຸນາເລືອກ</option>
                                        {PROVINCE?.map((item,index)=>
                                        <option key={index+1} value={item?.pr_name} selected={item?.pr_name===dataOne?.address?.length > 0 ? dataOne?.address[0]?.province:"-"}>{item?.pr_name}</option>
                                        )}
                                    </Form.Select>
                                    {/* <Form.Control onChange={(e)=>setProvinceAddress(e.target.value)} defaultValue={dataOne?.address?.village} type="text" placeholder="ກະລຸນາປ້ອນ" /> */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ເມືອງ</Form.Label>
                                    <Form.Select onChange={e => {
                                        setDistricAddress(e?.target?.value)
                                    }} >
                                        <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {dataDristric?.map((item,index)=>
                                        <option key={index+1} value={item?.dr_name} selected={item?.dr_name === dataOne?.address?.length > 0 ? dataOne?.address[0]?.district:"-"}>{item?.dr_name}</option>
                                        )}
                                    </Form.Select>
                                    {/* <Form.Control onChange={(e)=>setDistricAddress(e.target.value)} defaultValue={dataOne?.address?.district} type="text" placeholder="ກະລຸນາປ້ອນ" /> */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ບ້ານ</Form.Label>
                                    <Form.Control onChange={(e)=>setVillageAddress(e?.target?.value)} defaultValue={dataOne?.address?.length > 0 ? dataOne?.address[0]?.village:"-"} type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                        </div>
                    </Col>
                    <Col  className="mb-5">
                        <div>
                            <h3 className="border-bottom">ຂໍ້ມູນບ່ອນຈັດສົ່ງ</h3>
                                <Form.Group className="mb-3">
                                    <Form.Label>ແຂວງ</Form.Label>
                                    <Form.Select onChange={e => {
                                        _filterDristApprover(e?.target?.value)
                                    }} >
                                           <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {PROVINCE?.map((item,index)=>
                                        <option key={index+1} value={item?.pr_name} selected={item?.pr_name===dataOne?.shipment?.length> 0 ? dataOne?.shipment[0]?.province:"-"}>{item?.pr_name}</option>
                                        )}
                                    </Form.Select>
                                    {/* <Form.Control onChange={(e)=>setProvinceSend(e.target.value)} type="text" placeholder="ກະລຸນາປ້ອນ" /> */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ເມືອງ</Form.Label>
                                    <Form.Select onChange={e => {
                                        setDistricSend(e?.target?.value)
                                    }} 
                                    >
                                        <option value="" selected disabled>ກະລຸນາເລືອກ</option>
                                        {dataDristricApprover?.map((item,index)=>
                                        <option key={index+1} value={item?.dr_name} selected={item?.dr_name === dataOne?.shipment?.length> 0 ? dataOne?.shipment[0]?.district:"-"} >{item?.dr_name}</option>
                                        )}
                                    </Form.Select>
                                    {/* <Form.Control onChange={(e)=>setDistricSend(e.target.value)} type="text" placeholder="ກະລຸນາປ້ອນ" /> */}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>ບ້ານ</Form.Label>
                                    <Form.Control onChange={(e)=>setVillageSend(e.target.value)} defaultValue={dataOne?.shipment?.length> 0 ? dataOne?.shipment[0]?.village:"-"} type="text" placeholder="ກະລຸນາປ້ອນ" />
                                </Form.Group>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col  className="my-2">
                        <Button onClick={()=>History.push(consts.CUSTOMER_MANAGEMENT)} variant="light" style={{width:'509px',height:'49px'}}>ຍົກເລີກ</Button>
                    </Col>
                    <Col  className="my-2">
                        <button type="button" className="buttonRedColor" style={{width:'509px',height:'49px'}} onClick={()=>handleSubmit()}>ບັນທຶກການແກ້ໄຂ</button>
                    </Col>
                 </Row>
              </Form>
            </Card>
            <div style={{ display: fail ? 'inline' : 'none', width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.4)', position: 'fixed', top: '0', left: '0' }}>
                <Fail confirm={() => setFail(false)} />
            </div>
            <div style={{ display: suc ? 'inline' : 'none', width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.4)', position: 'fixed', top: '0', left: '0' }}>
                <Success />
            </div>
        </RootStyle>
    )
}

const RootStyle = styled.div`
    padding:20px;
`