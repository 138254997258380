import React, { useState } from "react";
/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
/**
 *
 * @Component
 *
 */
import { Row, Col, Form, Modal, Card } from "react-bootstrap";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SecondaryButton from "../../common/Button/SecondaryButton";
import ModalBootstrap from "../../common/popup/success/ModalBootstrap";
import CounterEdit from "./CounterEdit";
import ConfirmDelete from "../../common/popup/delete/ConfirmDelete";
import Success from '../../common/popup/success/Success'
import Fail from '../../common/popup/fail/fail'
/**
 *
 * @Constant
 *
 */
import { COUNTER_MANAGEMENT } from '../../consts/index'

/**
 *
 * @Function
 *
 */
import formatCurrency from "../../helpers/formatCurrency";
import { deleteCounter } from "../../api/counter";
import { getUserDataFromLCStorage } from "../../helpers/user";


export default function CounterDetail({ showCounterDetail, clickCloseCounterDetail, formValue }) {
  const history = useHistory();
  const [editForm, setEditForm] = useState(false);
  const closeEditForm = () => setEditForm(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [suc, setSuc] = useState(false)
  const [fail, setFail] = useState(false)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={showCounterDetail} onHide={clickCloseCounterDetail} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ລາຍລະອຽດ</Modal.Title>
        </Modal.Header>
        <Card style={{ padding: "20px 20px" }}>
          {getUserDataFromLCStorage()?.role === 'MANAGER' ? 
            <div className='d-flex gap-1 justify-content-end mb-3'>
              <SecondaryButton
                style={{ color: "#2E72D2" }}
                onClick={() => {
                  clickCloseCounterDetail();
                  setEditForm(true)
                  }}>
                <FontAwesomeIcon icon={faEdit} />
              </SecondaryButton>
              <SecondaryButton
                style={{ color: "#D82C0D" }}
                onClick={() => {
                  setConfirmDelete(true)
                  clickCloseCounterDetail()
                  }}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </SecondaryButton>
            </div>
            : <></>
          }
          <div style={{ padding: 10 }}>
            <Row className='mb-3'>
              <Col xs={6}>ເລກເຄົາເຕີ</Col>
              <Col xs={6} className='text-end'> {formValue?.counter_name}</Col>
              <hr />
            </Row>
            <Row className='mb-3'>
              <Col xs={6}> ຜູ້ດູແລເຄົາເຕີ</Col>
              <Col xs={6} className='text-end'>
                {formValue?.counter_owners?.map(e => (
                    <Form.Label style={{ display: 'block' }}>{`${e.first_name || "-"} ${e.last_name}`}</Form.Label>
                  ))}
              </Col>
              <hr />
            </Row>

            <Row className='mb-3'>
              <Col xs={6}>ວົງເງິນສູງສຸດ</Col>
              <Col xs={6} className='text-end'>{formatCurrency(formValue?.max_amount_money)} ກີບ</Col>
              <hr />
            </Row>

            <Row className='mb-3'>
              <Col xs={6}> ວົງເງິນຕໍ່າສຸດ</Col>
              <Col xs={6} className='text-end'> {formatCurrency(formValue?.min_amount_money)} ກີບ</Col>
              <hr />
            </Row>

            <Row className='mb-3'>
              <Col xs={6}> ປະເພດເຄົາເຕີ</Col>
              <Col xs={6} className='text-end'><p>{formValue.counter_type === "GET_MONEY" ? "ຮັບເງິນ" : "ຈ່າຍເງິນ"}</p> </Col>
              <hr />
            </Row>

            <Row className='mb-3'>
              <Col xs={6}> ວັນທີສ້າງ </Col>
              <Col xs={6} className='text-end d-flex flex-column'> {moment(formValue?.created_at).format("DD/MM/YYYY LT")}</Col>
              <hr />
            </Row>

            <Row className='mb-3'>
              <Col xs={6}> ວັນທີແກ້ໄຂ </Col>
              <Col xs={6} className='text-end'> {moment(formValue?.updated_at).format("DD/MM/YYYY LT")}</Col>
              <hr />
            </Row>
          </div>
        </Card>

      </Modal>
      <CounterEdit
          formValue={formValue}
          editForm={editForm}
          closeEditForm={closeEditForm}
        />
        <ConfirmDelete
          style={{ display: confirmDelete ? "flex" : "none" }}
          cancel={() => setConfirmDelete(false)}
          backClose={() => setConfirmDelete(false)}
          confirm={() => {
            deleteCounter(formValue?.id).then(e => {
              if (e.data === 'successfully delete') {
                handleShow();
              }
              if (e.error === false) {
                setTimeout(() => {
                  setSuc(false)
                  history.push(COUNTER_MANAGEMENT);
                  handleClose();
                }, 2000)
              } else setFail(true)
            })
          }}
        />
      <ModalBootstrap show={show} close={() => handleClose()} title="ລຶບເຄົາເຕີສຳເລັດ" />
    </>
  );
}

