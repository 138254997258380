import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
*/
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import styled from "styled-components";
import ReactLoading from "react-loading";
import moment from "moment";
import * as _ from 'lodash'
import axios from "axios";
import queryString from 'query-string';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
/**
 *
 * @Component
 *
 */
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Form, Row, Table, Tabs, Tab, Breadcrumb, Modal, Button } from "react-bootstrap";
import Status from "../../common/status";
import CustomSearch from "../../common/CustomSearch";
import PrimaryButton from "../../common/Button/PrimaryButton";
import SecondaryButton from "../../common/Button/SecondaryButton";
import EditCabinet from "./EditCabinet";
import ConfirmDelete from "../../common/popup/delete/ConfirmDelete";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
/**
 *
 * @API
 *
 */
import { deleteCabinet } from "../../api/cabinage";
import { SERVER_URL } from "../../api";
/**
 *
 * @Function
 *
 */
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";
import { selectCabinet, loadCabinetAsync } from "../../redux/cabinet";
import formatCurrency from "../../helpers/formatCurrency";
import PaginationHelper from "../../helpers/PaginationHelper";
import { formatCurrencyGold } from "../../helpers";


export default function CabinageDetail() {
  const { idCabinage } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  const parsed = queryString.parse(history?.location?.search);

  // state
  const [searchval, setSearchVal] = useState("");
  const [showCabinetEdit, setShowCabinetEdit] = useState(false);
  const handleShowEdit = () => setShowCabinetEdit(true);
  const handlesCloseEdit = () => setShowCabinetEdit(false);
  const [popConfirm, setPopConfirm] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalNumberOrder, setTotalNumberOrder] = useState(0);
  const [totalCanSell, setTotalCanSell] = useState(0);
  const [pattern, setPattern] = useState(parsed?.pattern ? parsed?.pattern : '');

  // global state
  const CabinetData = useSelector(selectCabinet);
  const CabinetOneData = CabinetData.data?.find((e) => e.id === idCabinage);

  const SearchVal = (val) => setSearchVal(val);

  const [patternData, setPatternData] = useState();
  const [tableData, setTableData] = useState("");
  const [filterName, setfilterName] = useState("");
  const [isLoading, setisLoading] = useState('');
  const [tableDataPDF ,setTableDataPDF] = useState('');

  useEffect(() => {
    getPattern();
    getCabinetProduct();
    getCabinetProductTotal()
  }, []);
  useEffect(() => {
    getCabinetProduct();
    getCabinetProductTotal()
  }, [pattern, filterName]);

  const getCabinetProduct = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      setisLoading(true)
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/cabinet-product?limit=${_limit}&skip=${(_skip - 1) * _limit}&name=${filterName}&pattern_id=${pattern}&cabinet=${idCabinage}&branch=${getUserDataFromLCStorage().branch_id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setTableData(data?.data);
        setisLoading(false)
        setTotal(data?.data?.total);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCabinetProduct());
      }
    }
  };
  const getCabinetProductTotal = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const dataTotal = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/cabinet-product?name=${filterName}&pattern_id=${pattern}&cabinet=${idCabinage}&branch=${getUserDataFromLCStorage().branch_id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (dataTotal.status === 200) {
        console.log('dataTotal: ', dataTotal?.data?.total_Amount);
        setTotalAmount(dataTotal?.data?.total_Amount)
        setTotalWeight(dataTotal?.data?.total_weight)
        
        const result = _.sumBy(dataTotal?.data?.data, 'numberOrder')
        setTotalNumberOrder(result)

        setTotalCanSell((dataTotal?.data?.total_Amount - (result ? result : 0)))
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCabinetProduct());
      }
    }
  };

  const getPattern = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/product-service/api/v1/pattern`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setPatternData(data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, pattern());
      }
    }
  };

  const exportToPDF = async () => {
    let _token = getAccessTokkenFromLCS();
    handleShow();
    try {
      setisLoading(true)
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/cabinet-product?limit=${100000000}&name=${filterName}&pattern_id=${pattern}&cabinet=${idCabinage}&branch=${getUserDataFromLCStorage().branch_id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setTableDataPDF(data?.data);
        setisLoading(false)
        // setTotal(data?.data?.total);
        // setTotalAmount(data?.data?.total_Amount)
        // setTotalWeight(data?.data?.total_weight)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {

    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Downld.pdf' + date + '.pdf');
    }
    )
  };

  // console.log('object', tableData);
 
  return (
    <div>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => history.push(consts.CABINAGE_MANAGEMENT + '/limit/40/skip/1')}>ຈັດການຕູ້</Breadcrumb.Item>
          <Breadcrumb.Item active href="#">
            {CabinetOneData?.cabinet_name || "-"}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Tabs defaultActiveKey="ສິນຄ້າໃນຕູ້" className="mb-3">
        <Tab eventKey="ລາຍລະອຽດຕູ້" title="ລາຍລະອຽດຕູ້">
          <TabBody>
            {getUserDataFromLCStorage()?.role === 'MANAGER' ?
              <div className="d-flex gap-1 justify-content-end mb-3">
                <SecondaryButton
                  onClick={() => handleShowEdit()}
                  style={{ color: "#2E72D2" }}>
                  <FontAwesomeIcon icon={faEdit} />
                </SecondaryButton>
                <SecondaryButton
                  style={{ color: "#D82C0D" }}
                  onClick={() => setPopConfirm(true)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </SecondaryButton>
              </div>
              : <></>
            }
            <Row className="justify-content-center">
              <Col xs={5}>
                <h4 style={{ fontWeight: 700 }}>
                  {CabinetOneData?.cabinet_name}
                </h4>
                <Hr />
                <div>
                  <Row>
                    <Col xs={6}>ປະຈໍາເຄົາເຕີ</Col>
                    <Col xs={6} className="text-end">
                      {CabinetOneData?.choose_counter}
                    </Col>
                  </Row>
                  <Hr />
                  <Row>
                    <Col xs={12}>ຜູ້ດູແລຕູ້</Col>
                    {CabinetOneData?.cabinet_owners?.map((e, i) => (
                      <Col key={i} xs={12} className="text-end">
                        {`${e.first_name} ${e.last_name}`}
                      </Col>
                    ))}
                  </Row>
                  <Hr />
                  <Row>
                    <Col xs={6}>ວັນທີສ້າງ</Col>
                    <Col xs={6} className="text-end">
                      {moment(CabinetOneData?.created_at).format(
                        "DD/MM/YYYY LT"
                      )}
                      <div>{CabinetOneData?.created_by_full_name || "-"}</div>
                    </Col>
                  </Row>
                  <Hr />
                  <Row>
                    <Col xs={6}>ວັນທີແກ້ໄຂ</Col>
                    <Col xs={6} className="text-end">
                      {moment(CabinetOneData?.updated_at).format(
                        "DD/MM/YYYY LT"
                      )}
                      <div>{CabinetOneData?.updated_by_full_name || "-"}</div>
                    </Col>
                  </Row>
                  <Hr />
                </div>
              </Col>
              <Col xs={1} style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    backgroundColor: "#960000",
                    width: 5,
                    height: "100%",
                    minHeight: "70vh",
                  }}
                />
              </Col>
              <Col xs={5}>
                <h4 style={{ fontWeight: 700 }}>ສາມາດເຮັດທຸລະກໍາ</h4>
                <Hr />
                <div>
                  <Row>
                    <Col xs={6}>ປະຈໍາເຄົາເຕີ</Col>
                    <Col xs={6} className="text-end">
                      ບໍ່ມີ
                    </Col>
                  </Row>
                  <Hr />
                </div>
              </Col>
            </Row>
          </TabBody>
        </Tab>
        <Tab eventKey="ສິນຄ້າໃນຕູ້" title="ສິນຄ້າໃນຕູ້">
          <TabBody>
            <Card style={{ minHeight: "80vh", paddingBottom: 100 }}>
              <Row className="mb-5">
                <Col>
                  <Row>
                    <Col className="dashboard-grid">
                      <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                        <span className="dashboard-text-darkblue">ຈຳນວນຢ່າງທີ່ຍັງເຫຼືອ</span>
                        <span className="dashboard-text-all-total dashboard-text-orange">{totalAmount ? totalAmount : 0}</span>
                      </div>
                    </Col>
                    <Col className="dashboard-grid">
                      <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                        <span className="dashboard-text-darkblue">ນ້ຳໜັກຄຳລວມ</span>
                        <span className="dashboard-text-all-total dashboard-text-orange">{totalWeight ? totalWeight : 0}</span>
                      </div>
                    </Col>
                    <Col className="dashboard-grid">
                      <div className="dashboard-container d-flex-center-space-around-column dashboard-bg-orange">
                        <span className="dashboard-text-darkblue">ຈຳນວນຄຳທີ່ຈອງ</span>
                        <span className="dashboard-text-all-total dashboard-text-orange">{totalNumberOrder ? totalNumberOrder : 0}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <div className="mb-3 d-flex justify-content-end">
                    <PrimaryButton
                      onClick={exportToPDF} id="format-PDF"
                    >
                      Export
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() => history.push(`${location.pathname}/add`)} style={{marginLeft: 10}}
                    >
                      + ເພີ່ມສິນຄ້າເຂົ້າຕູ້
                    </PrimaryButton>
                  </div>
                </Col>
              </Row>
              <div className="mb-3">
                <CustomSearch
                  value={SearchVal}
                  placeholder={"ຄົ້ນຫາຕາມຊື່ສິນຄ້າ"}
                  onChange={(e) => setfilterName(e.target.value)}
                />
              </div>
              <Row>
                {/* <Col md={3}>
                  <Form.Group controlId='doj'>
                    <Form.Label>ໝວດຄໍາ</Form.Label>
                    <Form.Select 
                      value={goldType}
                      onChange={e=>setGoldType(e.target.value)}
                    >
                      <option value="">ຕົວເລືອກ</option>
                      {
                        cabinetProductData?.map((e,i)=>(
                          <option key={i} value={e?.gold_type}>{e?.gold_type}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>
                </Col> */}
                <Col md={3}>
                  <Form.Group controlId="doj">
                    <Form.Label>ລວດລາຍ</Form.Label>
                    <Form.Select
                      value={pattern}
                      onChange={(e) => setPattern(e.target.value)}
                    >
                      <option value="">ຕົວເລືອກ</option>
                      {patternData?.data?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                {/* <Col md={9}>
                  <Form.Group controlId='doj'>
                    <Form.Label>ສະຖານະ</Form.Label>
                    <Form.Select
                      value={status}
                      onChange={e=>setStatus(e.target.value)}
                    >
                    <option value="">ຕົວເລືອກ</option>
                    <option value="true">ເປີດໃຊ້ງານ</option>
                    <option value="false">ປິດໃຊ້ງານ</option>
                    </Form.Select>
                  </Form.Group>
                </Col> */}
              </Row>
              {
                isLoading ? (
                  <div className="d-flex justify-content-center mt-4">
                    <div className="mt-5">
                      <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} /> <br />
                      <span>Loading....</span>
                    </div>
                  </div>
                ) : tableData?.data == null ? (
                  <div>
                    <div>
                      <img style={{ marginLeft: "40%", marginTop: "10%" }} src='/asset/image/animation_200_ldu129fc.gif' />
                      <p style={{ marginLeft: "45%", marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
                    </div>
                  </div>
                ) :
                  (
                    <>
                      <Table className="table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>ຮູບພາບ</th>
                            <th>ລະຫັດສິນຄ້າ</th>
                            <th>ຊື່ສິນຄ້າ</th>
                            <th>ລວດລາຍ</th>
                            <th>ນໍ້າໜັກຄໍາ</th>
                            <th>ຄ້າງໃນຕູ້</th>
                            <th>ຈຳນວນທີ່ສາມາດຂາຍໄດ້</th>
                            <th>ຈຳນວນທີ່ຈອງໄວ້</th>
                            <th>ນໍ້າໜັກທັງໝົດ</th>
                            <th>ສະຖານະ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.data?.map((item, index) => (
                            <tr style={{ cursor: "pointer" }} className="align-middle"
                              onClick={() => history.push(`${consts.CABINAGE_MANAGEMENT}/detail/${idCabinage}/product/${item?.id}`, item)}
                              key={index}
                            >
                              <td>{index + 1 + _limit * (_skip - 1)}</td>
                              <td><img src={consts.S3 + item?.image?.[0] ?? '/asset/image/image-2-64.ico'} alt="" style={{ width: 80 }} /></td>
                              <td>{item?.product_code}</td>
                              <td>{item?.name}</td>
                              <td>{item?.branch_product?.pattern_name || "-"}</td>
                              <td>{formatCurrencyGold(item?.weight?.[0].gram) || "-"} g</td>
                              <td>{item?.weight?.[0].amount || "0"}</td>
                              <td>{item?.weight?.[0].amount ? (item?.weight?.[0].amount - item?.numberOrder) : "0"}</td>
                              <td>{item?.numberOrder || "0"}</td>
                              <td>{formatCurrencyGold(item?.total_weight_product) || "0"} g</td>
                              {/* <td>{item?.total_weight_product || "0"} g</td> */}
                              <td><Status status={true} /></td>
                            </tr>
                          ))}
                          <tr className="fw-bold">
                            <td colSpan={6}>ລວມ</td>
                            <td>{formatCurrency(totalAmount)}</td>
                            <td>{formatCurrency(totalCanSell)}</td>
                            <td>{formatCurrency(totalNumberOrder)}</td>
                            <td>{totalWeight} g</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  )
              }

              {Pagination_helper(total, consts.CABINAGE_MANAGEMENT + '/detail/' + idCabinage, `pattern=${pattern}`)}
            </Card>
          </TabBody>
        </Tab>
      </Tabs>

      <EditCabinet showCabinetEdit={showCabinetEdit} handlesCloseEdit={handlesCloseEdit} idCabinet={idCabinage} />
      <ConfirmDelete
        style={{ display: popConfirm ? "flex" : "none" }}
        cancel={() => setPopConfirm(false)}
        confirm={() => {
          deleteCabinet(idCabinage).then(() => {
            setPopConfirm(false);
            dispatch(loadCabinetAsync());
            history.replace(consts.CABINAGE_MANAGEMENT + '/limit/40/skip/1');
          });
        }}
      />
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className='buttonRedColor'
              style={{ width: "125px", height: "51px" }}
              onClick={handleDownloadPdf}
            >
              Export PDF
            </Button>
          </div>
          <div className="" ref={printRef}>
          <Table className="table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>ຮູບພາບ</th>
                            <th>ລະຫັດສິນຄ້າ</th>
                            <th>ຊື່ສິນຄ້າ</th>
                            <th>ລວດລາຍ</th>
                            <th>ນໍ້າໜັກຄໍາ</th>
                            <th>ຄ້າງໃນຕູ້</th>
                            <th>ນໍ້າໜັກທັງໝົດ</th>
                            <th>ສະຖານະ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableDataPDF?.data?.map((item, index) => (
                            <tr style={{ cursor: "pointer" }} className="align-middle"
                              onClick={() => history.push(`${consts.CABINAGE_MANAGEMENT}/detail/${idCabinage}/product/${item?.id}`, item)}
                              key={index}
                            >
                              <td>{index + 1 }</td>
                              <td><img src={consts.S3 + item?.image?.[0] ?? '/asset/image/image-2-64.ico'} alt="" style={{ width: 80 }} /></td>
                              <td>{item?.product_code}</td>
                              <td>{item?.name}</td>
                              <td>{item?.branch_product?.pattern_name || "-"}</td>
                              <td>{formatCurrencyGold(item?.weight?.[0].gram) || "-"} g</td>
                              <td>{(item?.weight?.[0].amount) || "0"}</td>
                              <td>{formatCurrencyGold(item?.total_weight_product) || "0"} g</td>
                              <td><Status status={true} /></td>
                            </tr>
                          ))}
                          <tr className="fw-bold">
                            <td colSpan={6}>ລວມ</td>
                            <td>{formatCurrency(tableDataPDF?.total_Amount)}</td>
                            <td>{formatCurrencyGold(tableDataPDF?.total_weight)} g</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
const TabBody = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-top: -16px;
  min-height: 80vh;
`;
const Hr = styled.hr`
  margin: "5px 0";
`;
