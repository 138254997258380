import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
 */
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
/**
 *
 * @Component
 *
 */
import { Card, Col, Form, Row, Button, Breadcrumb, Modal } from "react-bootstrap";
import CustomList from "../../common/CustomList";
import Warning from '../../common/popup/fail/Warning'
import Approve from "../../common/popup/approve/ConfirmApprove";
import ModalBootstrap from "../../common/popup/success/ModalBootstrap";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
/**
 *
 * @API
 *
 */
import { SERVER_URL } from "../../api";
/**
 *
 * @Function
 *
 */
// import { selectStock } from "../../redux/stock";
import { selectCabinet } from "../../redux/cabinet";
import { selectCabinetProduct } from "../../redux/cabinet_product";
import { selectProduct } from "../../redux/product";
import formatCurrency from "../../helpers/formatCurrency";
import { getAccessTokkenFromLCS, getReNewToken } from "../../helpers/user";
import { convertGender, formatCurrencyGold } from "../../helpers";
import { addCabinetProduct } from "../../api/cabinage";
import PrimaryButton from "../../common/Button/PrimaryButton";
import Swal from "sweetalert2";
// import {AiFillPlusCircle} from 'react-icons/ai'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function Detail() {
  const { idCabinage } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [dataBranchProduct, setDataBranchProduct] = useState({})
  const [dataProduct, setDataProduct] = useState({})
  const [dataStock, setDataStock] = useState({})
  // global state
  const CabinetData = useSelector(selectCabinet);
  const cabinedProductData = useSelector(selectCabinetProduct);
  const ProductData = useSelector(selectProduct);
  const [titleValidation, setTitleValidation] = useState('');
  const [CabinetOneData, setCabinetOneData] = useState({})

  const [disabledAdd, setDisabledAdd] = useState(false);
  const [movedData, setMovedData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [popupConform, setPopupConfirm] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [showAddStock, setShowAddStock] = useState(false);
  const [goldAmount, setGoldAmount] = useState(null);

  const handleCloseMoveData = () => setMovedData(false);
  const handleOpenMoveData = () => setMovedData(true);

  const handleCloseModalAdd = () => setShowAddStock(false)
  const handleCloseModal = () => setShowModal(false)
  const [amountInput, setAmountInput] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    getProduct()
  }, [dataBranchProduct])

  useEffect(() => {
    setDataBranchProduct(location?.state)
    getProduct()
    getStock()
  }, [location])

  useEffect(() => {
    setCabinetOneData(CabinetData?.data?.find((e) => e.id === idCabinage))
  }, [CabinetData, cabinedProductData, ProductData])

  const getStock = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/branch-product/${location?.state?.branch_product?.id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setDataStock(data?.data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getStock());
      }
    }
  };

  const getProduct = async () => {
    let _token = getAccessTokkenFromLCS();
    // console.log('_token: ', _token);

    try {
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/cabinet-product?cabinet=${idCabinage}&product_id=${dataBranchProduct?.branch_product?.product_id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setDataProduct(data?.data?.data[0])
        // console.log('data?.data?.data[0]: ', data?.data?.data[0]);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getProduct());
      }
    }
  };
  const delayToClose = () => {
    setPopupSuccess(true);
    setTimeout(() => {
      setPopupSuccess(false);
    }, 3000);
  };

  const handleChangeAmount = (e) => {
    if (dataStock?.stock_amount === 0) {
      setDisabledAdd(true)
      setTitleValidation('ສິນຄ້າຄ້າງໃນສະຕ໊ອກເຫຼືອ 0')
      setShowModal(true)
      setTimeout(() => {
        setShowModal(false)
      }, 1500);
    } else if (dataStock?.stock_amount < e) {
      setDisabledAdd(true)
      setTitleValidation(`ສິນຄ້າຄ້າງໃນສະຕ໊ອກເຫຼືອ ${dataStock?.stock_amount}`)
      setShowModal(true)
      setTimeout(() => {
        setShowModal(false)
      }, 1500);
    } else {
      setDisabledAdd(false)
      setAmountInput(e)
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
  
    if (!isNaN(inputValue) && inputValue !== '-1' && inputValue !== '0') {
      setGoldAmount(inputValue);
      setError(false);
    } else {
      setError(true);
    }
  };

  const movedDataBrokenProduct = async () => {
    let _token = getAccessTokkenFromLCS();
    let dataLocal = localStorage.getItem('KPV_MANAGEMENT')
    const branchData = JSON.parse(dataLocal)
    
    try {
      if (parseFloat(goldAmount) <= String(dataBranchProduct?.branch_product?.cabinet_amount)) {
        const data = {
          product_id:dataBranchProduct?.product_id,
          from_branch:dataBranchProduct?.branch,
          from_branch_name:branchData?.data?.user?.branch_name,
          from_cabinet: dataBranchProduct?.cabinet,
          from_cabinet_name: CabinetOneData?.cabinet_name,
          amount_take_off:parseFloat(goldAmount),
          gold_status: "DAMAGED_GOLD"
          
        }
        // console.log('data',data);
        setError(false)
        // alert('=====')
        await axios.post(`${SERVER_URL}/stock-service/api/v1/take-off`, data,
          {
            headers: {
              Authorization: _token
            }
          })
          .then((res) => {
            // console.log(res.data);
            Swal.fire({
              icon: 'success',
              text: 'ນຳເຂົ້າປະຫວັດຄຳເພສຳເລັດແລ້ວ',
            })
            handleCloseMoveData();
            history.goBack();
          
          })
      } else {
        setError(true)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleAddProductToCabinage = (event) => {
    const data = {
      cabinet: idCabinage,
      name: dataStock?.name,
      product_id: dataStock?.product_id,
      product_code: dataStock?.product_code,
      image: dataStock?.image,
      amount: amountInput,
      pattern_id: dataStock?.pattern_id,
      gender: dataStock?.gender,
      goldType: dataStock?.goldType,
      product_type_id: dataStock?.product_type_id,
      branch_product: dataStock?.branch_product,
      weight: [{ name: dataStock?.name, gram: dataStock?.weight?.gram, amount: parseInt(amountInput)}],
    }

    if (data?.amount === 0) {
      setShowModal(true)
    } else {
      addCabinetProduct(data).then((e) => {
        if (e.error === false) {
          console.log("{VVVVVß")
          delayToClose()
          getStock()
          getProduct()
        } 
        
      });
    };
  }
  // console.log("idCabinage====>",idCabinage);
  return (
    <div>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" onClick={() => history.push(consts.CABINAGE_MANAGEMENT+"/limit/40/skip/1")}>ຈັດການຕູ້</Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => history.push(consts.CABINAGE_MANAGEMENT + "/detail/" + idCabinage)}>
            {CabinetOneData?.cabinet_name || "-"}
          </Breadcrumb.Item>
          <Breadcrumb.Item active> ລາຍລະອຽດສິນຄ້າໃນ {CabinetOneData?.cabinet_name || "-"} </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Card style={{ padding: 20 }}>
        <Row>
          <Col md={6}>
            <h2>ລາຍລະອຽດ</h2>
          </Col>
          <Col md={6} className='d-flex justify-content-end' style={{marginBottom:10}}>
            <PrimaryButton
                onClick={()=> handleOpenMoveData()}
              >
               + ນຳເຂັ້າປະຫວັດຄຳເພ
              </PrimaryButton>
          </Col>
        </Row>
        <Hr className='mb-3' />
        <Row className='g-5 mb-3'>
          <CustomCol md={7}>
            <CustomList
              value={[
                {
                  title: "ລະຫັດສິນຄ້າ",
                  value: `${dataBranchProduct?.product_code || "-"}`,
                },
                { title: "ຊື່ສິນຄ້າ", value: `${dataBranchProduct?.name || "-"}` },
                {
                  title: "ໝວດສິນຄ້າ",
                  value: `${dataProduct?.branch_product?.product_type_name || "-"}`,
                },
                { title: "ເພດ", value: `${convertGender(dataBranchProduct?.gender) || "-"}` },
                {
                  title: "ລາຍ",
                  value: `${dataProduct?.branch_product?.pattern_name || "-"}`,
                },
                {
                  title: "ບ່ອນຜະລິດ",
                  value: `${dataProduct?.branch_product?.place_production || "-"}`,
                },
                {
                  title: "ຄ່າແຮງຫນ້າຮ້ານ (ກີບ)",
                  value: `${formatCurrency(
                    dataProduct?.store_wage_kip || 0
                  )} ກີບ`,
                },
                {
                  title: "ຄ່າແຮງໂຮງງານ (ກີບ)",
                  value: `${formatCurrency(
                    dataProduct?.factory_wage_kip || 0
                  )} ກີບ`,
                },
                {
                  title: "ຄ່າແຮງເຄື່ອງສົ່ງ (ກີບ)",
                  value: `${formatCurrency(
                    dataProduct?.transmitter_wage_kip || 0
                  )} ກີບ`,
                },
                {
                  title: "ຄ່າແຮງທົ່ວໄປ (ກີບ)",
                  value: `${formatCurrency(
                    dataProduct?.sale_wage_kip || 0
                  )} ກີບ`,
                },
                {
                  title: "ສະຖານະ",
                  value: dataProduct?.status || false,
                },
                {
                  title: "ລາຍລະອຽດສິນຄ້າ (ສະແດງທີແອັບອອມຄຳ)",
                  values: `${dataProduct?.details || "-"}`,
                },
                { title: "ໝາຍເຫດ", values: `${dataProduct?.note || "-"}` },
              ]}
            />
            <CustomList
              value={[
                {
                  title: "ສ້າງໂດຍ",
                  value: [
                    `${dataProduct?.created_by_full_name || "-"}`,
                    moment(dataBranchProduct?.created_at).format("DD/MM/YYYY LT"),
                  ],
                },
                {
                  title: "ອັບເດດໂດຍ",
                  value: [
                    `${dataProduct?.updated_by_full_name || "-"}`,
                    moment(dataBranchProduct?.updated_at).format("DD/MM/YYYY LT"),
                  ],
                },
              ]}
            />
          </CustomCol>
          <CustomCol md={5}>
            <Form.Group className='mb-3'>
              <Form.Label>
                ຮູບພາບ ({dataBranchProduct?.image?.length || 0}/10)
              </Form.Label>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridGap: 5,
                }}>
                {[
                  ...(dataBranchProduct?.image?.[0] ? dataBranchProduct?.image : []),
                ]?.map((e, i) => (
                  <Card
                    style={{
                      aspectRatio: "1/1",
                      padding: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #BDBDBD",
                    }}
                    key={i}>
                    <img
                      src={`${consts.S3}${e}`}
                      alt=''
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Card>
                ))}
              </div>
            </Form.Group>
            <Card
              className='px-4'
              style={{ border: `1px solid #ccc` }}>
              <table>
                <tr style={{ borderBottom: "1px solid #ccc" }}>
                  <th style={{ width: "auto" }}>ລຳດັບ</th>
                  <th>ນໍ້າໜັກຄໍາ</th>
                  <th>ຄ້າງໃນຕູ້</th>
                </tr>
                {dataProduct?.weight?.map((e, i) => (
                  <tr style={{ borderBottom: "1px solid #ccc" }}>
                    <td> 0{i + 1}</td>
                    <td>{formatCurrencyGold(e.gram) ?? "0"} g</td>
                    <td>{e.amount ?? "0"}</td>
                  </tr>
                ))
                }

              </table>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
                <Button 
                onClick={() => setShowAddStock(true)} 
                className="btn-add-stock-to-cabined">
                  + ເພີ່ມ
                </Button>
              </div>
            </Card>
          </CustomCol>
        </Row>
      </Card>

      <Warning title={titleValidation} show={showModal} close={handleCloseModal} />
      <Modal show={movedData} onHide={handleCloseMoveData} centered>
      <Card style={{ width: "692px", padding: "0px 15px 0px 15px"}}>
      <Modal.Header closeButton>
          <Modal.Title>ຍ້າຍຂໍ້ມູນເຂົ້າປະຫວັດສິນຄ້າເພ</Modal.Title>
        </Modal.Header>
          <Modal.Body >
            {dataProduct?.weight?.map((e, i) => (
            <div  key={i}>
                <div style={{ display: "flex", alignItems: "center", height: 50 }} >
                  <div style={{ padding: "0 20px" }}>{i + 1}</div>
                  <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #DFE2E9", height: "100%", paddingRight: 20 }}>
                <b>{ dataProduct?.name }</b>
                    <span> <span >ນໍ້າຫນັກ</span> <span className="text-bold">{e?.gram} </span> g</span>
                  </div>
                  <div>
                    <br/>
                <Form.Group className='mb-3'>
                  <input type="text"
                    value={goldAmount}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="ປ້ອນຈຳນວນ" />
                  </Form.Group>
              </div>
                </div>
              {error == true ? <><p className="text-danger d-flex justify-content-end">ປ້ອນຈຳນວນໃຫ້ໜ້ອຍກ່ວາຈຳນວນໃນຕູ້</p></> : <></>} 
                </div>
              ))}
            </Modal.Body>
        <Modal.Footer>
        <div className='d-flex justify-content-end' style={{ margin: "20px 0" }}>
              <button style={{ width: "217px", height: "51px" }} className='buttonRedColor'
                // disabled={true}
                // disabled={ parseFloat(goldAmount) <= dataBranchProduct?.branch_product?.cabinet_amount ? true : false}
              onClick={() => {
                movedDataBrokenProduct()
              }}
              >
              + ເພີ່ມສິນຄ້າເຂົ້າປະຫວັດຄຳເພ
            </button>
            </div>
          </Modal.Footer>
          </Card>
        </Modal>
        
      <Modal show={showAddStock} onHide={handleCloseModalAdd} centered>
        
        <Card style={{ width: "692px", padding: "0px 15px 0px 15px"}}>
        <Modal.Header closeButton>
          <Modal.Title>ເພີ່ມສິນຄ້າເຂົ້າຕູ້</Modal.Title>
        </Modal.Header>

          <div style={{ border: "1px solid #D1D5DB", padding: 10, marginTop: "20px",}}>
            {dataProduct?.weight?.map((e, i) => (
                <div key={i} style={{ display: "flex", alignItems: "center", height: 50 }} >
                  <div style={{ padding: "0 20px" }}>{i + 1}</div>
                  <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #DFE2E9", height: "100%", paddingRight: 20 }}>
                    <b>ນໍ້າຫນັກ</b>
                    <span>{e?.gram}</span>
                  </div>
                  <div>
                    <br/>
                    <Form.Group className='mb-3'>
                    <Form.Control type='number' placeholder="ປ້ອນຈຳນວນ"
                    onChange={(e) =>{
                      setAmountInput(parseInt(e.target.value))
                      handleChangeAmount(e.target.value)
                    }} />
                  </Form.Group>
                  </div>
                </div>
              ))}
          </div>
          <div className='d-flex justify-content-end' style={{ margin: "20px 0" }}>
            <button style={{ width: "217px", height: "51px" }} className='buttonRedColor' disabled={disabledAdd !== false ? true : false}
              onClick={() => {
                disabledAdd === false ? setPopupConfirm(true) : setPopupConfirm(false) 
                handleCloseModalAdd()
              }}
              >
              + ເພີ່ມສິນຄ້າເຂົ້າຕູ້
            </button>
          </div>
        </Card>
      </Modal>

      <div className='fixed'
        style={{ display: popupConform === true ? "block" : "none" }}>
        <Approve
          title="ທ່ານຕ້ອງການເພີ່ມຈຳນວນຄຳແທ້ ຫຼື ບໍ່?"
          cancel={() => setPopupConfirm(false)}
          confirm={() => {
            setPopupConfirm(false);
            handleAddProductToCabinage();
          }}
        />
      </div>

      <ModalBootstrap
        title='ເພີ່ມສິນຄ້າເຂົ້າຕູ້ສຳເລັດ'
        show={popupSuccess}
        close={popupSuccess}
      />
    </div>
  );
}
const CustomCol = styled(Col)({
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

const Hr = styled.hr`
  margin: 5px 0;
`;