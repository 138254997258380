import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from 'yup';

import { faUser, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Logo from "../../image/logo.svg";
import PrimaryBottom from "../../common/Button/PrimaryButton";

import { signin } from "../../api/auth";
import useScriptRef from "../../hooks/useScriptRef";
let versionApp = require("../../../package.json");

// ----------------------------------------------------------------- //
export default function Login() {
  const scriptedRef = useScriptRef();
  const History = useHistory();
  const [eye, setEye] = useState(true);
  const [valid, setValid] = useState(false);

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    username: Yup.string().required('ກະລຸນາປ້ອນບັນຊີ!'),
    password: Yup.string().required('ກະລຸນາປ້ອນລະຫັດຜ່ານ!'),
  });
  return (
    <RootStyle>
      <Container>
        <div style={{ display: "flex", justifyContent: "center", padding: 30 }}>
          <img src={Logo} alt="login" />
        </div>
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          validationSchema={DisplayingErrorMessagesSchema}
          validate={(values) => {
            if (values.username === '' || values.password === '')
              setValid(false);
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (values.username && values.password) {
                const { username, password } = values;
                const _resData = await signin({ username, password });
                if (_resData?.data?.accessToken) {
                  if(_resData?.data?.user?.role === 'MANAGER') {
                    History.replace("/report/limit/40/skip/1");
                  }else {
                    History.replace("/counter-management/limit/40/skip/1");
                  }
                } else {
                  setValid(true);
                }
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.username) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <div>
              <FormLogin>
                <h2
                  style={{
                    color: "#960000",
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: 36,
                  }}
                >
                  ເຂົ້າສູ່ລະບົບ Manager
                </h2>
                <Form.Group className="mb-3">
                  <CustomLabel htmlFor="username">
                    ຊື່ບັນຊີ<span style={{ color: "red" }}>*</span>
                  </CustomLabel>
                  <div style={{ position: "relative" }}>
                    <CustomInput
                      id="username"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="ກະລຸນາປ້ອນ"
                      value={values.username}
                      style={{
                        paddingLeft: 30,
                        borderColor: errors.username ? "red" : "#d1d5db",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ position: "absolute", top: 12, left: 10 }}
                    />
                  </div>
                  {touched.username && errors.username && <div className="text-danger">{errors.username}</div>}

                </Form.Group>
                <Form.Group className="mb-3">
                  <CustomLabel htmlFor="password">
                    ລະຫັດຜ່ານ<span style={{ color: "red" }}>*</span>
                  </CustomLabel>
                  <div style={{ position: "relative" }}>
                    <CustomInput
                      type={eye ? "password" : "text"}
                      id="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
                      value={values.password}
                      style={{
                        borderColor: errors?.password ? "red" : "#d1d5db",
                        paddingLeft: 10,
                      }}
                    />
                    <FontAwesomeIcon
                      icon={eye ? faEyeSlash : faEye}
                      style={{
                        position: "absolute",
                        top: 12,
                        right: 12,
                        cursor: "pointer",
                      }}
                      onClick={() => setEye((prev) => !prev)}
                    />
                  </div>
                  {touched.password && errors.password && <div className="text-danger">{errors.password}</div>}

                  <div style={{ display: valid ? "block" : "none" }} className="text-danger">
                    ບັນຊີ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ!
                  </div>
                </Form.Group>
                <PrimaryBottom
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  style={{ marginTop: 40, width: "100%" }}
                >
                  ເຂົ້າສູ່ລະບົບ
                </PrimaryBottom>
              </FormLogin>
              <div style={{ fontSize: 12, color: "grey" }}>
                {versionApp?.version}
              </div>
            </div>
          )}
        </Formik>
      </Container>
    </RootStyle>
  );
}

const RootStyle = styled.div`
  background-color: "#fff";
`;
const Container = styled.div`
  width: 600px;
  margin: 0 auto;
`;
const FormLogin = styled.div`
  background-color: #f0f1f3;
  width: 625px;
  display: flex;
  padding: 40px;
  flex-direction: column;
  border-radius: 2px;
`;
const CustomInput = styled.input`
  border: 1px solid #d1d5db;
  border-radius: 2px;
  width: 100%;
  height: 40px;
`;
const CustomLabel = styled.label`
  font-size: 14px;
  font-weight: 700;
`;
