import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function ExpensesSkeleton() {
  return (
    <Card style={{ padding: 20 }} className="mb-2 placeholder-glow">
      <Row style={{ height: 150 }}>
        <Col className="dashboard-grid">
          <div className="dashboard-container dashboard-bg-lightblue">
            <div className="h-50 p-1">
              <span className="placeholder col-12 h-100"></span>
            </div>
            <div className="h-50 p-1">
              <span className="placeholder col-12 h-100"></span>
            </div>
          </div>
        </Col>
        <Col className="dashboard-grid">
          <div className="dashboard-container dashboard-bg-red">
            <div className="h-50 p-1">
              <span className="placeholder col-12 h-100"></span>
            </div>
            <div className="h-50 p-1">
              <span className="placeholder col-12 h-100"></span>
            </div>
          </div>
        </Col>
        <Col className="dashboard-grid">
          <div className="dashboard-container dashboard-bg-orange">
            <div className="h-50 p-1">
              <span className="placeholder col-12 h-100"></span>
            </div>
            <div className="h-50 p-1">
              <span className="placeholder col-12 h-100"></span>
            </div>
          </div>
        </Col>
        <Col className="dashboard-grid">
          <div className="dashboard-container dashboard-bg-orange">
            <div className="h-50 p-1">
              <span className="placeholder col-12 h-100"></span>
            </div>
            <div className="h-50 p-1">
              <span className="placeholder col-12 h-100"></span>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
