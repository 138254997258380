import { createSlice } from "@reduxjs/toolkit";
import { getCounter } from "../../api/counter";
import { getUserDataFromLCStorage } from "../../helpers/user";
// --------------------------------------------------------------------- //
export const counterData = createSlice({
  name: "counter",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    setCounter: (state, action) => {
      state.data = action.payload;
    },
    addCounter: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addCounter, setCounter } = counterData.actions;

export const loadCounterAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const {data} = await getCounter();
    dispatch(
      setCounter(data)
    );
  } catch {
    console.error("load counter has fail");
  }
  dispatch(setLoading(false));
};

export const selectCounter = (state) => state.counter;

export default counterData.reducer;

