import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
 */
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as _ from 'lodash'
import axios from "axios";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactLoading from 'react-loading';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
/**
 *
 * @Component
 *
 */
import { Card, Row, Col, Form, Table, Breadcrumb, InputGroup } from "react-bootstrap";
// import CustomSearch from "../../common/CustomSearch";
import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import { faBoxes, faSearch } from "@fortawesome/free-solid-svg-icons";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
/**
 *
 * @API
 *
 */
import { SERVER_URL } from "../../api";
/**
 *
 * @Function
 *
 */
import { selectCategory } from "../../redux/category_data";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";
import PaginationHelper from "../../helpers/PaginationHelper";
import { formatCurrency, formatCurrencyGold } from "../../helpers";

export default function CustomerManagement() {
  const History = useHistory();
  const parsed = queryString.parse(History?.location?.search);
  const categoryData = useSelector(selectCategory);
  const [patternData, setPatternData] = useState();
  const [stockTotal, setStockTotal] = useState(0);
  const [dataStock, setDataStock] = useState([]);
  const [dataStockPDF, setDataStockPDF] = useState([]);
  const [totalStockWeight, setStockWeight] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [cabinetAmount, setCabinetAmount] = useState(0)
  const [remainAmount, setRemainAmount] = useState(0)
  const [weightTotal, setWeightTotal] = useState(0)
  // data
  const [filterNameData, setFilterNameData] = useState(parsed?.name ? parsed?.name : '');
  const [filterWeightData, setFilterWeightData] = useState(parsed?.weight ? parsed?.weight : '');
  const [filterCategoryData, setFilterCategoryData] = useState(parsed?.product_type_id ? parsed?.product_type_id : '');
  const [filterPatternData, setFilterPatternData] = useState(parsed?.pattern_id ? parsed?.pattern_id : '');
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  const [isLoading, setIsLoading] = useState(false)

  // ---------

  useEffect(() => {
    pattern();
    getStock();
  }, []);

  console.log('stock', dataStock);

  useEffect(() => {
    getStock();
  }, [filterCategoryData, filterPatternData, filterNameData, filterWeightData]);

  const getStock = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/branch-product?limit=${_limit}&skip=${(_skip - 1) * _limit}&product_type_id=${filterCategoryData}&pattern_id=${filterPatternData}&name=${filterNameData}&gram=${filterWeightData}&branch=${getUserDataFromLCStorage().branch_id
        }`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setStockTotal(data?.data?.total)
        setDataStock(data?.data);
        const result = _.sumBy(data?.data?.data, 'stock_amount')
        const resultTotalAmount = _.sumBy(data?.data?.data, 'amount')
        const resultCabinet_amount = _.sumBy(data?.data?.data, 'cabinet_amount')
        const resultRemain_amount = _.sumBy(data?.data?.data, 'remain_amount')
        
        setStockWeight(result)
        setTotalAmount(resultTotalAmount)
        setCabinetAmount(resultCabinet_amount)
        setRemainAmount(resultRemain_amount)
        let sumWeight = 0
        for (let i = 0; i < data?.data?.data?.length; i++) {
          sumWeight = data?.data?.data[i]?.weight[0]?.gram
        }
        setWeightTotal(sumWeight)
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getStock());
      }
    }
  };

  const getStockToPDF = async () => {
    handleShow();
    let _token = getAccessTokkenFromLCS();
    try {
      setIsLoading(true)
      const data = await axios.get(
        `${SERVER_URL}/stock-service/api/v1/branch-product?limit=${100000}&product_type_id=${filterCategoryData}&pattern_id=${filterPatternData}&name=${filterNameData}&gram=${filterWeightData}&branch=${getUserDataFromLCStorage().branch_id
        }`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        // setStockTotal(data?.data?.total)
        setDataStockPDF(data?.data);
        setIsLoading(false)
        const result = _.sumBy(data?.data?.data, 'stock_amount')
        const resultTotalAmount = _.sumBy(data?.data?.data, 'amount')
        const resultCabinet_amount = _.sumBy(data?.data?.data, 'cabinet_amount')
        const resultRemain_amount = _.sumBy(data?.data?.data, 'remain_amount')
        setStockWeight(result)
        setTotalAmount(resultTotalAmount)
        setCabinetAmount(resultCabinet_amount)
        setRemainAmount(resultRemain_amount)
        console.log('stock data to pdf', data?.data);
        let sumWeight = 0
        for (let i = 0; i < data?.data?.data?.length; i++) {
          sumWeight = data?.data?.data[i]?.weight[0]?.gram
        }
        setWeightTotal(sumWeight)
        console.log('sum weight', sumWeight);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const pattern = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      const data = await axios.get(
        `${SERVER_URL}/product-service/api/v1/pattern`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );

      if (data.status === 200) {
        setPatternData(data?.data);
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, pattern());
      }
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Downld.pdf' + date + '.pdf');
    }
    )
  };

  return (
    <div>
      {/* <div style={{ paddingTop: 12, marginBottom: -12 }}>
        <Breadcrumb>
          <Breadcrumb.Item href="#" active>ຈັດການສະຕັອກ</Breadcrumb.Item>
        </Breadcrumb>
      </div> */}
      <CustomBreadcrumbs value={[{ title: "" }]} />
      <Card style={{ border: "none", margin: "2px 0" }} body>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mt-2">
            <span>
              <FontAwesomeIcon
                style={{ width: "23px", height: "23px", margin: "0 5px" }}
                icon={faBoxes}
              />
            </span>
            ສະຕ໊ອກທັງໝົດ
          </h4>
        </div>
      </Card>

      <Card style={{ padding: "20px", paddingTop: "10px", border: "none" }}>
        {/* <div style={{ display: "flex", gap: 150 }}>
          <CustomSearch
            value={filterNameData}
            placeholder={"ຄົ້ນຫາຊື່ສິນຄ້າ"}
            onChange={(e) => setFilterNameData(e.target.value)}
          />
          <CustomSearch
            value={filterWeightData}
            placeholder={"ຄົ້ນຫານ້ຳຫນັກສິນຄ້າ"}
            onChange={(e) => setFilterWeightData(e.target.value)}
          />
        </div> */}
        <Row>
          <Col md={6}>
            <Form.Label>ຊື່ສິນຄ້າ</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="ຊື່ສິນຄ້າ"
                aria-label="ຊື່ສິນຄ້າ"
                aria-describedby="basic-addon2"
                onChange={(e) => setFilterNameData(e.target.value)}
              />
              <Button variant="outline-danger" id="button-addon2">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Col>
          <Col md={6}>
            <Form.Label>ຄົ້ນຫານ້ຳຫນັກສິນຄ້າ</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="ຄົ້ນຫານ້ຳຫນັກສິນຄ້າ"
                aria-label="ຄົ້ນຫານ້ຳຫນັກສິນຄ້າ"
                aria-describedby="basic-addon2"
                onChange={(e) => setFilterWeightData(e.target.value)}
              />
              <Button variant="outline-danger" id="button-addon2">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={5}>
            <Form.Group className="mb-3">
              <Form.Label>ໝວດຄຳ</Form.Label>
              <Form.Select
                value={filterCategoryData}
                onChange={(e) => setFilterCategoryData(e.target.value)}
              >
                <option value="">ກະລຸນາເລືອກ</option>
                {categoryData?.data?.map((e, i) => (
                  <option value={e.id} key={i}>
                    {e.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group className="mb-3">
              <Form.Label>ລວດລາຍ</Form.Label>
              <Form.Select
                value={filterPatternData}
                onChange={(e) => setFilterPatternData(e.target.value)}
              >
                <option value="">ກະລຸນາເລືອກ</option>
                {patternData?.data?.map((e, i) => (
                  <option value={e.id} key={i}>
                    {e.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2} className="d-flex justify-content-end" style={{ marginTop: 33 }}>
            <div>
              <button onClick={getStockToPDF}
                id="format-PDF"
                className='buttonRedColor-export'    >
                Export
              </button>
            </div>

          </Col>

        </Row>

        {dataStock?.length <= 0 ?
          <div className="loading-card">
            <div>
              <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
              <span>Loading....</span>
            </div>
          </div>
          :
          <div className="tableFixHead"  >
            <Table className="table-hover table-fix">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ຮູບພາບ</th>
                  <th>ຊື່ສິນຄ້າ</th>
                  <th>ລວດລາຍ</th>
                  <th>ນໍ້າໜັກ</th>
                  <th>ຈຳນວນເພີ່ມເຂົ້າ</th>
                  <th>ຄ້າງໃນສະຕັອກ</th>
                  <th>ຄ້າງໃນຕູ້</th>
                  <th>ຈຳນວນຫຍັງເຫຼືອ</th>
                  <th>ນໍ້າໜັກທັງໝົດ</th>
                </tr>
              </thead>
              <tbody>
                {dataStock?.data?.map((e, i) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      History.push(`${consts.STOCK_MANAGEMENT}/detail/${e?.id}`)
                    }
                  >
                    <td>{i + 1 + _limit * (_skip - 1)}</td>
                    <td>
                      <img
                        src={`${e?.image?.length > 0
                          ? consts.S3 + e?.image[0]
                          : "/image/pv.jpeg"
                          }`}
                        alt=""
                        style={{ width: 80, height: "auto" }}
                      />
                    </td>
                    <td>{e?.name}</td>
                    <td>{e?.pattern_name}</td>
                    <td>{formatCurrencyGold(e?.weight?.[0].gram) || "-"} g</td>
                    <td>{e?.amount}</td>
                    <td>{e?.stock_amount}</td>
                    <td>{e?.cabinet_amount}</td>
                    <td>{e?.remain_amount}</td>
                    <td>{(formatCurrencyGold(parseFloat(e?.remain_amount) * parseFloat(e?.weight?.[0].gram)))}</td>
                  </tr>
                ))}
                <tr className="fw-bold">
                  <td colSpan={5}>ລວມຍອດສິນຄ້າ</td>
                  <td>{formatCurrency(totalAmount)}</td>
                  <td>{formatCurrency(totalStockWeight)}</td>
                  <td>{formatCurrency(cabinetAmount)}</td>
                  <td>{formatCurrency(remainAmount)}</td>
                  <td>{dataStock?.totalWeight} g</td>
                </tr>
              </tbody>
            </Table>
          </div>
        }
        {Pagination_helper(stockTotal, consts.STOCK_MANAGEMENT, `product_type_id=${filterCategoryData}&pattern_id=${filterPatternData}&name=${filterNameData}&gram=${filterWeightData}`)}
      </Card>
      <Modal show={show} onHide={handleClose} size="xl">

        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className='buttonRedColor'
              style={{ width: "125px", height: "51px" }} onClick={handleDownloadPdf}>
              Export PDF
            </Button>
          </div>
          <div className="" ref={printRef}>
            <Table className="">

              <thead>
                <tr>
                  <th>#</th>
                  <th>ຊື່ສິນຄ້າ</th>
                  <th>ລວດລາຍ</th>
                  <th>ນໍ້າໜັກ</th>
                  <th>ຈຳນວນເພີ່ມເຂົ້າ</th>
                  <th>ຄ້າງໃນສະຕັອກ</th>
                  <th>ຄ້າງໃນຕູ້</th>
                  <th>ຈຳນວນຫຍັງເຫຼືອ</th>
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ? (
                    <tr>
                      <td colSpan={8}>
                      <div className="loading-card">
                      <div>
                        <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
                        <span>Loading....</span>
                      </div>
                    </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {dataStockPDF?.data?.map((e, i) => (
                        <tr
                          style={{ cursor: "pointer" }}

                        >
                          <td>{i + 1}</td>
                          <td>{e?.name}</td>
                          <td>{e?.pattern_name}</td>
                          <td>{e?.weight?.[0].gram || "-"} g</td>
                          <td>{e?.amount}</td>
                          <td>{e?.stock_amount}</td>
                          <td>{e?.cabinet_amount}</td>
                          <td>{e?.remain_amount}</td>
                        </tr>
                      ))}
                    </>
                  )
                }

                <tr className="fw-bold">
                  <td colSpan={4}>ລວມຍອດສິນຄ້າ</td>
                  <td>{formatCurrency(totalAmount)}</td>
                  <td>{formatCurrency(totalStockWeight)}</td>
                  <td>{formatCurrency(cabinetAmount)}</td>
                  <td>{dataStock?.totalWeight}</td>
                </tr>
              </tbody>
            </Table>

          </div>
        </Modal.Body>

      </Modal>
    </div>
  );
}
