import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function ReportAllSkeleton() {
  return (
    <div>
        <Card style={{ padding: 20 }} className="mb-2 placeholder-glow">
            <div className="document-head"><p className="doc-head placeholder">ລາຍງານຕາມວັນທີ</p></div>
            <Row style={{ height: 165 }}>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
            </Row>
        </Card>
        <Card style={{ padding: 20 }} className="mb-2 placeholder-glow">
            {/* income */}
            <Row className="mt-5" style={{ height: 165 }}>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-green">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
            </Row>

            {/* expense */}
            <Row className="mt-5" style={{ height: 165 }}>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-red">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-red">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-red">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-red">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
            </Row>

            {/* gold */}
            <Row className="mt-5" style={{ height: 165 }}>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-orange">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-25 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
                <Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-orange">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col><Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-orange">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col><Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-orange">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col><Col className="dashboard-grid">
                <div className="dashboard-container dashboard-bg-orange">
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                    <div className="h-50 p-1">
                    <span className="placeholder col-12 h-100"></span>
                    </div>
                </div>
                </Col>
            </Row>
        </Card>
    </div>
  );
}
