import axios from 'axios'
import { SERVER_URL } from '..'

import { getUserDataFromLCStorage,getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

// ----------------------------------------------## Edit by Phoudthavisack ( therd ) ##----------------------------------------------------- //

 const getStock = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/stock-service/api/v1/branch-product?branch=${
        getUserDataFromLCStorage().branch_id
      }`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getStock());
    }
  }
};
 const oneStock = async (product_id) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/stock-service/api/v1/branch-product/${product_id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,oneStock());
    }
  }
};
 const addStock = async (values) => {
  let _token = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/stock-service/api/v1/branch-product/`,
      { ...values, branch: getUserDataFromLCStorage().branch_id },
      {
        headers: {
          Authorization: _token,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,addStock());
    }
  }
};
 const updateStock = async (stock_id, values) => {
  let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.put(
      `${SERVER_URL}/stock-service/api/v1/branch-product/${stock_id}`,
      { ...values },
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,updateStock());
    }
  }
};

 const deleteCabinetProduct = async (id)=>{
  let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.delete(
      `${SERVER_URL}/stock-service/api/v1/cabinet-product/${id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data?.status >= 300) return false;
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,deleteCabinetProduct());
    }
  }
}
export {getStock,oneStock,addStock,updateStock,deleteCabinetProduct}