import { Card, Modal,Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Approve from "../../../common/popup/approve/ConfirmApprove";
import ModalBootstrap from "../../../common/popup/success/ModalBootstrap";
import { useState, useEffect } from "react";

export default function AddStock(props) {
  const { show = true, weight = [], onClose, onSubmit } = props;
  const [weightData, setWeightData] = useState([]);
  const [popupConform, setPopupConfirm] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [amount, setAmount] = useState(0);

  const delayToClose = () => {
    setPopupSuccess(true);
    setTimeout(() => {
      setPopupSuccess(false);
      onClose();
    }, 3000);
  };
  const handleChangeWeight = (e) => {
       setWeightData([{...weightData[0], amount: e.target.value}]);
  };
  useEffect(() => {
    setWeightData(weight?.map((e) => ({ ...e, amount: 0 })));
  }, [weight]);

  return (
    <div>
      <Modal show={show} onHide={onClose} centered>
        <Card style={{ width: "692px", height: "auto", margin: "0 auto", padding: "20px"}}>
          <div
            style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #666"}}>
            <span style={{ fontSize: "22px", fontWeight: "bold" }}>
              ເພີ່ມຈຳນວນຄຳ
            </span>
            <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={onClose} icon={faX}/>
          </div>

          <div style={{ border: "1px solid #D1D5DB", padding: 10, marginTop: "20px",}}>
            {weight?.[0]
              ? weightData?.map((e, i) => (
                <div key={i} style={{ display: "flex", alignItems: "center", height: 50 }} >
                  <div style={{ padding: "0 20px" }}>{i + 1}</div>
                  <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #DFE2E9", height: "100%", paddingRight: 20 }}>
                    <b>ນໍ້າຫນັກ</b>
                    <span>{e?.gram}</span>
                  </div>
                  <div>
                    <br/>
                    <Form.Group className='mb-3'>
                    <Form.Control type='number' placeholder="ປ້ອນຈຳນວນ"
                    onChange={(e) =>{
                      setAmount(parseInt(e.target.value))
                      handleChangeWeight(e)
                    }} />
                  </Form.Group>
                  </div>
                </div>
              ))
              : ""}
          </div>
          <div className='d-flex justify-content-end' style={{ margin: "20px 0" }}>
            <button style={{ width: "217px", height: "51px" }} className='buttonRedColor'
              onClick={() => {
                amount > 0 ? setPopupConfirm(true) : setPopupConfirm(false)
                onClose()
              }}>
              + ເພີ່ມສິນຄ້າເຂົ້າສະຕ໋ອກ
            </button>
          </div>
        </Card>
      </Modal>

      <div
        className='fixed'
        style={{ display: popupConform === true ? "block" : "none" }}>
        <Approve
          title="ທ່ານຕ້ອງການເພີ່ມຈຳນວນຄຳແທ້ ຫຼື ບໍ່?"
          cancel={() => setPopupConfirm(false)}
          confirm={() => {
            setPopupConfirm(false);
            onSubmit(weightData);
            delayToClose();
          }}
        />
      </div>
      <ModalBootstrap
        title='ເພີ່ມສິນຄ້າເຂົ້າສະຕ໋ອກສຳເລັດ'
        show={popupSuccess}
        close={popupSuccess}
      />
    </div>
  );
}
