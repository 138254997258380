import React, { useState, useRef, useEffect } from "react";

import axios from "axios";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";

//api
import { SERVER_URL } from "../../../api";
import {
  getAccessTokkenFromLCS,
  getReNewToken,
  getUserDataFromLCStorage,
} from "../../../helpers/user";
import consts from "../../../consts";
import { warring } from "../../../helpers/sweetalert";

import { Form, Modal, Row, Col } from "react-bootstrap";
import {
  FaDownload,
  FaEdit,
  FaFilePdf,
  FaTimes,
  FaTrashAlt,
} from "react-icons/fa";

//redux
import { loadDocumentAsync } from "../../../redux/document";
import { loadDocumentSeenPersonAsync } from "../../../redux/document/DocumentSeen";

export default function DetailDocument({
  handleShowDetailDocument,
  showDetailDocument,
  handleClose,
  documentId,
  handleDelete,
  handleEdit,
  handleCloseEdit,
}) {
  console.log("documentId:::", documentId);

  const dispatch = useDispatch();
  const checkboxRef = useRef();
  const [documentDetail, setDocumentDetail] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [modalImage, setModalImage] = useState();
  const [isShowModalImage, setIsShowModalImage] = useState(false);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedRoles([...selectedRoles, value]);
    } else {
      setSelectedRoles(selectedRoles.filter((role) => role !== value));
    }
  };

  const roleArray = [
    "ADMIN",
    "MANAGER",
    "STAFF",
    "AOMKHAM",
    "IT_KPV",
    "KYC_KPV",
    "STAFF_AOMKHAM",
  ];

  //query one
  const fetchDataDetail = async (documentId) => {
    let _tokken = getAccessTokkenFromLCS();
    await axios
      .get(`${SERVER_URL}/website-service/api/v1/document/${documentId}`, {
        headers: {
          Authorization: _tokken,
        },
      })
      .then((data) => {
        setDocumentDetail(data?.data?.data);
        dispatch(loadDocumentAsync());
        dispatch(loadDocumentSeenPersonAsync());
      })
      .catch((err) => {
        if (err?.response?.data?.msg === "Token is expired") {
          getReNewToken(err, fetchDataDetail());
          return;
        }
        if (err?.response?.data?.msg === "UNAUTHORIZED") {
          warring("ທ່ານບໍ່ມີສິດເຂົ້າເຖິງ");
          handleClose();
          return;
        }
      });
  };

  //useEffect
  useEffect(() => {
    fetchDataDetail(documentId);
  }, [documentId]);

  useEffect(() => {
    if (documentDetail) {
      setSelectedRoles(documentDetail?.valid_roles);
    }
  }, [documentDetail]);

  return (
    <div>
      <Modal
        show={showDetailDocument}
        onHide={() => {
          handleClose();
          setDocumentDetail();
        }}
        size="lg"
        className="detail-doc"
      >
        <Modal.Body style={{ padding: "30px" }}>
          <div className="detail-head">
            <p className="title"> {documentDetail?.title ?? ""}</p>
            <p
              className="title"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClose();
                setDocumentDetail();
              }}
            >
              <FaTimes />
            </p>
          </div>
          <hr></hr>
          {getUserDataFromLCStorage().role !== "ADMIN" ? (
            ""
          ) : (
            <div className="detail-manage">
              <button
                type="button"
                onClick={() => {
                  handleEdit();
                  handleCloseEdit();
                  setDocumentDetail();
                }}
                className="detail-btn btn-edit"
              >
                <FaEdit style={{ fontSize: 14 }} />
              </button>
              <button
                type="button"
                onClick={handleDelete}
                className="detail-btn btn-delete"
              >
                <FaTrashAlt style={{ fontSize: 14 }} />
              </button>
            </div>
          )}
          <div className="reader-box">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>ລາຍລະອຽດເນື້ອຫາ</Form.Label>
                <div className="detail-text">
                  {/* {renderHTML(documentDetail?.text)} */}
                  {documentDetail?.text ? (
                    parse(documentDetail?.text)
                  ) : (
                    <em>No content to display</em>
                  )}
                </div>
              </Form.Group>

              {documentDetail?.files === null ? (
                ""
              ) : (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ເອກກະສານທີ່ອັບໂຫຼດ</Form.Label>

                  {documentDetail?.files?.map((item, index) => (
                    <div className="detail-file-doc" key={"file" + index}>
                      <div className="detail-file-title">
                        <FaFilePdf style={{ fontSize: 20 }} />
                        &nbsp;
                        {item?.name ?? "-"}
                      </div>
                      <a
                        href={`${consts.S3}${item?.file}`}
                        className="detail-file-download"
                      >
                        <FaDownload style={{ fontSize: 20 }} />
                        &nbsp;
                        <u>ດາວໂຫຼດ</u>
                      </a>
                    </div>
                  ))}
                </Form.Group>
              )}
              {documentDetail?.image === null ? (
                ""
              ) : (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>ຮູບພາບທີ່ອັບໂຫຼດ</Form.Label>

                  <Row>
                    {documentDetail?.image?.map((item, index) => (
                      <Col
                        md="4"
                        key={"image" + index}
                        onClick={() => {
                          setModalImage(`${consts.S3}${item}`);
                          setIsShowModalImage(true);
                          handleClose();
                        }}
                      >
                        <img
                          className="detail-image"
                          src={`${consts.S3}${item}`}
                          alt=""
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isShowModalImage}
        onHide={() => {
          setIsShowModalImage(false);
          handleShowDetailDocument();
        }}
        size="lg"
      >
        <Modal.Body style={{ padding: 30 }}>
          <img className="modal-image" src={modalImage} alt="" />
        </Modal.Body>
      </Modal>
    </div>
  );
}
