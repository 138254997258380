import React from 'react';
import { Button } from "react-bootstrap";
import styled from "styled-components";
import {Fill_COLOR } from "../../../consts";
import image from "../../../image/undraw.svg";
// ----------------------------------------------- //
export default function ConfirmDelete({ cancel,confirm,backClose, ...other }) {
  return (
    <RootStyle {...other}>
      <Card>
        <img src={image} alt='' style={{ width: 110, height: "auto" }} />
        <h3>ທ່ານຕ້ອງການລຶບຂໍ້ມູນຫຼືບໍ່?</h3>
        <p>ການກະທຳນີ້ແມ່ນບໍ່ສາມາດປ່ຽນແປງໄດ້ ຫຼື ບໍ່ສາມາດກູ້ຂໍ້ມູນກັບຄືນໄດ້.</p>
        <div className='d-flex gap-2 justify-content-end'>
          <CustomButton
            style={{ backgroundColor: Fill_COLOR, color: "#180E19" }}
            onClick={cancel}>
            {/* <FontAwesomeIcon icon={faEdit} /> */}
            ຍົກເລີກ
          </CustomButton>
          <CustomButton
            style={{ backgroundColor: "#D21C1C" }}
            onClick={confirm}>
            {/* <FontAwesomeIcon icon={faTrashAlt} /> */}
            ລຶບ
          </CustomButton>
        </div>
      </Card>
      <div 
        style={{
          width:'100%',
          height:'100%',
          backgroundColor: 'rgba(0,0,0,0.2)',
          position:'absolute',
          top:'0',
          left:'0',
          zIndex:'901'
        }}
        onClick={backClose}
      ></div>
    </RootStyle>
  );
}

const RootStyle = styled.div`
    background-color: rgba(0,0,0,0.2);
    width:100vw;
    height:100vh;
    position: fixed;
    top:0;
    left: 0;
    z-index: 900;
    display:flex;
    justify-content: center;
    align-items: center; 
`
const Card = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 9px;
  box-shadow: 0px 12px 24px rgba(37, 42, 49, 0.24),
    0px 8px 60px rgba(37, 42, 49, 0.32);
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index:902;
`;
const CustomButton = styled(Button)({
  padding: "10px 20px",
  fontWeight: 700,
  display: "flex",
  gap: 10,
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: 0,
  zIndex: 100,
});