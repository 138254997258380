import { createSlice } from "@reduxjs/toolkit";
// api
import { order, updateOrder } from "../../api/order";

// ---------------------------------------------------
export const orderData = createSlice({
  name: "order",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    setOrder: (state, action) => {
      state.data = [...action.payload];
    },
    addOrder: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    removeOrder: (state, action) => {
      state.data = [
        ...state.data
          .map(JSON.stringify)
          .filter((e) => e !== JSON.stringify(action.payload)),
      ].map(JSON.parse); // ລົບ
    },
    editOrder: (state, action) => {
      state.data = [
        ...state.data.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      ];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addOrder, removeOrder, editOrder, setOrder } =
  orderData.actions;

export const loadOrderAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await order();
    dispatch(setOrder(data));
  } catch {
    console.error("load Order");
  }
  dispatch(setLoading(false));
};

// export const deleteOrderAsync = (value) => async (dispatch) => {
//   dispatch(setLoading(true));
//   try {
//     await deleteOrder(value.id);
//     dispatch(removeOrder(value));
//   } catch {
//     console.error("delete Order");
//   }
//   dispatch(setLoading(false));
// };

export const editOrderAsync = (value) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await updateOrder(value.id, value);
    dispatch(editOrder(value));
  } catch {
    console.error("edit Order");
  }
  dispatch(setLoading(false));
};

export const selectOrder = (state) => state.order;

export default orderData.reducer;
