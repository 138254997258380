import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from 'react-loading';
import { selectDocument, loadDocumentAsync } from "../../../redux/document";
import Cookies from 'js-cookie';


import { Modal } from 'react-bootstrap';
import { FaFilePdf, FaTimes } from 'react-icons/fa';

import { calutionTime } from "../../../helpers"

export default function NotiDocument({
    handleCloseNoti,
    isNoti,
    setShowDetailDocument,
    setDocumentId,
}) {
    const dispatch = useDispatch();

    const ducumentData = useSelector(selectDocument);

    useEffect(() => {
        const getData = () => {
            if (ducumentData.isLoading) {
                dispatch(loadDocumentAsync());
            }

        };
        return getData();
    }, []);

    const readLater = async () => {
        await Cookies.set('CHECK_POPUP', 'READ_LATER');
        await handleCloseNoti()
    }



    return (
        <div>
            <Modal show={isNoti} onHide={handleCloseNoti} size="md" className='detail-doc' centered>
                <Modal.Body style={{ padding: 0 }}>
                    <div className='detail-head' style={{ borderBottom: "1px solid gray" }}>
                        <div style={{ width: "100%", margin: "30px 20px 0px 20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <p className='title' >
                                ເເຈ້ງເຕືອນ ({ducumentData?.data.length})
                            </p>
                            <p className='title' style={{ cursor: "pointer" }} onClick={handleCloseNoti}><FaTimes /></p>
                        </div>
                    </div>
                    
                    <div style={{ overflowY: "scroll", height: "250px" }}>
                        {ducumentData?.isLoading ?
                            <div className="loading-card">
                                <div>
                                    <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} />
                                    <span>Loading....</span>
                                </div>
                            </div> : ducumentData?.data.length === 0 ? <div>
                                <center>
                                    <img alt="" src='/image/animation_200_ldu129fc.gif' />
                                    <p style={{ marginTop: -20, color: 'grey' }}>ຍັງບໍ່ມີຂໍ້ມູນ!</p>
                                </center>
                            </div> :
                                ducumentData?.data?.map((item, index) =>
                                    <div key={index} className='noti-doc'
                                     onClick={() => { setShowDetailDocument(true); setDocumentId(item?.id); }}
                                    >
                                        <span className='popup-menu-icon'>
                                            <FaFilePdf />
                                        </span>
                                        <div className='popup-menu-detail'>
                                            {item?.title}
                                            <span>{calutionTime(item?.created_at)}</span>
                                        </div>

                                    </div>
                                )}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className='noti-footer'>
                        <button className='readAfter' onClick={readLater}>ອ່ານພາຍຫຼັງ</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
