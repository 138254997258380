import { createSlice } from "@reduxjs/toolkit";
// import { getProduct } from "../../api/product";
import { getStock } from "../../api/stock";

export const stockData = createSlice({
  name: "stock",
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {
    setStock: (state, action) => {
      state.data = [...action.payload];
    },
    addStock: (state, action) => {
      state.data = [
        ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
      ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading, addStock, setStock } = stockData.actions;

export const loadStockAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await getStock();
    dispatch(setStock(data));
  } catch {
    console.error("load product");
  }
  dispatch(setLoading(false));
};

export const selectStock = (state) => state.stock;

export default stockData.reducer;

