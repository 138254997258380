import { configureStore } from "@reduxjs/toolkit";
import todoReducer from "./todoSlice";
import Cabinet from './cabinet'
import Product from "./product";
import Stock from "./stock";
import Counter from './counter'
import users_staff from "./users_staff";
import order_data from './order_data'
import customer_data from './customer'
import branch_data from "./branch_data";
import cabinet_product from "./cabinet_product";
import pattern_data from "./pattern_data";
import category_data from "./category_data";
import order_product from "./order_product";
import document_data from "./document";
import documentSeen_data from "./document/DocumentSeen"
import notiDoc_data from "./document/NotiDocument"


export default configureStore({
  reducer: {
    order_product: order_product,
    todos: todoReducer,
    cabinet: Cabinet,
    product: Product,
    category: category_data,
    pattern: pattern_data,
    stock: Stock,
    users_staff: users_staff,
    counter: Counter,
    branch: branch_data,
    order: order_data,
    users_customer: customer_data,
    cabinet_product: cabinet_product,
    document:document_data,
    documentSeen:documentSeen_data,
    documentNoti:notiDoc_data,
  },
});
