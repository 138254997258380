import axios from 'axios'
import { SERVER_URL } from '..'
import { getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

const getProduct = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/product-service/api/v1/products`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,getProduct());
    }
  }
};
const productLS = async (_skip=1,_limit=3)=>{
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/product-service/api/v1/products/limit/${_limit}/skip/${_skip}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    )
    if (data.status >= 300)return
    return data.data
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,productLS());
    }
  }
}
const updateProduct = async (id,value)=>{
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.put(`${SERVER_URL}/product-service/api/v1/products/${id}`,value,
    {
      headers: {
        Authorization: _tokken,
      },
    })
    if(data.status >= 300) return false
    return true
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,updateProduct());
    }
  }
}

const productOne = async (product_id) => {
  let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/product-service/api/v1/products/${product_id}`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) return;
    return data.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,productOne());
    }
  }
}

export { getProduct, productOne,updateProduct,productLS }