import React, { useState } from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import consts from "../consts";
import { faSquarePollVertical,faMobileButton, faBoxOpen, faBoxes,faFile, faUsersGear, faIdCard, faTablet, faChevronRight, faMoneyCheckDollar} from "@fortawesome/free-solid-svg-icons";
import "./sidenav.css";
import { getUserDataFromLCStorage } from "../helpers/user";
const selectedTabBackgroundColor = "#D1D5DB";
const APP_COLOR_FOCUS = "#003049";
// ------------------------------------------------------------------- //
export default function Sidenav({ location, history }) {
  const [selectStatus, setSelectStatus] = useState(location.pathname);
  let NevConfig = []

  if(getUserDataFromLCStorage()?.role === 'MANAGER') {
    NevConfig = [...menu_manager]
  }else {
    NevConfig = [...menu_staff]
  }
  return (
    <SideNav
    style={{
      background: "linear-gradient(90deg, #A73737 0%, #7A2828 100%)",
      height: "100vh",
      display: "block",
      position: "fixed",
      zIndex: 9000,
      boxShadow:
        "0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12)",
    }}
    onSelect={(selected) => {
      setSelectStatus(selected);
      const to = "/" + selected;
      if (location.pathname !== to) {
        history.push(to);
      }
    }}>
    <SideNav.Toggle style={{ color: "white" }} />

    <SideNav.Nav defaultSelected={location.pathname.split("/")[1]}>
      {NevConfig.map((value) => (
        <NavItem
          eventKey={value.eventKey}
          key={value.eventKey}
          style={{
            position: "relative",
            backgroundColor: selectStatus === value.eventKey ? "#DDA81C" : "",
            color: "#212529",
          }}>
          <NavIcon>
            <FontAwesomeIcon
              icon={value.icon}
              style={{
                fontSize: "20px",
                color:
                  selectStatus.slice(0, selectStatus.indexOf("/")) ===
                    value.eventKey || selectStatus === value.eventKey
                    ? APP_COLOR_FOCUS
                    : selectedTabBackgroundColor,
              }}
            />
          </NavIcon>
          <NavText
            style={{
              color:
                selectStatus === value.eventKey
                  ? APP_COLOR_FOCUS
                  : selectedTabBackgroundColor,
              fontSize: "17px",
              display: "flex",
              justifyContent: "space-between",
            }}>
            {value.title}
            <span
              style={{
                marginLeft: 30,
                position: "absolute",
                right: 15,
              }}>
              {value?.element ? (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{
                    width: 9,
                    height: 16,
                    color:
                      selectStatus === value.eventKey
                        ? APP_COLOR_FOCUS
                        : selectedTabBackgroundColor,
                  }}
                />
              ) : (
                ""
              )}
            </span>
          </NavText>
          {/* <CustomNavItem> */}
          {value.element?.map((e) => {
            return (
              <NavItem eventKey={e.eventKey} key={e.eventKey}>
                <NavIcon>
                  {e.icon ? <FontAwesomeIcon icon={e.icon} /> : ""}
                </NavIcon>
                <NavText>
                  {e.title}
                  <span
                    style={{
                      marginLeft: 30,
                      position: "absolute",
                      right: 15,
                    }}>
                    {/* <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{
                        width: 9,
                        height: 16,
                      }}
                    /> */}
                  </span>
                </NavText>
              </NavItem>
            );
          })}
        </NavItem>
      ))}
    </SideNav.Nav>
  </SideNav>

  );
}

const menu_manager = [
  {
    eventKey: "report",
    title: "ລາຍງານ",
    icon: faSquarePollVertical,
    element: [
      {
        eventKey: "report/limit/40/skip/1",
        title: "ລາຍງານລວມ",
      },
      {
        eventKey: "report/sale/limit/40/skip/1",
        title: "ລາຍງານການຂາຍ",
      },
      {
        eventKey: "report/change-free/limit/40/skip/1",
        title: "ລາຍງານການປ່ຽນຟຣີ",
      },
      {
        eventKey: "report/change-more/limit/40/skip/1",
        title: "ລາຍງານການປ່ຽນຕື່ມ",
      },
      {
        eventKey: "report/change-refund/limit/40/skip/1",
        title: "ລາຍງານການປ່ຽນຄືນເງິນ",
      },
      {
        eventKey: "report/buy-more/limit/40/skip/1",
        title: "ລາຍງານການຊື້ຕື່ມ",
      },
      {
        eventKey: "report/buy/limit/40/skip/1",
        title: "ລາຍງານຊື້ຄຳຄືນ",
      },
      {
        eventKey: "report/borrow/limit/40/skip/1",
        title: "ລາຍງານການເຊົ່າ",
      },
      {
        eventKey: "report/order/limit/40/skip/1",
        title: "ລາຍງານການຈອງ",
      },
      {
        eventKey: "report/pledge/limit/40/skip/1",
        title: "ລາຍງານການຈໍາຄຳ",
      },
    
    ],
  },
  {
    eventKey: "counter-and-cabinage",
    title: "ເຄົາເຕີ & ຕູ້",
    icon: faTablet,
    element: [
      {
        eventKey: "counter-management/limit/40/skip/1",
        title: "ຈັດການເຄົາເຕີ",
      },
      {
        eventKey: "cabinage-management/limit/40/skip/1",
        title: "ຈັດການຕູ້",
      },
    ],
  },
  {
    eventKey: "customer-management/limit/40/skip/1?page=RETAIL",
    title: "ຈັດການລູກຄ້າ",
    icon: faIdCard,
  },
  {
    eventKey: "product-management/limit/40/skip/1",
    title: "ຈັດການສິນຄ້າ",
    icon: faBoxOpen,
  },
  {
    eventKey: "stock-management/limit/40/skip/1",
    title: "ສະຕັອກທັງໝົດ",
    icon: faBoxes,
  },
  {
    eventKey: "staff-management/limit/40/skip/1",
    title: "ຈັດການພະນັກງານ",
    icon: faUsersGear,
  },
  // {
  //   eventKey: "aomkham/limit/50/skip/1",
  //   title: "ອອມຄໍາ",
  //   icon: faHandHoldingDollar,
  // },
  {
    eventKey: "expense",
    title: "ບັນທຶກລາຍຈ່າຍ",
    icon: faMoneyCheckDollar,
  },
  {
    eventKey: "document-list/limit/40/skip/1",
    title: "ເອກະສານ",
    icon: faFile,
  },
  {
    eventKey: "history-buy-and-order",
    title: "ລາຍການສັ່ງຊື້&ເຮັດຄໍາ",
    icon: faMobileButton,
    element: [
      {
        eventKey: "history-buy",
        title: "ລາຍການສັ່ງຊື້ຄໍາ",
      },
      {
        eventKey: "history-buy-gold-bars",
        title: "ລາຍການສັ່ງຊື້ຄໍາແທ່ງ",
      },
    ],
  },
];
const menu_staff = [
  {
    eventKey: "counter-and-cabinage",
    title: "ເຄົາເຕີ & ຕູ້",
    icon: faTablet,
    element: [
      {
        eventKey: "counter-management/limit/40/skip/1",
        title: "ຈັດການເຄົາເຕີ",
      },
      {
        eventKey: "cabinage-management/limit/40/skip/1",
        title: "ຈັດການຕູ້",
      },
    ],
  },
  {
    eventKey: "customer-management/limit/40/skip/1?page=RETAIL",
    title: "ຈັດການລູກຄ້າ",
    icon: faIdCard,
  },
  {
    eventKey: "product-management/limit/40/skip/1",
    title: "ຈັດການສິນຄ້າ",
    icon: faBoxOpen,
  },
  {
    eventKey: "stock-management/limit/40/skip/1",
    title: "ສະຕັອກທັງໝົດ",
    icon: faBoxes,
  },
  {
    eventKey: "staff-management/limit/40/skip/1",
    title: "ຈັດການພະນັກງານ",
    icon: faUsersGear,
  },
  // {
  //   eventKey: "aomkham/limit/50/skip/1",
  //   title: "ອອມຄໍາ",
  //   icon: faHandHoldingDollar,
  // },
  {
    eventKey: "expense",
    title: "ບັນທຶກລາຍຈ່າຍ",
    icon: faMoneyCheckDollar,
  },
  {
    eventKey: "document-list/limit/40/skip/1",
    title: "ເອກະສານ",
    icon: faFile,
  },
  {
    eventKey: "history-buy-and-order",
    title: "ລາຍການສັ່ງຊື້&ເຮັດຄໍາ",
    icon: faMobileButton,
    element: [
      {
        eventKey: "history-buy",
        title: "ລາຍການສັ່ງຊື້ຄໍາ",
      },
      {
        eventKey: "history-buy-gold-bars",
        title: "ລາຍການສັ່ງຊື້ຄໍາແທ່ງ",
      },
    ],
  },
];
