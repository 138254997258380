import { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import CustomBreadcrumbs from "../../common/CustomBreadcrumbs";
import { useParams } from "react-router-dom";
import consts from "../../consts";
// redux
import { useSelector } from "react-redux";
import { selectStock } from "../../redux/stock";
import { selectCabinet } from "../../redux/cabinet";
import { selectCabinetProduct } from "../../redux/cabinet_product";
// api
import { addCabinetProduct } from "../../api/cabinage";

// router
import { useHistory } from "react-router-dom";
import { getUserDataFromLCStorage } from "../../helpers/user";

// ------------------------
export default function Edit() {
  const { idCabinage, id } = useParams();
  const History = useHistory();

  // global state
  const stockData = useSelector(selectStock);
  const CabinetData = useSelector(selectCabinet);
  const CabinetProductData = useSelector(selectCabinetProduct);
  const CabinetProductOneData = CabinetProductData?.data?.find(
    (e) => e.product_id === id && e.cabinet === idCabinage
  );
  const CabinetOneData = CabinetData.data?.find((e) => e.id === idCabinage);
  const [formValue, setFormValue] = useState();
  const [dataForSubmit, setDataForSubmit] = useState({
    cabinet: idCabinage,
    name: "",
    product_id: "",
    product_code: "",
    image: [],
    amount: 5,
    branch_product: "",
    weight: { name: "", gram: 0, amount: 0 },
  });

  const handleAddProductToCabinage = () => {
    addCabinetProduct(dataForSubmit).then((e) => {
      History.replace(consts.CABINAGE_MANAGEMENT);
    });
  };

  const handleChangeAmount = (e) => {
    setDataForSubmit((prev) => ({
      ...prev,
      amount: e > 0 ? e : 0,
      weight: { ...prev.weight, amount: e > 0 ? e : 0 },
    }));
  };
  useEffect(() => {
    const load = () => {
      setDataForSubmit({
        cabinet: idCabinage,
        name: CabinetProductOneData?.name,
        product_id: CabinetProductOneData?.product_id,
        product_code: CabinetProductOneData?.product_code,
        image: CabinetProductOneData?.image,
        amount: CabinetProductOneData?.amount,
        branch_product: CabinetProductOneData?.branch_product,
        weight: CabinetProductOneData?.weight || {},
      });
    };
    return load();
  }, [CabinetProductOneData]);


  return (
    getUserDataFromLCStorage()?.role === 'MANAGER' ? 
    <>
      <CustomBreadcrumbs
        value={[
          { title: "ຈັດການຕູ້", path: consts.CABINAGE_MANAGEMENT },
          {
            title: `${CabinetOneData?.cabinet_name || "-"}`,
            path: `${consts.CABINAGE_MANAGEMENT}/detail/${idCabinage}`,
          },
          { title: "ແກ້ໄຂສິນຄ້າໃນຕູ້" },
        ]}
      />
      <Card style={{ padding: "20px" }}>
        <h4 className='border-bottom border-2'>ແກ້ໄຂສິນຄ້າ</h4>
        <Form className='mt-3'>
          <Form.Group className='mb-3'>
            <Form.Label>ລະຫັດສິນຄ້າ</Form.Label>
            <Form.Select
              onChange={(e) => {
                const findOne = stockData?.data?.find(
                  (item) => item.id === e.target.value
                );
                setFormValue({
                  ...findOne,
                });
              
                setDataForSubmit((prev) => ({
                  ...prev,
                  name: findOne?.name,
                  product_id: findOne?.product_id,
                  amount: 0,
                  product_code: findOne?.product_code,
                  branch_product: findOne?.id,
                  image: findOne?.image,
                }));
              }}>
              <option value=''>ຕົວເລືອກ</option>
              {stockData?.data?.map((e) => (
                <option value={e.id}>{e.product_code}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>ຊື່ສິນຄ້າ</Form.Label>
            <Form.Control
              type='text'
              placeholder='ກະລຸນາປ້ອນ'
              value={formValue?.name}
              disabled
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>ໝວດສິນຄ້າ</Form.Label>
            <Form.Control
              type='text'
              placeholder='-'
              value={formValue?.product_type_name}
              disabled
            />
          </Form.Group>
          <Form.Group controlId='doj' className='mb-3'>
            <Form.Label className='d-block'>ສຳລັບເພດ</Form.Label>
            <Form.Check
              inline
              name='radioGroup'
              label='ຍິງ'
              type='radio'
              id='radio-1'
            />
            <Form.Check
              inline
              name='radioGroup'
              label='ຊາຍ'
              type='radio'
              id='radio-2'
            />
            <Form.Check
              inline
              name='radioGroup'
              label='ທຸກເພດ'
              type='radio'
              id='radio-3'
              checked
            />
          </Form.Group>
          <div className='p-3 border border-2'>
            <Form.Group controlId='doj'>
              <Form.Label>ນ້ຳຫນັກສິນຄ້າ</Form.Label>
              <div className='d-flex align-items-center'>
                <span className='p-2'>01</span>
                <Form.Select
                  value={dataForSubmit?.weight?.gram}
                  onChange={(e) => {
                    setDataForSubmit((prev) => ({
                      ...prev,
                      weight: {
                        ...prev?.weight,
                        gram: Number(e.target.value),
                      },
                    }));
                  }}
                  disabled={!formValue}>
                  <option value=''>ຕົວເລືອກ</option>
                  {formValue?.weight?.[0]
                    ? formValue?.weight?.map((e, i) => (
                        <option key={i} value={e.gram}>
                          {e.gram}
                        </option>
                      ))
                    : ""}
                </Form.Select>
                <span className='d-flex align-items-center mx-4'>
                  <div
                    style={{ cursor: "pointer", fontSize: "30px" }}
                    onClick={() =>
                      handleChangeAmount(dataForSubmit?.weight?.amount - 1)
                    }>
                    -
                  </div>
                  <div
                    className='center-point'
                    style={{
                      margin: "0 10px",
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#960000",
                      borderRadius: "50%",
                      color: "#ffffff",
                      padding: "5px",
                    }}>
                    {dataForSubmit?.weight?.amount}
                  </div>
                  <div
                    style={{ cursor: "pointer", fontSize: "30px" }}
                    onClick={() =>
                      handleChangeAmount(dataForSubmit?.weight?.amount + 1)
                    }>
                    +
                  </div>
                </span>
              </div>
            </Form.Group>
          </div>
          <div
            className='d-flex justify-content-around'
            style={{ margin: "80px 0" }}>
            <Button style={buttonStyle} variant='light'>
              ຍົກເລີກ
            </Button>
            <Button
              style={buttonStyle}
              variant='danger'
              onClick={handleAddProductToCabinage}>
              ເພີ່ມສິນຄ້າ
            </Button>
          </div>
        </Form>
      </Card>
    </>
    : <div></div>
  );
}

const buttonStyle = {
  width: "509px",
  height: "49px",
};
