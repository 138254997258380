import React, { useEffect, useState } from "react";
/**
 *
 * @Library
 *
 */
import axios from "axios";
import moment from "moment";
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import ReactLoading from 'react-loading';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
/**
 *
 * @Component
 *
 */
import { Card, Col, Form, Row, Table, Breadcrumb, Button, Modal } from "react-bootstrap";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import Add from "./Add";
import CustomTitleCard from "../../common/CustomTitleCard";
import PrimaryButton from "../../common/Button/PrimaryButton";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
/**
 *
 * @API
 *
 */
import { SERVER_URL } from "../../api";
/**
 *
 * @Function
 *
 */
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage, } from "../../helpers/user";
import PaginationHelper from "../../helpers/PaginationHelper";

export default function CabinageManagement() {
  const History = useHistory();
  const parsed = queryString.parse(History?.location?.search);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  // state
  const [showAddCabinet, setShowAddCabinet] = useState(false);

  const [filterCabinet, setFilterCabinet] = useState("");
  const [filterCounter, setfilterCountere] = useState("");
  const [cabinetData, setCabinetData] = useState();
  console.log('cabinetData: ', cabinetData);
  const [cabinetsData, setCabinetsData] = useState();
  const [dataCounter, setDataCounter] = useState()
  const [cabinetTotal, setCabinetTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const _clickShowAddModal = () => setShowAddCabinet(true);
  const clickCloseAddModal = () => setShowAddCabinet(false);
  useEffect(() => {
    getCabinet();
    getCabinets()
    getCounter()
  }, []);

  useEffect(() => {
    getCabinets()
  }, [filterCabinet, filterCounter])

  const getCounter = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      if (getUserDataFromLCStorage()?.branch_id) {
        const data = await axios.get(
          `${SERVER_URL}/branch-service/api/v1/counter/limit/40/skip/0?branch=${getUserDataFromLCStorage()?.branch_id
          }`,
          {
            headers: {
              Authorization: _token,
            },
          }
        );
        if (data.status === 200) {
          setDataCounter(data?.data)
        }
      } else return;
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCounter());
      }
    }
  };
  const getCabinet = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      setIsLoading(true)
      const data = await axios.get(
        `${SERVER_URL}/branch-service/api/v1/cabinet/limit/1000/skip/0?branch=${getUserDataFromLCStorage().branch_id}`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setCabinetData(data?.data);
        setIsLoading(false)
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCabinet());
      }
    }
  };
  const getCabinets = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      setIsLoading(true)
      const data = await axios.get(
        `${SERVER_URL}/branch-service/api/v1/cabinet/limit/1000/skip/0?cabinet_name=${filterCabinet}&choose_counter=${filterCounter}&branch=${getUserDataFromLCStorage().branch_id
        }`,
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      if (data.status === 200) {
        setCabinetTotal(data?.data?.total)
        setCabinetsData(data?.data);
        setIsLoading(false)
      }
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getCabinets());
      }
    }
  };

  // const getProduct = async () => {
  //   let _token = getAccessTokkenFromLCS();
  //   try {
  //     const data = await axios.get(
  //       // https://hhxxc6jxtb.execute-api.ap-southeast-1.amazonaws.com/stock-service/api/v1/cabinet-product?product_id=63d0d6ce6e8d15efe9924581
  //       `${SERVER_URL}/stock-service/api/v1/cabinet-product?product_id=${dataBranchProduct?.branch_product?.product_id}`,
  //       {
  //         headers: {
  //           Authorization: _token,
  //         },
  //       }
  //     );
  //     if (data.status === 200) {
  //       setDataProduct(data?.data?.data[0])
  //     }
  //   } catch (err) {
  //     if (err?.response?.data?.msg === "Token is expired") {
  //       getReNewToken(err, getProduct());
  //     }
  //   }
  // };


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const printRef = React.useRef();

  const handleDownloadPdf = async () => {

    const element = printRef.current;
    const canvas = await html2canvas(element, {
      logging: true, letterRendering: 1, useCORS: true, allowTaint: false,
    }).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      const date = new Date();
      doc.save('Downld.pdf' + date + '.pdf');
    }
    )
  };

  return (
    <div>
      <div style={{ paddingTop: 12 }}>
        <Breadcrumb>
          <Breadcrumb.Item active> ຈັດການຕູ້ </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <CustomTitleCard
        title="ຂໍ້ມູນຕູ້ທັງໝົດ"
        icon={faCashRegister}
        style={{ padding: 20, marginBottom: 4 }}
      >
        <div style={{ flexGrow: 1 }} />
        <PrimaryButton onClick={_clickShowAddModal}>+ ເພີ່ມຕູ້</PrimaryButton>
      </CustomTitleCard>
      <Card style={{ padding: 20, minHeight: "80vh", paddingBottom: 100 }}>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group controlId="doj">
              <Form.Label>ເລກຕູ້</Form.Label>
              <Form.Select
                value={filterCabinet}
                onChange={(e) => {
                  setFilterCabinet(e?.target?.value);
                }}
              >
                <option value="">ຕົວເລືອກ</option>
                {cabinetData?.data?.map((select, index) => (
                  <option key={index} value={select.cabinet_name}>
                    {select.cabinet_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="doj">
              <Form.Label>ເລກເຄົາເຕີ</Form.Label>
              <Form.Select
                value={filterCounter}
                onChange={(e) => {
                  setfilterCountere(e.target.value);
                }}
              >
                <option value={""}>ຕົວເລືອກ</option>
                {dataCounter?.data?.map((select, index) => (
                  <option key={index} value={select.counter_name}>
                    {select.counter_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} className="d-flex justify-content-end" style={{ marginTop: 35 }}>
            <div>
              <button
                onClick={handleShow}
                id="format-PDF"
                className='buttonRedColor-export'>  Export </button>
            </div>
          </Col>
        </Row>

        {isLoading ?
          (
            <div className="d-flex justify-content-center mt-4">
              <div className="mt-5">
                <ReactLoading className="loading-cus" type='spin' color="#960000" height={50} width={50} /> <br />
                <span>Loading....</span>
              </div>
            </div>
          )
          :
          (
            <Table className="table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ເລກຕູ້</th>
                  <th>ຜູ້ດູແລ</th>
                  <th>ປະຈໍາເຄົາເຕີ</th>
                  <th>ວັນທີສ້າງ</th>
                </tr>
              </thead>
              <tbody>
                {cabinetsData?.data?.map((item, index) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() =>
                      History.push(
                        `${consts.CABINAGE_MANAGEMENT}/detail/${item.id}/limit/50/skip/1`,
                        item
                      )
                    }
                  >
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle">{item.cabinet_name}</td>
                    <td className="align-middle">
                      {item?.cabinet_owners?.[0].first_name}
                    </td>
                    <td className="align-middle">{item.choose_counter}</td>
                    <td className="align-middle">
                      {moment(item.created_at).format("DD/MM/YYYY LT")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        }
        {/* {Pagination_helper(cabinetTotal,consts.CABINAGE_MANAGEMENT, `filterCabinet=${filterCabinet}&filterCounter=${filterCounter}`)} */}
      </Card>
      <Add showAddCabinet={showAddCabinet} clickCloseAddModal={clickCloseAddModal} />
      <Modal show={show} onHide={handleClose} size="xl">

        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end", gap: 10 }}>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button className='buttonRedColor'
              style={{ width: "125px", height: "51px" }} onClick={handleDownloadPdf}>
              Export PDF
            </Button>
          </div>
          <div className="" ref={printRef}>
            <Table className="table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>ເລກຕູ້</th>
                  <th>ຜູ້ດູແລ</th>
                  <th>ປະຈໍາເຄົາເຕີ</th>
                  <th>ວັນທີສ້າງ</th>
                </tr>
              </thead>
              <tbody>
                {cabinetsData?.data?.map((item, index) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() =>
                      History.push(
                        `${consts.CABINAGE_MANAGEMENT}/detail/${item.id}/limit/50/skip/1`,
                        item
                      )
                    }
                  >
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle">{item.cabinet_name}</td>
                    <td className="align-middle">
                      {item?.cabinet_owners?.[0].first_name}
                    </td>
                    <td className="align-middle">{item.choose_counter}</td>
                    <td className="align-middle">
                      {moment(item.created_at).format("DD/MM/YYYY LT")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>

      </Modal>
    </div>
  );
}
