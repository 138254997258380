import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import styled from "styled-components"
// ------------------------------------------- //
export default function ExcelButton({data}) {
  return (
    <CustomButton>
      <FontAwesomeIcon icon={faFileExcel} /> ດາວໂຫຼດ Excel
    </CustomButton>
  );
}

const CustomButton = styled.button`
  background-color: #f6f6f7;
  border: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1);
  &:hover {
    background: #ededed;
  }
`;
