import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Consts from "../../../consts";

export default function NavBar() {
  const History = useHistory();
  const Location = useLocation();

  const _historyPush = (item) => {
    History.push(item);
  };
  return (
    <div>
      <ul class="nav nav-tabs">
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY)}
        >
          <a
            class="nav-link"
            style={{
              color: Location?.pathname === Consts.HISTORY_BUY ? "#2E72D2" : "",
            }}
          >
            ລາຍການສັ່ງຊື້ຄໍາ
          </a>
        </li>
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_WAITTING)}
        >
          <a
            class="nav-link"
            style={{
              cursor: "pointer",
              color:
                Location?.pathname === Consts.HISTORY_BUY_WAITTING
                  ? "#2E72D2"
                  : "",
            }}
          >
            ກໍາລັງເຮັດ
          </a>
        </li>
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_SUCCESS)}
        >
          <a
            class="nav-link"
            style={{
              cursor: "pointer",
              color:
                Location?.pathname === Consts.HISTORY_BUY_SUCCESS
                  ? "#2E72D2"
                  : "",
            }}
          >
            ເຮັດສໍາເລັດ
          </a>
        </li>
        {/* <li class="nav-item" style={{backgroundColor:"#EFF2F6"}} onClick={()=>_historyPush(Consts.HISTORY_BUY_SUCCESS)}> */}
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_APPOVER)}
        >
          <a
            class="nav-link"
            style={{
              color:
                Location?.pathname === Consts.HISTORY_BUY_APPOVER
                  ? "#2E72D2"
                  : "",
            }}
          >
            ຮັບຄໍາແລ້ວ
          </a>
        </li>
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.HISTORY_BUY_REJECT)}
        >
          <a
            class="nav-link"
            style={{
              color:
                Location?.pathname === Consts.HISTORY_BUY_REJECT
                  ? "#2E72D2"
                  : "",
            }}
          >
            ປະຫວັດການປະຕິເສດ
          </a>
        </li>
      </ul>
    </div>
  );
}
