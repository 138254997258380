import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import axios from "axios";
import * as _ from "lodash";
/**
 *
 * @Component
 *
 */
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal, Card } from "react-bootstrap";
import PrimaryButton from "../../common/Button/PrimaryButton";
import ModalBootstrap from "../../common/popup/success/ModalBootstrap";
import ModalError from "../../common/popup/fail/ModalError";
/**
 *
 * @Constant
 *
 */
import consts from "../../consts";
import { SERVER_URL } from "../../api";

/**
 *
 * @API
 *
 */
import { addCounter } from "../../api/counter";

/**
 *
 * @Function
 *
 */
import shortUUID from "short-uuid";
import { loadCounterAsync } from "../../redux/counter";
import { getAccessTokkenFromLCS, getReNewToken, getUserDataFromLCStorage } from "../../helpers/user";


export default function CounterAdd({ showCounterAdd, clickCloseCounterAdd }) {
  const History = useHistory();
  const dispatch = useDispatch();
  const [fail, setFail] = useState(false);
  const { branch_id } = getUserDataFromLCStorage();
  const [counterAttendant, setCounterAttendant] = useState([]);
  const [textValidation, setTextValidation] = useState("");
  const [staffData, setStaffData] = useState([]);
  const [success, setSuccess] = useState(false);

  function validate() {
    const errors = {};
    if (!values.counter_name) {
      errors.counter_name = "Required";
    }
    if (!values.max_amount_money) {
      errors.max_amount_money = "Required";
    }
    if (!values.min_amount_money) {
      errors.min_amount_money = "Required";
    }
    if (!values.counter_type) {
      errors.counter_type = "Required";
    }
    return errors;
  }
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    resetForm,
  } = useFormik({
    initialValues: {
      counter_name: "",
      max_amount_money: 0,
      min_amount_money: 0,
      counter_type: "",
    },
    validate,
    onSubmit: (values) => {
      const data = {
        ...values,
        counter_owners: counterAttendant?.map((e) => ({
          id: e.userId,
          first_name: e.first_name,
          last_name: e.last_name,
        })),
      };
      if (counterAttendant.length < 1) {
        setTextValidation("ກະລຸນາເພີ່ມຜູ້ດູແລຕູ້ກ່ອນ!")
      } else {
        setTextValidation("")
        addCounter(data).then((e) => {
          clickCloseCounterAdd();
          if (e.error === false) {
            setSuccess(true)
            setTimeout(() => {
              resetForm({ values: "" });
              dispatch(loadCounterAsync());
              History.push(`${consts.COUNTER_MANAGEMENT +'/limit/40/skip/1'}`);
            }, 3000);
          } else setFail(true);
        });
      }
    },
  });
  useEffect(() => {
    getUserRoleCashier();
  }, []);


  const getUserRoleCashier = async () => {
    let _token = getAccessTokkenFromLCS();
    try {
      if (getUserDataFromLCStorage()?.branch_id) {
        const data = await axios.get(
          `${SERVER_URL}/user-service/api/v1/user?role=CASHIER&branch_id=${branch_id.toString()}&is_delete=NO`,

          {
            headers: {
              Authorization: _token,
            },
          }
        );
        if (data.status === 200) {

          setStaffData(data?.data?.data);
        }
      } else return;
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, getUserRoleCashier());
      }
    }
  };


  return (
    <>
      <Modal show={showCounterAdd} onHide={clickCloseCounterAdd} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ເພີ່ມເຄົາເຕີ</Modal.Title>
        </Modal.Header>
        <Card style={{ padding: "20px 20px" }}>
          <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3">
              <Form.Label>
                ເລກເຄົາເຕີ<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control type="text" placeholder="001" name="counter_name" value={values.counter_name} onChange={handleChange} onBlur={handleBlur} required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                ວົງເງິນສູງສຸດ<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control type="number" min="0" placeholder="12,000,000" name="max_amount_money" value={values.max_amount_money < 0 ? 0 : values.max_amount_money} onChange={handleChange} onBlur={handleBlur} required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                ວົງເງິນຕໍ່າສຸດ<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control type="number" placeholder="0" name="min_amount_money" value={values.min_amount_money < 0 ? 0 : values.min_amount_money} onChange={handleChange} onBlur={handleBlur} required />
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label>
                ປະເພດເຄົາເຕີ<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select required value={values.counter_type} onChange={handleChange} onBlur={handleBlur} name="counter_type">
                <option value="">ເລືອກປະເພດ</option>
                <option value={"PAY_MONEY"}>ຈ່າຍເງິນ</option>
                <option value={"GET_MONEY"}>ຮັບເງິນ</option>
              </Form.Select>
            </Form.Group>
            <Form.Label>
              ຜູ້ດູແລເຄົາເຕີ<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Group className="mb-5">
              <div
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  border: `1px solid #D1D5DB`,
                }}
              >

                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  {counterAttendant.map((result, index) => (
                    <div
                      className="d-flex align-items-center mb-3 gap-2"
                      key={result.id}
                    >
                      <span
                        style={{
                          width: 30,
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {index + 1}
                      </span>
                      <Form.Select
                        required
                        value={result.name}
                        onChange={(event) => {
                          if(event.target.value !== 'none'){
                          setCounterAttendant((prev) => [
                            ..._.filter(prev, (o) => o.id !== result.id),
                            {
                              id: result.id,
                              first_name: event.target.value,
                              last_name: staffData?.find(
                                (e) => e.first_name === event.target.value
                              ).last_name,
                              userId: staffData?.find(
                                (e) => e.first_name === event.target.value
                              ).id,
                            },
                          ])
                        }}}
                      >
                        <option value="none"> -- ເລືອກ -- </option>
                        {staffData?.map((ele, index) => (
                          <option
                            key={index}
                            value={ele.first_name}
                          >{`${ele.first_name} ${ele.last_name}`}</option>
                        ))}
                      </Form.Select>
                      <span
                        style={{
                          width: 30,
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: 900,
                          color: "#D21C1C",
                        }}
                        onClick={() =>
                          setCounterAttendant((prev) => [
                            ...prev.filter((e, i) => i !== index),
                          ])
                        }
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    </div>
                  ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <input
                    type="button"
                    className="mx-5"
                    style={{
                      color: "#1059BF",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      border: "none",
                    }}
                    onClick={() =>
                      setCounterAttendant((prev) => [
                        ...prev,
                        {
                          id: shortUUID.generate(),
                          first_name: "",
                          last_name: "",
                          userId: "",
                        },
                      ])
                    }
                    value="+ ເພີ່ມ"
                  />
                </div>
              </div>
              <span style={{ color: "red" }}>{textValidation}</span>
            </Form.Group>

            <PrimaryButton type="submit" style={{ width: "100%" }}>
              ບັນທຶກ
            </PrimaryButton>
          </Form>
        </Card>
      </Modal>
      <ModalError title='ເກິດຂໍ້ຜິດຜາດ ກະລຸນາລອງໃໝ່ອີກຄັ້ງ!' show={fail} />
      <ModalBootstrap show={success} title="ເພີ່ມເຄົາເຕີສຳເລັດ" />
    </>
  );
}

