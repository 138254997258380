import { Card, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";
import consts from "../../consts";
import upload from "../../api/upload";
import { updateUsers } from "../../api/users";
import Fail from "../../common/popup/fail/fail";

export default function Edit({ showModalEdit, clickCloseModalEdit, data }) {
  const history = useHistory();
  const [fail, setFail] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false)
  const handleCloseModalEdit = () => clickCloseModalEdit()

  const [picture, setPicture] = useState("");
  const [username, setUsername] = useState(data.username);
  const [imageName, setImageName] = useState(data.image_profile);
  const [firstName, setFirstName] = useState(data.first_name);
  const [lastName, setLastName] = useState(data.last_name);
  const [email, setEmail] = useState(data.email);
  const [gender, setGender] = useState(data.gender);
  const [role, setRole] = useState(data.role);
  const [phone, setPhone] = useState(data.phone);
  const [note, setNote] = useState(data.note);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState([]);

  const form_data = {
    username: username,
    image_profile: imageName,
    first_name: firstName,
    last_name: lastName,
    email: email,
    gender: gender,
    role: role,
    phone: phone,
    note: note,
  };

  useEffect(() => {
    setFormData(data);
    setPicture(consts.S3 + data.image_profile);
    setUsername(data.username);
    setImageName(data.image_profile);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setEmail(data.email);
    setRole(data.role);
    setGender(data.gender);
    setPhone(data.phone);
    setNote(data.note);
  }, [data]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    // setValidated(true);
    updateUsers(data.id, form_data).then((e) => {
      handleCloseModalEdit()
      if (e.error === false) {
        setShow(true);
        setTimeout(() => {
          handleClose(false);
          history.push(consts.STAFF_MANAGEMENT + "/limit/40/skip/1")
        }, 3000);
      } else setFail(false);
    });
  };
  return (
    <>
      <Modal show={showModalEdit} onHide={handleCloseModalEdit} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>ແກ້ໄຂຂໍ້ມູນ</Modal.Title>
        </Modal.Header>
        <Card style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="text-center my-4">
            <div style={{ marginLeft: "-100px" }}>ຮູບພະນັກງານ</div>

            <input
              id="fileEdit"
              style={{ display: "none" }}
              type="file"
              onChange={(e) => {
                upload(e).then((event) => {
                  setPicture(consts.S3 + event.imageName);
                  setImageName(event.imageName);
                });
              }}
            />
            <label htmlFor="fileEdit">
              <div
                style={{
                  padding: "3px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #666",
                  height: "170px",
                  width: "170px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={picture}
                />
              </div>
            </label>
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>ຊື່ຜູ້ໃຊ້</Form.Label>
              <Form.Control
                defaultValue={data.username}
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                placeholder="ກະລຸນາປ້ອນ"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ຊື່</Form.Label>
              <Form.Control
                defaultValue={data.first_name}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                placeholder="ກະລຸນາປ້ອນ"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ນາມສະກຸນ</Form.Label>
              <Form.Control
                defaultValue={data.last_name}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="ກະລຸນາປ້ອນ"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>email</Form.Label>
              <Form.Control
                defaultValue={data.email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="ກະລຸນາປ້ອນ"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ເພດ</Form.Label>
              <Form.Select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                defaultValue={data.gender}
              >
                <option value="">ຕົວເລືອກ</option>
                <option value="female">ຍິງ</option>
                <option value="male">ຊາຍ</option>
                <option value="other">ອື່ນໆ</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ສິດທິນຳໃຊ້ລະບົບ</Form.Label>
              <Form.Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                defaultValue={data.role}
              >
                <option value="">ຕົວເລືອກ</option>
                <option value="STAFF">Staff</option>
                <option value="SELLER">Seller</option>
                <option value="CASHIER">Cashier</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ເບີໂທລະສັບ</Form.Label>
              <Form.Control
                defaultValue={data.phone}
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                placeholder="ກະລຸນາປ້ອນ"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>ໝາຍເຫດ</Form.Label>
              <Form.Control
                defaultValue={data.note}
                onChange={(e) => setNote(e.target.value)}
                as="textarea"
                placeholder="ກະລຸນາປ້ອນ"
              />
            </Form.Group>
            <div className="my-5">
              <button
                type="submit"
                className="w-100 buttonRedColor"
                style={{ height: "49px" }}
              >
                ບັນທຶກການແກ້ໄຂ
              </button>
            </div>
          </Form>
        </Card>

        <div
          style={{ display: fail ? "inline" : "none", width: "100%", height: "100%", backgroundColor: "rgb(0,0,0,0.4)", position: "fixed", top: "0", left: "0" }}>
          <Fail confirm={() => setFail(false)} />
        </div>
      </Modal>

      <Modal show={show} onHide={handleClose} centered>
        <div style={{ textAlign: "center", paddingBottom: 32, paddingTop: 32 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div><FontAwesomeIcon icon={faCheckCircle} size="9x" color='#0ca678' /></div>
          </div>
          <div style={{ marginTop: 18, fontSize: 24, fontWeight: 700 }}>ແກ້ໄຂຂໍ້ມູນສຳເລັດ</div>
        </div>
      </Modal>
    </>
  );
}
