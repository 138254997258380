import axios from "axios";
import { SERVER_URL } from "..";
import { getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

const branch = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(`${SERVER_URL}/branch-service/api/v1/branch`,
    {
      headers: {
        Authorization: _tokken,
      },
    });
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,branch());
    }
  }
};

const addBranch = async (value) => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.post(
      `${SERVER_URL}/branch-service/api/v1/branch`,
      value,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err,addBranch());
    }
  }
};
export { branch, addBranch };
