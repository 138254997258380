import React from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import Consts from "../../consts";
import PaginationHelper from "../../helpers/PaginationHelper";

export default function NavBar() {
  const History = useHistory();
  const Location = useLocation();
  const { _limit, _skip, } = PaginationHelper();

  const _historyPush = (item) => {
    History.push(item);
  };
  return (
    <div>
      <ul class="nav nav-tabs">
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.EXPENSE)}
        >
          <a
            class="nav-link"
            style={{
              color: Location?.pathname === Consts.EXPENSE ? "#2E72D2" : "",
            }}
          >
            ບັນທຶກລາຍຈ່າຍ
          </a>
        </li>
        <li
          class="nav-item"
          style={{ backgroundColor: "#EFF2F6", cursor: "pointer" }}
          onClick={() => _historyPush(Consts.EXPENSE_HISTORY+`/limit/40/skip/1`)}
        >
          <a
            class="nav-link"
            style={{
              cursor: "pointer",
              color:
                Location?.pathname === Consts.EXPENSE_HISTORY+`/limit/${_limit}/skip/${_skip}`
                  ? "#2E72D2"
                  : "",
            }}
          >
            ປະຫວັດລາຍຈ່າຍ
          </a>
        </li>
      </ul>
    </div>
  );
}
