import { createSlice } from "@reduxjs/toolkit";
import { orderProduct } from "../../api/order";

export const order_product = createSlice({
    name: 'order_product',
    initialState: {
        isLoading: true,
        data: [],
    },
    reducers: {
        addOrderProduct: (state, action) => {
            state.data = [
                ...new Set([...action.payload, ...state.data].map(JSON.stringify)),
            ].map(JSON.parse); // ຂໍ້ມູນບໍ່ຊ້ຳ
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
})

export const { setLoading, addOrderProduct } = order_product.actions;

export const loadOrderProductAsync = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await orderProduct();
    dispatch(addOrderProduct(data));
  } catch {
    console.error("load orderProduct");
  }
  dispatch(setLoading(false));
};

export const selectOrderProduct = (state) => state.order_product;

export default order_product.reducer;