import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
// ----------------------------- //
const CustomSearch = ({ placeholder,value, ...other}) => {
  const [val,setVal] = useState('')
  return (
    <div
      style={{
        borderRadius: 100,
        width: "100%",
        border: "1px solid #D1D5DB",
        overflow: "hidden",
        position: "relative",
      }}>
      <input
        type='text'
        placeholder={placeholder}
        onChange={(e)=>{
          setVal(e.target.value)
          value(e.target.value)
        }}
        style={{
          border: "none",
          width: "100%",
          padding: 10,
          backgroundColor: "#F6F6F7",
          outline: "none",
          paddingLeft: 30,
        }}
        {...other}
      />
      <div
        style={{
          position: "absolute",
          right: 15,
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "white",
          width: 30,
          height: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
        }}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          style={{ width: 14, height: 14 }}
        />
      </div>
    </div>
  );
};
export default CustomSearch;
