import axios from "axios";
import { SERVER_URL } from "..";
import { getUserDataFromLCStorage } from "../../helpers/user";
import { getAccessTokkenFromLCS,getReNewToken } from "../../helpers/user";

// ------------------------------------- //
const documents = async () => {
let _tokken = getAccessTokkenFromLCS()
  try {
    const data = await axios.get(
      `${SERVER_URL}/website-service/api/v1/document-unseen?is_delete=NO`,
      {
        headers: {
          Authorization: _tokken,
        },
      }
    );
    if (data.status >= 300) {
      return false;
    }
    return data?.data;
  } catch (err) {
    if (err?.response?.data?.msg === "Token is expired") {
      getReNewToken(err, documents());
    }
  }
};

const documentsSeenPersonal = async () => {
  let _tokken = getAccessTokkenFromLCS()
    try {
      const data = await axios.get(
        `${SERVER_URL}/website-service/api/v1/document-seen-personal?is_delete=NO`,
        {
          headers: {
            Authorization: _tokken,
          },
        }
      );
      if (data.status >= 300) {
        return false;
      }
      return data?.data;
    } catch (err) {
      if (err?.response?.data?.msg === "Token is expired") {
        getReNewToken(err, documentsSeenPersonal());
      }
    }
  };

export { documents,documentsSeenPersonal};
