import React from "react";

export default function Status(props) {
  const { status } = props;

  return (
    <div
      style={{
        padding: "2px 10px",
        border: `1px solid ${status ? "#2e72d2" : "#d82c0d"}`,
        color: status ? "#2e72d2" : "#d82c0d",
        borderRadius: 4,
        textAlign: "center",
        maxWidth: 90,
        minWidth: 90,
      }}>
      {status ? "ເປີດໃຊ້ງານ" : "ປິດໃຊ້ງານ"}
    </div>
  );
}
